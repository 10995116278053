import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, SvgIcon, Switch, TextField, ThemeProvider, createTheme } from '@mui/material';
import { BusinessCenter, Close, CopyAll, Label, MailOutlineOutlined, PasswordOutlined, Person } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useState } from 'react';
import { createNewUser, resetCreateUserDetails, resetEditUserID, setCreateUserCompanies, setCreateUserCpCompany, setCreateUserEmail, setCreateUserFirstName, setCreateUserLastName, setCreateUserPassword, setCreateUserRole, setCreateUserStatus, setEditUserCPOnlyCompany, setEditUserEmail, setEditUserName, setEditUserRole, setEditUserStatus, updaateUserLevel, updateUser, updateUserAccount } from '../../../actions/cpManagement';
import styled from '@emotion/styled';
import AssignEventsCompanies from '../approved/AssignCompanies';
import AssignEvents from '../approved/AssignEvents';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { useEffect } from 'react';

const theme = createTheme({
    typography: {
      event: {
        fontSize: '1rem',
        textAlign:'right',
        width:'10vw',
        // background:'blue'
      },
      // Disable h3 variant
      h3: undefined,
    },
  });

 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 94,
    p: "0 2rem 2rem 2rem",
    borderRadius: '1rem'
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 52,
    height: 36,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#7CB332',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'gray'
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 32,
        height: 32,
    },
    '& .MuiSwitch-track': {
        borderRadius: 36 / 2,
        backgroundColor: '#39393D',
        opacity: 1
    },
}));

export default function AddUser({ handleClose, open, user }) {

    const access_token = useSelector((state) => state.auth.token)
    const cpUserCreate = useSelector((state) => state.cpManagementReducer.cpUserCreate)

    const dispatch = useDispatch()

    // const [defaultCompanies, setDeaultCompanies] = useState([])
    // const [defaultEvents, setDefaultEvents] = useState([])

    const [status, setStatus] = React.useState('');


    const [userFields, setUserFields] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        company: "",
        clientPortalOnlyCompany: ""
    })

    const [errors, setErrors] = useState({
        company:false,
        events:false
    })

    useEffect(()=>{
        dispatch(setCreateUserRole(1))
                dispatch(setCreateUserStatus(false))
                dispatch(setCreateUserCompanies([]))



    },[])

    const handleChangeForm = (key, event) => {

        if(key === 'firstname') {
            dispatch(setCreateUserFirstName(event.target.value))
        }
        if(key === 'lastname') {
            dispatch(setCreateUserLastName(event.target.value))
        }
        if(key === 'email') {
            dispatch(setCreateUserEmail(event.target.value))
        }
        if(key === 'company') {
            dispatch(setCreateUserCpCompany(event.target.value))
        }
        if(key === 'password') {
            dispatch(setCreateUserPassword(event.target.value))
        }

        setUserFields({
            ...userFields,
            [key]: event.target.value
        })
    }


    const handleChange = (event) => {
        setStatus(event.target.value);
        dispatch(setCreateUserRole(event.target.value))
    };

    const [userStatus, setUserStatus] = useState(true)
    const handleChangeStatus = (event) => {
        setUserStatus(event.target.checked)
        dispatch(setCreateUserStatus(event.target.checked))
        
    }







    const updateLevel = (row, level) => {
        dispatch(updaateUserLevel(row.id, level))

    }


    const handleCloseWithReset = () => {
        console.log('closing----------------', 'handleRes')
        dispatch(resetCreateUserDetails())
        setUserFields({})
        handleClose()
        setErrors({
            company:false,
            events:false,
                })
        // setDeaultCompanies([])
        // setDefaultEvents([])

    }


    const handleSubmit = (e) => {

        if (cpUserCreate.companies.length === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                company: true
            }));
        }

        if (cpUserCreate.events.length === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                events: true
            }));
        }
        if (cpUserCreate.companies.length > 0 && cpUserCreate.events.length > 0) {
            e.preventDefault();
            console.log('all user', cpUserCreate)
            dispatch(createNewUser(cpUserCreate))
            handleCloseWithReset()
        }
   
    }


    return (
        <div>
                <ThemeProvider theme={theme}>

            <Modal
                open={open}
                onClose={handleCloseWithReset}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: '0.2rem', background: '', p: 2 }}>
                        <Typography sx={{ fontSize: '2rem', color: 'black' }}>
                            Create User
                        </Typography>
                        <IconButton sx={{ color: 'black', background: '#ecf0f1' }} onClick={handleCloseWithReset}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Divider />


                    <Box sx={{ border: '', m: '1rem', p: '1rem 1rem 0 0' }} >
                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', gap: '1rem' }}>

                            <Typography sx={{ fontSize: '1.1rem' }}>
                                Active
                            </Typography>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <IOSSwitch checked={cpUserCreate.status} onChange={handleChangeStatus} name="status" />
                                    }
                                //   label="Gilad Gray"
                                />
                            </FormGroup>
                        </Stack>


                        <ValidatorForm
                            onSubmit={handleSubmit}
                            onError={(errors) => console.log(errors)}
                        >
                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>

                                    
                                    <Typography variant='event'>
                                        First Name
                                    </Typography>
                                    
                                    <TextValidator
                                        style={{ fontSize: '1rem', width: '40vw', background: '#ecf0f1' }}
                                        placeholder='First Name'
                                        name='firstname'
                                        value={userFields.firstname}
                                        onChange={(e) => handleChangeForm('firstname', e)}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <SvgIcon>
                                                        <Person />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Stack>

                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>


                                <Typography variant='event'>
                                        Last Name
                                    </Typography>
                                    <TextValidator
                                        style={{ fontSize: '2rem', width: '40vw', background: '#ecf0f1' }}
                                        placeholder='Last Name'
                                        name='lastname'
                                        value={userFields.lastname}
                                        onChange={(e) => handleChangeForm('lastname', e)}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <SvgIcon>
                                                        <Person />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Stack>


                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>


                                <Typography variant='event'>
                                        Email
                                    </Typography>
                                    <TextValidator
                                        style={{ fontSize: '2rem', width: '40vw', background: '#ecf0f1' }}

                                        placeholder='Email'
                                        name='email'
                                        value={userFields.email}
                                        onChange={(e) => handleChangeForm('email', e)}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <MailOutlineOutlined
                                                        sx={{ color: 'var(--clr-gray40)' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Stack>


                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>


                                <Typography variant='event'>
                                        Password
                                    </Typography>
                                    <TextValidator
                                        style={{ fontSize: '2rem', width: '40vw', background: '#ecf0f1' }}

                                        placeholder='Password'
                                        name='password'
                                        value={userFields.password}
                                        onChange={(e) => handleChangeForm('password', e)}
                                        validators={['required', "minStringLength: 6"]}
                                        errorMessages={[
                                            'this field is required',
                                            'Weak password, Required minimum 6 ',
                                        ]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <PasswordOutlined
                                                        sx={{ color: 'var(--clr-gray40)' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Stack>








                            {/* <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>
                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    Name
                                </Typography>
                            </Stack>

                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>
                                <TextField
                                    sx={{ background: '#ecf0f1' }}
                                    fullWidth id="outlined-basic" variant="outlined" value={userFields.name} onChange={(event) => handleChangeForm('name', event)} />
                            </Stack>




                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    Email
                                </Typography>

                            </Stack>

                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>
                                <TextField
                                    sx={{ background: '#ecf0f1' }}
                                    type='email' fullWidth id="outlined-basic" variant="outlined" value={userFields.email} onChange={(event) => handleChangeForm('email', event)} />
                            </Stack> */}


                            {/* <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>
                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    Company
                                </Typography>
                            </Stack>


                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>
                                <TextField
                                    sx={{ background: '#ecf0f1' }}
                                    fullWidth id="outlined-basic" variant="outlined" value={userFields.company} onChange={(event) => handleChangeForm('company', event)} />
                            </Stack> */}


                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>

                            
                                <Typography variant='event'>
                                        Company
                 

                                    </Typography>



                                    <TextValidator
                                        style={{ fontSize: '2rem', width: '40vw', background: '#ecf0f1' }}
                                        placeholder='Company'
                                        name='company'
                                        value={userFields.company}
                                        onChange={(e) => handleChangeForm('company', e)}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <SvgIcon>
                                                        <BusinessCenter />
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                </Box>


                            </Stack>
                                

                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>

                                <Typography variant='event'>
                                        User Level
                                    </Typography>

                                    <FormControl sx={{ width: '40vw' }}>
                                        <InputLabel id="demo-simple-select-label">level</InputLabel>
                                        <Select
                                            sx={{ background: '#ecf0f1' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cpUserCreate.role}
                                            label="Select user level"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>Presenter</MenuItem>
                                            <MenuItem value={2}>Moderator</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Stack>




                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>

                                <Typography variant='event'>
                                    Assign Company
                                    {
                                         errors.company&&   <span><p style={{color:'tomato'}}>*Please add company</p></span>

                                        }
                                    </Typography>
                                    <Box sx={{width:'41vw'}}>
                                    <AssignEventsCompanies actionType="add" />

                                    </Box>

                                </Box>
                            </Stack>

                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ display: 'flex', direction: 'column', gap: '1rem', alignItems: 'center' }}>

                                <Typography variant='event'>
                                    Assign Events
                                    {
                                         errors.events&&   <span><p style={{color:'tomato'}}>*Please add event</p></span>

                                        }
                                    </Typography>
                                    <Box sx={{width:'41vw'}}>
                                    <AssignEvents actionType="add" />

                                    </Box>

                                </Box>
                            </Stack>

                            {/* <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    Assign Company
                                </Typography>

                            </Stack> */}



                            {/* <AssignEventsCompanies actionType="add" /> */}



{/* 
                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    Assign Event
                                </Typography>

                            </Stack> */}


                            {/* <AssignEvents actionType="add" /> */}



                            {/* 
                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                                <Typography sx={{ fontSize: '1.2rem' }}>
                                    User Level
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">level</InputLabel>
                                    <Select
                                        sx={{ background: '#ecf0f1' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Select user level"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>Presenter</MenuItem>
                                        <MenuItem value={2}>Moderator</MenuItem>

                                    </Select>
                                </FormControl>

                            </Stack> */}








                            <Stack direction={'row'} m={2} sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>

                                <Button variant='contained'
                                    onClick={handleCloseWithReset}
                                    sx={{
                                        background: 'white',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#aed581'
                                        }

                                    }}> Cancel </Button>
                                <Button variant='contained' sx={{
                                    background: '#7CB332', color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#aed581'
                                    }
                                }} type='submit'  > Create </Button>


                            </Stack>
                        </ValidatorForm>
                    </Box>


                </Box>
            </Modal>

            </ThemeProvider>
        </div>
    );
}