import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX
import { clearRegister } from '../../actions/auth';

// MUI
import MuiLink from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Avatar from '@mui/material/Avatar';

// COMPONENTS
import BRRSocialFollow from '../UI/inputs/BRRSocialFollow';

// IMG
import logo from '../../static/img/brr-logo.png';

import './ThankYou.css';
import { Pending, PendingActions } from '@mui/icons-material';

const ThankYou = ({ auth: { registration }, clearRegister }) => {
  return (
    <>


      <div className='thank-you_container'>
        <div className='thank-you_header'>
          <Link to='/login'>
            <Avatar
              alt='BRR Logo'
              variant='square'
              src={logo}
              sx={{ height: '100%', width: '60px' }}
            />
          </Link>
        </div>
        <div className='thank-you_content'>
          <PendingActions
            sx={{
              fontSize: 'clamp(6rem, 10vw, 12rem)',
              color: 'var(--clr-success)',
            }}
          />
          <Typography variant='h3' sx={{ fontWeight: 700, marginBottom: 4 }}>
           Pending Approval
          </Typography>
          {/* <Typography variant='h4'>We just received your message.</Typography> */}
          <Typography variant='h5' align='center'>
          Your request is being processed. 

          </Typography>
          <Typography variant='h5' align='center'>
          You will receive email confirmation upon completion.

          </Typography>
          <div className='thank-you_content_btns-container'>
            <Button variant='outlined'>
              <MuiLink
                href='https://www.brrmedia.co.uk/about-us/'
                target='_blank'
                rel='noopener'
              >
                Visit our website
              </MuiLink>
            </Button>
            {/* <Button variant='outlined'>
              <MuiLink
                href='https://www.brrmedia.co.uk/investor-content'
                target='_blank'
                rel='noopener'
              >
                Content
              </MuiLink>
            </Button> */}
          </div>
          {/* <Typography variant='h5' sx={{ marginTop: 6 }}>
            Talk to you soon
          </Typography> */}
        </div>
        {/* <div className='thank-you_footer'>
          <BRRSocialFollow link='https://www.linkedin.com/company/brr-media-uk/' />
          <BRRSocialFollow link='https://twitter.com/brr_mediauk' />
          <BRRSocialFollow link='https://www.facebook.com/brrmediauk/' />
        </div> */}
      </div>
    </>
  );
};

ThankYou.propTypes = {
  auth: PropTypes.object.isRequired,
  clearRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { clearRegister })(ThankYou);
