import axios from "axios";

export async function createPassivePresenter(data) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/account/register/?token=${process.env.REACT_APP_API_TOKEN}`, data)
        return  response.data;
    } catch (e) {
        console.log(e)
    }
  
}

export async function updateToClientPortalUser(id) {
    try {
        let data = {
            token: process.env.REACT_APP_API_TOKEN,
            data: { user: { _id: id, clientPortalOnly: true } }
        }
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/account/updateClientPortal/?token=${process.env.REACT_APP_API_TOKEN}`, data)
        return  response.data;
    } catch (e) {
        console.log(e)
    }
  
}



export const shortenJwtlinlk = async (link, token)=>{
    try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/account/generatePresenterLink/?token=${process.env.REACT_APP_API_TOKEN}`,{
            originalUrl:link,
            token:token
          }
        );

        console.log('Short Link', response.data.shortLink)
        return {
            link:response.data.shortLink,
            createdBy:response.data.createdBy,
            createdAt:response.data.createdAt,
            expiresIn:response.data.expiresIn


        }
        // setShortenedLink(response.data.shortLink);
      } catch (e) {
        console.log(e);
        
      }
    }


    // export const getPresentLinkByEventID = async (email, eventID)=>{
    //   try {
    //       const response = await axios.post(
    //         `${process.env.REACT_APP_API_BASE_URL}/account/generatePresenterLink/?token=${process.env.REACT_APP_API_TOKEN}`,{
    //           originalUrl:link,
    //           token:token
    //         }
    //       );
  
    //       console.log('Short Link', response.data.shortLink)
    //       return response.data.shortLink
    //       // setShortenedLink(response.data.shortLink);
    //     } catch (e) {
    //       console.log(e);
          
    //     }
    //   }