import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { useMobileOrientation } from 'react-device-detect';

// MUI
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';

// IMG
import logo from '../../static/img/brr-logo.png';

const AuthHeader = () => {
  const { isPortrait } = useMobileOrientation();
  const url = useLocation();
  const pathName = url.pathname;
  let isLogin = true;

  if (!pathName.includes('login')) isLogin = false;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isPortrait ? 'space-between' : 'flex-end',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '4% 5% 0',
      }}
    >
      {isPortrait && !isMobileOnly && (
        <CardMedia
          component='img'
          height='60'
          image={logo}
          alt='BRR Logo'
          sx={{ objectFit: 'contain', width: 'auto' }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '2rem',
          marginLeft: 'auto',
          marginBottom: isMobileOnly ? '2.5rem' : '0px',
        }}
      >
        <Typography variant='body1'>
          {isLogin ? 'Don’t have an account?' : 'Already have an account?'}
        </Typography>
        <Button
          variant='contained'
          disableElevation
          sx={[
            {
              padding: 0,
              bgcolor: 'var(--clr-w)',
              '&:hover': {
                bgcolor: 'var(--clr-gray60)',
              },
              '& a': {
                color: 'var(--clr-gray80)',
                padding: '0.625rem 1.25rem',
                textTransform: 'capitalize',
              },
              '& a:hover': {
                color: 'var(--clr-gray20)',
              },
            },
          ]}
        >
          <Link to={isLogin ? '/register' : '/login'}>
            {isLogin ? 'Register' : 'Sign in'}
          </Link>
        </Button>
      </Box>
    </Box>
  );
};

export default AuthHeader;
