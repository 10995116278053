import React from 'react';

// MUI
import { styled } from '@mui/system';
// import SwitchUnstyled, {
//   switchUnstyledClasses,
// } from '@mui/base/SwitchUnstyled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Switch, switchClasses } from '@mui/material';

const blue = {
  500: '#007FFF',
};

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
};

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${grey[400]};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 22px;
      top: 3px;
      background-color: #fff;
    }

    .${switchClasses.track} {
      background: ${blue[500]};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

const CookiesSwitch = () => {
  const label = {
    componentsProps: { input: { 'aria-label': 'Cookies switch' } },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant='body2' sx={{ color: 'var(--clr-gray40)' }}>
        Functional
      </Typography>
      {/* <SwitchUnstyled component={Root} {...label} defaultChecked disabled /> */}
      <Switch  component={Root} {...label} defaultChecked disabled/>
    </Box>
  );
};

export default CookiesSwitch;
