export const SET_LOADING = 'SET_LOADING';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERT = 'REMOVE_ALL_ALERT';

export const SET_ACTIVE_ICON = 'SET_ACTIVE_ICON';
export const REMOVE_ACTIVE_ICON = 'REMOVE_ACTIVE_ICON';


export const RESET_LOADING = 'RESET_LOADING' 