import { AddCircle, Circle, Delete, Edit, Replay } from "@mui/icons-material";
import { Badge, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';

import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import React from "react";
import ApprovedUsers from "./approved";
import Pending from "./pending";
import { connect, useSelector } from "react-redux";
import AddUser from "./add/AddUser";
const useStyles = makeStyles({
    dataGrid: {
        //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        borderRadius: 3,
        color: "black",
        //   height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        width: "100%"
    }
});

const CPManagement = () => {
    const cpUsers = useSelector((state) => state.cpManagementReducer.cpUsers)
    const pendingCpUsers = useSelector((state) => state.cpManagementReducer.pendingCpUsers)

    const apiRef = useGridApiRef();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = (data) => {
        // console.log('data', data)
        // setUser(data)
        // dispatch(setEditUserID(data.id))
        setOpen(true);

    }
    const handleClose = () => setOpen(false);
    return (
        <Box
            sx={{
                p: '0.5rem 1rem 0rem 1rem',
                backgroundColor: 'var(--clr-w)',
                // background:'blue',
                // height:'fi',
                borderRadius: '10px',
                boxShadow: 2,
            }}
        >


  <AddUser handleClose={handleClose} open={open} />

            {/* <Typography sx={{ fontSize: '1.5rem', mb: '1rem' }}>
                Client Portal User Management
            </Typography> */}
            <Box sx={{display:'flex',gap:'1rem',justifyContent:'end'}}>
            {/* <Button variant="contained" sx={{mb:'0.4rem'}}>
                Create
            </Button> */}
            {/* <IconButton  onClick={handleOpen}> */}
                {/* Create User */}
            {/* <AddCircle sx={{fontSize:'2rem'}} /> */}
            {/* </IconButton> */}

            <Button variant="contained" onClick={handleOpen} startIcon={<AddCircle/>} 
            sx={{background:'#7CB332', 
            m:'0.4rem',
            '&:hover': {
                backgroundColor: '#6ab04c', color:''}
            }}>

            Create User
            </Button>

            </Box>

            <Tabs aria-label="Basic tabs" defaultValue={0} sx={{ borderRadius: 'lg' }}>
                <TabList>
                    <Tab>
                        <Typography>Approved Users</Typography>
                        {/* <Badge badgeContent={cpUsers.length} color="primary" sx={{ml:3}}/> */}
          
                    </Tab>
                    <Tab>

                        <Typography>Pending Approval</Typography>
                        <Badge badgeContent={pendingCpUsers.length} color="secondary" sx={{ ml: 2, "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#7CB332"
    } }} />
                    </Tab>
                </TabList>
                <TabPanel value={0} sx={{ p: 2 }}>

                    <ApprovedUsers />


                </TabPanel>

                <TabPanel value={1} sx={{ p: 2 }}>
                    <Pending />
                    
                </TabPanel>

            </Tabs>






        </Box>
    )
}

export default connect()(CPManagement)