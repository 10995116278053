import { useState, useEffect } from 'react';

export default function useEventTitleFromUrl(url, eventsArray) {
  const [isEditUrl, setIsEditUrl] = useState(false);
  const [eventTitle, setEventTitle] = useState('');

  useEffect(() => {
    const regex = /\d/;

    if (eventsArray === null || eventsArray === undefined) return;
    if (eventsArray) {
      if (!regex.test(url.pathname)) {
        setIsEditUrl(false);
      } else {
        const urlID = url.pathname.split('/').pop();
        const event = eventsArray.filter((event) => event._id === urlID);

        setIsEditUrl(true);
        setEventTitle(event[0] ? event[0].title : 'undefined');
      }
    }
  }, [url, eventsArray]);

  return {
    isEditUrl,
    eventTitle,
  };
}
