import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  path,
  auth: { isAuthenticated, loading, user },
}) => {
  console.log('Private component', path)

  //Check if the private component is CpManagement




  if (isAuthenticated) {
    // console.log('<<<<<<<< private route 1>>>>>>>>');
    if(path === 'root') {
      //check user level
      if(user.level === 6) {
        return <Component/>
      } else {
        return <Navigate to='/notAllowed' />;

      }
    }
    return <Component />;
  } else {
    // console.log('<<<<<<<< private route 2>>>>>>>>');
    return <Navigate to='/login' />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
