import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// UTILS
import { fullDate } from '../../utils/convertDateAndTime';
import {
  formatEventStatus,
  colorEventStatus,
} from '../../utils/formatEventStatus';

// MUI
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { lightGreen, lightBlue } from '@mui/material/colors';

// COMPONENTS
import Spinner from '../UI/Spinner';
import ViewEventBtn from '../UI/inputs/ViewEventBtn';
import EditEventBtn from '../UI/inputs/EditEventBtn';

const clrBlue400 = lightBlue[400];
const clrGreen300 = lightGreen[300];
const clrGreen600 = lightGreen[600];

const SearchedEvent = ({ searchedEvent }) => {
  const [isEventReady, setIsEventReady] = useState(false);
  const [eventItems, setEventItems] = useState([]);
  const [notFound, setNotFound] = useState(null);

  useEffect(() => {
    if (searchedEvent !== null) {
      if (searchedEvent.items.length > 0) {
        setEventItems(searchedEvent.items);
        setNotFound(false);
      } else {
        setNotFound(true);
      }
      setIsEventReady(true);
    }
  }, [searchedEvent]);

  return (
    <TableBody>
      {!isEventReady ? (
        <TableRow>
          <TableCell>
            <Spinner />
          </TableCell>
        </TableRow>
      ) : (
        eventItems.map((item) => (
          <TableRow
            hover
            key={item._id}
            sx={{
              '&:last-child td': { borderBottom: '0px' },
            }}
          >
            <TableCell align='left' sx={{ maxWidth: '380px' }}>
              <Typography>{item.title}</Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography>{item.companies[0].name}</Typography>
            </TableCell>
            <TableCell align='left'>
              {item.presenters.map((presenter) => (
                <p key={presenter._id}>{presenter.name}</p>
              ))}
            </TableCell>
            <TableCell align='left'>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography>{fullDate(item.releaseTime)}</Typography>
                <Typography>{item.releaseTimezone}</Typography>
                <Badge
                  color={colorEventStatus(item.status)}
                  badgeContent={' '}
                  variant='dot'
                  sx={{
                    left: '0px',
                    transform: 'translateX(8px)',
                    '& span': {
                      transform: 'translateY(25%)',
                      right: '100%',
                      top: '25%',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      color: 'var(--clr-gray40)',
                      transform: 'translateX(8px)',
                    }}
                  >
                    {formatEventStatus(item.status)}
                  </Typography>
                </Badge>
              </Box>
            </TableCell>
            <TableCell align='left'>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {item.assetType}
                </Typography>
                <Typography
                // sx={{ fontWeight: item.isLive ? '700' : undefined }}
                >
                  {item.isLive ? 'Webcast Live' : 'Brightcove'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell align='center' sx={{ width: '250px' }}>
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  maxHeight: '100px',
                  justifyContent: 'center',
                }}
              >
                <EditEventBtn eventId={item._id} backgroundClr={clrBlue400} />
                <Stack direction='column' spacing={1}>
                  <ViewEventBtn
                    icon='view'
                    eventId={item._id}
                    preview={false}
                    backgroundClr={clrGreen600}
                  />
                  <ViewEventBtn
                    icon='preview'
                    eventId={item._id}
                    preview={true}
                    backgroundClr={clrGreen300}
                  />
                </Stack>
              </Stack>
            </TableCell>
          </TableRow>
        ))
      )}
      {notFound && (
        <TableRow>
          <TableCell
            sx={{
              padding: '2rem',
            }}
          >
            <Typography variant='h5'>Event not found</Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

SearchedEvent.propTypes = {
  searchedEvent: PropTypes.object,
};

const mapStateToProps = (state) => ({
  searchedEvent: state.event.searchedEvent,
});

export default connect(mapStateToProps)(SearchedEvent);
