import { Close, Message, Send } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { setChats } from '../../../actions/chat';
import submitLogs from '../../../config/AWSGlobalLog';

const ChatMessages = ({close}) => {

  const [message, setMessage] = useState('')

  // const [chats, setChats] = useState([
  //   {
  //     roomId: '64ba8d68a2fe714416d190f4',
  //     sender: '64ee381ac6e9d7476c27aedb',
  //     text: 'Message 1',
  //     timestamp: '2023-08-30T11:16:56.768Z'
  //   },
  //   {
  //     roomId: '64ba8d68a2fe714416d190f4',
  //     sender: '64ee3d1ac6e9d7476c27aedb',
  //     text: 'Message 2',
  //     timestamp: '2023-08-30T11:16:56.768Z'
  //   }
  // ])

  const userID = useSelector((state) => state.auth.loggedInUserID);
  const eventID = useSelector((state)=> state.event.event.items[0]._id)
  const chats = useSelector((state)=> state.chatReducer.chats)
  const messagesByRoomId = useSelector((state)=> state.chatReducer.messagesByRoomId)
  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const userFirstName = useSelector((state) => state.auth.user.name.first);
  const userLastName = useSelector((state) => state.auth.user.name.last);
  const userName = `${userFirstName} ${userLastName}`;
  const dispatch = useDispatch()

  const handleSendMessage =(e)=>{
    e.preventDefault()
      console.log('Your message', message)
      setMessage('')
      try {
        socket_cp.emit('newMessage', {
          roomId:eventID,
          sender:userID,
          text:message,
          senderName:userName,
          at:new Date()
        })


            
        submitLogs(
          JSON.stringify({
              status: 'SUCCESS',
              data:{
                roomId:eventID,
                sender:userID,
                text:message,
                senderName:userName,
                at:new Date()
              },
              user:{
                userID:userID,
                name:userFirstName + ' ' + userLastName
              },
              message: 'SOCKET.IO - message sent'
          }) )


      } catch (error) {
        
        submitLogs(
          JSON.stringify({
              status: 'Chat message socket-io error',
              data:{
                roomId:eventID,
                sender:userID,
                text:message,
                senderName:userName,
                at:new Date()
              },
              user:{
                userID:userID,
                name:userFirstName + ' ' + userLastName
              },
              message: 'SOCKET.IO Chat message socket-io error',
              error: error
          }) )

          console.log('Chat message socket-io error', error)
      }
   


  }
  const chatContainerRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom when a new message is added and the container's height exceeds the maximum
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chats]);

  // console.log('messagesByRoomId', messagesByRoomId[eventID])


  useEffect(()=>{
    console.log('Socket', socket_cp, 'chats', chats)






    // socket.emit('createRoom', eventID);

    return ()=>{
        // socket.off('message')
    }     


  },[])

  return (
    <Box sx={{width: isMobile ?'50vw': '25vw', height:'auto', display:'flex', flexDirection:'column', justifyContent:'space-around'}}> 
    <form onSubmit={handleSendMessage}>

    <div style={{display:'flex', justifyContent:'space-between',background:'rgba(92, 137, 37,1)'}}>

    <Typography sx={{background:'rgba(92, 137, 37,0.9)', p:'0.9rem', textAlign:'center', fontSize:'1.2rem',color:'white'}}>
    Moderator Group Chat
  </Typography>
  
    <IconButton onClick={close}>
    <Close sx={{color:'white'}}/>
    </IconButton>

  </div>

  <Divider/>
    <Box style={{ padding: '10px', width: '100%', margin:'auto', marginBottom:'0.2rem' }}>
<div style={{ overflowY: 'scroll', height:'360px' }} ref={chatContainerRef}>
     
    {
    messagesByRoomId[eventID] !== undefined &&   messagesByRoomId[eventID].length >0  ? messagesByRoomId[eventID].map((each)=>(

     each.sender === userID  ?  
     <Box sx={{display:'flex',justifyContent:'end',  m:'0.4rem'}}>

          <div style={{display:'flex', flexDirection:'column'}}>
          <Typography variant='p' sx={{fontSize:'0.7rem'}}>
        {  new Date(each.timestamp).toLocaleDateString()  }   {  new Date(each.timestamp).toLocaleTimeString()  } , you
        </Typography>
       <Typography sx={{background:'rgba(92, 137, 37,0.9)', borderRadius:'1rem', p:'0.6rem',  width: 'fit-content', color:'white', textAlign:'right' }}>
          {each.text}
        </Typography> 
          </div>



        </Box>
        
        : 
        <Box sx={{display:'flex', flexDirection:'column',justifyContent:'start',  m:'0.4rem'}}>
        <Typography variant='p' sx={{fontSize:'0.7rem'}}>
        {  new Date(each.timestamp).toLocaleDateString()  }   {  new Date(each.timestamp).toLocaleTimeString()  } , <span style={{fontStyle:'italic'}}> {each.senderName}</span>
        </Typography>
        <Typography sx={{background:'var(--clr-gray40)', borderRadius:'1rem', p:'0.6rem',  width: 'fit-content', color:'white' }}>
          {each.text}
        </Typography>
        </Box>
        )) :

        <Box sx={{height:'100%', display:'flex', justifyContent:'center', placeItems:'center', flexDirection:'column'}}>
          <Message sx={{fontSize:'2rem'}} />
          <Typography sx={{background:'', textAlign:'center'}}>
          No Messages
        </Typography>
        </Box>


    }
 
</div>

</Box>



<div style={{display: 'flex', justifyContent:'center', margin:'0.8rem', marginTop:'0.2rem', gap:'0.4rem' }}>
  <TextField
    value={message}
    onChange={(e) => setMessage(e.target.value)}
    label="Type your message"
    fullWidth
    sx={{width:'90%'}}
  />
  {/* <ListItemIcon onClick={'handleSendMessage'}> */}
  <IconButton type='submit' onClick={handleSendMessage}  sx={{width:'10%', background:'rgba(92, 137, 37,0.9)', borderRadius:'20%'}}>
    <Send  sx={{placeContent:'center', placeItems:'center', placeSelf:'center', color:'white'}}/>
  </IconButton>
  {/* </ListItemIcon> */}
</div>
</form>

    </Box>
  );
};

export default connect()(ChatMessages);
