import { RESET_LOADING, SET_LOADING } from "../constants/utilsConstants";
import {
  GET_MESSAGES,
  GET_ALL_MESSAGES,
  MESSAGES_FAIL,
  CLEAR_MESSAGES,
  UPDATE_MESSAGE_STATUS,
  UPDATE_MESSAGE_STATUS_IN_STORE,
  UPDATE_MESSAGE_FAIL,
  MESSAGE_ORDER,
  CLEAR_MESSAGE_ORDER,
  IS_ORDER_LIST_SAVED,
  SAVE_LIST_COUNTER,
  MESSAGE_ORDER_NOT_FOUND,
  NO_MESSAGES,
  GET_MESSAGES_CSV_FILE,
  CLEAR_MESSAGES_CSV,
  ADD_MESSAGE_NOTE,
  CREATE_PRESENTER_VIEW,
  UPDATE_PRESENTER_VIEW,
  REORDER_PRESENTER_VIEW,
  REBUILD_PRESENTER_VIEW,
  CUSTOM_FIELDS,
  FORCE_REBUILD_PRESENTER_VIEW,
  FINAL_PUSH_ORDER,
} from "../constants/eventMessagesConstants";

const initialState = {
  messages: null,
  allMessage: {
    messages: null,
    lastUpdate: null,
  },
  newMessagesArr: [],
  presenterMessageList: {
    success: false,
    messages: [],
  },
  archivedMessagesArr: [],
  noMessages: false,
  totalMessages: null,
  updatedMessageStatus: null,
  addedMessageNote: false,
  messageOrderNotFound: false,
  messagesOrder: [],
  lastUpdate: null,
  loading: true,
  saveListCounter: 0,
  orderListSaved: false,
  messagesCsvFile: {
    success: false,
    total: null,
    items: null,
  },
  finalPush:false,

  CustomField: [],
  error: {},
};

function messagesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: payload,
        totalMessages: payload.total,
        lastUpdate: new Date(),
        loading: false,
      };
    case GET_ALL_MESSAGES:
      return {
        ...state,
        allMessage: {
          messages: payload,
          lastUpdate: new Date(),
        },
        loading: false,
      };

    case CUSTOM_FIELDS:
      return {
        ...state,
        CustomField: payload,
      };
    case CREATE_PRESENTER_VIEW:
      const allowedMsgs = state.allMessage.messages.items.filter(
        (el) => el.status === "allowed"
      );
      // const repliedMsgs = state.allMessage.messages.items.filter(
      //   (el) => el.status === 'replied'
      // );
      // const combined = [...allowedMsgs, ...repliedMsgs];
      const combined = [...allowedMsgs];

      return {
        ...state,
        presenterMessageList: {
          success: combined.length > 0 ? true : false,
          messages: combined,
        },
      };
    case UPDATE_PRESENTER_VIEW:
      // NOTES:
      // this reducer needs refactoring
      const { id, status } = payload;

      // id -> 2121
      // status -> (replied,answer)

      const statePresenterMsgs = state.presenterMessageList.messages;

      const filteredPresenterList = statePresenterMsgs.filter(
        (el) => el._id !== id
      );

      const updatedMsg = state.allMessage.messages.items
        .filter((el) => el._id === id)
        .map((el) => {
          return { ...el, status: status };
        });

      if (status === "allowed") {
        const allowedMsgArr = filteredPresenterList.filter(
          (el) => el.status === "allowed"
        );
        const repliedMsgArr = filteredPresenterList.filter(
          (el) => el.status === "replied"
        );

        console.log("REDUCER---------------", [
          ...allowedMsgArr,
          ...updatedMsg,
        ]);

        return {
          ...state,
          presenterMessageList: {
            success: true,
            // messages: [...allowedMsgArr, ...updatedMsg, ...repliedMsgArr],
            messages: [...allowedMsgArr, ...updatedMsg],
          },
        };
      }
      //Answered
      if (status === "replied") {
        const allowedMsgArr = filteredPresenterList.filter(
          (el) => el.status === "allowed"
        );
        const repliedMsgArr = filteredPresenterList.filter(
          (el) => el.status === "replied"
        );
        console.log("REDUCER Answered---------------", [...allowedMsgArr]);
        return {
          ...state,
          presenterMessageList: {
            success: true,
            // messages: [...allowedMsgArr, ...repliedMsgArr, ...updatedMsg],
            messages: [...allowedMsgArr],
          },
        };
      }

      return {
        ...state,
        presenterMessageList: {
          success: true,
          messages: filteredPresenterList,
        },
      };

    case FORCE_REBUILD_PRESENTER_VIEW:
      const msgOrder = payload.order.items[0].order;
      // console.log('msg order reducer', msgOrder)

      const filteredMsgsInStore_FORCE = payload.messages.filter(
        (el1) => msgOrder.some((el2) => el2._id === el1._id)
      );
      // console.log('filteredMsgsInStore_FORCEr reducer', filteredMsgsInStore_FORCE)

      const reorderFiltered_FORCE = filteredMsgsInStore_FORCE
        .sort((a, b) => {
          return (
            msgOrder.findIndex((p) => p._id === a._id) -
            msgOrder.findIndex((p) => p._id === b._id)
          );
        })
        // .filter((el) => el.status === 'allowed' || el.status === 'replied');
        .filter((el) => el.status === "allowed");

      // const approvedInStore_FORCE = payload.messages
      //   // .filter((el) => el.status === 'allowed' || el.status === 'replied')
      //   .filter((el) => el.status === "allowed")

      //   .filter((el1) => !msgOrder.some((el2) => el2._id === el1._id))
      //   .sort((a, b) => (a.status < b.status ? -1 : 1));
      //   console.log('approvedInStore_FORCE reducer', approvedInStore_FORCE)

      return {
        ...state,
        presenterMessageList: {
          success: true,
          messages: [...reorderFiltered_FORCE],
        },
        messagesOrder: msgOrder,
        loading: false,
      };

      // const allowedMsgArr = payload.messages.filter(
      //     (el) => el.status === 'allowed'
      //   );

      //   console.log("FORCE REBUIL", payload.order);

      // return {
      //   ...state,
      //   presenterMessageList: {
      //     success: true,
      //     messages: allowedMsgArr,
      //   },
      // };

    case REORDER_PRESENTER_VIEW:
      return {
        ...state,
        presenterMessageList: {
          success: true,
          messages: payload,
        },
      };
    case REBUILD_PRESENTER_VIEW:
      const filteredMsgsInPresenterView =
        state.presenterMessageList.messages.map((el1) => {
          const exists = payload.find((el2) => el2.messageId === el1._id);

          if (exists) {
            el1.status = exists.status;
          }
          return el1;
        });
      console.log("Rebuild Presenter", filteredMsgsInPresenterView);
      return {
        ...state,
        presenterMessageList: {
          success: true,
          messages: filteredMsgsInPresenterView,
        },
      };


    case FINAL_PUSH_ORDER:
      return {
        ...state,
        finalPush:payload
      }
    case NO_MESSAGES:
      return {
        ...state,
        noMessages: true,
        lastUpdate: new Date(),
        loading: false,
      };
    case UPDATE_MESSAGE_STATUS_IN_STORE:
      const updatedMsgArr = state.allMessage.messages.items.map((el) => {
        if (el._id !== payload.id) {
          return el;
        }
        return {
          ...el,
          status: payload.status,
        };
      });

      return {
        ...state,
        allMessage: {
          // ...state.allMessage,
          messages: {
            // ...state.allMessage.messages,
            items: updatedMsgArr,
          },
        },
      };
    case UPDATE_MESSAGE_STATUS:
      return {
        ...state,
        updatedMessageStatus: payload,
        loading: false,
      };
    case ADD_MESSAGE_NOTE:
      return {
        ...state,
        addedMessageNote: payload.success,
        loading: false,
      };
    case MESSAGE_ORDER:
      const msgOrderArr = payload.data.items[0].order;

      const filteredMsgsInStore = state.allMessage.messages.items.filter(
        (el1) => msgOrderArr.some((el2) => el2._id === el1._id)
      );

      const reorderFiltered = filteredMsgsInStore
        .sort((a, b) => {
          return (
            msgOrderArr.findIndex((p) => p._id === a._id) -
            msgOrderArr.findIndex((p) => p._id === b._id)
          );
        })
        // .filter((el) => el.status === 'allowed' || el.status === 'replied');
        .filter((el) => el.status === "allowed");

      const approvedInStore = state.allMessage.messages.items
        // .filter((el) => el.status === 'allowed' || el.status === 'replied')
        .filter((el) => el.status === "allowed")

        .filter((el1) => !msgOrderArr.some((el2) => el2._id === el1._id))
        .sort((a, b) => (a.status < b.status ? -1 : 1));

      return {
        ...state,
        presenterMessageList: {
          success: true,
          messages: [...reorderFiltered, ...approvedInStore],
        },
        messagesOrder: msgOrderArr,
        loading: false,
      };
    case MESSAGE_ORDER_NOT_FOUND:
      if (payload.lowAccessLevel) {
        return {
          ...state,
          messageOrderNotFound: true,
          presenterMessageList: {
            success: false,
            messages: [],
          },
        };
      }

      const getApprovedInStore = state.allMessage.messages.items
        // .filter((el) => el.status === 'allowed' || el.status === 'replied')
        .filter((el) => el.status === "allowed")

        .sort((a, b) => (a.status < b.status ? -1 : 1));

      return {
        ...state,
        messageOrderNotFound: true,
        presenterMessageList: {
          success: true,
          messages: getApprovedInStore,
        },
      };
    case SAVE_LIST_COUNTER:
      return {
        ...state,
        saveListCounter: state.saveListCounter + 1,
      };
    case IS_ORDER_LIST_SAVED:
      return {
        ...state,
        orderListSaved: payload,
      };
    case GET_MESSAGES_CSV_FILE:
      return {
        ...state,
        messagesCsvFile: {
          success: payload.success,
          total: payload.total,
          items: payload.items,
        },
      };
    case MESSAGES_FAIL:
    case UPDATE_MESSAGE_FAIL:
      return {
        ...state,
        error: payload.message,
        loading: false,
      };
    case CLEAR_MESSAGES_CSV:
      return {
        ...state,
        messagesCsvFile: {
          success: false,
          total: null,
          items: null,
        },
      };
    case CLEAR_MESSAGE_ORDER:
      return {
        ...state,
        messagesOrder: [],
        loading: false,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: null,
        allMessage: {
          messages: null,
          lastUpdate: null,
        },
        newMessagesArr: [],
        presenterMessageList: {
          success: false,
          messages: [],
        },
        archivedMessagesArr: [],
        noMessages: false,
        totalMessages: null,
        updatedMessageStatus: null,
        lastUpdate: null,
        messageOrderNotFound: false,
        messagesOrder: [],
        saveListCounter: 0,
        messagesCsvFile: {
          success: false,
          total: null,
          items: null,
        },
        error: {},
        loading: true,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };


      case RESET_LOADING:
        return {
          ...state,
          loading: false,
        };
    default:
      return state;
  }
}

export default messagesReducer;
