import { RemoveCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useRef, useState, useCallback } from 'react';
import ContentEditable from 'react-contenteditable';
import { connect, useDispatch } from 'react-redux';

const MessageContentEditable = ({ noteContent, newNote, handleUpdateNote }) => {
  const dispatch = useDispatch()
  const [localNoteContent, setLocalNoteContent] = useState('');
  const textRef = useRef('');

  // console.log('new note?', newNote, 'old', noteContent)

  const editableRef = useCallback(
    (editableNode) => {
      if (editableNode) {
        editableNode.el.current.spellcheck = false;
      }
      console.log('new?', noteContent.replace(/(<([^>]+)>)/ig,""))
      if (newNote === noteContent) {
        console.log('New note', noteContent.replace(/(<([^>]+)>)/ig,""))
        setLocalNoteContent(newNote.replace(/(<([^>]+)>)/ig,""));
      } else {
        console.log('Not a New note', noteContent.replace(/(<([^>]+)>)/ig,""))

        setLocalNoteContent(noteContent.replace(/(<([^>]+)>)/ig,""));
      }
    },
    [noteContent, newNote]
  );

  // disableNewLine handler
  // NOTE: pressing enter will create a new line and contenteditable will create a new DIV with BR element
  const disableNewlines = (e) => {
    const keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  };

  // pasteAsPlainText handler
  // NOTE: execCommand is deprecated - check MDN for more info
  const pasteAsPlainText = (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text/plain');

    const trimSpaces = (string) => {
      return string
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&gt;/g, '>')
        .replace(/(<([^>]+)>)/ig,"")
        .replace(/&lt;/g, '<');
    };
    
    const trimmedText =text.replace(/(<([^>]+)>)/ig,"");
    console.log('trimSpaces:', trimmedText);

    document.execCommand('insertHTML', false, trimmedText);
  };

  // handleContentEditableUpdate
  const handleContentEditableUpdate = (e) => {
    console.log('EDITING:', e.target.value.replace(/(<([^>]+)>)/ig,"").replace(/&lt;/g, '').replace(/&gt;/g, '')   )  ;
    textRef.current = e.target.value.replace(/(<([^>]+)>)/ig,"").replace(/&lt;/g, '').replace(/&gt;/g, '') ;
  };

  // handleBlur
  const handleOnBlur = () => {
    if (!textRef.current) {
      // console.log('FIRST ON BLUR');
      return;
    } else {
      setLocalNoteContent(textRef.current);
      handleUpdateNote(textRef.current);
    }
  };

  const handleOnFocus = () => {
    textRef.current = '';
  };

  const handleRemoveNote = ()=>{
    setLocalNoteContent("");

    handleUpdateNote('empty')
  }

  return (
    <Box sx={{display:'flex'}}>
    
    <ContentEditable
      ref={editableRef}
      html={localNoteContent}
      onKeyPress={disableNewlines}
      onPaste={pasteAsPlainText}
      onChange={handleContentEditableUpdate}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      className='message-content-editable'
      />
        <RemoveCircle color='error' sx={{cursor:'pointer'}} onClick={handleRemoveNote}/>
      </Box>
  );
};

export default connect()(MessageContentEditable);
