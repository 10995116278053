import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import { PrimusContext } from '../../websocket/PrimusWs';

// REDUX
import {
  updateMessageStatus,
  saveOrderMessage,
  updateMessageOrder,
  reorderPresenterView,
  resetOrderListSaved,
} from '../../../actions/messages';

// MUI
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';

// UTILS
import {
  hourMinuteDate,
  dayMonthYear,
} from '../../../utils/convertDateAndTime';
import { selectElColors } from '../../../utils/selectMsgStatusColor';
import questionTableStyles from './TableStyles';
import tableColumnsSections from './TableColumnsSections';

// COMPONENTS
import MessageContent from './MessageContent';
import RegDetails from '../../RegDetails';
import { addNotification } from '../../../actions/notification';

// >>>>>>>>>> Draggable utility funcs - START
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  // const removedWithOrder = { ...removed, orderNumber: endIndex };

  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'var(--clr-gray10)' : 'var(--clr-w)',

  // styles we need to apply on draggables
  ...draggableStyle,
});
// <<<<<<<<<< Draggable utility funcs - END

const FilteredQuestionsTableDraggableEl = ({
  userAccessToken,
  updateMessageStatus,
  saveOrderMessage,
  updateMessageOrder,
  saveListCounter,
  presenterMessageList,
  reorderPresenterView,
  orderListSaved,
  resetOrderListSaved,
  CustomField,
  event,
  auth: { user },
  ...rest
}) => {
  const { sectionName } = rest;
  const dispatch = useDispatch()

  const { eventId, userId } = rest;
  const primusMethods = useContext(PrimusContext);

  const [localMessages, setLocalMessages] = useState([]);
  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const questionTableClasses = questionTableStyles(rest);
  const activeParticipant = useSelector((state) => state.auth.activeParticipant);

  // @desc
  // Set messages locally from store
  useEffect(() => {
    let isMounted = true;

    if (isMounted && presenterMessageList.success) {
      return setLocalMessages(presenterMessageList.messages);
    }

    return () => {
      isMounted = false;
    };
  }, [presenterMessageList]);

  // @desc
  // SET websocket message if orderList has been saved in db successfully
  // useEffect(() => {
  //   if (orderListSaved) {
  //     primusMethods.sendOrderList(userId, 'orderList', eventId);
  //     const timeout = setTimeout(() => {
  //       resetOrderListSaved();
  //     }, 1000);

  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [orderListSaved]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      localMessages,
      result.source.index,
      result.destination.index
    );

    const areEquals = JSON.stringify(localMessages) === JSON.stringify(items);

    if (areEquals) {
      return;
    }

    reorderPresenterView(items);
    updateMessageOrder(items, eventId)

    //Send message order updated
    // data -> {from, eventID, userID}
    let data = {
      from:userId,
      eventID:eventId,
      userID:userId
    }
    socket_cp.emit('messageOrder',data)


    // primusMethods.sendOrderList(userId, 'orderList', eventId);
    dispatch(addNotification({
      title:`Message order update`,
      time: new Date().toLocaleTimeString(),
      read:false
    }))

  };

  const handleChangeStatus = (e, id) => {
    updateMessageStatus(userAccessToken, id, e.target.value);


    let data = {

      userID:userId,
      messageId:id,
      eventID:eventId,
      status:e.target.value
    }
socket_cp.emit('messageStatus',data)

StatusChanged()



    // NOTES:
    // Maybe we need the next code block to send WS messages to other Moderators

    // if (e.target.value !== 'allowed') {
    //   let currentMsg = msgList.filter((item) => item._id === id);
    //   currentMsg[0].status = e.target.value;
    //   const filterdMsg = msgList.filter((el) => el._id !== id);
    //   setMsgList(filterdMsg);
    //   // NOTE: send ws message on status change
    //   // primusMethods.sendMessageApproved(
    //   //   user.items[0]._id,
    //   //   e.target.value,
    //   //   event.items[0]._id
    //   // );
    // }
  };


  const StatusChanged = () => {

    try {
      // primusMethods.sendOrderList(userId, 'orderListForce', eventId)

      // primusMethods.sendOrderListForPresenter(userId, 'MessageStatus', eventId, 'presenter')


      const msgOrderIds = localMessages.map((el) => {
        if (el.messageId === undefined) el.messageId = el._id;
        if (el.orderNumber === undefined) el.orderNumber = 1000;
        return el;
      });


      // console.log('---------', msgOrderIds)

      if (saveListCounter === 0) {
        console.log('Save counte SAVE', saveListCounter)
        saveOrderMessage(msgOrderIds, eventId);

      } else {
        console.log('Save counter update', saveListCounter)

        updateMessageOrder(msgOrderIds, eventId);
      }


      // getAllMessages(1, eventId);
      // getMessageOrder(eventId);
      let data = {
        from:userId,
        eventID:eventId,
        userID:userId
      }
      socket_cp.emit('messageOrder',data)

                  dispatch(addNotification({
              title:`Message status updated`,
              time: new Date().toLocaleTimeString(),
              read:false
            }))

    } catch (error) {
      console.log(error)
    }


  };

  const handleSaveList = () => {
    const msgOrderIds = localMessages.map((el) => {
      if (el.messageId === undefined) el.messageId = el._id;
      if (el.orderNumber === undefined) el.orderNumber = 1000;
      return el;
    });

    if (saveListCounter === 0) {
      saveOrderMessage(msgOrderIds, eventId);
    } else {
      updateMessageOrder(msgOrderIds, eventId);
    }
  };

  return (
    <>
             <div
              className="blackBox"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                opacity: "0.5",
                zIndex: 500,
                display: `${
                  activeParticipant === user._id
                    ? "none"
                    : "block"
                }`,
              }}
            ></div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '0.7rem',
          padding: '3rem 3rem 0.5rem',
          '& p': {
            fontStyle: 'italic',
          },
        }}
      >
        <div>
          <Typography variant='body2'>
            Drag questions into preferred order for presenters
          </Typography>
          <Typography variant='body2'>
            Only when you click Update Presenter button will the presenter see
            the updated list
          </Typography>
        </div>
        <Button
          variant='contained'
          startIcon={<AutorenewIcon />}
          className={`${questionTableClasses.btn} ${questionTableClasses.saveListBtn}`}
          onClick={handleSaveList}
          disabled={localMessages.length === 0}
          sx={{
            placeSelf: 'center',
          }}
        >
          Update 
        </Button>
      </Box>
      {localMessages.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* {tableColumnsSections.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant='head'
                    sx={{
                      color: 'var(--clr-gray60)',
                      fontSize: '1.2rem',
                      paddingTop: 'max(3rem, 1.5vh)',
                      width: `${column.width}`,
                      // minWidth: `${column.minWidth}`,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))} */}

<TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:{sm:'26%',md:'26%',lg:'20%'}
                            // width: `${column.width}`,
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Details
                        </TableCell>
                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:'maxContent'
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Message
                        </TableCell>

                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: '160px',
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Status
                        </TableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='message'>
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {localMessages.map((msg, idx) => (
                      <Draggable
                        key={msg._id}
                        draggableId={msg._id}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseDown={(e) => e.currentTarget.focus()}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {/* <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <DragIndicatorIcon />
                                <Box>
                                  <Typography
                                 variant="event"
                                    sx={{
                                      minWidth: '100px',
                                      // whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {msg.name || 'NA'}
                                  </Typography>
                                  <Tooltip title='Copy email'>
                                    <Typography
                                      onClick={() =>
                                        navigator.clipboard.writeText(msg.email)
                                      }
                                      sx={{
                                        color: 'var(--clr-gray40)',
                                        textDecoration: 'underline',
                                        '&:hover': {
                                          color: 'var(--clr-info60)',
                                          cursor: 'pointer',
                                          fontSize:'0.8rem'
                                        },
                                      }}
                                    >
                                      {msg.email}
                                    </Typography>
                                  </Tooltip>

                                  {CustomField.map((eachCustomFields, idx) => (
                                    <Typography key={idx}>
                                      {msg[eachCustomFields]}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </TableCell> */}

<TableCell>
                          {msg.regid && msg.regid.fields ? <RegDetails user={msg.regid.fields} date={msg.createTime} dragIcon={true} regId={true} /> : 
                          <RegDetails user={msg} date={msg.createTime} dragIcon={true} regId={false}  CustomField={CustomField}/>}
                        </TableCell>
                            {/* <TableCell>
                              <Typography variant="event">{msg.company || 'NA'}</Typography>
                            </TableCell> */}
                            <TableCell>
                              <MessageContent
                                content={msg.content}
                                notes={msg.notes}
                                messageId={msg._id}
                                msg={msg}
                                sectionName={sectionName}
                                eventId={eventId}
                              />
                            </TableCell>
                            {/* <TableCell
                              align='center'
                              sx={{
                                '& :first-of-type': {
                                  marginBottom: '0.375rem',
                                },
                              }}
                            >
                              <Typography variant="event">
                                {dayMonthYear(msg.createTime)}
                              </Typography>
                              <Typography variant="event">
                                {hourMinuteDate(msg.createTime)}
                              </Typography>
                            </TableCell> */}
                            <TableCell align='center'>
                              <FormControl sx={{ minWidth: 130 }}>
                                <Select
                                  name='status'
                                  displayEmpty={true}
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  onChange={(e) =>
                                    handleChangeStatus(e, msg._id)
                                  }
                                  value={msg.status}
                                  MenuProps={{
                                    classes: {
                                      list: questionTableClasses.list,
                                    },
                                  }}
                                  sx={{
                                    textAlign: 'center',
                                    backgroundColor: `${
                                      selectElColors(msg.status, msg._id).bg
                                    }`,
                                    color: `${
                                      selectElColors(msg.status, msg._id).color
                                    }`,
                                  }}
                                >
                                  <MenuItem
                                    value='new'
                                    className={`${questionTableClasses.new} ${questionTableClasses.menuItemTransition}`}
                                    classes={{
                                      selected: questionTableClasses.selected,
                                    }}
                                  >
                                    new
                                  </MenuItem>
                                  <MenuItem
                                    value='allowed'
                                    className={`${questionTableClasses.allowed} ${questionTableClasses.menuItemTransition}`}
                                    classes={{
                                      selected: questionTableClasses.selected,
                                    }}
                                  >
                                    to be asked
                                  </MenuItem>
                                  <MenuItem
                                    value='replied'
                                    className={`${questionTableClasses.replied} ${questionTableClasses.menuItemTransition}`}
                                  >
                                    answered
                                  </MenuItem>
                                  <MenuItem
                                    value='archive'
                                    className={`${questionTableClasses.archive} ${questionTableClasses.menuItemTransition}`}
                                    classes={{
                                      selected: questionTableClasses.selected,
                                    }}
                                  >
                                    archive
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Typography
            variant='h5'
            gutterBottom
            sx={{
              padding: '1.5rem 2rem',
              marginBottom: '0px',
              textAlign: 'center',
              color: 'var(--clr-danger)',
            }}
          >
            No messages have been added to the list
          </Typography>
        </Box>
      )}
    </>
  );
};

FilteredQuestionsTableDraggableEl.propTypes = {
  updateMessageStatus: PropTypes.func.isRequired,
  userAccessToken: PropTypes.string.isRequired,
  saveListCounter: PropTypes.number,
  presenterMessageList: PropTypes.object.isRequired,
  saveOrderMessage: PropTypes.func.isRequired,
  updateMessageOrder: PropTypes.func.isRequired,
  reorderPresenterView: PropTypes.func.isRequired,
  orderListSaved: PropTypes.bool.isRequired,
  resetOrderListSaved: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userAccessToken: state.auth.token,
  saveListCounter: state.messages.saveListCounter,
  presenterMessageList: state.messages.presenterMessageList,
  orderListSaved: state.messages.orderListSaved,
  CustomField: state.messages.CustomField,
  event: state.event.event,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateMessageStatus,
  saveOrderMessage,
  updateMessageOrder,
  reorderPresenterView,
  resetOrderListSaved,
})(FilteredQuestionsTableDraggableEl);
