export const SOCKET_IO_CONNECTED = 'SOCKET_IO_CONNECTED';
export const SOCKET_IO_DISSCONNECTED = 'SOCKET_IO_DISSCONNECTED';
export const SOCKET_IO_ERROR = 'SOCKET_IO_ERROR';
export const RESET_SOCKET_IO_ERROR = 'RESET_SOCKET_IO_ERROR';


//socketio client portal namespace

export const SOCKETIO_CP_CONNECTED = 'SOCKETIO_CP_CONNECTED' 

export const SOCKETIO_CP_DISCONNECTED = 'SOCKETIO_CP_DISCONNECTED' 