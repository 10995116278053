import { makeStyles } from '@mui/styles';

const questionTableStyles = makeStyles((theme) => ({
  sectionHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    paddingBlock: '0.5rem',
    marginBlock: '0.5rem',
    '& > button': {
      marginLeft: '60px',
    },
    content: '""',
    // position: 'absolute',
    top: '-1px',
    left: '0px',
    width: '100%',
    // height: 'calc(50% + 1px)',
    background: (props) =>
      props.color === 'green'
        ? 'linear-gradient(180deg, #97d411 50%, #97d411 50%)'
        : props.color,
  },
  sectionTitle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
    paddingBlock: '0.5rem',
    marginBlock: '0.5rem',
    '& > button': {
      marginLeft: '60px',
    },
    content: '""',
    // position: 'absolute',
    top: '-1px',
    left: '0px',
    width: '100%',
    // height: 'calc(50% + 1px)',
   
  },
  btn: {
    padding: '6px 24px',
    fontSize: '1rem',
    lineHeight: '1.5',
    textTransform: 'capitalize',
    color: 'var(--clr-success-dark)',
  },
  saveListBtn: {
    backgroundColor: 'var(--clr-success)',
    '&:hover': {
      backgroundColor: 'var(--clr-success60)',
    },
  },
  selected: {
    backgroundColor: 'white !important',
    color: 'black !important',
  },
  list: {
    padding: 0,
    '& li': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
  menuItemTransition: {
    transition: '350ms ease',
  },
  new: {
    backgroundColor: 'var(--clr-info) ',
    color: 'var(--clr-w)',
    '&:hover': {
      backgroundColor: 'var(--clr-info60) ',
    },
  },
  allowed: {
    backgroundColor: 'var(--clr-success)',
    color: 'var(--clr-w)',
    '&:hover': {
      backgroundColor: 'var(--clr-success60) ',
    },
  },
  archive: {
    backgroundColor: 'var(--clr-danger)',
    color: 'var(--clr-w)',
    '&:hover': {
      backgroundColor: 'var(--clr-danger60)',
    },
  },
  replied: {
    backgroundColor: 'var(--clr-warning)',
    '&:hover': {
      backgroundColor: 'var(--clr-warning60)',
    },
  },
  openSliderBtn: {
    backgroundColor: 'var(--clr-success)',
    '&:hover': {
      backgroundColor: 'var(--clr-success60)',
    },
  },
  tRow: {
    '& :nth-child(-n+3)': {
      verticalAlign: 'top',
    },
  },
}));

export default questionTableStyles;
