import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

import { setActiveIcon } from '../../actions/icon';
import { updatePassword } from '../../actions/auth';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';
import BadgeIcon from '@mui/icons-material/Badge';

import './Profile.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const Profile = ({
  auth: { user, token, passwordUpdated,loggedInUserID },
  updatePassword,
  open,
  close,
  setActiveIcon,
}) => {
  const [showPsw, setShowPsw] = useState();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const { name, email, password } = formData;

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        name: `${user.name.first} ${user.name.last}`,
        email: user.email,
      });
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (open) {
      setActiveIcon();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (passwordUpdated) {
      close();
    }
    // eslint-disable-next-line
  }, [passwordUpdated]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    // setCurrentPassword(e.target.value)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePassword({ access_token: token, password: formData.password, userID:loggedInUserID });
  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <Dialog open={open} onClose={close} maxWidth='750px'>
      <DialogContent
        sx={{
          width: '750px',
          maxWidth: '750px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className='profile-dialog-content__header'
      >
        <Avatar sx={{ bgcolor: 'var(--clr-gray80)' }}>
          <PersonIcon fontSize='large' />
        </Avatar>
        <DialogTitle>Profile</DialogTitle>
      </DialogContent>


      <DialogContent sx={{ pt: '0' }} className='profile-dialog-content__body'>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          <Stack
            spacing={7}
            sx={{
              color: 'var(--clr-gray40)',
            }}
          >
            <TextValidator
              disabled
              placeholder='Name'
              name='name'
              value={name}
              // onChange={(e) => handleChange(e)}
              validators={['required']}
              errorMessages={['this field is required']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <BadgeIcon sx={{ color: 'inherit' }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />

            <TextValidator
              disabled
              placeholder='Email'
              name='email'
              value={email}
              // onChange={(e) => handleChange(e)}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MailOutlineOutlinedIcon sx={{ color: 'inherit' }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            
            <TextValidator
              placeholder='New Password'
              name='password'
              value={password}
              onChange={(e) => handleChange(e)}
              type={showPsw ? 'text' : 'password'}
              validators={['required', 'minStringLength: 6']}
              errorMessages={[
                'this field is required',
                'min length is 6 characters',
              ]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockIcon sx={{ color: 'inherit' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPsw ? (
                        <VisibilityIcon sx={{ color: 'inherit' }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: 'inherit' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              autoFocus
            />
            {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Generated Passive Presenter Links</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{maxHeight:'200px', height:'200px', overflowY:'scroll'}}>

            <Box sx={{display:'flex', justifyContent:'space-between', background:'lightgrey', p:'0.6rem', borderRadius:'10px', m:'0.2rem'}}>

          <Box>

          <Typography>
        Event - Event name
          </Typography>
          <Typography>
        Link - Link
          </Typography>
          </Box>

            <Box>
              <Typography>Expires at - 12/12/3002</Typography>
            </Box>
            </Box>
            
        </AccordionDetails>
      </Accordion> */}

            <DialogActions
              sx={{
                // justifyContent: 'space-between',
                justifyContent: 'center',

                flexDirection: { md: 'row', xs: 'column' },
                p: '0px',
                '& :not(:first-of-type)': {
                  marginLeft: { md: '8px', xs: '0px' },
                },
              }}
            >
              <Button
                onClick={close}
                variant='contained'
                sx={{
                  width: { md: '50%', xs: '100%' },
                  textTransform: 'capitalize',
                  fontSize: '1.25rem',
                  bgcolor: 'var(--clr-gray40)',
                  '&:hover': {
                    bgcolor: 'var(--clr-w)',
                    color: 'var(--clr-gray80)',
                  },
                }}
              >
                Close
              </Button>
              <Button
                type='submit'
                variant='contained'
                sx={{
                  width: { md: '50%', xs: '100%' },
                  textTransform: 'capitalize',
                  fontSize: '1.25rem',
                  bgcolor: 'var(--clr-success)',
                  '&:hover': {
                    bgcolor: 'var(--clr-w)',
                    color: 'var(--clr-gray80)',
                  },
                }}
              >
                Update Password
              </Button>
            </DialogActions>
          </Stack>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setActiveIcon: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updatePassword,
  setActiveIcon,
})(Profile);
