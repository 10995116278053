import axios from "axios";
import { ADD_LOGS, ADD_QUESTION, ERROR_SPLIT_MESSAGE, LOADING_SPLIT_QUESTION, POST_NEW_SPLIT_MESSAGE, SUCCESS_SPLIT_MESSAGE } from "../constants/splitMessageConstant";

import { setAlert } from './alert';
import { getAllMessages } from "./messages";

import { toast } from 'react-toastify';
import { CheckBoxOutlined } from "@mui/icons-material";
import { addNotification } from "./notification";

// @type 
// if passed as edited then don't trigger split alert
export const postNewSplitMessage =
  (content, email, eventid, name, organisation, status, MessageType) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body =  {
        content,
        email,
        eventid,
        name,
        organisation,
        status
    }

    try {
        setLoading(true)
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/message/?token=${process.env.REACT_APP_API_TOKEN}`,
        body,
        config
      );
      console.log('Spliting each', res)

      if (res.data.success === false) {
        // dispatch(setAlert('splitting failed', 'error'));
        toast.error('splitting failed', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
            icon: true,
          theme: "colored",
          });
        dispatch({
          type: ERROR_SPLIT_MESSAGE,
          payload: 'splitting failed',
        });
      } else {
        // dispatch(
        //   setAlert('splitting completed', 'success', 1500)
        // );
        MessageType !== 'edited' && 
        dispatch(addNotification({
          title:`Splitting completed`,
          time: new Date().toLocaleTimeString(),
          read:true
        }))
        
        toast.success(
            <div
            style={{
              height: "100%",
              borderLeft: "5px solid green",
              display: "flex",
              alignItems: "center",
              paddingLeft: 5
            }}
          >
            <CheckBoxOutlined color={"white"} height="25px" width="25px" />
            <span style={{ marginLeft: 5 }}>Splitting completed</span>
          </div>
          )


        dispatch({
            type: SUCCESS_SPLIT_MESSAGE,
            payload: 'splitting done',
          });

        dispatch(getAllMessages(1, eventid))

      }
    } catch (err) {
        console.log('Error in spliting', err)
      dispatch({
        type: ERROR_SPLIT_MESSAGE,
        payload: 'splitting failed',
      });
    }
  };




// Add message logs
export const addMessageLogs =
(eventid, email, name, company, createTime, content) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
   
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/message_logs/?token=${process.env.REACT_APP_API_TOKEN}`, 
        {eventid,email, name, company, createTime, content},
        config
      );
    console.log('Message logs', res.data)

    if (res.data.success === false) {
      console.log('failed adding logs',  res.data.errorMsg)
      // dispatch(setAlert('Error while adding message logs', 'error'));
      toast.error('Error while adding message logs', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
          icon: true,
        theme: "colored",
        });
      dispatch({
        type: ERROR_SPLIT_MESSAGE,
        payload: res.data.errorMsg,
      });
    } else {
      // dispatch(
      //   setAlert('Deletion completed', 'success', 1500)
      // );


        // Add message log status 
      dispatch({
          type: ADD_LOGS,
          payload: 'MESSAGE LOGS',
        });

      // dispatch(getAllMessages(1, evntid))

    }
  } catch (err) {
      console.log('Message logs failed', err)
    dispatch({
      type: ERROR_SPLIT_MESSAGE,
      payload: 'Deletion failed',
    });
  }
};





  export const deleteMessage =
  (id, eventid) => async (dispatch) => {

    try {
        setLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/message/${id}?token=${process.env.REACT_APP_API_TOKEN}`);
      console.log('deleting message', res)

      if (res.data.success === false) {
        // dispatch(setAlert('deleting failed', 'error'));
        toast.error('deleting failed', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
            icon: true,
          theme: "colored",
          });
        dispatch({
          type: ERROR_SPLIT_MESSAGE,
          payload: 'deleting failed',
        });
      } else {
        // dispatch(
        //   setAlert('Deletion completed', 'success', 1500)
        // );
        // toast.success('Deletion completed', {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //     icon: true,
        //   theme: "colored",
        //   });
        dispatch({
            type: SUCCESS_SPLIT_MESSAGE,
            payload: 'Deleted',
          });

        dispatch(getAllMessages(1, eventid))

      }
    } catch (err) {
        console.log('Error in spliting', err)
      dispatch({
        type: ERROR_SPLIT_MESSAGE,
        payload: 'Deletion failed',
      });
    }
  };


  export const addNewQuestion =
  (content, email, eventid, name, organisation, status) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body =  {
        content,
        email,
        eventid,
        name,
        organisation,
        status
    }

    try {
        setLoading(true)
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/message/?token=${process.env.REACT_APP_API_TOKEN}`,
        body,
        config
      );
      console.log('Spliting each', res)

      if (res.data.success === false) {
        // dispatch(setAlert('Adding new question failed', 'error'));
        toast.error('Adding new question failed', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
            icon: true,
          theme: "colored",
          });

        dispatch({
          type: ERROR_SPLIT_MESSAGE,
          payload: 'Adding new question failed',
        });
      } else {
        // dispatch(
        //   setAlert('Question added', 'success', 1500)
        // );
        dispatch(addNotification({
          title:`Question added`,
          time: new Date().toLocaleTimeString(),
          read:true
        }))

          // toast.success(
          //   <div
          //   style={{
          //     height: "100%",
          //     borderLeft: "5px solid green",
          //     display: "flex",
          //     alignItems: "center",
          //     paddingLeft: 5
          //   }}
          // >
          //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
          //   <span style={{ marginLeft: 5 }}>Question added</span>
          // </div>
          // )
        dispatch({
            type: SUCCESS_SPLIT_MESSAGE,
            payload: 'Question added',
          });

        dispatch(getAllMessages(1, eventid))

      }
    } catch (err) {
        console.log('Error in spliting', err)
      dispatch({
        type: ERROR_SPLIT_MESSAGE,
        payload: 'splitting failed',
      });
    }
  };

















  // SET LOADING
export const setLoading = (toggle) => (dispatch) => dispatch({ type: LOADING_SPLIT_QUESTION, payload:toggle});