import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { loginFromMobile } from '../../actions/auth';

// MUI
import { Box } from '@mui/system';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

// IMG
import logo from '../../static/img/brr-logo.png';

// COMPONENTS
import DesktopIcon from '../UI/icons/DesktopIcon';

const LandingMobile = ({ loginFromMobile }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'var(--clr-gray80)',
      }}
    >
      <Box
        sx={{
          maxWidth: '400px',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2rem 1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 'min(4rem, 8vh)',
          }}
        >
          <CardMedia
            component='img'
            height='50'
            image={logo}
            alt='BRR Logo'
            sx={{
              objectFit: 'contain',
              width: 'auto',
            }}
          />
          <Typography
            gutterBottom
            variant='h5'
            component='h5'
            sx={{
              color: 'var(--clr-w)',
              marginTop: '0.5rem',
            }}
          >
            Client Portal
          </Typography>
          <Box
            sx={{
              width: '33%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: 'max(4rem, 12vh)',
            }}
          >
            <PhoneIphoneIcon fontSize='large' sx={{ color: 'var(--clr-w)' }} />
            <TrendingFlatIcon sx={{ color: 'var(--clr-w)' }} />
            <DesktopIcon fontSize='large' sx={{ marginLeft: '0.2rem' }} />
          </Box>
          <Typography
            variant='h6'
            sx={{
              color: 'var(--clr-w)',
              marginTop: 'max(60px, 10vh)',
            }}
          >
            Mobile devices are not supported
          </Typography>
          <Typography
            variant='subtitle2'
            align='center'
            sx={{
              color: 'var(--clr-w)',
              marginTop: '0.5rem',
            }}
          >
            Please switch to your desktop or laptop to use BRR Client Portal
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => loginFromMobile()}
            variant='outlined'
            endIcon={<ArrowForwardIcon />}
            sx={{
              textTransform: 'none',
              color: 'var(--clr-brr)',
              borderColor: 'var(--clr-brr)',
              borderRadius: '60px',
            }}
          >
            Continue anyway
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

LandingMobile.propTypes = {
  loginFromMobile: PropTypes.func.isRequired,
};

export default connect(null, { loginFromMobile })(LandingMobile);
