export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const PASSWORD_UPDATED_INITIAL_STATE = 'PASSWORD_UPDATED_INITIAL_STATE';


//PASSWORD RESET
export const PASSWORD_REST_SUCCESS = 'PASSWORD_REST_SUCCESS'
export const PASSWORD_REST_FAIL = 'PASSWORD_REST_FAIL'
export const PASSWORD_RESET = 'PASSWORD_RESET'


export const REGISTRANT_SUCCESS = 'REGISTRANT_SUCCESS';
export const REGISTRANT_FAIL = 'REGISTRANT_FAIL';
export const CLEAR_REGISTRANT = 'CLEAR_REGISTRANT';

export const LOGIN_FROM_MOBILE = 'LOGIN_FROM_MOBILE';

export const OPEN_SELECT_GROUP_MODAL = 'OPEN_SELECT_GROUP_MODAL';
export const LOGIN_DATA_HEIGH_ACCESSLEVEL = 'LOGIN_DATA_HEIGH_ACCESSLEVEL';
export const LOGIN_WITH_GROUP = 'LOGIN_WITH_GROUP';

export const SET_ACTIVE_PARTICIPANT = 'SET_ACTIVE_PARTICIPANT'
export const SET_ACTIVE_PARTICIPANT_NAME = 'SET_ACTIVE_PARTICIPANT_NAME'

export const SET_PASSIVE_PRESENTER_DETAILS = 'SET_PASSIVE_PRESENTER_DETAILS'

