export const selectElColors = (status, id) => {
  let bg;
  let color;
  // const status = list.find((el) => el.id === id).status;

  if (status === 'new') {
    bg = 'var(--clr-info)';
    color = 'var(--clr-w)';
  }
  if (status === 'allowed') {
    bg = 'var(--clr-success)';
    color = 'var(--clr-gray80)';
  }
  if (status === 'replied') {
    bg = 'var(--clr-warning)';
    color = 'var(--clr-gray80)';
  }
  if (status === 'archive') {
    bg = 'var(--clr-danger)';
    color = 'var(--clr-w)';
  }

  return { bg, color };
};
