import axios from 'axios';
import { FETCH_AND_APPEND_CHATS, RESET_CHATS, RESET_CHAT_NOTIFICATION_COUNT, SET_CHATS, SET_CHAT_MENU, SET_CHAT_NOTIFICATION_COUNT } from '../constants/chatConstant';


export const setChats = (data) => (dispatch) => {
    dispatch({
      type: SET_CHATS,
      payload:data
    });
  };


  export const fetchAndAppendChats = (roomId) => async (dispatch) => {

    try {
        
    const chats = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/chat/${roomId}/?token=${process.env.REACT_APP_API_TOKEN}`
      );
        console.log('all chats', chats.data)
        let messages = chats.data
    dispatch({
        type: FETCH_AND_APPEND_CHATS,
        payload:{ roomId, messages }
      });
    } catch (error) {
        console.log('all chats error',error)

    }

  };

  export const resetChats = () => (dispatch) => {
    dispatch({
      type: RESET_CHATS   
     });
  };

  
  

  export const setChatMenu = (status) => (dispatch) => {
    dispatch({
      type: SET_CHAT_MENU,
      payload:status
    });
  };
  


  export const setChatNotificationCount = () => (dispatch) => {
    dispatch({
      type: SET_CHAT_NOTIFICATION_COUNT
    });
  };


  export const resetChatNotificationCount = () => (dispatch) => {
    dispatch({
      type: RESET_CHAT_NOTIFICATION_COUNT
    });
  };


  



