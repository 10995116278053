import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Outlet, Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { isMobileOnly } from 'react-device-detect';
import { useMobileOrientation } from 'react-device-detect';

// MUI
import { Box } from '@mui/system';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// IMG
// import logo from '../../static/img/brr-logo.png';

// COMPONENTS
import AuthAdBtn from '../UI/inputs/AuthAdBtn';
import CookiesSwitch from '../UI/inputs/CookiesSwitch';
import LandingMobile from '../layout/LandingMobile';
import Description from './Description';

import './Auth.css';
import './utilsAuth.css';

const Auth = ({ isLoginFromMobile }) => {
  // useMobileOrientation return isLandscape (bool), isPortrait (bool), orientation (string)
  const { isPortrait } = useMobileOrientation();
  const [continueAnyway, setContinueAnyway] = useState(false);

  useEffect(() => {
    setContinueAnyway(isLoginFromMobile);
  }, [isLoginFromMobile]);

  return (
    <>
      {isMobileOnly && !continueAnyway ? (
        <LandingMobile />
      ) : (
        <>
          <Box className={!isPortrait ? 'desktop-layout' : undefined}>
            {!isPortrait && (
              <>
                <Box className='desktop-layout__left-panel'>
                  <Description />
                  <Box
                    sx={{
                      marginTop: 'min(3vh, 16px)',
                    }}
                  >
                    <AuthAdBtn />
                  </Box>
                </Box>
              </>
            )}

            <Box
              sx={{
                display: 'grid',
                minWidth: '350px',
                color: 'white',
                bgcolor: 'var(--clr-gray80)',
                overflowY: 'auto',
              }}
              className={
                isPortrait ? 'auth-form-container' : 'shadow-auth-section'
              }
            >
              <Outlet />
            </Box>
          </Box>
          <CookieConsent
            cookieName='BRRClientPortalCookieConsent'
            location='bottom'
            disableStyles={true}
            containerClasses='cookie-container'
            contentClasses='cookie-content'
            ButtonComponent={Button}
          >
            <Typography variant='body1'>
              We use cookies to offer you a better browsing experience, provide
              and improve our services and to analyse how our application is
              used. If you continue to use this site, you consent to our use of
              cookies.
            </Typography>
            <Link to='/cookies' target='_blank' rel='noopener'>
              Read more
            </Link>
            <CookiesSwitch />
          </CookieConsent>
        </>
      )}
    </>
  );
};

Auth.propTypes = {
  isLoginFromMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoginFromMobile: state.auth.isLoginFromMobile,
});

export default connect(mapStateToProps, {})(Auth);
