import { DragIndicator } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { dayMonthYear, hourMinuteDate } from "../../utils/convertDateAndTime";

const RegDetails = ({ user, date, dragIcon, regId, CustomField }) => {

        // regId &&    Object.keys(JSON.parse(user)).map(v => (
        //                 console.log('Whats in v', v)
        // ))
    return (
        regId ?
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                {
                    dragIcon && <DragIndicator />
                }
                <Box>
                    {
                        Object.keys(JSON.parse(user)).map(v => (
                                
                   <Typography
                                // variant="event"
                                sx={{
                                    minWidth: '100px',
                                    // whiteSpace: 'nowrap',
                                }}
                            >
                                
                                <span style={{ textTransform: 'capitalize' }}>{v} </span>  - <span style={{ textTransform: '' }}>
                                    {JSON.parse(user)[v] ? JSON.parse(user)[v] : 'N/A'}
                                </span>
                            </Typography>
                        ))


                    }


                    <Typography
                        // variant="event"
                        sx={{
                            minWidth: '100px',
                            // whiteSpace: 'nowrap',
                        }}
                    >

                        Date -
                        {" "} {dayMonthYear(date)} {" "}  {hourMinuteDate(date)}
                    </Typography>




                </Box>
            </Box>
            :

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                {
                    dragIcon && <DragIndicator />
                }

                <Box>
                    <Typography
                        variant="event"
                        sx={{
                            minWidth: '100px',
                            // whiteSpace: 'nowrap',
                        }}
                    >
                        Name -  {user.name || 'NA'}
                    </Typography>
                    <Typography sx={{ fontSize: { sm: '0.8rem', md: '1rem', ld: '1rem' } }}>Email -  {user.email === undefined ? "NA" : user.email}</Typography>
                    <Typography> Company -  {user.company === undefined ? "NA" : user.company}</Typography>
                    <Typography>Date -
                        {dayMonthYear(user.createTime)}
                        {hourMinuteDate(user.createTime)}
                    </Typography>
                    {CustomField.map(
                        (eachCustomFields, idx) => (
                            <Typography key={idx}>
                                {user[eachCustomFields]}
                            </Typography>
                        )
                    )}
                </Box>
            </Box>




    )
}

export default RegDetails;