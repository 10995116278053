import { FETCH_AND_APPEND_CHATS, RESET_CHATS, RESET_CHAT_NOTIFICATION_COUNT, SET_CHATS, SET_CHAT_MENU, SET_CHAT_NOTIFICATION_COUNT } from '../constants/chatConstant';


const initialState = {
    chats: [],
    messagesByRoomId: {},
    chatNotificationCount: 0,
    chatMenu: false,
    loading: true,
    error: {},
};

function chatReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case SET_CHATS:
            console.log('REDUCER CHAT', payload)

            const existingRoomMessages = state.messagesByRoomId[payload.roomId] || [];
            const updatedRoomMessages = [...existingRoomMessages, payload];



            // const updatedMessages =  [...state.chats, payload]
            // const updatedMessagesByRoomId = {
            //     ...state.messagesByRoomId,
            //     [payload.roomId]: updatedMessages,
            //   };
        
            return {
                ...state,
                chats: [...state.chats, payload],
                messagesByRoomId: {
                    ...state.messagesByRoomId,
                    [payload.roomId]: updatedRoomMessages,
                  }
            }
            case FETCH_AND_APPEND_CHATS:
                const { roomId, messages } = action.payload;
                const existingMessages = state.messagesByRoomId[roomId] || [];
                const allFetchedMessages = [...messages, ...existingMessages];
          
                return {
                  ...state,
                  messagesByRoomId: {
                    ...state.messagesByRoomId,
                    [roomId]: allFetchedMessages,
                  },
                };
          

            case RESET_CHATS:
                return {
                    ...state,
                    chats: []
                }

        case SET_CHAT_MENU:
            return {
                ...state,
               chatMenu:payload
            }

        case SET_CHAT_NOTIFICATION_COUNT:
            return {
                ...state,
                chatNotificationCount: state.chatMenu ? state.chatNotificationCount : state.chatNotificationCount+1
            }


        case RESET_CHAT_NOTIFICATION_COUNT:
            return {
                ...state,
                chatNotificationCount:0
            }

        default:
            return state;
    }
}

export default chatReducer;
