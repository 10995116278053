import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

 const useEventMessages = (messages)=> {
  const [isMessagePresent, setIsMessagePresent] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(true);

  

  useEffect(() => {
        setLoadingMessages(true)
        if(messages?.length > 0){
            setIsMessagePresent(true)
            setLoadingMessages(false)

        }
  }, [messages]);

  return [isMessagePresent, loadingMessages];
}

export default useEventMessages
