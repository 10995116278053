import { Button, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import PresenterLinkModal from "./PresenterLinkModal";
import { createPassivePresenter, shortenJwtlinlk, updateToClientPortalUser } from "./PresenterService";
const { nanoid } = require("nanoid");

const sign = require("jwt-encode");

const SplitButtomCustom = styled(Button)(({ theme }) => ({
    marginTop: '0.2rem',
    backgroundColor: 'var(--clr-info)',
    color: 'var(--clr-w)',
    '&:hover': {
      backgroundColor: 'var(--clr-info60)',
    },
    width:'100%'

}))
const PresenterViewButton = ({eventId, eventTitle, modName, modEmail}) =>{

  

    const [open, setOpen] = useState(false);
    const [clearCopyText, setClearCopyText] = useState(false)

    const [token,setToken] = useState(null)

    console.log('Presenter button----------------',eventId)



    const GetPresenterLink = async()=>{
        const data = {
            isVerified:true,
            eventID:eventId,
            eventTitle:eventTitle
        }
        const secret = "secret";
        
        // Post new account as presenter with access level 1

        let uid = nanoid(20)
        let userDetails = {
            email:modName + '.passivePresenter.' + uid + '@brrmedia.co.uk',
            password:modName + '.passivePresenter.' + uid,
            firstname:modName + 'passivePresenter',
            lastname:uid,
            active:true
        }
        let createPresenterAccount = await createPassivePresenter(userDetails)
        
        userDetails._id = createPresenterAccount.id
        userDetails.access_token = createPresenterAccount.access_token
        data.accountDetails = userDetails
        let currentDate = new Date()
        let currentDateTo7Day = new Date()
        currentDateTo7Day.setDate(currentDateTo7Day.getDate() + 729)
        data.startDate = currentDate;
        data.endDate = currentDateTo7Day
        data.createdBy = modEmail

        
        console.log('User created',createPresenterAccount)

        let updateToClientPortal = await updateToClientPortalUser(createPresenterAccount.id)
        const jwt = sign(data, secret);


        let shortnedLink = await shortenJwtlinlk(`https://${window.location.hostname}/presenterView/${jwt}`, jwt,)
        console.log('Short link ------------------------', shortnedLink)
        setToken(shortnedLink)
        // setOpen(true);
        setClearCopyText(true)
        console.log('shortend', shortenJwtlinlk)
    }


    useEffect(()=>{
        GetPresenterLink()
    },[eventId])












    const handleOpen = async() => {
        GetPresenterLink()
        // const data = {
        //     isVerified:true,
        //     eventID:eventId,
        //     eventTitle:eventTitle
        // }
        // const secret = "secret";
        
        // // Post new account as presenter with access level 1

        // let uid = nanoid(20)
        // let userDetails = {
        //     email:modName + '.passivePresenter.' + uid + '@brrmedia.co.uk',
        //     password:modName + '.passivePresenter.' + uid,
        //     firstname:modName + 'passivePresenter',
        //     lastname:uid
        // }
        // let createPresenterAccount = await createPassivePresenter(userDetails)
        
        // userDetails._id = createPresenterAccount.id
        // userDetails.access_token = createPresenterAccount.access_token
        // data.accountDetails = userDetails
        // let currentDate = new Date()
        // let currentDateTo7Day = new Date()
        // currentDateTo7Day.setDate(currentDateTo7Day.getDate() + 90)
        // data.startDate = currentDate;
        // data.endDate = currentDateTo7Day
        // data.createdBy = modEmail

        
        // console.log('User created',createPresenterAccount)

        // let updateToClientPortal = await updateToClientPortalUser(createPresenterAccount.id)
        // const jwt = sign(data, secret);


        // let shortnedLink = await shortenJwtlinlk(`https://${window.location.hostname}/presenterView/${jwt}`, jwt,)
        // console.log('Short link', shortnedLink)
        // setToken(shortnedLink)
        setOpen(true);
        // setClearCopyText(true)
        // console.log('shortend', shortenJwtlinlk)
    }
    const handleClose = () => {
        setOpen(false);
        setToken(null)

    }

    useEffect(()=>{
        console.log('token value', token)
    },[token])




    return (
        <>
        <SplitButtomCustom variant="contaied"  onClick={handleOpen}>
          Generate presenter link
        </SplitButtomCustom>

{
    token &&  <PresenterLinkModal open={open} handleClose={handleClose} setOpen={setOpen} eventid={eventId} link={token}  clearCopyText={clearCopyText}/>

}


      
        </>
    )
}

export default PresenterViewButton;