import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, styled, TextField } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { addNewQuestion, addQuestion, deleteMessage, postNewSplitMessage } from "../../../../actions/splitQuestions";
import { PrimusContext } from "../../../websocket/PrimusWs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 9000,
};


const SplitButton = styled(Button)(({ theme }) => ({
    // marginTop: '0.2rem',
    backgroundColor: 'var(--clr-gray40)',
    color: 'var(--clr-gray10)',
    '&:hover': {
      backgroundColor: 'var(--clr-gray80)',
    },
}))

const AddQuestionModal = ({ open, handleClose, setOpen, eventid }) => {
  const primusMethods = useContext(PrimusContext);

    const authFirstName = useSelector((state) => state.auth.user.name.first);
    const authLastName = useSelector((state) => state.auth.user.name.last);
    const authEmail = useSelector((state)=> state.auth.user.email)
    const userID = useSelector((state) => state.auth.loggedInUserID);
    const socket_cp = useSelector((state) => state.socketio.socketioCP);

    const eventID = useSelector((state)=> state.event.event.items[0]._id)

    const [newQuestion, setNewQuestion] = useState()
    
    const dispatch = useDispatch();
    
    
    const handleChange = (e) =>{
        setNewQuestion(e)
    }
    
    
    const handleAddQuestion = ()=>{
    console.log('new question', authFirstName, authLastName, authEmail)
    
    
    const body =  {
        content:newQuestion,
        email:authEmail,
        eventid:eventID,
        name:authFirstName + ' '+ authLastName,
        // organisation,
        status:'new'
    }
    
    // dispatch(addQuestion(body, eventID))
    dispatch(addNewQuestion(newQuestion, authEmail, eventID, authFirstName, '', 'new'))
    try {
      // primusMethods.newMessage(eventID, userID)
      let data = {
        eventID:eventID,
        type:'new',
        from:userID,
        messageContent:newQuestion
      }
      socket_cp.emit('messageSent',data);  

    } catch (error) {
      console.log('Primus error', error)
    }
    setNewQuestion('')
    setOpen(false)
}


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ background: "#dcdde1", fontSize: "1rem", textAlign:'center' }}
            p={2}
          >
                Add Question
          </Typography>
          <Stack direction={'row'} sx={{display:'flex', justifyContent:'center'}} spacing={2} m={2}>

          <TextField multiline onChange={(e)=>handleChange(e.target.value)} value={newQuestion}  rows={4} id="add-question" label="Enter your question here" variant="outlined"  sx={{width:'80%'}}/>
          
            </Stack>


         

            <Stack  sx={{display:'flex', justifyContent:'center'}} spacing={2} m={2}>
          


            </Stack>

            <Stack direction={'row'} sx={{display:'flex', justifyContent:'center'}} spacing={2} m={2}>
                <Button variant="contained" onClick={handleAddQuestion}>Add Question</Button>
            </Stack>
        </Box>
        
      </Modal>
    </div>
  );
};

export default connect()(AddQuestionModal);