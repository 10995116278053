import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import {
  getEvents,
  getGroupEvents,
  searchEvent,
  searchEventInStore,
  clearSearchedEvent,
} from '../../actions/event';

// UTILS
import { fullDate } from '../../utils/convertDateAndTime';
import {
  formatEventStatus,
  colorEventStatus,
} from '../../utils/formatEventStatus';

// MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Badge from '@mui/material/Badge';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { lightGreen, lightBlue } from '@mui/material/colors';

// COMPONENTS
import Spinner from '../UI/Spinner';
import ViewEventBtn from '../UI/inputs/ViewEventBtn';
import EditEventBtn from '../UI/inputs/EditEventBtn';
import SearchedEvent from './SearchedEvent';
import PrimusScriptDom from '../websocket/PrimusScriptDom';

import './utilsEvent.css';
import { createTheme, ThemeProvider } from '@mui/material';
import NoEvents from '../utils-pages/NoEvents';

const columns = [
  { id: 'title', label: 'Title', align: 'left', width: 'auto' },
  { id: 'company', label: 'Company', align: 'left', width: 'auto' },
  { id: 'presenters', label: 'Presenters', align: 'left', width: 'auto' },
  { id: 'release', label: 'Release Date', align: 'left', width: '220px' },
  { id: 'type', label: 'Type/Source', align: 'left', width: '190px' },
  { id: 'action', label: 'Action', align: 'center', width: '250px' },
];

const clrBlue400 = lightBlue[400];
const clrGreen300 = lightGreen[300];
const clrGreen600 = lightGreen[600];


const theme = createTheme({
  typography: {
    event: {
      fontSize: '0.8rem'
    },
    // Disable h3 variant
    h3: undefined,
  },
});


const Events = ({
  getEvents,
  getGroupEvents,
  searchEvent,
  searchEventInStore,
  events: { items, total },
  lastFetch,
  searchedEvent,
  clearSearchedEvent,
  auth,
}) => {
  const [itemsReady, setItemsReady] = useState(false);
  const [page, setPage] = useState(0);
  const [eventTitle, setEventTitle] = useState('');
  const [isSubmitted, setIsSubbmitted] = useState(false);
  const pageTopRef = useRef(null);

  // Get events only the first time page load
  useEffect(() => {
    if (auth.user !== null && lastFetch < 1) {
      if (auth.user.level === 6 || auth.user.level === 5) {
        getGroupEvents();
      }
      if (auth.user.level === 4) {
        getGroupEvents();
      } else {
        getEvents();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (items !== undefined && items.length > 0 ) {
        setItemsReady(true);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [items]);

  // Get events when the page change
  useEffect(() => {
    if (lastFetch !== 0) {
      if (searchedEvent !== null) {
        searchEvent(page + 1, auth.token, eventTitle, auth.user.company._id);
      }
      if (auth.user.level >= 4) {
        getGroupEvents(page + 1);
      } else {
        getEvents(page + 1);
      }
    }
    // eslint-disable-next-line
  }, [page]);

  const handleChangePage = (e, newPage) => {
    pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    setPage(newPage);
  };

  const handleChangeInput = (e) => {
    setEventTitle(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (eventTitle === '') return;
    if (page > 0) setPage(0);
    if (auth.user.level === 6) {
      searchEvent(
        1,
        auth.token,
        eventTitle,
        auth.user.company._id,
        '&group=brr'
      );
    } else if (auth.user.level < 4) {
      searchEventInStore(eventTitle);
    } else {
      searchEvent(
        1,
        auth.token,
        eventTitle,
        `&companies=${auth.user.company._id}`
      );
    }
    setIsSubbmitted(true);
  };

  const handleClear = (e) => {
    setEventTitle('');
    setIsSubbmitted(false);
    clearSearchedEvent();
    setPage(0);
  };

  useEffect(() => {
    if (eventTitle === '') {
      handleClear();
    }
    // eslint-disable-next-line
  }, [eventTitle]);

  return (
    <>
    <ThemeProvider theme={theme}>
{ total === 0 ? <>

  <Box sx={{height:'90vh', display:'flex', placeContent:'center', alignItems:'center'}}>
  <Typography variant='h5' align='center' sx={{width:'50vw', background:'gray', p:'4rem', color:'white', borderRadius:'1rem'}}>
            No Events Found
          </Typography>
  </Box>

</> :
  <>
      <Box
        ref={pageTopRef}
        sx={{
          padding: '1rem 1rem',
          backgroundColor: 'var(--clr-w)',
          borderRadius: '3px',
          boxShadow: 2,
        }}
      >

        

        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            width: '300px',
            marginBottom: '1rem',
            borderRadius: '3px',
            border: '1px solid var(--clr-gray20)',
          }}
        >
          <InputBase
            onChange={(e) => handleChangeInput(e)}
            value={eventTitle ? eventTitle : ''}
            sx={{
              flex: 1,
              ml: 2,
            }}
            placeholder='Search by title'
            inputProps={{ 'aria-label': 'search event by title' }}
          />
          <Divider sx={{ height: 30, mt: 0.6 }} orientation='vertical' />
          {!isSubmitted ? (
            <IconButton
              onClick={handleSubmit}
              sx={{
                borderRadius: '0px',
                '& svg': { color: 'var(--clr-gray60)' },
              }}
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleClear}
              sx={{
                borderRadius: '0px',
                '& svg': { color: 'var(--clr-gray60)' },
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
        {itemsReady ? (
          <>
            <Box
              sx={{
                width: '100%',
                overflow: 'hidden',
                border: '1px solid var(--clr-gray20)',
              }}
              className='fade-in-box'
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          variant='head'
                          sx={{
                            width: `${column.width !== '' && column.width}`,
                            backgroundColor: 'var(--clr-gray60)',
                            color: 'var(--clr-w)',
                            fontSize: '1rem',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {searchedEvent !== null ? (
                    <SearchedEvent />
                  ) : (
                    <TableBody>
                      {items.map((item) => (
                        <TableRow
                          hover
                          key={item._id}
                          sx={{
                            '&:last-child td': { borderBottom: '0px' },
                          }}
                        >
                          <TableCell align='left'>
                            <Typography variant="event">{item.title}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <Typography variant="event">{item.companies[0].name}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            {item.presenters.map((presenter) => (
                              <p variant="event"  key={presenter._id}>{presenter.name} </p>
                            ))}
                          </TableCell>
                          <TableCell align='left'>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Typography variant="event">
                                {fullDate(item.releaseTime)}
                              </Typography>
                              <Typography variant="event">{item.releaseTimezone}</Typography>
                              <Badge
                                color={colorEventStatus(item.status)}
                                badgeContent={' '}
                                variant='dot'
                                sx={{
                                  left: '0px',
                                  transform: 'translateX(8px)',
                                  '& span': {
                                    transform: 'translateY(25%)',
                                    right: '100%',
                                    top: '35%',
                                  },
                                }}
                              >
                                <Typography 
                                variant="event"
                                  sx={{
                                    textTransform: 'capitalize',
                                    color: 'var(--clr-gray40)',
                                    transform: 'translateX(8px)',
                                  }}
                                >
                                  {formatEventStatus(item.status)}
                                </Typography>
                              </Badge>
                            </Box>
                          </TableCell>
                          <TableCell align='left'>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Typography variant="event" sx={{ textTransform: 'capitalize' }}>
                                {item.assetType}
                              </Typography>
                              {item.source === 'wc' ? (
                                <Typography variant="event">Webcast Ondemand</Typography>
                              ) : item.source === 'ls' ? (
                                <Typography variant="event">Webcast Live</Typography>
                              ) : (
                                item.source === 'bc' && (
                                  <Typography variant="event">Brightcove</Typography>
                                )
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align='center'>
                            <Stack
                              direction='row'
                              spacing={1}
                              sx={{
                                maxHeight: '100px',
                                justifyContent: 'center',
                              }}
                            >
                              <EditEventBtn
                                eventId={item._id}
                                backgroundClr={clrBlue400}
                              />
                              <Stack direction='column' spacing={1}>
                                <ViewEventBtn
                                  icon='view'
                                  eventId={item._id}
                                  preview={false}
                                  backgroundClr={clrGreen600}
                                />
                                <ViewEventBtn
                                  icon='preview'
                                  eventId={item._id}
                                  preview={true}
                                  backgroundClr={clrGreen300}
                                />
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>

            <TablePagination
              rowsPerPageOptions={[{ label: 'All', value: -1 }]}
              component='div'
              count={searchedEvent !== null ? searchedEvent.total : total}
              page={page}
              rowsPerPage={20}
              onPageChange={handleChangePage}
              showLastButton
              showFirstButton
            />
          </>
        ) : (
          !itemsReady && <Spinner />
        )}





      </Box>
  
  </>
}
      <PrimusScriptDom />
      </ThemeProvider>
    </>
  );
};

Events.propTypes = {
  getEvents: PropTypes.func.isRequired,
  getGroupEvents: PropTypes.func.isRequired,
  searchEvent: PropTypes.func.isRequired,
  searchEventInStore: PropTypes.func.isRequired,
  clearSearchedEvent: PropTypes.func.isRequired,
  events: PropTypes.object,
  searchedEvent: PropTypes.object,
  lastFetch: PropTypes.number,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  events: state.event.events,
  searchedEvent: state.event.searchedEvent,
  lastFetch: state.event.lastFetch,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getEvents,
  getGroupEvents,
  searchEvent,
  searchEventInStore,
  clearSearchedEvent,
})(Events);
