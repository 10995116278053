import React, { useState, useEffect, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { addMessageNote, getAllMessages, getMessageOrder } from '../../../actions/messages';

// MUI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InsertCommentIcon from '@mui/icons-material/InsertComment';

// COMPONENTS
import MessageContentEditable from './MessageContentEditable';
import SplitButton from './split/SplitButton';
import { Stack } from '@mui/material';
import { PrimusContext } from '../../websocket/PrimusWs';
import { Flag } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({

  messageContentContainer: {
    '& > p': {
      fontSize: 'var(--fs-message)',
      paddingBottom: '0px',
    },
    '& > div > div': {
      // fontSize: 'var(--fs-message-note)',
    },
    '& button': {
      minWidth: '70px',
      textTransform: 'capitalize',
    },
  },
  addNoteBtn: {
    marginTop: '0.4rem',
    backgroundColor: 'var(--clr-gray40)',
    color: 'var(--clr-gray10)',
    '&:hover': {
      backgroundColor: 'var(--clr-gray60)',
    },
  },
  closeBtn: {
    backgroundColor: 'var(--clr-gray10)',
    color: 'var(--clr-gray80)',
    '&:hover': {
      backgroundColor: 'var(--clr-gray20)',
    },
  },
  addBtn: {
    backgroundColor: 'var(--clr-success)',
    color: 'var(--clr-gray80)',
    '&:hover': {
      backgroundColor: 'var(--clr-success60)',
    },
  },
}));

const MessageContent = ({
  content,
  notes,
  messageId,
  userAccessToken,
  addMessageNote,
  eventId,
  msg,
  sectionName,
  type
}) => {
  const dispatch = useDispatch();
  const primusMethods = useContext(PrimusContext);
  const userID = useSelector((state) => state.auth.loggedInUserID);
  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const [checked, setChecked] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [noteFounded, setNoteFounded] = useState(false);
  const [newNote, setNewNote] = useState('');



  useEffect(() => {
    if (notes) {
      // console.log('MSG NOTE ------------------------- ', userAccessToken, messageId, 'event id', eventId)
      console.log('Should have to go here !!!!!!!!!!!!!!!! message content noteDebug', notes.length, noteFounded)

      if (notes.length > 1) {
        console.log('Note length 1 noteDebug', notes.length, noteFounded)

        setNoteContent(notes);
        setNoteFounded(true);
      } 
      if(notes.length === 1) {
        console.log('note content noteDebug', notes, noteFounded)

        setNoteFounded(false);
      }
    }

    // eslint-disable-next-line
  }, [notes]);

  const handleOpenTextField = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (e) => {
    // Trim
    setNewNote(e.target.value.replace(/(<([^>]+)>)/ig,""));
  };

  const handleSubmit = () => {
    // console.log('MSG NOTE ------------------------- ', userAccessToken, messageId, newNote)
    addMessageNote(userAccessToken, messageId, newNote, eventId);
    try {

      // primusMethods.messageEdited(userID, 'noteAdded', eventId)
      let data = {
        messageID:messageId,
        userID:userID,
        eventID:eventId,
        noteContent:newNote,
        type:'notesAdded'
      }
      socket_cp.emit('notes', data)

    } catch (error) {
      console.log('Primus error', error)
    }
    setChecked(!checked);

    if (newNote.length > 1) {
      setNoteFounded(true);
      
    }
      dispatch(getAllMessages(1, eventId, 6, false))
      // dispatch(getMessageOrder(eventId))

  };

  const handleUpdateNote = (str) => {
    // console.log('MSG NOTE ------------------------- ', userAccessToken, messageId, str)

    addMessageNote(userAccessToken, messageId, str, eventId);
    if(str === 'empty') {
      try {
        // primusMethods.messageEdited(userID, 'deleted', eventId)
        let data = {
          messageID:messageId,
          userID:userID,
          eventID:eventId,
          noteContent:newNote,
          type:'notesDeleted'
        }
        socket_cp.emit('notes', data)
      } catch (error) {
        console.log('Primus error', error)
      }
    } else {
      try {
        let data = {
          messageID:messageId,
          userID:userID,
          eventID:eventId,
          noteContent:{from:notes, content:str},
          type:'notesEdited'
        }
        socket_cp.emit('notes', data)
        // primusMethods.messageEdited(userID, 'noteEdited', eventId)
      } catch (error) {
        console.log('Primus error', error)
      }
    }
    

      dispatch(getAllMessages(1, eventId, 6, false))
      dispatch(getMessageOrder(eventId))
    
  };
  const classes = useStyles();

  return (
    <Box className={classes.messageContentContainer}>
       {
                            msg.anonymous &&
                          <Box display={'flex'} justifyContent={'start'} flexDirection={'row'}>
                          <Flag sx={{color:"#eb4d4b"}}/> 
                            <Typography sx={{color:"#eb4d4b"}}>Anonymous question</Typography>

                          </Box>
                          }
      <Stack>
    {  sectionName === 'new' &&  <SplitButton type={'type2'} eachMsg={msg} />}


      <Typography id={type+messageId} variant="event">{content}</Typography>
    
      </Stack>
     

      {noteFounded && (
        <>
          <Divider
            light
            sx={{
              marginTop: '1rem',
              marginBottom: '0.35rem',
            }}
          />
          <Box
            sx={{
              display:'flex'
              // position: 'relative',
              // '& > svg': {
              //   width: '1.25rem',
              //   height: '1.25rem',
              //   marginTop: '0.25rem',
              // },
            }}
          >

            <InsertCommentIcon color='warning' />
            <MessageContentEditable
              noteContent={noteContent}
              newNote={newNote}
              handleUpdateNote={handleUpdateNote}
            />
          </Box>
        </>
      )}

      {!noteFounded && (
        <Collapse orientation='vertical' in={!checked} timeout={{ exit: 200 }}>
          <Box sx={{display:'flex', gap:'1rem'}}>

          <Button
            variant='contained'
            disableElevation
            onClick={handleOpenTextField}
            className={classes.addNoteBtn}
            >
            Add Note
          </Button>

            </Box>
        </Collapse>
      )}

      <Collapse
        orientation='vertical'
        in={checked}
        sx={{
          height: checked ? 'auto !important' : '',
          marginTop: '1rem',
          '& > div': {
            paddingBlock: '5px',
          },
        }}
        timeout={{ exit: 200 }}
        unmountOnExit
      >
        <TextField
          multiline
          value={newNote}
          onChange={handleChange}
          name='note'
          fullWidth
          autoFocus
          
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'var(--clr-gray40)',
                fontSize:'0.8rem'
              },
            },
          }}
        />
        <Box
          sx={{
            marginTop: '0.5rem',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <Button
            variant='contained'
            disableElevation
            className={classes.closeBtn}
            onClick={handleOpenTextField}
          >
            Close
          </Button>
          <Button
            variant='contained'
            className={classes.addBtn}
            disableElevation
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

MessageContent.protoType = {
  addMessageNote: PropTypes.func.isRequired,
  userAccessToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userAccessToken: state.auth.token,
});

export default connect(mapStateToProps, { addMessageNote })(MessageContent);
