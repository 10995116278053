import { toast } from "react-hot-toast";
import { ADD_NOTIFICATION, CLEAR_NOTIFICATION, MAKE_ALL_AS_READ, MARK_ALL_AS_READ } from "../constants/notification";



export const addNotification = (notification) => (dispatch) => {
    
    toast.dismiss()

    toast.success(notification.title, {
        icon:false,
        style: {
         background:'green',
          color: 'white',
        }
      })
    dispatch({ 
        type: ADD_NOTIFICATION,
        payload:notification
        });
  };


  export const markAllasRead = () => (dispatch) => {
    dispatch({ type: MARK_ALL_AS_READ });
  };

  export const clearNotification = () => (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATION });
  };


