import { createStore, applyMiddleware } from 'redux';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
//const initialState = {};

const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;



const persistConfig = {
  key: 'reducer',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['websocket', 'messages', 'alert', 'notification', 'chatReducer', 'socketio']
};
const presistedReducer = persistReducer(persistConfig, rootReducer );
const store = createStore(presistedReducer,{} ,composeWithDevTools(applyMiddleware(...middleware)));
const persistor = persistStore(store);
export { persistor, store };