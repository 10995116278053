import React from 'react';

// MUI
import { Box } from '@mui/system';
import Link from '@mui/material/Link';

const AuthFooter = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Box sx={{ padding: '0 4% 40px 4%' }}>
      <p>
        For help please email{' '}
        <Link
          href='mailto:support@brrmedia.co.uk'
          color='inherit'
          underline='hover'
          sx={{ '&:hover': { color: 'var(--clr-brr)' } }}
        >
          support@brrmedia.co.uk
        </Link>{' '}
        or call +44 20 3435 7010
      </p>
      <p>Copyright © {year} BRR Media</p>
      <p>Version 1.0.0</p>
    </Box>
  );
};

export default AuthFooter;
