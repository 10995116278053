import axios from 'axios';

import { APP_LIST_PAGE_SIZE } from '../config';
import { SET_LOADING } from '../constants/utilsConstants';
import {
  GET_EVENTS,
  GET_EVENT,
  EVENT_ERROR,
  SEARCH_EVENT,
  SERACH_EVENT_IN_STORE,
  CLEAR_EVENTS,
  CLEAR_EVENT,
  CLEAR_SEARCHED_EVENT,
  SET_TAB_VALUE_GLOBAL,
} from '../constants/eventConstants';
import { setAlert } from './alert';
import { SET_ACTIVE_PARTICIPANT } from '../constants/userConstants';
import { setActivePartipant } from './auth';

// GET EVENTS
export const getEvents =
  (page = 1) =>
  async (dispatch) => {
    let userAccessToken;
    let companyID;
    let group;
    let apiCall = false;

    if (localStorage.cpUserInfo) {
      const data = JSON.parse(localStorage.getItem('cpUserInfo'));
      // console.log('localstorage data', data);
      if (data !== undefined) {
        userAccessToken = data.token;
        companyID = data.companyID;
        group=data.group
      }
    }
    if (companyID !== undefined) {
      apiCall = true;
    }

    if (apiCall) {
      try {

        if(group === 'brr') {

          const res = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/event/search/${page}/${APP_LIST_PAGE_SIZE}/?access_token=${userAccessToken}&group=null&orderBy=-createTime`
          );
          dispatch({
            type: GET_EVENTS,
            payload: res.data,
          });
        } else {
          const res = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/event/search/${page}/${APP_LIST_PAGE_SIZE}/?access_token=${userAccessToken}&group=${group?group:'null'}&orderBy=-createTime`
          );
          dispatch({
            type: GET_EVENTS,
            payload: res.data,
          });
        }
       
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: err,
          // payload: { data: err.response.data, status: err.response.status },
        });
      }
    }
  };

// GET GROUP EVENTS
export const getGroupEvents =
  (page = 1) =>
  async (dispatch) => {
    let userAccessToken;
    let group;
    let apiCall = false;

    if (localStorage.cpUserInfo) {
      const data = JSON.parse(localStorage.getItem('cpUserInfo'));
      // console.log('localstorage data', data);
      if (data !== undefined) {
        userAccessToken = data.token;
        group = data.group;
      }
    }
    if (group !== undefined) {
      apiCall = true;
    }

    if (apiCall) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/event/search/${page}/${APP_LIST_PAGE_SIZE}/?access_token=${userAccessToken}&group=${group}`
        );

        dispatch({
          type: GET_EVENTS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: EVENT_ERROR,
          payload: err,
          // payload: { data: err.response.data, status: err.response.status },
        });
      }
    }
  };

// GET SINGLE EVENT
export const getEvent = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/event/${id}/?token=${process.env.REACT_APP_API_TOKEN}`
    );
    dispatch({
      type: GET_EVENT,
      payload: res.data,
    });

    console.error('GET EVENT', res.data);

 
  dispatch(setActivePartipant(id,res.data.items[0].webcast.live.active.participant))

  
  } catch (err) {
    console.error('GET EVENT', err);
    dispatch({
      type: EVENT_ERROR,
      payload: err,
    });
  }
};

// SEARCH EVENT
export const searchEvent =
  (page = 1, accessToken, eventTitle, companyGroupQuery) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      let userAccessToken;
      let group
      const data = JSON.parse(localStorage.getItem('cpUserInfo'));
      // console.log('localstorage data', data);
      if (data !== undefined) {
        userAccessToken = data.token;
        group = data.group;
      }


      // const res = await axios.get(
      //   `${process.env.REACT_APP_API_BASE_URL}/event/search/${page}/20/?access_token=${accessToken}&idOr=${eventTitle}&${companyGroupQuery}`
      // );
      
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/event/search/${page}/20/?access_token=${accessToken}&idOr=${eventTitle}&group=${group}`
      );

      if (!res.data.success) {
        dispatch(setAlert('No data found', 'error', 4000));
      } else {
        dispatch({
          type: SEARCH_EVENT,
          payload: res.data,
        });
      }
    } catch (err) {
      console.error('GET EVENT', err);
      dispatch({
        type: EVENT_ERROR,
        payload: err,
      });
    }
  };

export const searchEventInStore = (eventTitle) => (dispatch) => {
  dispatch({
    type: SERACH_EVENT_IN_STORE,
    payload: eventTitle,
  });
};




// Set global tab value 
export const setGlobalTabValue = (value)=> (dispatch)=>{
    dispatch({
      type:SET_TAB_VALUE_GLOBAL,
      payload:value
    })
}

// CLEAR EVENTS
export const clearEvents = () => (dispatch) => dispatch({ type: CLEAR_EVENTS });

// CLEAR EVENT
export const clearEvent = () => (dispatch) => dispatch({ type: CLEAR_EVENT });

// CLEAR SEARCHED EVENT
export const clearSearchedEvent = () => (dispatch) =>
  dispatch({ type: CLEAR_SEARCHED_EVENT });

// SET LOADING
export const setLoading = () => (dispatch) => dispatch({ type: SET_LOADING });
