import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { lightGreen } from '@mui/material/colors';

// const bgColor = lightGreen[500];

const TooltipAlert = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  // [`& .${tooltipClasses.arrow}`]: {
  //   color: bgColor,
  // },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: '1rem',
    // backgroundColor: bgColor,
    fontSize: '0.95rem',
    fontWeight: '900',
    letterSpacing: '0.025rem',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 7px 8px -4px, rgb(0 0 0 / 14%) 0px 12px 17px 2px, rgb(0 0 0 / 12%) 0px 5px 22px 4px',
  },
}));

export default TooltipAlert;
