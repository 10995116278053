const AWS = require('aws-sdk');
require('dotenv').config();
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
        secretAccessKey:  process.env.REACT_APP_AWS_SECRET_KEY,
        region: 'eu-west-1',
})

const cloudWatchLogs = new AWS.CloudWatchLogs()

const logGroupName = '/brr-frontend-logs'
const logStreamName = 'brr-client-portal'

const submitLogs = async(message)=>{
    if(process.env.CLIENT_PORTAL_ENV !== 'development') {
        try {
            const params  = {
                logGroupName,
                logStreamName,
                logEvents:[
                    {
                        message,
                        timestamp:new Date().getTime()
                    }
                ]
            }
            await cloudWatchLogs.putLogEvents(params).promise()
        } catch (error) {
            console.log('AWS CLoudWatch Error', error)
        }
    }
          
}

module.exports = submitLogs;