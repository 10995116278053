import axios from 'axios';

import { APP_LIST_PAGE_SIZE } from '../config';
import { RESET_LOADING, SET_LOADING } from '../constants/utilsConstants';
import {
  GET_MESSAGES,
  GET_ALL_MESSAGES,
  MESSAGES_FAIL,
  CLEAR_MESSAGES,
  UPDATE_MESSAGE_STATUS,
  UPDATE_MESSAGE_STATUS_IN_STORE,
  UPDATE_MESSAGE_FAIL,
  MESSAGE_ORDER,
  CLEAR_MESSAGE_ORDER,
  IS_ORDER_LIST_SAVED,
  SAVE_LIST_COUNTER,
  MESSAGE_ORDER_NOT_FOUND,
  NO_MESSAGES,
  GET_MESSAGES_CSV_FILE,
  CLEAR_MESSAGES_CSV,
  ADD_MESSAGE_NOTE,
  CREATE_PRESENTER_VIEW,
  UPDATE_PRESENTER_VIEW,
  REORDER_PRESENTER_VIEW,
  REBUILD_PRESENTER_VIEW,
  CUSTOM_FIELDS,
  FORCE_REBUILD_PRESENTER_VIEW,
  FINAL_PUSH_ORDER,
  // UPDATE_MESSAGE_ORDER,
} from '../constants/eventMessagesConstants';
import { setAlert } from './alert';
import { wsOrderListRecived } from './websocket';
import { toast } from 'react-toastify';
import { CheckBoxOutlined } from '@mui/icons-material';
import { addNotification } from './notification';
import { store } from '../store';

// GET MESSAGES
export const getMessages =
  (page, eventId, pageSize = APP_LIST_PAGE_SIZE) =>
    async (dispatch) => {
      // dispatch(setLoading());
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/message/search/${page}/${pageSize}/?token=${process.env.REACT_APP_API_TOKEN}&eventId=${eventId}&paginated=true`
        );

        if (res.data.success) {
          // console.log('All messages from getMessage', res.data.customFields)
          dispatch({
            type: GET_MESSAGES,
            payload: res.data,
          });

          // check for custom fields and if present then store
        } else {
          dispatch({
            type: MESSAGES_FAIL,
            payload: res.data,
          });
        }
      } catch (err) {
        // console.log('GET MESSAGES FAILED IN CATCH');
        dispatch({
          type: MESSAGES_FAIL,
          payload: 'Get messages failed',
        });
      }
    };

// GET ALL MESSAGES
export const getAllMessages =
  (page, eventId, userAccessLevel = 6, reorder) =>
    async (dispatch, getState) => {
      // get custom from event state
      const custom_fields_check =
        getState().event.event.items[0].customFields.map((each) => {
          return each.hasOwnProperty('key');
        });

      const custom_field = getState().event.event.items[0].customFields;

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/message/search/${page}/${APP_LIST_PAGE_SIZE}/?token=${process.env.REACT_APP_API_TOKEN}&eventId=${eventId}&paginated=false`
        );

        if (res.data.success) {
          if (custom_fields_check[0]) {
            // There are custom fields
            // Dispatch to store custom fields
            let CustomEventFields = custom_field.map((each) => each.key);

            dispatch({
              type: CUSTOM_FIELDS,
              payload: CustomEventFields,
            });
          }

          dispatch({
            type: GET_ALL_MESSAGES,
            payload: res.data,
          });
          // console.log('FORCE ',res.data.items)

          console.log('Reordering?', reorder)
          if (reorder) {

            //Get message order and rorder

            try {
              const resOrder = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/message_order/search/1/1000/?eventId=${eventId}&token=${process.env.REACT_APP_API_TOKEN}`
              );

              if (!res.data.success) {
                // dispatch(setAlert('Could not find any message order', 'info', 5000));
                // dispatch({
                //   type: MESSAGE_ORDER_NOT_FOUND,
                //   payload: 'lowAccessLevel',
                // });
              }

              if (resOrder.data.success) {
                dispatch({
                type: FORCE_REBUILD_PRESENTER_VIEW,
                payload: { messages: res.data.items, order: resOrder.data }
                });

                dispatch(getMessageOrder(eventId, userAccessLevel))

                dispatch({ type: SAVE_LIST_COUNTER });

              }
            } catch (err) {
              // console.log('GET MSG ORDER FAILED', err);
              dispatch({
                type: MESSAGES_FAIL,
                payload: 'Get message order failed!',
              });
            }
          }


          if (userAccessLevel === 1) {
            dispatch(wsOrderListRecived(false));
            // dispatch(
            //   setAlert('New message order has been dispatched', 'success', 3000)
            // );
          }
        } else {
          dispatch({
            type: NO_MESSAGES,
            payload: res.data,
          });
        }
      } catch (err) {
        // console.log('GET ALL MESSAGES FAILED', err);
        dispatch({
          type: MESSAGES_FAIL,
          payload: err,
        });
      }
    };

// CREATE PRESENTER VIEW FROM ALLMESSAGE ARRAY IN STORE
export const createPresenterView = () => (dispatch) => {
  dispatch({
    type: CREATE_PRESENTER_VIEW,
  });
};

// UPDATE MESSAGE STATUS IN DATABASE
export const updateMessageStatus =
  (access_token, id, status) => async (dispatch) => {

    dispatch({
      type: FINAL_PUSH_ORDER,
      payload:false
    });


    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      access_token,
      data: {
        id,
        status,
        createTime: Date.now(),
      },
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/message/`,
        body,
        config
      );

      if (res.data.success === false) {
        // dispatch(setAlert('Message status did not change', 'error'));
        toast.error('Message status did not change', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
            icon: true,
          theme: "colored",
        });
        dispatch({
          type: UPDATE_MESSAGE_FAIL,
          payload: res.data.message.message,
        });
      } else {
        // dispatch(
        //   setAlert('Message status updated successfully', 'success', 1500)
        // );
        status !== 'edited' &&   
        dispatch(addNotification({
          title:`Message status updated`,
          time: new Date().toLocaleTimeString(),
          read:false
        }))
        
        // toast.success(
        //   <div
        //   style={{
        //     height: "100%",
        //     borderLeft: "5px solid green",
        //     display: "flex",
        //     alignItems: "center",
        //     paddingLeft: 5
        //   }}
        // >
        //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        //   <span style={{ marginLeft: 5 }}>Message status updated</span>
        // </div>
        // )

        status === 'edited' && 
        dispatch(addNotification({
          title:`Message Edited`,
          time: new Date().toLocaleTimeString(),
          read:false
        }))

        // toast.success(
        //   <div
        //   style={{
        //     height: "100%",
        //     borderLeft: "5px solid green",
        //     display: "flex",
        //     alignItems: "center",
        //     paddingLeft: 5
        //   }}
        // >
        //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        //   <span style={{ marginLeft: 5 }}>Message Edited</span>
        // </div>
        // )


        dispatch({
          type: UPDATE_MESSAGE_STATUS_IN_STORE,
          payload: { id, status },
        });
        dispatch({
          type: UPDATE_MESSAGE_STATUS,
          payload: res.data,
        });
        dispatch({
          type: UPDATE_PRESENTER_VIEW,
          payload: { id, status },
        });

        console.log('ORDERD MESSAGES', store.getState().messages.presenterMessageList.messages)

        dispatch({
          type: FINAL_PUSH_ORDER,
          payload:true
        });
        // updateMessageOrder(store.getState().messages.presenterMessageList.messages, store.getState().messages.presenterMessageList.messages[0].event._id)
        
        //After updating presenter view, update order
      }
    } catch (err) {
      dispatch({
        type: UPDATE_MESSAGE_FAIL,
        payload: err,
      });
    }
  };

export const rebuildPresenterView = (msgArr) => (dispatch) => {
  dispatch({
    type: REBUILD_PRESENTER_VIEW,
    payload: msgArr,
  });
};

export const reorderPresenterView = (msgArr) => (dispatch) => {
  dispatch({
    type: REORDER_PRESENTER_VIEW,
    payload: msgArr,
  });
};

export const addMessageNote = (access_token, id, notes, eventId) => async (dispatch) => {
  console.log('Add notes-------------------', notes.toString())
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    access_token,
    data: {
      id,
      notes: notes === 'empty' ? " " : notes,
      // createTime: Date.now(),
    },
  });

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/message/`,
      body,
      config
    );

    if (res.data.success === false) {
      // dispatch(setAlert('Note has not been added', 'error'));
      // dispatch(addNotification({
      //   title:`Note has been added`,
      //   time: new Date().toLocaleTimeString(),
      //   read:true
      // }))
      // toast.error('Note has not been added', {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //     icon: true,
      //   theme: "colored",
      // });
      dispatch({
        type: UPDATE_MESSAGE_FAIL,
        payload: res.data,
      });
    } else {

      if (notes === 'empty') {
        // console.log('action', res.data);
        //  dispatch(setAlert('Note has been deleted', 'success', 1500));
        dispatch(addNotification({
          title:`Note has been deleted`,
          time: new Date().toLocaleTimeString(),
          read:true
        }))
        // toast.error('Note has been deleted', {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //     icon: true,
        //   theme: "colored",
        // });
        dispatch({
          type: ADD_MESSAGE_NOTE,
          payload: res.data,
        });

        dispatch(getAllMessages(1, eventId))
        dispatch(getAllMessages(1, eventId, 6, true))

      } else {
        // console.log('action', res.data);
        // dispatch(setAlert('Note has been added successfully', 'success', 1500));
        // dispatch(addNotification({
        //   title:`Note has been added`,
        //   time: new Date().toLocaleTimeString(),
        //   read:true
        // }))
        // toast.success(
        //   <div
        //   style={{
        //     height: "100%",
        //     borderLeft: "5px solid green",
        //     display: "flex",
        //     alignItems: "center",
        //     paddingLeft: 5
        //   }}
        // >
        //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        //   <span style={{ marginLeft: 5 }}>Note has been added</span>
        // </div>
        // )
        dispatch({
          type: ADD_MESSAGE_NOTE,
          payload: res.data,
        });

        dispatch(getAllMessages(1, eventId))
        dispatch(getAllMessages(1, eventId, 6, true))

      }



    }
  } catch (err) {
    // dispatch(setAlert('Something went wrong', 'error'));
    toast.error('Something went wrong', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
        icon: true,
      theme: "colored",
    });
    dispatch({
      type: UPDATE_MESSAGE_FAIL,
      payload: err,
    });
  }
};


//Order saved to DB
export const isMessageOrderSaved = (bool)=>(dispatch)=>{
  dispatch({
    type: IS_ORDER_LIST_SAVED,
    payload: bool,
  });
}

// GET MESSAGE ORDER FROM DB
export const getMessageOrder =
  (eventId, lowAccessLevel = false) =>
    async (dispatch,getState) => {
      // dispatch(setLoading());
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/message_order/search/1/1000/?eventId=${eventId}&token=${process.env.REACT_APP_API_TOKEN}`
        );

        console.log('getMessageOrdergetMessageOrder', res.data)  

        // if (!res.data.success) {
        //   dispatch({
        //     type: MESSAGE_ORDER_NOT_FOUND,
        //     payload: lowAccessLevel,
        //   });
        // }
        if (!res.data.success) {
          //Message order not found, check error message and check if there are any messages with allowed status then save message order
          // from the redux store
          if (res.data.message?.message === 'Could not find any message order for this search null') {
            //Save message order
            console.log('getMessageOrdergetMessageOrder', res.data.message?.message, getState().messages.allMessage.messages?.items.filter((msg) => msg.status === 'allowed'))
            let allowedMessages = getState().messages.allMessage.messages?.items.filter((msg) => msg.status === 'allowed')
            let allMessageWithMessageID = allowedMessages.map((each) => ({ ...each, orderNumber: 1000, messageId: each._id }))
            //Need to add messageId for each object
            allMessageWithMessageID.length > 0 && dispatch(saveOrderMessage(allMessageWithMessageID, eventId))
          }
        }
        if (res.data.success) {
          console.log('MSG ORDER', res.data)

          const data = res.data;
          dispatch({
            type: MESSAGE_ORDER,
            payload: { data, lowAccessLevel },
          });
          dispatch({ type: SAVE_LIST_COUNTER });
        }
      } catch (err) {
        // console.log('GET MSG ORDER FAILED', err);
        dispatch({
          type: MESSAGES_FAIL,
          payload: 'Get message order failed!',
        });
      }
    };

// SAVE MESSAGE ORDER
export const saveOrderMessage = (msgsArr, eventId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Accept: 'application/json, */*',
    },
  };

  const body = {
    eventId: eventId,
    order: msgsArr,
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/message_order/?token=${process.env.REACT_APP_API_TOKEN}`,
      body,
      config
    );

    if (res.data.success) {
      // dispatch(setAlert('Message order saved', 'success', 1500));
  
      dispatch(addNotification({
        title:`Message order saved`,
        time: new Date().toLocaleTimeString(),
        read:true
      }))


      dispatch({ type: SAVE_LIST_COUNTER });
      dispatch({
        type: IS_ORDER_LIST_SAVED,
        payload: true,
      });
    }
  } catch (err) {
    console.log('>>>>> err', err.message.includes('409'), err.message);
    // dispatch(setAlert('An error occurred, please try again later', 'error'));
    !err.message.includes('409') &&  toast.error('An error occurred, please try again later', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
        icon: true,
      theme: "colored",
    });
  }
};

// UPDATE MESSAGE ORDER
export const updateMessageOrder = (msgsArr, eventId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Accept: 'application/json, */*',
    },
  };

  const body = {
    eventId: eventId,
    order: msgsArr,
  };

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/message_order/?token=${process.env.REACT_APP_API_TOKEN}`,
      body,
      config
    );

   

    if (res.data.success) {
      // dispatch(setAlert('Message order updated', 'success', 1500));

      // dispatch(addNotification({
      //   title:`Message order updated`,
      //   time: new Date().toLocaleTimeString(),
      //   read:true
      // }))
      // toast.success( 
      //   <div
      //   style={{
      //     height: "100%",
      //     borderLeft: "5px solid green",
      //     display: "flex",
      //     alignItems: "center",
      //     paddingLeft: 5
      //   }}
      // >
      //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //   <span style={{ marginLeft: 5 }}>Message order updated</span>
      // </div>
      // )


      console.log('MSG ORDER UPDATED', res.data)
      dispatch({
        type: IS_ORDER_LIST_SAVED,
        payload: true,
      });
    }
    else if (!res.data.succes){
        if(res.data.message.errorMsg === 'message order missing') {
          console.log('MESSAGE ORDER???????? MISSING',res.data.message.errorMsg)
              dispatch(saveOrderMessage(msgsArr,eventId))
        }
    }
  } catch (err) {
    // console.log('>>>>> err', err.message.includes('409'));
    // dispatch(setAlert('An error occurred, please try again later', 'error'));

    toast.error(
      <div
      style={{
        height: "100%",
        borderLeft: "5px solid green",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5
      }}
    >
      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      <span style={{ marginLeft: 5 }}>An error occurred, please try again later</span>
    </div>
    )
  }
};

export const resetOrderListSaved = () => (dispatch) => {
  dispatch({
    type: IS_ORDER_LIST_SAVED,
    payload: false,
  });
};

// REMOVE MESSAGE ORDER
export const clearMessageOrder = () => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_ORDER });
};

// GET MESSAGES FOR CSV FILE
export const getMessagesCsvFile = (page, eventId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/message/search/${page}/${APP_LIST_PAGE_SIZE}/?token=${process.env.REACT_APP_API_TOKEN}&eventId=${eventId}&paginated=false`
    );

    if (res.data.success) {
      dispatch({
        type: GET_MESSAGES_CSV_FILE,
        payload: res.data,
      });
    } else {
      dispatch({
        type: NO_MESSAGES,
        payload: res.data,
      });
    }
  } catch (err) {
    // console.log('GET ALL MESSAGES FAILED', err);

    dispatch({
      type: MESSAGES_FAIL,
      payload: err,
    });
  }
};

// CLEAR MESSAGES CSV
export const clearMessagesCsv = () => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGES_CSV });
};

// CLEAR MESSAGES
export const clearMessages = () => (dispatch) =>
  dispatch({ type: CLEAR_MESSAGES });

//Reset loading 
export const resetLoading = () => (dispatch) => dispatch({ type: RESET_LOADING });


// SET LOADING
export const setLoading = () => (dispatch) => dispatch({ type: SET_LOADING });
