import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { checkWsStatusReady } from '../../actions/websocket';

// Hook
// Create script and append to the DOM
function useScript(src) {
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }
    let script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);
      const setAttributeFromEvent = (event) => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        );
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status'));
    }
    const setStateFromEvent = (event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]);
  return status;
}

const PrimusScriptDom = ({ checkWsStatusReady }) => {
  let status = useScript(process.env.REACT_APP_PRIMUS_JS_FILE_URL);

  useEffect(() => {
    if (status === 'ready') {
      checkWsStatusReady();
    }
  }, [status, checkWsStatusReady]);

  return <></>;
};


PrimusScriptDom.propTypes = {
  checkWsStatusReady: PropTypes.func.isRequired,
};

export default connect(null, {
  checkWsStatusReady,
})(PrimusScriptDom);
