import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

import PrimusWs, { PrimusContext } from "../../websocket/PrimusWs";

// REDUX
import {
  getMessagesCsvFile,
  clearMessagesCsv,
  getMessageOrder,
  getAllMessages,
  rebuildPresenterView,
} from "../../../actions/messages";
import {
  wsResetMessageArray,
  // wsAddApprovedMessage,
} from "../../../actions/websocket";
import { setAlert } from "../../../actions/alert";

// UTILS
import { fullDate } from "../../../utils/convertDateAndTime";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { lightGreen } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";

// COMPONENTS
import CollapsableSection from "./CollapsableSection";
import CollapsableSectionDraggableEl from "./CollapsableSectionDraggableEl";
import FilteredQuestionsTable from "./FilteredQuestionsTable";
import FilteredQuestionsTableDraggableEl from "./FilteredQuestionsTableDraggableEl";
import Spinner from "../../UI/Spinner";
import NotFoundData from "../NotFoundData";
import ReloadBtn from "../../UI/inputs/ReloadBtn";
import ToggleStatusBtns from "./ToggleStatusBtns";
import BRRBadge from "../../UI/inputs/BRRBadge";

import "../utilsEvent.css";
import TooltipAlert from "../../UI/TooltipAlert";
import { makeStyles } from '@mui/styles';

import { Badge, Divider, ListItemIcon, Menu, MenuItem, Paper, TextField, Tooltip, Typography, styled } from "@mui/material";
import AddQuestionButton from "./add/AddQuestionButton";
import PresenterViewButton from "./presenterView/PresenterViewButton";
import { Chat, Send } from "@mui/icons-material";
import ChatMessages from "../chat/ChatMessages";
import { fetchAndAppendChats, resetChatNotificationCount, resetChats, setChatMenu } from "../../../actions/chat";


const tooltipStatusChanged =
  "Message list has been updated! Click reload to update";

const OptionButton = styled(Button)(({ theme }) => ({
  marginTop: "0.2rem",
  backgroundColor: "var(--clr-info)",
  color: "var(--clr-w)",
  "&:hover": {
    backgroundColor: "var(--clr-info60)",
  },
 
  

  width:'100%'
}));


const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#fff',
    border: '2px solid var(--clr-info40)',
    color: '#4A4A4A',
    fontSize: '12px',
  },
}));
const BoxLg = styled(Box)(({ theme }) => ({

    

  [theme.breakpoints.down("xs")]: {
    display: 'block',

  },
  [theme.breakpoints.down("sm")]: {
    display: 'block',

  },
  [theme.breakpoints.up("md")]: {
    display:'block',

  },
  [theme.breakpoints.down("md")]: {
    display:'block',

  },
  [theme.breakpoints.up("lg")]: {
    display: 'block',

  },
  [theme.breakpoints.up("xl")]: {
    display: 'block',

  },
}));


const BoxSm = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    display: 'block',
  },
  [theme.breakpoints.down("sm")]: {
    display: 'block',
  },
  [theme.breakpoints.up("md")]: {
    display:'block',
  },
  [theme.breakpoints.down("md")]: {
    display:'block',
  },
  [theme.breakpoints.up("lg")]: {
    display: 'block',
  },
  [theme.breakpoints.up("xl")]: {
    display: 'block',
  },
}));




const EventMessages = ({
  loading,
  allMessage,
  lastUpdate,
  event,
  wsNewMessagesRecived,
  wsResetMessageArray,
  wsApprovedMessagesRecived,
  // wsAddApprovedMessage,
  wsChangedMessageStatus,
  getMessagesCsvFile,
  messagesCsvFile,
  clearMessagesCsv,
  setAlert,
  auth: { user },
  getMessageOrder,
  saveListCounter,
  getAllMessages,
  rebuildPresenterView,
  isWsOrderListRecived,
  socket,
  QAControlledBy

}) => {
  const classes = useStyles();
  const authFirstName = useSelector((state) => state.auth.user.name.first);
    const activeParticipant = useSelector((state) => state.auth.activeParticipant);
    const authEmail = useSelector((state)=> state.auth.user.email)
  const primusMethods = useContext(PrimusContext);
  const bgColorNewMessage = lightGreen[500];

  const userID = useSelector((state) => state.auth.loggedInUserID);
  const eventID = useSelector((state)=> state.event.event.items[0]._id)
  const chatNotificationCount = useSelector((state) => state.chatReducer.chatNotificationCount);

  const questionToolbarRef = useRef(null);
  const [messagesFounded, setMessagesFounded] = useState(false);
  const [filterQuestions, setFilterQuestion] = useState({
    isActive: false,
    messageStatus: "",
  });
  const [hasBeenReloaded, setHasBeenReloaded] = useState(false);
  const [areApproved, setAreApproved] = useState(false);
  const [isToolbarSticky, setIsToolbarSticky] = useState(false);
  const [csvData, setCsvData] = useState();
  const [csvDataReady, setCsvDataReady] = useState(false);
  const csvLinkRef = useRef();
  const didMount = useRef(true);

  // @desc -  Tooltip
  //          State start
  const bgColorNewStatusMessage = yellow[500];
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [tooltipBgColor, setTooltipBgColor] = useState("#FFEB3B");
  const [tooltipTextColor, setTooltipTextColor] = useState("#fff");
  const [openTooltip, setOpenTooltip] = useState(0);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  //          State end

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl_chat, setAnchorEl_chat] = React.useState(null);

  const openOption = Boolean(anchorEl);
  const openOption_chat = Boolean(anchorEl_chat);


  const dispatch = useDispatch()

  const handleClickOption = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOption_chat = (event) => {
    dispatch(setChatMenu(true))
    dispatch(resetChatNotificationCount())
    setAnchorEl_chat(event.currentTarget);
  };
  const handleCloseOption = () => {
    setAnchorEl(null);
  };
  const handleCloseOption_chat = () => {
    dispatch(setChatMenu(false))
    // dispatch(resetChats())
    setAnchorEl_chat(null);
  };





  useEffect(() => {
    wsResetMessageArray();
    // dispatch(fetchAndAppendChats(event.items[0]._id))
    // console.log(event.items[0]._id)
    //    console.log(user._id)
    //    let userName = `${user.name.first} ${user.name.last}`
    //    console.log(`${user.name.first} ${user.name.last}`)
    //     // eslint-disable-next-line

    //     primusMethods.liveParticipantJoined(event.items[0]._id,user._id, userName )
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      allMessage.messages !== null &&
      Object.keys(allMessage.messages).length > 0
    ) {
      setMessagesFounded(true);

      if (saveListCounter === 0) {
        getMessageOrder(event.items[0]._id);
      }
    }

    // eslint-disable-next-line
  }, [allMessage, saveListCounter, getMessageOrder]);

  // @desc Tooltip Message
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }
    if (isWsOrderListRecived) {
      setTooltipBgColor(bgColorNewStatusMessage);
      setTooltipTextColor("#5A5300");
      setTooltipMessage(tooltipStatusChanged);
      setTooltipIndex(1);
      setOpenTooltip(1);
    }
    // eslint-disable-next-line
  }, [isWsOrderListRecived]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        (wsChangedMessageStatus.length > 0 && hasBeenReloaded) ||
        (wsApprovedMessagesRecived.length > 0 && hasBeenReloaded)
      ) {
        const changedMsgs = wsChangedMessageStatus.filter(
          (el) => el.status === "replied"
        );
        const allowedMsgs = wsApprovedMessagesRecived.filter(
          (el) => el.status === "allowed"
        );

        const combinedWhithoutDuplicate = changedMsgs.concat(
          allowedMsgs.filter((bo) =>
            changedMsgs.every((ao) => ao.messageId !== bo.messageId)
          )
        );

        if (changedMsgs.length > 0 || allowedMsgs.length > 0) {
          rebuildPresenterView(combinedWhithoutDuplicate);
        }
      }
    }

    return () => {
      isMounted = false;


    };
  }, [
    wsChangedMessageStatus,
    wsApprovedMessagesRecived,
    hasBeenReloaded,
    rebuildPresenterView,
  ]);

  //
  // @DESC  START CSV
  function handleEvent(e) {
    if (e.type === "loadend") {
      clearMessagesCsv();
      setAlert("Download completed", "success", 3000);
    }
    if (e.type === "error") {
      setAlert("An error occured", "error", 3000);
    }
  }

  function addListeners(xhr) {
    xhr.addEventListener("loadend", handleEvent);
    xhr.addEventListener("error", handleEvent);
  }

  function runXHR(url) {
    const xhr = new XMLHttpRequest();
    addListeners(xhr);
    xhr.open("GET", url);
    xhr.send();
    return xhr;
  }

  useEffect(() => {
    let fields;

    if (messagesCsvFile.success) {
      fields = messagesCsvFile.items.map((el) => {
        return {
          Email: el.email,
          Name: el.name,
          Company: el.company,
          Content: el.content,
          Date: fullDate(el.createTime),
          Status: el.status,
        };
      });
    }

    if (fields !== undefined) {
      setCsvData(fields);

      setCsvDataReady(
        true,
        setTimeout(() => {
          csvLinkRef.current.link.click();
        })
      );
    }
  }, [messagesCsvFile]);

  useEffect(() => {
    if (csvDataReady) {
      runXHR(csvLinkRef.current.link);
    }
    // eslint-disable-next-line
  }, [csvDataReady]);
  //  END CSV
  //

  //
  // START TOOLBAR OBSERVER -----------------------------------------
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const rootBoundInfo = entry.rootBounds;
        const targetInfo = entry.boundingClientRect;

        if (targetInfo.bottom < rootBoundInfo.top) {
          questionToolbarRef.current.classList.add("question-toolbar-position");
          setIsToolbarSticky(true);
        }
        if (
          targetInfo.bottom >= rootBoundInfo.top &&
          targetInfo.bottom < rootBoundInfo.bottom
        ) {
          questionToolbarRef.current.classList.remove(
            "question-toolbar-position"
          );
          setIsToolbarSticky(false);
        }
      },
      {
        rootMargin: "-180px",
      }
    );

    const elRef = questionToolbarRef.current;

    if (elRef) observer.observe(elRef);

    return () => {
      if (elRef) observer.unobserve(elRef);
    };
  }, [questionToolbarRef]);
  // END TOOLBAR OBSERVER -----------------------------------------
  //

  const handleFilterMessages = (status) => {
    if (status === "all") {
      setHasBeenReloaded(false);
      setFilterQuestion((filterQuestions) => ({
        ...filterQuestions,
        isActive: false,
        messageStatus: status,
      }));
      setAreApproved(false);
    } else {
      setFilterQuestion((filterQuestions) => ({
        ...filterQuestions,
        isActive: true,
        messageStatus: status,
      }));
    }
  };

  const handleAllowed = () => {
    setFilterQuestion((filterQuestions) => ({
      ...filterQuestions,
      isActive: false,
      messageStatus: "",
    }));
    setAreApproved(true);
  };

  const handleReload = () => {
    getAllMessages(1, event.items[0]._id);
    getMessageOrder(event.items[0]._id);
    setHasBeenReloaded(true);
    wsResetMessageArray();
  };

  // const handleReload = () => {
  //   getAllMessages(1, event.items[0]._id);
  //   getMessageOrder(event.items[0]._id);
  //   setHasBeenReloaded(true);
  //   wsResetMessageArray();
  // };

  const handleCsvFileDownload = () => {
    getMessagesCsvFile(1, event.items[0]._id);
  };

  return (
    <>
      <Box>
        <Box
          ref={questionToolbarRef}
          sx={{
            display: "flex",
            // gridTemplateColumns: '2fr auto 1fr 1fr 2fr',
            gap: '1rem',
            justifyContent:'space-between',
            alignItems: "center",
            // minHeight: "104px",
            padding: "1.5rem 3.75rem",
            // marginBlock: "2rem"
            overflow:'scroll'
          }}
        >   
        <Box display={'flex'} gap={'1rem'}>
        {/* {isToolbarSticky && 
                  <Typography  sx={{background:'#7CB332', p:'0.6rem', borderRadius:'0.4rem', width:'max-content', color:'white'}}>{QAControlledBy} is controlling the Q&A</Typography>
        } */}
          <ToggleStatusBtns
            handleFilterMessages={handleFilterMessages}
            hasBeenReloaded={hasBeenReloaded}
            handleAllowed={handleAllowed}
          />
</Box>


          <Box sx={{display:'flex'}} gap={'1rem'}>

          <AddQuestionButton />
         
          <BoxSm sx={{ mr: 1 }}>
            <OptionButton
              id="basic-button"
              aria-controls={openOption ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openOption ? "true" : undefined}
              onClick={handleClickOption}
            >
              More
            </OptionButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openOption}
              onClose={handleCloseOption}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
          
              <MenuItem>
              
                <PresenterViewButton
                  eventId={event.items[0]._id}
                  eventTitle={event.items[0].title}
                  modName={authFirstName}
                  modEmail={authEmail}
                />
              </MenuItem>

              <MenuItem>
              <Box>
            <Button
              onClick={handleCsvFileDownload}
              disabled={!messagesFounded}
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{ width: "max-content", backgroundColor: "var(--clr-info)" }}
            >
              Download Messages CSV
            </Button>
            {csvDataReady && (
              <CSVLink
                data={csvData}
                asyncOnClick={true}
                filename={`${event.items[0].title
                  .trim()
                  .replaceAll(" ", "-")}-questions.csv`}
                target="_blank"
                ref={csvLinkRef}
              />
            )}
          </Box>

              </MenuItem>
              {/* <MenuItem>
                <AddQuestionButton />
              </MenuItem> */}
            </Menu>



          </BoxSm>


          

          <Box sx={{display:'flex'}}>
            {isToolbarSticky && (
              <>
                <BRRBadge
                  right={-260}
                  top={-8}
                  background={bgColorNewMessage}
                  badgeContent={wsNewMessagesRecived.length}
                />
                <BRRBadge
                  right={-225}
                  top={-8}
                  color={"#5A5300"}
                  background={"#FFEB3B"}
                  badgeContent={
                    wsChangedMessageStatus.length ||
                    wsApprovedMessagesRecived.length
                  }
                />
              </>
            )}

            <TooltipAlert
              title={tooltipMessage}
              placement="left"
              open={openTooltip === tooltipIndex}
              leaveDelay={500}
              enterNextDelay={2000}
              sx={{
                zIndex: 1099,
                "& .MuiTooltip-tooltip": {
                  color: `${tooltipTextColor}`,
                  backgroundColor: `${tooltipBgColor}`,
                },
                "& .MuiTooltip-arrow": {
                  color: `${tooltipBgColor}`,
                },
              }}
            >
              <ReloadBtn
                handleReload={handleReload}
                loading={loading}
                lastUpdate={lastUpdate}
                userAccessLevel={user.level}
                newMessage={wsNewMessagesRecived}
                newMessageStatus={{
                  wsChangedMessageStatus,
                  wsApprovedMessagesRecived,
                }}
              />
            </TooltipAlert>
          </Box>

                  
          { isToolbarSticky &&
              <BoxSm sx={{ mr: 1 }}>
                <OptionButton
                  id="basic-button"
                  aria-controls={openOption_chat ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openOption_chat ? "true" : undefined}
                  onClick={handleClickOption_chat}
                  sx={{
                    background: 'rgba(92 ,133, 37,0.9)',
                    '&:hover': {
                      background: 'rgba(92, 137, 37,1)',


                      '& h6': {
                        color: 'var(--clr-w)',
                      },
                    },
                  }}
                >
                    <Badge badgeContent={
            chatNotificationCount >0 ? <span style={{color:'black', background:'yellow',height:20 ,width:20, borderRadius:'50%', textAlign:'center'}}>
                <p style={{marginTop:5}}>  
                {chatNotificationCount > 0 ? chatNotificationCount  : 0}
                </p>
                
                </span>
            : 0 
        } >

            <Chat sx={{lineHeight:'1.9rem'}}/>
            </Badge>
                </OptionButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl_chat}
                  open={openOption_chat}
                  onClose={handleCloseOption_chat}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    style: {
                      paddingTop: 0
                    }
                  }}
                  PaperProps={{
                    style: {
                      transform: 'translateX(-10%) translateY(0.4rem)',
                      borderRadius: '5px',
                      overflowY: 'scroll',
                      padding: 0
                    }
                  }}

                >



                  <ChatMessages socket={socket} close={handleCloseOption_chat} />


                </Menu>



              </BoxSm>
            }
          </Box>



        </Box>

        {loading ? (
          <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
            <Spinner />
          </Box>
        ) : messagesFounded && !filterQuestions.isActive && !areApproved ? (
          <Box className="fade-in-box">
            <div
              className="blackBox"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                opacity: "0.5",
                zIndex: 500,
                display: `${
                  activeParticipant === user._id
                    ? "none"
                    : "block"
                }`,
              }}
            ></div>
            <CollapsableSection
              sectionName="new"
              filterName="new"
              color="var(--clr-info)"
              eventId={event.items[0]._id}
              userId={user._id}
            />
            <CollapsableSectionDraggableEl
              sectionName="approved"
              color="green"
              eventId={event.items[0]._id}
              userId={user._id}
            />

            <Divider />
            <Divider />
            <Divider />
            <Divider />

            <CollapsableSection
              sectionName="Answered"
              filterName="replied"
              color="var(--clr-warning)"
              eventId={event.items[0]._id}
              userId={user._id}
            />

            <CollapsableSection
              sectionName="archived"
              filterName="archive"
              color="var(--clr-danger)"
              eventId={event.items[0]._id}
              userId={user._id}
            />
          </Box>
        ) : filterQuestions.isActive ? (
          <FilteredQuestionsTable sectionName={filterQuestions.messageStatus} />
        ) : areApproved ? (
          <FilteredQuestionsTableDraggableEl
            eventId={event.items[0]._id}
            userId={user._id}
          />
        ) : (
          <NotFoundData message={"Could not find any messages"} />
        )}
      </Box>
    </>
  );
};

EventMessages.propTypes = {
  loading: PropTypes.bool,
  allMessage: PropTypes.object,
  lastUpdate: PropTypes.any,
  event: PropTypes.object,
  wsNewMessagesRecived: PropTypes.array,
  wsResetMessageArray: PropTypes.func.isRequired,
  wsApprovedMessagesRecived: PropTypes.array,
  wsChangedMessageStatus: PropTypes.array,
  // wsAddApprovedMessage: PropTypes.func.isRequired,
  auth: PropTypes.object,
  getMessagesCsvFile: PropTypes.func.isRequired,
  messagesCsvFile: PropTypes.object,
  clearMessagesCsv: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getMessageOrder: PropTypes.func.isRequired,
  saveListCounter: PropTypes.number.isRequired,
  getAllMessages: PropTypes.func.isRequired,
  rebuildPresenterView: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.messages.loading,
  allMessage: state.messages.allMessage,
  lastUpdate: state.messages.lastUpdate,
  event: state.event.event,
  wsNewMessagesRecived: state.websocket.wsNewMessagesRecived,
  wsApprovedMessagesRecived: state.websocket.wsApprovedMessagesRecived,
  wsChangedMessageStatus: state.websocket.wsChangedMessageStatus,
  auth: state.auth,
  messagesCsvFile: state.messages.messagesCsvFile,
  saveListCounter: state.messages.saveListCounter,

});

export default connect(mapStateToProps, {
  wsResetMessageArray,
  getMessagesCsvFile,
  clearMessagesCsv,
  setAlert,
  getMessageOrder,
  getAllMessages,
  rebuildPresenterView,
  // wsAddApprovedMessage,
})(EventMessages);
