import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// HOOKS
import useViewportDimension from '../../hooks/useViewportDimension';

// MUI
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// COMPONENTS
import HeaderBar from '../layout/HeaderBar';
import Sidebar from './Sidebar';

const Layout = () => {
  const [width] = useViewportDimension();
  const [maxWidth, setMaxWidth] = useState('');

  useEffect(() => {
    if (width >= 1700) setMaxWidth('min(2100px, 90vw)');
    if (width >= 1300 && width < 1700) setMaxWidth('xl');
    if (width < 1300) setMaxWidth('100%');
  }, [width]);

  return (
    <Box
      sx={{
        // minWidth: '900px',
        // display: 'grid',
        // width:'100%',
        // gridTemplateColumns: '75px 1fr',
        background: 'var(--clr-gray10)',
      }}
    >

      {/* <Sidebar /> */}
      <Box>
        <HeaderBar />
        <Container
          maxWidth={false}
          sx={{
            // height: 'calc(100vh - 40px)',
            minHeight:'calc(100vh - 40px)',
            // paddingTop: `${width < 1300 ? '4.5rem' : '6rem'}`,
            paddingTop: '1rem',
            paddingBottom: '1rem',
            // paddingRight: `${width < 1400 && '0px !important'}`,
            // paddingLeft: `${width < 1400 && '0px !important'}`,
            marginLeft: `${width < 1400 ? '0px' : 'auto'}`,
            marginRight: `${width < 1400 ? '0px' : 'auto'}`,
            // maxWidth: `${maxWidth}`,
            // minWidth: 'var(--layout-container-min-width)',
            background: 'var(--clr-gray10)'
          }}
        >
          <Outlet />


        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
