import { SET_LOADING } from '../constants/utilsConstants';
import {
  GET_EVENTS,
  GET_EVENT,
  SEARCH_EVENT,
  SERACH_EVENT_IN_STORE,
  EVENT_ERROR,
  CLEAR_EVENTS,
  CLEAR_EVENT,
  CLEAR_SEARCHED_EVENT,
  SET_TAB_VALUE_GLOBAL,
} from '../constants/eventConstants';

const initialState = {
  events: {},
  event: null,
  searchedEvent: null,
  lastFetch: 0,
  loading: true,
  error: {},
  globalTabValue:0
};

function eventReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS:
      return {
        ...state,
        events: payload,
        lastFetch: Date.now(),
        loading: false,
      };
    case GET_EVENT:
      return {
        ...state,
        event: payload,
        loading: false,
      };
    case SEARCH_EVENT:
      return {
        ...state,
        searchedEvent: payload,
        loading: false,
      };
    case SERACH_EVENT_IN_STORE:
      const cleanedPayload = payload
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .replace(/  +/g, ' ')
        .split(' ')
        .join('|');
      const eventsInStore = state.events.items;
      const filterdEvents = eventsInStore.filter((el) =>
        el.title.toLowerCase().match(new RegExp(cleanedPayload, 'g'))
      );

      return {
        ...state,
        searchedEvent: {
          success: true,
          total: filterdEvents.length,
          page: 1,
          pageSize: 20,
          items: filterdEvents,
        },
        loading: false,
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        events: {},
        event: null,
        lastFetch: 0,
        searchedEvent: null,
        loading: true,
        error: {},
      };
    case CLEAR_EVENT:
      return {
        ...state,
        event: null,
        loading: true,
        error: {},
      };
    case CLEAR_SEARCHED_EVENT:
      return {
        ...state,
        searchedEvent: null,
      };

    case SET_TAB_VALUE_GLOBAL:
      return {
        ...state,
      globalTabValue:payload  
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default eventReducer;
