import axios from "axios";
import { ACTIVE_TAB, CREATE_NEW_USER, GET_CP_USERS, RESET_CREATE_USER, RESET_EDIT_USER, RESET_EDIT_USER_ID, SET_CREATE_USER_COMPANIES, SET_CREATE_USER_COMPANY, SET_CREATE_USER_EMAIL, SET_CREATE_USER_EVENTS, SET_CREATE_USER_FIRST_NAME, SET_CREATE_USER_LAST_NAME, SET_CREATE_USER_PASSWORD, SET_CREATE_USER_ROLE, SET_CREATE_USER_STATUS, SET_EDIT_CP_ONLY_COMPANY, SET_EDIT_USER_COMPANIES, SET_EDIT_USER_EMAIL, SET_EDIT_USER_EVENTS, SET_EDIT_USER_ID, SET_EDIT_USER_NAME, SET_EDIT_USER_PASSWORD, SET_EDIT_USER_ROLE, SET_EDIT_USER_STATUS, SET_ERROR, UPDATE_USER_SUCCESS } from "../constants/cpManagement";
import { setAlert } from "./alert";
import { UPDATE_MESSAGE_FAIL } from "../constants/eventMessagesConstants";
import { toast } from "react-toastify";
import { CheckBoxOutlined } from "@mui/icons-material";
import { updateToClientPortalUser } from "../components/event/questions/presenterView/PresenterService";

export const getCpUsers =
  () => async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/account/CPusers/?token=${process.env.REACT_APP_API_TOKEN}`
      );

      if (res.data.success) {


            // id-> _id 
            // name -> first_last 
            // Role -> level 
            // data -> registerDate 
            // active 
            console.log('CP users', res.data.users[0])


            const newArray = res.data.users.map(obj => ({
                name: `${obj.name.first} ${obj.name.last}`,
                id: obj._id,
                email:obj.email,
                role:obj.level === 1 ? 'presenter' : (obj.level === 2 ? 'moderator' : (obj.level === 6 ? 'root' : 'N/A')),
                registerDate:obj.registerDate,
                companys:obj.company,
                events:obj.events,
                active:obj.active,
                clientPortalOnlyCompany:obj.clientPortalOnlyCompany
                

              }));


              console.log('CP users', newArray[0])


        dispatch({
          type: GET_CP_USERS,
          payload: newArray,
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: SET_ERROR,
        payload: err,
      });
    }
  };




// Set active tab
export const setActiveTab = (tabName) => (dispatch) => {
  dispatch({
    type: ACTIVE_TAB,
    payload:tabName
  });
};


  //update user level 
export const updaateUserLevel = (id, level) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };



  let formData =   JSON.stringify({
    data:{
      token:process.env.REACT_APP_API_TOKEN,
      user:{
        _id:id,
        level:level
      }
    }
     
    
    }) 


  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/account/updateAccessLevel/?token=${process.env.REACT_APP_API_TOKEN}`,
      formData,
      config
    );

    console.log('Level Updated', res.data)
      dispatch(getCpUsers())
    // dispatch({
    //   type: PASSWORD_UPDATED,
    //   payload: res.data,
    // });
    toast.success(
      <div
      style={{
        height: "100%",
        borderLeft: "5px solid green",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5
      }}
    >
      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      <span style={{ marginLeft: 5 }}>User Level Updated</span>
    </div>
    )
    // dispatch(setAlert('User Level Updated', 'success', 4000));
  } catch (err) {
    console.log('update level fail', err);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    dispatch(setAlert('Updates failed', 'error', 4000));
  }
};


//update user status

export const updateUserStatus = (id, status) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };



  let formData =   JSON.stringify({
    data:{
      token:process.env.REACT_APP_API_TOKEN,
      user:{
        _id:id,
        active:status
      }
    }
     
    
    }) 


  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/account/updateStatus/?token=${process.env.REACT_APP_API_TOKEN}`,
      formData,
      config
    );

    console.log('Level Updated', res.data)
      dispatch(getCpUsers())
    // dispatch({
    //   type: PASSWORD_UPDATED,
    //   payload: res.data,
    // });
    toast.success(
      <div
      style={{
        height: "100%",
        borderLeft: "5px solid green",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5
      }}
    >
      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      <span style={{ marginLeft: 5 }}>User Status Changed</span>
    </div>
    )  } catch (err) {
    console.log('update level fail', err);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    dispatch(setAlert('Updates failed', 'error', 4000));
  }
};



export const setEditUserID = (id) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_ID,
    payload:id
  });
};

export const resetEditUserID = () => (dispatch) => {
  console.log('closing----------------', 'actual')

  dispatch({
    type: RESET_EDIT_USER_ID
   });
};



export const setEditUserEvents = (events) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_EVENTS,
    payload:events
  });
};


export const setEditUserCompanies = (companies) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_COMPANIES,
    payload:companies
  });
};


export const setEditUserStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_STATUS,
    payload:status
  });
};

export const setEditUserCPOnlyCompany = (company) => (dispatch) => {
  dispatch({
    type: SET_EDIT_CP_ONLY_COMPANY,
    payload:company
  });
};


export const setEditUserRole = (role) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_ROLE,
    payload:role
  });
};


export const setEditUserName = (role) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_NAME,
    payload:role
  });
};


export const setEditUserEmail = (role) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_EMAIL,
    payload:role
  });
};


export const setEditUserPassword = (password) => (dispatch) => {
  dispatch({
    type: SET_EDIT_USER_PASSWORD,
    payload:password
  });
};

export const resetEditUserDetails = () => (dispatch) => {
  dispatch({
    type: RESET_EDIT_USER  
  });
};




export const updateUserAccount = (user, type) => async(dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {

    let newUser ={}
    let name = user.name.split(" ").filter(element => element.trim() !== '')
    newUser.name = {'first':name[0],'last':name[1]};
    newUser.email = user.email
    newUser.company = user.companies
    newUser.cPonlyCompany=user.cPonlyCompany
    newUser.events = user.events
    newUser.level = user.role
    newUser.active = user.status
    newUser._id = user.userID
    newUser.groups=[]
    if(user.password &&  user.password.length >0) {
      newUser.password = user.password
    }

    console.log('BEFORE USER NAME SPLIT', user.name.split(" "))
    console.log('USER NAME SPLIT', name)


    const formData = JSON.stringify({user: newUser})

    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/account/updateAccount/?token=${process.env.REACT_APP_API_TOKEN}`,
      formData,
      config
    );
    if(res.data.success) {
      dispatch(getCpUsers())
      // If password is changed, then send password 
     

      //If details are changed/updated, then email

      toast.success(
        <div
        style={{
          height: "100%",
          borderLeft: "5px solid green",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
      >
        <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        <span style={{ marginLeft: 5 }}>User Updated</span>
      </div>
      )
      console.log('res-------', res.data)

      if(type === 'approved') {
          // send approved email 
          let sendApprovedEmail_formdata = {
            toEmail:user.email,
            companies:user.companies,
            events:user.events
          }

          console.log('approvedEmail', sendApprovedEmail_formdata)
          const sendApprovedEmail = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/account/approvedEmail/?token=${process.env.REACT_APP_API_TOKEN}`,
            sendApprovedEmail_formdata,
            config
          );
      }

      if(type === 'edited') {
        // send approved email 
        let sendEditedEmail_formdata = {
          toEmail:user.email,
          companies:user.companies,
          events:user.events,
          name:name,
          email:user.email,
          password:user.password
        }

        console.log('editedEmail', sendEditedEmail_formdata)
        const sendEditedEmail = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/account/editedEmail/?token=${process.env.REACT_APP_API_TOKEN}`,
          sendEditedEmail_formdata,
          config
        );
    }


    } else {
      console.log('cpManagementError', res)
      toast.error(
        <div
        style={{
          height: "100%",
          borderLeft: "5px solid green",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
      >
        <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        <span style={{ marginLeft: 5 }}>Failed updating user</span>
      </div>
      )
    }

  } catch (error) {
    console.log('Error', error)
    dispatch({
      type: UPDATE_MESSAGE_FAIL,
      payload:error
    });
  }

 
};

export const deleteCpUser = (id) => async(dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };


  try {


    let formData =   JSON.stringify({
      data:{
        token:process.env.REACT_APP_API_TOKEN,
        user:{
          _id:id,
          level:'level'
        }
      }
       
      
      }) 
    console.log('user delete' , id)
    const res = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/account/removeUser?token=${process.env.REACT_APP_API_TOKEN}`,
      {data:{id}},
      config
    );

    dispatch(getCpUsers())
    toast.success(
      <div
      style={{
        height: "100%",
        borderLeft: "5px solid green",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5
      }}
    >
      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      <span style={{ marginLeft: 5 }}>User Deleted</span>
    </div>
    )
    console.log('res-------', res.data)

    // dispatch({
    //   type: UPDATE_USER_SUCCESS,
    //   payload:role
    // });
  } catch (error) {
    console.log('Error', error)
    dispatch({
      type: UPDATE_MESSAGE_FAIL,
      payload:error
    });
  }

 
};





//Create user

export const setCreateUserFirstName = (firstname) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_FIRST_NAME,
    payload:firstname
  });
};

export const setCreateUserLastName = (lastname) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_LAST_NAME,
    payload:lastname
  });
};


export const setCreateUserEmail = (email) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_EMAIL,
    payload:email
  });
};

export const setCreateUserPassword = (password) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_PASSWORD,
    payload:password
  });
};

export const setCreateUserCpCompany = (company) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_COMPANY,
    payload:company
  });
};

export const setCreateUserCompanies = (companies) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_COMPANIES,
    payload:companies
  });
};


export const setCreateUserEvents = (events) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_EVENTS,
    payload:events
  });
};

export const setCreateUserStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_STATUS,
    payload:status
  });
};

export const setCreateUserRole = (role) => (dispatch) => {
  dispatch({
    type: SET_CREATE_USER_ROLE,
    payload:role
  });
};

export const resetCreateUserDetails = () => (dispatch) => {
  dispatch({
    type: RESET_CREATE_USER  
  });
};


export const createNewUser = (user)=> async(dispatch) =>{
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };


  try {

    let newUser ={}
    newUser.email = user.email
    newUser.firstname = user.firstname
    newUser.lastname = user.lastname
    newUser.level = user.role
    newUser.active = user.status ? user.status : false
    newUser.company = user.companies
    newUser.password = user.password

    newUser.events = user.events
    newUser.clientPortalOnlyCompany = user.company


    const formData = JSON.stringify({newUser})

    console.log('user update' , user)
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/register/?token=${process.env.REACT_APP_API_TOKEN}`,
      newUser,
      config
    );

    if(res.data.success) {

      let id = res.data.id
      let cp = await updateToClientPortalUser(id)
      console.log('cpppppppppppppp', cp)
      if(cp.success) {

        // dispatch(updateToClientPortalUser(id))
        //Send email to sales - User created with details
        const res_sendEmailToSales = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/account/sendEmailToSales/?token=${process.env.REACT_APP_API_TOKEN}`,
          newUser,
          config
        );
        
        dispatch(getCpUsers())
        toast.success(
          <div
          style={{
            height: "100%",
          borderLeft: "5px solid green",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
        >
      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      <span style={{ marginLeft: 5 }}>User Created</span>
    </div>
    )
    console.log('res-------', res.data)
  }
    
  } else {
    dispatch(setAlert('Creating user failed', 'error', 4000));

  }

  } catch (error) {
    console.log('new user error', error)
    dispatch(setAlert('Creating user failed', 'error', 4000));


  }

  dispatch({
    type:CREATE_NEW_USER
  })

}

