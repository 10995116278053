import React, { useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import PrimusWs, { PrimusContext } from '../websocket/PrimusWs';

// REDUX
import { getAllMessages } from '../../actions/messages';
import { clearMessages } from '../../actions/messages';
import { clearEvent, clearSearchedEvent } from '../../actions/event';
import { wsResetMessageArray } from '../../actions/websocket';

// MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { lightGreen } from '@mui/material/colors';

// COMPONENTS
import BackToEventsListBtn from '../UI/inputs/BackToEventsListBtn';
import TabPanel from '../UI/TabPanel';
import PresenterMessages from './questions/PresenterMessages';
import BRRBadge from '../UI/inputs/BRRBadge';
import NotFoundData from './NotFoundData';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EventDashboardLowAccessLevel = ({
  event,
  getAllMessages,
  clearMessages,
  clearEvent,
  clearSearchedEvent,
  wsApprovedMessagesRecived,
  wsChangedMessageStatus,
  wsResetMessageArray,
  auth: { user },
  socket_cp
}) => {
  const primusMethods = useContext(PrimusContext);
  const value = 0;
  const bgColorApprovedMsg = lightGreen[500];
  const auth = useSelector((state) => state.auth);




  useEffect(()=>{
    socket_cp.emit('joinEventPresenter',  event.items[0]._id, auth.loggedInUserID)

      return ()=>{
        // socket.off('message')
        socket_cp.off('joinEventPresenter')
        socket_cp.off('joinEventPresenter')

      }
  },[])



  useEffect(() => {
    if (user) {
      getAllMessages(1, event.items[0]._id);
    }
    // eslint-disable-next-line
  }, [user]);

  //  @desc Close primus connection on unmount
  // useEffect(() => {
  //   return function cleanup() {
  //     if (Object.keys(primusMethods).length > 0) {
  //       primusMethods.closeWsConnection();
  //     }
  //   };
  // }, [primusMethods]);

  const handleClick = () => {
    clearMessages();
    clearEvent();
    clearSearchedEvent();
    wsResetMessageArray();
  };

  return (
    <>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'var(--layout-container-min-width)',
      }}
    >
      <BackToEventsListBtn handleClick={handleClick} />
      <Box
        sx={{ width: '100%', backgroundColor: 'var(--clr-w)', boxShadow: 2 }}
      >
        <Box
          sx={{
            borderColor: 'divider',
            backgroundColor: 'var(--clr-w)',
          }}
          >
          <Tabs
            value={value}
            aria-label='tabs'
            sx={{
              color: 'white',
              overflow: 'visible',
              '& div:first-of-type': { overflow: 'visible !important' },
            }}
            >
            <Tab
              label={
                <>
                  {/* <BRRBadge
                    badgeContent={wsApprovedMessagesRecived.length}
                    background={bgColorApprovedMsg}
                    /> */}
                  <BRRBadge
                    badgeContent={wsChangedMessageStatus.length}
                    text='Q&A'
                    right={-40}
                    color={'#5A5300'}
                    background={'#FFEB3B'}
                    />
                </>
              }
              {...a11yProps(0)}
              sx={{
                minWidth: '300px',
                color: 'var(--clr-w)',
                fontSize: '1.2rem',
                textTransform: 'capitalize',
                overflow: 'visible',
              }}
              />
          </Tabs>
        </Box>
        {event.items[0].webcast.feedbackEnabled ? (
          <TabPanel value={value} index={0}>
            <PresenterMessages />
          </TabPanel>
        ) : (
          <NotFoundData message={'Online Q&A session is not enabled'} />
          )}
      </Box>
    </Box>
</>
  );
};

EventDashboardLowAccessLevel.propTypes = {
  getAllMessages: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearEvent: PropTypes.func.isRequired,
  clearSearchedEvent: PropTypes.func.isRequired,
  wsApprovedMessagesRecived: PropTypes.array,
  wsResetMessageArray: PropTypes.func.isRequired,
  wsChangedMessageStatus: PropTypes.array,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event.event,
  wsApprovedMessagesRecived: state.websocket.wsApprovedMessagesRecived,
  wsChangedMessageStatus: state.websocket.wsChangedMessageStatus,
});

export default connect(mapStateToProps, {
  getAllMessages,
  clearMessages,
  clearEvent,
  clearSearchedEvent,
  wsResetMessageArray,
})(EventDashboardLowAccessLevel);
