import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { deleteCpUser, getCpUsers, setEditUserID, updaateUserLevel } from "../../../actions/cpManagement";
import EditUser from "./EditUser";
import { setActiveTab } from "../../../actions/cpManagement";
import AlertDialog from "../alert/Alert";


const useStyles = makeStyles({
    dataGrid: {
        //   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        borderRadius: 3,
        color: "black",
        //   height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        width: "100%"
    },

    
    



});



const rows = [
    { id: 1, name: 'Cp TestUser', email: 'cpTestUser@gmail.com', age: 35 },
    { id: 2, name: 'kk TestUser', email: 'cpTestUser@gmail.com', age: 35 },

];



const ApprovedUsers = () => {
    const cpUsers = useSelector((state) => state.cpManagementReducer.cpUsers)
    const apiRef = useGridApiRef();
    const classes = useStyles();
    const dispatch = useDispatch()

    // console.log('cp users ------', cpUsers)
   


    const [open, setOpen] = React.useState(false);
    const [user,setUser] =  useState(null)
    const handleOpen = (data) => {
        // console.log('data', data)
        setUser(data)
        dispatch(setEditUserID(data.id))
        setOpen(true);

    }
    const handleClose = () => setOpen(false);


    const [selectedUser, setSelectedUser] = useState(null)

    const [openAlert, setOpenAlert] = React.useState(false);

    const handleClickOpenAlert = () => {
      setOpenAlert(true);
    };
  
    const handleCloseAlert = () => {
      setOpenAlert(false);
    };

    const handleDeleteUser = (user)=>{
        setSelectedUser(user)
        setOpenAlert(true)
    }



    const updateLevel = (row, level)=>{
        console.log('row', row, level)
        dispatch(updaateUserLevel(row.id, level))
    }

    useEffect(()=>{
        console.log('tabs cp entered Approved TAB')
        // set active tab as pending approval 
            dispatch(setActiveTab('approvedTab'))
    return ()=>{
        console.log('tabs cp LEFT Approved TAB')
        // remove active tab
        dispatch(setActiveTab(''))

    }

    },[])

    const columns = [
        {
            field: 'name', headerName: 'Name',
            minWidth: 200,
            width: "max-content",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            flex: 1
        },
    
        {
            field: 'email', headerName: 'Email',
            minWidth: 700,
            width: "max-content",
            minWidh:900,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <div>
                  <Typography sx={{color:'', wordBreak:'break-word'}}>{params.value}</Typography>
                 
                </div>
              )
        },
        // {
        //     field: 'active', headerName: 'Active',
        //     // minWidth: 400,
        //     width: 100,
        //     headerClassName: 'super-app-theme--header',
        //     headerAlign: 'center',
        //     flex: 1
        // },
        {
            field: 'role', headerName: 'Role',
            width: 300,
            // width: "max-content",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            // flex: 1,
            renderCell: (params) => {
                // console.log('each params', params)
                const onClick = (e) => {
                    const currentRow = params.row;
    
                    return alert(JSON.stringify(currentRow, null, 4));
                };
    
                return (
                    <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>


                 


                        {
                            params.row.role === 'moderator' ?
                                <>
                                <Tooltip title="Change role to Moderator">

                                <Button variant="contained"
                                    sx={{
                                        background: '#7CB332', color: 'white', '&:hover': {
                                            backgroundColor: '#6ab04c'
                                        }
                                    }}
                                    onClick={() => updateLevel(params.row, '2')}> Moderator</Button>
                                    </Tooltip>
                                    </>

                                : (params.row.role === 'root' ? <Button variant="contained"
                                    sx={{
                                        background: '#e74c3c', color: 'white', '&:hover': {
                                            backgroundColor: '#e74c3c'
                                        }
                                    }}
                                > Root</Button>
                                    : 
                                    <>
                                    <Tooltip title="Update role to Moderator">

                                    <Button variant="outlined" sx={{
                                        color: '', '&:hover': {
                                            backgroundColor: '#6ab04c',color:'white',border:0
                                        }
                                    }} onClick={() => updateLevel(params.row, '2')}> Moderator</Button>
                                    </Tooltip>
                                    
                                    </>
                                    
                                    )   
                        }
    
    {
                            params.row.role === 'presenter' ?
                            <>
                            <Tooltip title="Update role to presenter">

                                <Button variant="contained"
                                    sx={{
                                        background: '#7CB332', color: 'white', '&:hover': {
                                            backgroundColor: '#6ab04c'
                                        }
                                    }}
                                    onClick={() => updateLevel(params.row, '1')}> Presenter</Button>
                                    </Tooltip>
                                    </>

                                : (params.row.role === 'root' ? ''
                                    :
                                    <>
                             <Tooltip title="Update role to presenter">

                                    <Button variant="outlined"
                                        sx={{
                                            color: '', '&:hover': {
                                                backgroundColor: '#6ab04c',color:'white',border:0
                                            }
                                        }} onClick={() => updateLevel(params.row, '1')}> Presenter</Button>
                                        </Tooltip>
                                        </>
                                        )

                                    

                           
                        }
    
    
    {/* 
                        <Button variant="contained" sx={{ background: '#fe5e37', color: 'white' }} onClick={() => onClick('moderator')}> Moderator</Button>
                        <Button variant="outlined" sx={{ color: '' }} onClick={() => onClick('presenter')}> Presenter</Button> */}
    
                    </Box>
                );
            },
    
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 200,
            // width: "max-content",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            // flex: 1,
    
            renderCell: (params) => {
                const onClick = (e) => {
                    const currentRow = params.row;
                    return alert(JSON.stringify(currentRow, null, 4));
                };
    
                return (
                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                            <Tooltip title="Edit user">

                        <IconButton onClick={ ()=> handleOpen(params.row)}>
                            <Edit  />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete user">

                        {/* <IconButton onClick={()=> dispatch(deleteCpUser(params.row.id))}> */}
                        <IconButton onClick={()=>handleDeleteUser(params.row)}>

                            <Delete sx={{color:'#eb4d4b'}} />
                        </IconButton>
                        </Tooltip>
                    </Box>
                );
            },
        }
    ];


    useEffect(() => {
        dispatch(getCpUsers())
    }, [])



    return (
        <Box
            sx={{
                width: '100%',
                overflow: 'hidden',
                border: '1px solid var(--clr-gray20)',
                height: '75vh',
            }}
            className='fade-in-box'
        >
       {
       user &&    <EditUser handleClose={handleClose} open={open} user={user} />

       }
                   <AlertDialog handleClose={handleCloseAlert} open={openAlert} text={"Are you sure"} userID={selectedUser && selectedUser} action={'hi'} />


            <DataGrid
                apiRef={apiRef}
                density="comfortable"
                
                rows={cpUsers}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100]}
                slots={{ columnMenu: (<h1>Hi</h1>) }}
                className={classes.dataGrid}
                pageSize={20}
                // slots={{ toolbar: GridToolbar }}
                components={{ Toolbar: GridToolbar, }}
                componentsProps={{
                    Toolbar:{
                        background:'red'
                    },
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                // autoHeight={true}
                autoPageSize={true}
                pageSizeOptions={[5, 10]}
                disableSelectionOnClick={true}
                disableRowSelectionOnClick={true}
                sx={{
                    '& .super-app-theme--header': {
                        backgroundColor: 'gray',
                        color: 'white'
                    },
                    "& .MuiDataGrid-columnHeader": {
                        fontSize: "1rem",
                        textTransform: "capitalize",
                    },
                    '& .MuiDataGrid-cell': {
                        fontSize: "1rem",
                    }
                }}
            />
        </Box>

    )
}


export default connect()(ApprovedUsers)