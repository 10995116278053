import { Button, Chip, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import SplitQuestionModal from "./SplitQuestionModal";

const SplitButtomCustom = styled(Button)(({ theme }) => ({
    // marginTop: '0.2rem',
    backgroundColor: 'var(--clr-info40)',
    color: 'var(--clr-gray10)',
    '&:hover': {
        backgroundColor: 'var(--clr-info60)',
    },
    minWidth: 'auto'
}))
const SplitButtomCustomType2 = styled(Button)(({ theme }) => ({
    marginTop: '0.4rem',
    backgroundColor: 'var(--clr-gray40)',
    color: 'var(--clr-gray10)',
    '&:hover': {
        backgroundColor: 'var(--clr-gray60)',
    },
    width: '70px',
    padding: '6px 16px',
    marginBottom: '0.4rem'
}))

const SplitButton = ({ eachMsg, type }) => {



    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>

            {
                // type === 'text' ? <Chip label="here" onClick={handleOpen}/> 
                type === 'type2' ? <SplitButtomCustomType2 variant="contaied" onClick={handleOpen}>Edit </SplitButtomCustomType2>

                    : <SplitButtomCustom variant="contaied" onClick={handleOpen}>
                        Edit
                    </SplitButtomCustom>
            }


            <SplitQuestionModal open={open} handleClose={handleClose} setOpen={setOpen} msg={eachMsg} />
        </>
    )
}

export default SplitButton;