import React from 'react';

// MUI
import Badge from '@mui/material/Badge';

const BRRBadge = ({
  badgeContent,
  top = -12,
  right = -28,
  text,
  background,
  color = '#fff',
}) => {
  return (
    <Badge
      badgeContent={badgeContent}
      max={99}
      sx={{
        '& span': {
          right: `${right}px`,
          top: `${top}px`,
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          color: `${color}`,
          background: `${background} !important`,
          fontSize: '0.9rem',
          lineHeight: '1',
        },
      }}
    >
      {text}
    </Badge>
  );
};

export default BRRBadge;
