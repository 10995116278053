import { RESET_SOCKET_IO_ERROR, SOCKETIO_CP_CONNECTED, SOCKETIO_CP_DISCONNECTED, SOCKET_IO_CONNECTED, SOCKET_IO_DISSCONNECTED, SOCKET_IO_ERROR } from '../constants/socketio';


const initialState = {
    isSocketioConnected: false,
    socketError: null,
    loading: false,
    socketioCP: null

};

function socketio(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SOCKETIO_CP_CONNECTED:
            return {
                ...state,
                socketioCP: payload
            }

        case SOCKETIO_CP_DISCONNECTED:
            return {
                ...state,
                socketioCP: null
            }



        case SOCKET_IO_CONNECTED:
            return {
                ...state,
                isSocketioConnected: payload,
            };
        case SOCKET_IO_DISSCONNECTED:
            return {
                ...state,
                isSocketioConnected: payload,
            };

        case SOCKET_IO_ERROR:
            return {
                ...state,
                socketError: payload,
            };


        case RESET_SOCKET_IO_ERROR:
            return {
                ...state,
                socketError: null,
            };

        default:
            return state;
    }
}

export default socketio;
