import axios from 'axios';

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_DATA_HEIGH_ACCESSLEVEL,
  LOGIN_WITH_GROUP,
  LOGIN_FAIL,
  LOGOUT,
  REGISTRANT_SUCCESS,
  REGISTRANT_FAIL,
  CLEAR_REGISTRANT,
  PASSWORD_UPDATED,
  UPDATE_PASSWORD_FAIL,
  PASSWORD_UPDATED_INITIAL_STATE,
  LOGIN_FROM_MOBILE,
  OPEN_SELECT_GROUP_MODAL,
  SET_ACTIVE_PARTICIPANT,
  SET_PASSIVE_PRESENTER_DETAILS,
  PASSWORD_REST_SUCCESS,
  PASSWORD_REST_FAIL,
  PASSWORD_RESET,
  SET_ACTIVE_PARTICIPANT_NAME
} from '../constants/userConstants';
import { setAlert } from './alert';
import { getEvent } from './event';
import { setActiveMod } from './websocket';
import { CheckBoxOutlined } from '@mui/icons-material';
import { createBrowserHistory } from 'history';
import { toast } from 'react-hot-toast';
export const browserHistory = createBrowserHistory();

// @func  LOAD USER
// @desc  Is
export const loadUser = () => async (dispatch) => {
  let userAccessToken;
  let userID;
  let apiCall = false;

  if (localStorage.cpUserInfo) {
    const data = JSON.parse(localStorage.getItem('cpUserInfo'));
    userAccessToken = data.token;
    userID = data.id;
    apiCall = true;
  }

  if (apiCall) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account/${userID}/?access_token=${userAccessToken}`
      );

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      // console.log('>>>>>>>>>> BUG', err);
      dispatch({
        type: AUTH_ERROR,
      });
    }
  }
};

// @func  LOGIN
// @desc  Make first API request and dispatch getAuthUserInfo func
export const login = (email, password) => async (dispatch) => {

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    email,
    password,
    token: process.env.REACT_APP_API_TOKEN,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/login`,
      body,
      config
    );

    if (res.data.success === false) {
      dispatch(setAlert(res.data.message, 'error', 3000, '7rem'));
      dispatch({
        type: LOGIN_FAIL,
      });
    } else {
      const userId = res.data.id;
      const accessToken = res.data.access_token;
      const userActive = res.data.active;

      if(userActive) {
        
        dispatch(getAuthUserInfo(userId, accessToken));
      } else {
        // navigate('/contact-us/thank-you')
        browserHistory.push('/pending-approval')
        window.location.href = `/pending-approval`;

      }
      

    }
  } catch (err) {
    console.log('ERROR', err);
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> TODOS:
    // check the error response before anything else
    // const errors = err.response.data.errors;
    // if (errors) {
    //   errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    // }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};



// set active participant 
export const setActivePartipant = (event_id, userID)=> async (dispatch, getState)=>{
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    data:{eventId:event_id, participantId:userID},
    token: process.env.REACT_APP_API_TOKEN,
  });
  const {wsLiveUsers} = getState().websocket
        
        

  var modInControl = wsLiveUsers.find(element => element.userId === userID); 
  console.log('Who is controlling inside redux', modInControl)

  modInControl &&  

  toast.success(`${modInControl.userName} Controlling the Q&A`, {
    icon:false,
    style: {
     background:'green',
      color: 'white',
    }
  })



  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/event/setActiveParticipant`,
      body,
      config
    );

      dispatch({
        type:SET_ACTIVE_PARTICIPANT,
        payload:res.data.item.webcast.live.active.participant
      })

      // dispatch(getEvent(event_id))
      // dispatch(setActiveMod(userID))
      
  } catch (error) {
    console.log(error)
  }

}


// @func  GET USER INFO
// @desc  Check for user access level and based on that dispatch different action
const getAuthUserInfo = (userID, userAccessToken) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/account/${userID}/?access_token=${userAccessToken}`
    );
    // console.log('AUTH USER INFO', res.data);

    const userAccessLevel = res.data.token.user.level;

    if (userAccessLevel === 6 || userAccessLevel === 5) {
      dispatch({ type: OPEN_SELECT_GROUP_MODAL });
      dispatch({
        type: LOGIN_DATA_HEIGH_ACCESSLEVEL,
        payload: res.data,
      });
    }
    if (userAccessLevel === 4) {
      const groupId = res.data.token.user.groups[0]._id;
      const groupName = res.data.token.user.groups[0].name.toLowerCase();

      if (groupName === 'brr') {
        dispatch({
          type: LOGIN_DATA_HEIGH_ACCESSLEVEL,
          payload: res.data,
        });
        dispatch(loginWithGroup(groupName));
      } else {
        dispatch({
          type: LOGIN_DATA_HEIGH_ACCESSLEVEL,
          payload: res.data,
        });
        dispatch(loginWithGroup(groupId));
      }
    }
    
    if (userAccessLevel <= 3 || userAccessLevel === 7) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log('>>>>> ACCESS ERROR', err);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// @func  LOGIN WITH GROUP
export const loginWithGroup = (group) => async (dispatch) => {
  dispatch({ type: LOGIN_WITH_GROUP, payload: group });
};

// REGISTER
export const register = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(formData);

  try {

    console.log('Registration data', body)
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/register/?token=${process.env.REACT_APP_API_TOKEN}`,
      body,
      config
    );


    if(res.data.success) {
      dispatch({
        type: REGISTRANT_SUCCESS,
        payload: res.data,
      });

      console.log('Registration done ---------' , res.data)

      //Another API Call to make user Client portal - TRUE

      let formData1 = {
        data:{
          token:process.env.REACT_APP_API_TOKEN,
          user:{
            _id:res.data.id,
            clientPortalOnly:true
          }
        }
         
        
        }
   
      
   const body1 = JSON.stringify(formData1);
   const res1 = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/account/updateClientPortal/?token=${process.env.REACT_APP_API_TOKEN}`,
      body1,
      config
    );

    let body_sendEmail = JSON.stringify({toEmail:formData.email})
   const sendEmail = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/account/registerEmail/?token=${process.env.REACT_APP_API_TOKEN}`,
    body_sendEmail,
    config
  );

    console.log('CP TRUE?????', res1, sendEmail)


    } else {
      //Email already taken 
      dispatch({
        type: REGISTRANT_FAIL,
      });
      dispatch(setAlert('Email is already taken', 'error'));
    }



  } catch (err) {
    const errorMessage = err.response.data.message;

    if (err.response.status >= 500) {
      dispatch({
        type: REGISTRANT_FAIL,
      });
      dispatch(setAlert('Unexpected error, please try again later', 'error'));
    } else {
      dispatch({
        type: REGISTRANT_FAIL,
      });
      dispatch(setAlert(errorMessage, 'error'));
    }
  }
};

export const setActiveParticipantName = (name) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PARTICIPANT_NAME,
    payload:name
  });
};

// UPDATE PASSWORD
export const updatePassword = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      // `${process.env.REACT_APP_API_BASE_URL}/account/pass_change`, 
      `${process.env.REACT_APP_API_BASE_URL}/account/update-password-without-token`,
      data,
      config
    );

      console.log('passsword change', res.data.success)
      if(res.data.success) {
        dispatch({
          type: PASSWORD_UPDATED,
          payload: res.data,
        });
        dispatch(setAlert('Password changed successfully', 'success', 4000));
        dispatch(passwordUpdatedInitialState());
      } else {
        dispatch(setAlert('Incorrect Token', 'error', 4000));

      }

 
  } catch (err) {
    console.log('update password fail', err);
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
    });
    dispatch(setAlert('Password changed failed', 'error', 4000));
  }
};



// UPDATE PASSWORD
export const changePassword = (data) => async (dispatch) => {
  console.log('Change password', data)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/update-password?token=${process.env.REACT_APP_API_TOKEN}`,
      data,
      config
    );

      console.log('res', typeof(res.data.success) )
  
      if(res.data.success) {
        dispatch({
          type: PASSWORD_UPDATED,
          payload: res.data,
        });
        dispatch(setAlert('Password changed successfully', 'success', 4000));
        // dispatch(passwordUpdatedInitialState());
        // window.location.href = `/login`;

      }

      else {
        toast.error('Password changed failed')
        console.log('error')
        dispatch({
          type: UPDATE_PASSWORD_FAIL,
        });
      }

 
  } catch (err) {
    console.log('update password fail', err);
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
    });
    dispatch(setAlert('Password changed failed', 'error', 4000));
  }
};


// FORGOT PASSWORD
export const passwordRest = (data) => async (dispatch) => {
  console.log('Change password', data)
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/password-reset?token=${process.env.REACT_APP_API_TOKEN}`,
      data,
      config
    );

      console.log('res', res)

      if(res.data.success) {
        dispatch({
          type: PASSWORD_REST_SUCCESS
        });
        // dispatch(setAlert('Password changed successfully', 'success', 4000));
        // dispatch(passwordUpdatedInitialState());
        // window.location.href = `/login`;

      } else {
        dispatch({
          type: PASSWORD_REST_FAIL
          });
      }

 
  } catch (err) {
    console.log('Password rest fail', err);
    dispatch({
      type: PASSWORD_REST_FAIL,
    });
    dispatch(setAlert('Something went wrong!', 'error', 4000));
  }
};


export const clearPasswordReset = () => (dispatch) => {
  dispatch({
    type: PASSWORD_RESET,
  });
};

export const setPassivePresenterDetails = (data) => async (dispatch) => {
  dispatch({ type: SET_PASSIVE_PRESENTER_DETAILS, payload: data });
};

export const passwordUpdatedInitialState = () => (dispatch) => {
  dispatch({
    type: PASSWORD_UPDATED_INITIAL_STATE,
  });
};

export const clearRegister = () => (dispatch) => {
  dispatch({
    type: CLEAR_REGISTRANT,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const loginFromMobile = () => (dispatch) => {
  dispatch({
    type: LOGIN_FROM_MOBILE,
  });
};

