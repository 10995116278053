import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { setCreateUserCompanies, setEditUserCompanies } from "../../../actions/cpManagement";
import { useDispatch, useSelector } from "react-redux";



const AssignEventsCompanies = ({defaultCompanies, actionType})=>{
  const dispatch = useDispatch()
  const cpUserEdit = useSelector((state) => state.cpManagementReducer.cpUserEdit)

    const [openCompany, setOpenCompany] = React.useState(false);
    const [optionsForCompany, setOptionsForCompany] = React.useState([]);
    const [valueForCompany, setValueForCompany] = React.useState(null);
    const [inputValueForCompany, setInputValueForCompany] = React.useState("");
  
    const [openEvent, setOpenEvent] = React.useState(false);
    const [optionsForEvent, setOptionsForEvent] = React.useState([]);
    const [valueForEvent, setValueForEvent] = React.useState(null);
    const [inputValueForEvent, setInputValueForEvent] = React.useState("");

console.log('cpUserEdit----------- no UF', cpUserEdit)

  const [_companies, set_Companies] = useState([])
    // const [companies, setCompanies] = useState([{
    //     "reportOptions": {
    //       "sendPeriod": "0",
    //       "enabled": false
    //     },
    //     "displayOptions": {
    //       "enablePresenter": false,
    //       "enableChannel": false,
    //       "enableShare": false,
    //       "enableLogo": false,
    //       "enableEmailDirect": false
    //     },
    //     "clientOf": [],
    //     "tags": [
    //       "531d2cc43665390d28000058"
    //     ],
    //     "active": true,
    //     "shared": false,
    //     "managerId": 89347,
    //     "_id": "570dc4c2c95e0de723380c2c",
    //     "name": "4DS MEMORY LIMITED",
    //     "description": "4DS Memory Limited (4DS), with research and development facilities located in Silicon Valley, is a developer of emerging non-volatile memory, pioneering non-filamentary ReRAM technology for next generation storage in mobile devices and the cloud. ",
    //     "collections": [
    //       {
    //         "collection_ref": {
    //           "path": null,
    //           "_id": "531d2a6e3665390d20000000",
    //           "display": 7,
    //           "managerId": "5",
    //           "name": "ASX",
    //           "type": "collection"
    //         },
    //         "category": {
    //           "path": ",531d2a6e3665390d20000000,",
    //           "_id": "531d2a6e3665390d2000001f",
    //           "name": "Semiconductors &amp; Semiconductor Equipment",
    //           "type": "category",
    //           "managerId": "20"
    //         },
    //         "collection_code": "4DS",
    //         "_id": "570dc4c4c95e0de723380c39"
    //       }
    //     ],
    //     "logos": [
    //       {
    //         "upload": {
    //           "_id": "570dc45fc95e0de723380c2a",
    //           "fileName": "aa42a496-8e84-4092-865e-0226bb28b487.png",
    //           "fileType": "image/png",
    //           "bucket": "brrmedia-assets",
    //           "name": "4ds-logo.png",
    //           "path": "company/logo/aa42a496-8e84-4092-865e-0226bb28b487.png",
    //           "source": "s3",
    //           "tmpPath": "/media/uploads/9191-njpyvt.png"
    //         },
    //         "type": "website",
    //         "name": "4DS MEMORY LIMITED",
    //         "_id": "570dc4c4c95e0de723380c34"
    //       }
    //     ],
    //     "playlist": [],
    //     "numbers": [
    //       {
    //         "type": "business",
    //         "value": "(08) 6377 8043",
    //         "_id": "570dc4c4c95e0de723380c35"
    //       }
    //     ],
    //     "whitelabel": {
    //       "events": {
    //         "headingBold": false
    //       },
    //       "rows": {
    //         "border": false
    //       },
    //       "links": {
    //         "hover": false,
    //         "link": false
    //       },
    //       "active": false,
    //       "results": {
    //         "headingBold": false
    //       }
    //     },
    //     "emails": [
    //       {
    //         "type": "business",
    //         "value": "info@4dsmemory.com",
    //         "_id": "570dc4c4c95e0de723380c36"
    //       }
    //     ],
    //     "websites": [
    //       {
    //         "type": "company",
    //         "value": "http://www.4dsmemory.com",
    //         "_id": "570dc4c4c95e0de723380c37"
    //       }
    //     ],
    //     "addresses": [
    //       {
    //         "type": "business",
    //         "unit": "Level 2 ",
    //         "street": "50 Kings Park Road, WEST PERTH, WA, AUSTRALIA",
    //         "postcode": "6005",
    //         "_id": "570dc4c4c95e0de723380c38"
    //       }
    //     ]
    //   }])

    const [companies, setCompanies] = useState([])

      
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    //   const loading = open && options.length === 0;
  
    // useEffect(() => {
    //   if (globalFilter === "event") {
    //     setInputValueForCompany("");
    //   }
    // }, [globalFilter]);
  
    React.useEffect(() => {
      let active = true;
  
      if (!autoCompleteLoading) {
        return undefined;
      }
  
      return () => {
        active = false;
      };
    }, [autoCompleteLoading]);
  
    //Company
    React.useEffect(() => {
      if (!openCompany) {
        setOptionsForCompany([]);
      }
    }, [openCompany]);


    




    const getCompaniesByKeyStroke = async(name)=>{
        try {
            // https://api.brrmedia.co.uk/company/search/1/5/?name_or_code=tesco&orderBy=name&token=5726ffec6071cb6ec47ac8f9
      
            const res = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/company/search/1/5/?name_or_code=${name}&orderBy=name&token=${process.env.REACT_APP_API_TOKEN}`
            );
           
            // console.log('COMPANIES', res.data)
            setCompanies(res.data.items)
                
          } catch (error) {
            console.error(error);
    
          }
    }
    
    useEffect(() => {
      getCompaniesByKeyStroke(inputValueForCompany)

    }, [inputValueForCompany]);
  
    //Event
    React.useEffect(() => {
      if (!openEvent) {
        setOptionsForEvent([]);
      }
    }, [openCompany]);


    const getEventByKeyStroke = (value)=>{

    }
  
    useEffect(() => {
    getEventByKeyStroke(inputValueForEvent);
    }, [inputValueForEvent]);


    useEffect(()=>{
        console.log('companies', companies)
        actionType !== 'add' && dispatch(setEditUserCompanies(defaultCompanies))
        actionType !== 'add'&& set_Companies(defaultCompanies)
    },[defaultCompanies])

    // console.log('Default companies', defaultCompanies)



    return (
        <>
             <Autocomplete
            //  fullWidth
            multiple
          id="company"
          sx={{ m: 1,background:'#ecf0f1'}}
          open={openCompany}
          autoHighlight={true}
          onOpen={() => {
            setOpenCompany(true);
          }}
          onClose={() => {
            setOpenCompany(false);
          }}
          filterOptions={(x) => x}
          // defaultValue={defaultCompanies}
          value={_companies}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => (option.name) ? option.name : ''}
          options={companies}
          loading={autoCompleteLoading}
          onChange={(event, newValue) => {
            console.log('List if [[[[[companies]]]]]', newValue)
            set_Companies(newValue)
            if(actionType === 'add') {
              dispatch(setCreateUserCompanies(newValue))

            } else {
              dispatch(setEditUserCompanies(newValue))

            }

            setOptionsForCompany(
              newValue ? [newValue, ...optionsForCompany] : optionsForCompany
            );
            if (newValue) {

              // console.log(newValue.name)
              setLoading(false)

            //   props.dispatch(getEventByCompanyID(newValue._id));

            //   props.dispatch(setGlobalFilter("company", newValue.name));

            }
            // setValueForCompany(newValue.name ? newValue.name :'name');
            // Company selected in the text box-> trigger and display events accordingly
            //set Filter to true in redux store and display the result

          }}
          onInputChange={(event, newInputValue) => {
            //Dispatch action
            //   console.log("On each key stroke", newInputValue);
            if (newInputValue.length <= 0) {
            //   props.dispatch(setGlobalFilter(null, null));
            }
            if (newInputValue.length > 0) {
              setInputValueForCompany(newInputValue);



            }

          }}
          renderInput={(params) => (
            <TextField
              {...params}
            //   label="Company Name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoCompleteLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        
        </>
        
        
    )

}

export default AssignEventsCompanies;