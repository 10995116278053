import axios from 'axios';
import {
  WS_APPENDED_TO_DOM,
  WEBSOCKET_DISCONNECT,
  WS_NEW_MESSAGE_RECIVED,
  WS_RESET_MESSAGE_ARRAY,
  WS_APPROVED_MESSAGE_RECIVED,
  WS_ADD_APPROVED_MESSAGE,
  WS_CHANGED_MESSAGE_STATUS,
  WS_ORDER_LIST_RECIVED,
  WS_RESET_ORDER_LIST,
  WS_CONNECTED,
  WS_RESET_CONNECTION,
  WS_LIVE_USERS,
  SET_DIALOG_BOX_CONTROL,
  RESET_WS_LIVE_USERS,
  SET_ACTIVE_MOD,
  SET_DIALOG_BOX_TAKING_CONTROL,
  WS_LIVE_USERS_BY_EVENT
} from '../constants/websocketConstants';
import { getActiveParticipantName } from '../utils/getActiveParticipantName';
//import { setActivePartipant } from './auth';




export const checkWsStatusReady = () => (dispatch) => {
  dispatch({
    type: WS_APPENDED_TO_DOM,
  });
};



export const wsConnected = ()=>(dispatch)=>{
  dispatch({
    type:WS_CONNECTED,
    payload:true
  })
}

export const wsResetConnection =()=> (dispatch)=>{
  dispatch({
    type:WS_RESET_CONNECTION
  })
}

export const disconnectWebsocket = () => (dispatch) => {
  dispatch({
    type: WEBSOCKET_DISCONNECT,
  });
};

export const wsAddNewMessage = (msg) => (dispatch) => {
  dispatch({
    type: WS_NEW_MESSAGE_RECIVED,
    payload: msg,
  });
};

export const wsGetApprovedMessage = (msg) => (dispatch) => {
  dispatch({
    type: WS_APPROVED_MESSAGE_RECIVED,
    payload: msg,
  });
};

export const wsAddApprovedMessage = (userId, status, id) => (dispatch) => {
  dispatch({
    type: WS_ADD_APPROVED_MESSAGE,
    payload: { userId, status, id },
  });
};

export const wsResetMessageArray = () => (dispatch) => {
  dispatch({
    type: WS_RESET_MESSAGE_ARRAY,
  });
};

export const wsChangeMessageStatus = (msg) => (dispatch) => {
  dispatch({
    type: WS_CHANGED_MESSAGE_STATUS,
    payload: msg,
  });
};

export const wsOrderListRecived = (foo) => (dispatch) => {
  dispatch({
    type: WS_ORDER_LIST_RECIVED,
    payload: foo,
  });
};

export const wsResetOrderList = () => (dispatch) => {
  dispatch({
    type: WS_RESET_ORDER_LIST,
  });
};

export const setActiveMod = (id) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_MOD,
    payload:id
  });
};

// Live user 

export const wsLiveUsers = (data, eventID)=> async(dispatch)=>{
  console.log('live data',data)

  const fetchRequest = async n => {
    // console.log('each fetch',n)
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/account/${n.userId}/?access_token=${JSON.parse(localStorage.getItem('cpUserInfo')).token}`
    );
    if(res.data.items[0]?.level > 1) {
      return n;

    }
  };

  try {
    const asyncFunc = async() => {
      const unresolvedPromises = data.map(fetchRequest);
      const results = await Promise.all(unresolvedPromises);
      console.log('warning map ', results)

      let data1 = results.filter(function( element ) {
        return element !== undefined;
     });



     const uniqueIds = new Set();
     const uniqueLive = data1.filter(element => {
      const isDuplicate = uniqueIds.has(element.userId);
      
        // console.log('issue????????? here actions', element)
        if(element.event === eventID) {

          uniqueIds.add(element.userId);
        }
    
      if (!isDuplicate) {
        return true;
      }
    
      return false;
    });


    console.log('uniqueLive', uniqueLive)


     dispatch({
      type:WS_LIVE_USERS,
      // payload:data1
      payload:uniqueLive

    })

    };

   
    asyncFunc()
    


  } catch (error) {
    
  }

  // if(data.length === 1) {
  //   dispatch(setActivePartipant(data[0].event, data[0].userId))
  // }
 
}

export const wsLiveUsersByEvent = (liveUsers)=> async (dispatch)=>{

  // console.log('wsLiveUsersByEventwsLiveUsersByEvent', liveUsers.map((each)=> getActiveParticipantName(each.userID).then((res)=> res))  )





  async function processArrayAsync(liveUsers) {
    const results = [];
  
    for (const item of liveUsers) {
      const result = await getActiveParticipantName(item.userID);
      results.push({userID:item.userID, name:result, inControl:item.inControl});
    }
  
    return results;
  }

  processArrayAsync(liveUsers)
  .then(results => {
    console.log('All tasks completed. Results:', results);
    dispatch({
      type:WS_LIVE_USERS_BY_EVENT,
      // payload:data1
      payload:results
    })
  })
  .catch(error => {
    console.error('Error:', error);
  });

  
}

export const resetWsLiveUsers = () => async(dispatch)=>{
    dispatch({
      type:  RESET_WS_LIVE_USERS
    })
}

//Dialog pop up control
export const setDialogPopUpControl = (boolValue)=>(dispatch)=>{
  dispatch({
    type:SET_DIALOG_BOX_CONTROL,
    payload:boolValue
  })
}


//Dialog pop up control
export const setDialogPopUpTakingControl = (boolValue)=>(dispatch)=>{
  dispatch({
    type:SET_DIALOG_BOX_TAKING_CONTROL,
    payload:boolValue
  })
}