import axios from 'axios';

import { APP_LIST_PAGE_SIZE } from '../config';
import { SET_LOADING } from '../constants/utilsConstants';
import {
  GET_REGISTRANTS,
  GET_ALL_REGISTRANTS,
  REGISTRANTS_FAIL,
  CLEAR_REGISTRANTS,
} from '../constants/eventRegistrantsConstants';

// GET REGISTRANTS LIST
export const getRegistrants =
  (
    page,
    pageSize = APP_LIST_PAGE_SIZE,
    token,
    eventId,
    managerId,
    paginated = false
  ) =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/registrant/searchAllRegistrants/1/5000/?access_token=${token}&eventId=${eventId}&managerId=${managerId}&paginated=${paginated}`
      );

      if (res.data.success) {
        dispatch({
          type: GET_REGISTRANTS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: REGISTRANTS_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REGISTRANTS_FAIL,
        payload: err,
      });
    }
  };

// GET REGISTRANTS FOR CSV
export const getAllRegistrants =
  (token, eventId, managerId) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/registrant/searchAllRegistrants/1/${APP_LIST_PAGE_SIZE}/?access_token=${token}&eventId=${eventId}&managerId=${managerId}&paginated=false`
      );

      if (res.data.success) {
        dispatch({
          type: GET_ALL_REGISTRANTS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: REGISTRANTS_FAIL,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REGISTRANTS_FAIL,
        payload: err,
      });
    }
  };

// CLEAR REGISTRANTS
export const clearRegistrants = () => (dispatch) =>
  dispatch({ type: CLEAR_REGISTRANTS });

// SET LOADING
export const setLoading = () => (dispatch) => dispatch({ type: SET_LOADING });
