import React, { useEffect, useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// REDUX
import {
  getRegistrants,
  getAllRegistrants,
} from "../../../actions/registrants";
import { setAlert } from "../../../actions/alert";

// UTILS
import { fullDate, hourMinuteDate } from "../../../utils/convertDateAndTime";
//import { arrayToLowercase, mergeTwoArrays } from "../../../utils/mergeArray";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Button from "@mui/material/Button";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LoadingButton from "@mui/lab/LoadingButton";
// import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";

// import { CSVLink } from "react-csv";

// COMPONENTS
import Spinner from "../../UI/Spinner";
import NotFoundData from "../NotFoundData";
// import EnhancedTableHead from "./EnhancedTableHead";

import "../utilsEvent.css";
// import { getValidDate } from "../../../utils/formateDate";
import { v4 as uuidv4 } from "uuid";
import { Grid, Paper } from "@mui/material";
// import PrimusScriptDom from "../../websocket/PrimusScriptDom";
import PrimusWs, { PrimusContext } from "../../websocket/PrimusWs";

// Utility funcs
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const EventRegistrants = ({
  loading,
  getRegistrants,
  getAllRegistrants,
  registrantsList,
  lastUpdate,
  registrantsCSV,
  totalRegistrants,
  event,
  token,
  setAlert,
}) => {
  const csvLinkRef = useRef();
  const pageTopRef = useRef(null);

  const [page, setPage] = useState(30);
  const [pageSize, setPageSize] = React.useState(25);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [listFounded, setListFounded] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [registrantData, setRegistrantData] = useState();
  const [csvData, setCsvData] = useState();
  const [csvDataReady, setCsvDataReady] = useState(false);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");

  const [parsedRegistrant, setParsedRegistrant] = useState([]);
  const [registrantColumn, setRegistrantColumn] = useState([]);
  const primusMethods = useContext(PrimusContext);


  

  useEffect(() => {
    if (registrantsList !== null) {
      setRegistrantData(registrantsList);
    }
  }, [registrantsList]);

  useEffect(() => {
    //console.log(PrimusWs)
    if (registrantData) {
      let p = registrantData.map((eachReg) => {
        let parsed = JSON.parse(eachReg.fields);

        return {
          id: uuidv4(),
          ...parsed,
          date: fullDate(eachReg.date),
          // loggedInTime: eachReg.loggedInTime,
        };
      });

      setParsedRegistrant(p);

      console.log(Object.keys(p[0]));

      setRegistrantColumn(Object.keys(p[0]));

      let parsedColumn = Object.keys(p[0]).map((eachCo) => {
        return {
          field: eachCo,
          headerName: eachCo,
          align: "left",
          width:300,
        };
      });

      parsedColumn.shift();
      console.log(parsedColumn);

      setRegistrantColumn(parsedColumn);

      setListFounded(true);

      // setRegistrantColumn(Object.keys(parsedRegistrant[0]));
    }

    // console.log(parsedRegistrant)
  }, [registrantData]);
  // @DESC  START CSV
  function handleEvent(e) {
    if (e.type === "loadend") {
      setAlert("Download completed", "success", 3000);
    }
    if (e.type === "error") {
      setAlert("An error occured", "error", 3000);
    }
  }

  function addListeners(xhr) {
    xhr.addEventListener("loadend", handleEvent);
    xhr.addEventListener("error", handleEvent);
  }

  function runXHR(url) {
    const xhr = new XMLHttpRequest();
    addListeners(xhr);
    xhr.open("GET", url);
    xhr.send();
    return xhr;
  }

  useEffect(() => {
    let fields;

    if (registrantsCSV !== null && Object.keys(registrantsCSV).length > 0) {
      fields = registrantsCSV
        .map((el) => {
          return {
            fields: JSON.parse(el.fields),
            date: el.date,
            loggedInTime:
              el.loggedInTime === "0" ? fullDate(el.date) : el.loggedInTime,
          };
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    if (fields !== undefined) {
      setCsvData(
        fields.map((el) => {
          if (el.fields.type !== undefined) {
            return {
              Email: el.fields.email,
              Name: el.fields.name || "NA",
              Type: el.fields.type,
              Company: el.fields.organisation || "NA",
              Loggedintime: el.loggedInTime,
              Date: fullDate(el.date),
            };
          }
          return {
            Email: el.fields.email,
            Name: el.fields.name || "NA",
            Company: el.fields.organisation || "NA",
            Loggedintime: el.loggedInTime,
            Date: fullDate(el.date),
          };
        })
      );

      setCsvDataReady(
        true,
        setTimeout(() => {
          csvLinkRef.current.link.click();
        })
      );
    }
  }, [registrantsCSV]);

  useEffect(() => {
    if (csvDataReady) {
      runXHR(csvLinkRef.current.link);
    }
    // eslint-disable-next-line
  }, [csvDataReady]);
  //  END CSV
  //

  const handleChangePage = (e, newPage) => {
    pageTopRef.current.scrollIntoView({ behavior: "smooth" });
    setPage(newPage);
    getRegistrants(
      newPage + 1,
      token,
      event.items[0]._id,
      event.items[0].managerId
    );
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(parseInt(e.target.value, 10));

    if (e.target.value === -1) {
      return getRegistrants(
        1,
        20,
        token,
        event.items[0]._id,
        event.items[0].managerId,
        false
      );
    }

    getRegistrants(
      1,
      e.target.value,
      token,
      event.items[0]._id,
      event.items[0].managerId
    );
  };

  const handleClick = () => {
    getAllRegistrants(token, event.items[0]._id, event.items[0].managerId);
  };

  const handleReload = () => {
    getRegistrants(
      1,
      20,
      token,
      event.items[0]._id,
      event.items[0].managerId,
      true
    );
  };

  const handleRequestSort = (e, property) => {
    // console.log('SORT PROPERTY', property);
    // console.log('ORDER', orderBy);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // console.log('REGISTRANT DATA', registrantData);
  const apiRef = useGridApiRef();

  return (
    <>
      
    <Grid>
      <Box
        ref={pageTopRef}
        sx={{
          alignItems: "center",
          padding: "1.5rem 3.75rem 0",
          "& div:nth-of-type(1)": {
            gridColumnStart: 3,
          },
          "& div:nth-of-type(2)": {
            marginLeft: "auto",
          },
        }}
        >
        <Box
          sx={{
            display: "flex",
            // justifyContent: "spav",
            justifyContent: "flex-end",
            alignItems: "center",
            m: 1,
          }}
          >
          <Typography variant="body2" color={"var(--clr-gray60)"}>
            Last Update {hourMinuteDate(lastUpdate)}
          </Typography>
          <LoadingButton
            onClick={handleReload}
            loading={loading}
            endIcon={<AutorenewIcon />}
            loadingPosition="end"
            variant="contained"
            sx={{
              marginLeft: "1rem",
              backgroundColor: "var(--clr-info)",
              "&:hover": {
                backgroundColor: "var(--clr-info60)",
              },
            }}
            >
            Reload
          </LoadingButton>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
          <Spinner />
        </Box>
      ) : listFounded ? (
        <Box sx={{ m:4 }}>

       
            <DataGrid
              autoHeight={true}
              apiRef={apiRef}
              components={{ Toolbar: GridToolbar }}
              density="comfortable"
              rows={parsedRegistrant}
              columns={registrantColumn}
              disableSelectionOnClick={true}
              pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  fontSize: "1rem",
                  textTransform: "capitalize",
                },
                '& .MuiDataGrid-cell':{
                  fontSize: "0.8rem"
                }
              }}
            />
       
       </Box>
      ) : (
        <NotFoundData message={"Could not find any registrants"} />
        )}

    </Grid>
</>
  );
};

EventRegistrants.propTypes = {
  loading: PropTypes.bool,
  getRegistrants: PropTypes.func.isRequired,
  getAllRegistrants: PropTypes.func.isRequired,
  registrantsList: PropTypes.object,
  lastUpdate: PropTypes.any,
  registrantsCSV: PropTypes.array,
  totalRegistrants: PropTypes.number,
  event: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.registrants.loading,
  registrantsList: state.registrants.registrantsList,
  lastUpdate: state.registrants.lastUpdate,
  registrantsCSV: state.registrants.registrantsCSV,
  totalRegistrants: state.registrants.totalRegistrants,
  event: state.event.event,
  token: state.auth.token,
});

export default connect(mapStateToProps, {
  getRegistrants,
  getAllRegistrants,
  setAlert,
})(EventRegistrants);
