import { FlashlightOnRounded } from '@mui/icons-material';
import {
    SET_ACTIVE_ICON,
    REMOVE_ACTIVE_ICON,
  } from '../constants/utilsConstants';
import { GET_CP_USERS, RESET_CREATE_USER, RESET_EDIT_USER_ID, SET_CREATE_USER_COMPANIES, SET_CREATE_USER_COMPANY, SET_CREATE_USER_EMAIL, SET_CREATE_USER_EVENTS, SET_CREATE_USER_FIRST_NAME, SET_CREATE_USER_LAST_NAME, SET_CREATE_USER_PASSWORD, SET_CREATE_USER_ROLE, SET_CREATE_USER_STATUS, SET_EDIT_CP_ONLY_COMPANY, SET_EDIT_USER_COMPANIES, SET_EDIT_USER_EMAIL, SET_EDIT_USER_EVENTS, SET_EDIT_USER_ID, SET_EDIT_USER_NAME, SET_EDIT_USER_PASSWORD, SET_EDIT_USER_ROLE, SET_EDIT_USER_STATUS } from '../constants/cpManagement';
import { ACTIVE_TAB } from '../constants/cpManagement';
  
  const initialState = {
    cpUsers: [],
    pendingCpUsers:[],
    error:null,
    loading:false,
    activeTabName:'',
    cpUserEdit:{
      //user id
      //status
      //companies
      //event
      userID:'',
      companies:'',
      events:[],
      companies:[],
      cPonlyCompany:'',
      password:''
    },

    cpUserCreate:{
      //user id
      //status
      //companies
      //event
      firstname:'',
      lastname:'',
      email:'',
      password:'',
      userID:'',
      events:[],
      companies:[],
      cPonlyCompany:'',
      status:false,
      role:1
    }
  };
  
  function cpManagementReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_CP_USERS:
        return { 
            ...state,
            cpUsers:payload.filter((ele)=> ele.active === true),
            pendingCpUsers:payload.filter((ele)=> ele.active === false)
        };


      //Set Edit User ID
      case SET_EDIT_USER_ID:
        return { 
            ...state,
            cpUserEdit :{
                ...state.cpUserEdit,
                userID:payload
            }
        };

      //Set Edit User Status
      case SET_EDIT_USER_STATUS:
        return { 
            ...state,
            cpUserEdit :{
                ...state.cpUserEdit,
                status:payload
            }
        };



        //Set Edit User Events
      case SET_EDIT_USER_EVENTS:
        return { 
            ...state,
            cpUserEdit :{
                ...state.cpUserEdit,
                events:payload
            }
        };


      //Set Edit User Companies
      case SET_EDIT_USER_COMPANIES:
        console.log('in reducer', payload.length)
        if(payload.length === 0) {
          return { 
            ...state,
            cpUserEdit :{
                ...state.cpUserEdit,
                events:[],
                companies:payload

            }
        };
        }
        return { 
            ...state,
            cpUserEdit :{
                ...state.cpUserEdit,
                companies:payload
            }
        };

        case SET_EDIT_USER_NAME:
          return { 
              ...state,
              cpUserEdit :{
                  ...state.cpUserEdit,
                  name:payload
              }
          };


          case SET_EDIT_USER_EMAIL:
            return { 
                ...state,
                cpUserEdit :{
                    ...state.cpUserEdit,
                    email:payload
                }
            };


            case SET_EDIT_USER_PASSWORD:
              return { 
                  ...state,
                  cpUserEdit :{
                      ...state.cpUserEdit,
                      password:payload
                  }
              };

            case SET_EDIT_CP_ONLY_COMPANY:
              return { 
                  ...state,
                  cpUserEdit :{
                      ...state.cpUserEdit,
                      cPonlyCompany:payload
                  }
              };


        //Set Edit User Role
        case SET_EDIT_USER_ROLE:
          return { 
              ...state,
              cpUserEdit :{
                  ...state.cpUserEdit,
                  role:payload
              }
          };


          case RESET_EDIT_USER_ID:
            return { 
                ...state,
                cpUserEdit :{ 
                  events: [],
                  companies: [],

                 }
            };


    


      // Create new user

      case SET_CREATE_USER_FIRST_NAME:
        return { 
            ...state,
            cpUserCreate :{
                ...state.cpUserCreate,
                firstname:payload
            }
        };

      case SET_CREATE_USER_LAST_NAME:
          return { 
              ...state,
              cpUserCreate :{
                  ...state.cpUserCreate,
                  lastname:payload
              }
          };
    
      case SET_CREATE_USER_EMAIL:
            return { 
                ...state,
                cpUserCreate :{
                    ...state.cpUserCreate,
                    email:payload
                }
            };
      
      case SET_CREATE_USER_PASSWORD:
              return { 
                  ...state,
                  cpUserCreate :{
                      ...state.cpUserCreate,
                      password:payload
                  }
              };
    
      case SET_CREATE_USER_COMPANY:
                return { 
                    ...state,
                    cpUserCreate :{
                        ...state.cpUserCreate,
                        company:payload
                    }
                };
    
      case SET_CREATE_USER_COMPANIES:
                  return { 
                      ...state,
                      cpUserCreate :{
                          ...state.cpUserCreate,
                          companies:payload
                      }
                  };

      case SET_CREATE_USER_EVENTS:
                    return { 
                        ...state,
                        cpUserCreate :{
                            ...state.cpUserCreate,
                            events:payload
                        }
                    };

      case SET_CREATE_USER_ROLE:
                      return { 
                          ...state,
                          cpUserCreate :{
                              ...state.cpUserCreate,
                              role:payload
                          }
                      };

       case SET_CREATE_USER_STATUS:
                      return { 
                          ...state,
                          cpUserCreate :{
                              ...state.cpUserCreate,
                              status:payload
                          }
                      };

        case ACTIVE_TAB:
          return {
            ...state,
            activeTabName:payload
          }
    
      case RESET_CREATE_USER:
        return {
          ...state,
          cpUserCreate: {
            events: [],
            companies: [],
          }
        };
      default:
        return state;
    }
  }
  
  export default cpManagementReducer;
  