import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ValidatorForm,
  SelectValidator,
} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

// REDUX
import { loginWithGroup } from '../../actions/auth';

// MUI
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const SelectGroupModal = ({ open, auth: { user }, loginWithGroup }) => {
  const [groups, setGroups] = useState(null);
  const [group, setGroup] = useState('');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    if (user.groups.length > 0) setGroups(user.groups);
  }, [user]);

  const handleChange = (e) => {
    setGroup(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleCloseModal();
    loginWithGroup(group);
  };

  const handleCloseModal = (event, reason) => {
    if (reason === 'backdropClick') return;
    setOpenModal(false);
  };

  // console.log('>>>> group', group);
  // console.log('>>>> user', user);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      disableEscapeKeyDown
      aria-labelledby='select group modal'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > div:first-of-type': {
          backgroundColor: 'rgba(0,0,0,0.75)',
        },
      }}
    >
      <Box
        sx={{
          padding: '3rem 4.5rem',
          backgroundColor: 'var(--clr-w)',
          borderRadius: '5px',
        }}
      >
        <Typography
          variant='h5'
          color={'var(--clr-gray80)'}
          sx={{ marginBottom: '3rem' }}
        >
          Please select group to proceed
        </Typography>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          <SelectValidator
            name='group'
            // label='selectedGroup'
            validators={['required']}
            errorMessages={['required field']}
            value={group}
            onChange={handleChange}
            fullWidth
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {groups
              ? [
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>,
                  groups.map((group) => (
                    <MenuItem key={group._id} value={`${group._id}`}>
                      {group.name}
                    </MenuItem>
                  )),
                ]
              : [
                  <MenuItem key={0} value=''>
                    <em>None</em>
                  </MenuItem>,
                  <MenuItem key={1} value={'brr'}>
                    BRR Media
                  </MenuItem>,
                  <MenuItem key={2} value={'5ac3a1fe83d45353bc6a995c'}>
                    Buchanan
                  </MenuItem>,
                  <MenuItem key={3} value={'5c9e5759ce0cbf4b4c0fac96'}>
                    Emperor
                  </MenuItem>,
                ]}
          </SelectValidator>
          <Button
            type='submit'
            variant='contained'
            sx={{
              marginTop: '2rem',
              width: '100%',
              textTransform: 'capitalize',
              fontSize: '1.25rem',
              fontWeight: '700',
              bgcolor: 'var(--clr-btn-cta)',
              '&:hover': {
                bgcolor: 'var(--clr-btn-cta-hover)',
              },
            }}
          >
            Proceed
          </Button>
        </ValidatorForm>
      </Box>
    </Modal>
  );
};

SelectGroupModal.propTypes = {
  loginWithGroup: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginWithGroup })(SelectGroupModal);
