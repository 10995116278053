import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function DesktopIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10V20H8V22H16V20H14V18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2ZM21 16H3V4H21V16Z'
        fill='white'
      />
      <path
        d='M8 9.99992L11.3333 13.3333L17.3333 6.33325'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}

export default DesktopIcon;
