import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { getMessageOrder, getAllMessages } from '../../../actions/messages';
import {
  wsResetMessageArray,
  wsResetOrderList,
} from '../../../actions/websocket';

// UTILS
import { hourMinuteDate } from '../../../utils/convertDateAndTime';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { yellow } from '@mui/material/colors';

// COMPONENTS
import Spinner from '../../UI/Spinner';
import TooltipAlert from '../../UI/TooltipAlert';
import QuestionsTable from './QuestionsTable';
import NotFoundData from '../NotFoundData';

import '../utilsEvent.css';
import { Divider } from '@mui/material';

const tooltipStatusChanged =
  'Message list has been updated! Click reload to update';

const PresenterMessages = ({
  event,
  loading,
  allMessage: { messages, lastUpdate },
  presenterMessageList,
  getMessageOrder,
  userAccessToken,
  auth: { user },
  getAllMessages,
  messageOrderNotFound,
  lastFetch,
  isWsOrderListRecived,
  saveListCounter,
  wsResetMessageArray,
  wsResetOrderList,
}) => {
  // @desc -  Tooltip
  //          State start
  const bgColorNewStatusMessage = yellow[500];
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipBgColor, setTooltipBgColor] = useState('#FFEB3B');
  const [tooltipTextColor, setTooltipTextColor] = useState('#fff');
  const [openTooltip, setOpenTooltip] = useState(0);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  //          State end

  const [localMessages, setLocalMessages] = useState([]);
  const [localMessages1, setLocalMessages1] = useState([]);

  const [oldUpdate, setOldUpdate] = useState(null);

  const didMount = useRef(true);

  useEffect(() => {
    wsResetMessageArray();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (messages) {
      if (messages.items.length > 1 && saveListCounter === 0) {
        getMessageOrder(event.items[0]._id, true);
      }

      if (oldUpdate === null || oldUpdate < lastUpdate) {
        setOldUpdate(lastUpdate);
        console.log('Here')
        getMessageOrder(event.items[0]._id, true);
      }
    }

    // eslint-disable-next-line
  }, [messages, lastUpdate]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && presenterMessageList.success) {
      const allowedMsgArr = messages.items.filter(
        (el) => el.status === 'replied'
      );
      console.log('All message', messages)
      setLocalMessages1(allowedMsgArr)
      setLocalMessages(presenterMessageList.messages);
    }

    
    return () => {
      isMounted = false;
    };
  }, [presenterMessageList]);

  // @desc Tooltip Message
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }
    if (isWsOrderListRecived) {
      setTooltipBgColor(bgColorNewStatusMessage);
      setTooltipTextColor('#5A5300');
      setTooltipMessage(tooltipStatusChanged);
      setTooltipIndex(1);
      setOpenTooltip(1);
    }
    // eslint-disable-next-line
  }, [isWsOrderListRecived]);



  const handleReload = () => {
    getAllMessages(1, event.items[0]._id, user.level);
    setOpenTooltip(0);
    setTooltipIndex(null);
    wsResetMessageArray();
    wsResetOrderList();
  };

  // const handleReload = () => {
  //   getAllMessages(1, event.items[0]._id, user.level);
  //   setOldUpdate(lastUpdate);
  //   getMessageOrder(event.items[0]._id, true);
  //   setOpenTooltip(0);
  //   setTooltipIndex(null);
  //   wsResetMessageArray();
  //   wsResetOrderList();
  // };

  return (
    <>

          {/* <div className="blackBox" style={{position:'absolute', height:'100%', width:'100%', 
          backgroundColor:'white',opacity:'0.5', zIndex:1000, display:`${
            (event.items[0].webcast.live.active.participant ===user._id? 'none' : 'block')
            
            
            }` }}>

</div> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: { md: '1.5rem 3.75rem', sm: '1.5rem' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='body2' color={'var(--clr-gray60)'}>
            {messageOrderNotFound ? (
              <>Last Update {hourMinuteDate(lastFetch)}</>
            ) : (
              <>Last Update {hourMinuteDate(lastUpdate)}</>
            )}
          </Typography>

          <TooltipAlert
            title={tooltipMessage}
            placement='left'
            open={openTooltip === tooltipIndex}
            leaveDelay={500}
            enterNextDelay={2000}
            sx={{
              zIndex: 1099,
              '& .MuiTooltip-tooltip': {
                color: `${tooltipTextColor}`,
                backgroundColor: `${tooltipBgColor}`,
              },
              '& .MuiTooltip-arrow': {
                color: `${tooltipBgColor}`,
              },
            }}
          >
            <LoadingButton
              disabled={openTooltip === 0 && messageOrderNotFound}
              onClick={handleReload}
              loading={loading}
              endIcon={<AutorenewIcon />}
              loadingPosition='end'
              variant='contained'
              sx={{
                marginLeft: '1rem',
                backgroundColor: 'var(--clr-info)',
                '&:hover': {
                  backgroundColor: 'var(--clr-info60)',
                },
              }}
            >
              Reload
            </LoadingButton>
          </TooltipAlert>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '60px' }}>
          <Spinner />
        </Box>
      ) : (
        localMessages.length > 0 && (
          <Box className='fade-in-box'>
            <QuestionsTable
              userAccessToken={userAccessToken}
              userID={user._id}
              eventID={event.items[0]._id}
              messages={localMessages}
              userLevel={user.level}
            />
            <Divider/>
            <Divider/>

              <QuestionsTable
              status='replied'
              userAccessToken={userAccessToken}
              userID={user._id}
              eventID={event.items[0]._id}
              messages={localMessages1}
              userLevel={user.level}
            />

          </Box>
        )
      )}
      {/* {messageOrderNotFound && (
        <NotFoundData message={'Could not find any messages'} />
      )} */}
    </>
  );
};

PresenterMessages.propTypes = {
  event: PropTypes.object,
  loading: PropTypes.bool,
  allMessage: PropTypes.object,
  presenterMessageList: PropTypes.object.isRequired,
  auth: PropTypes.object,
  getMessageOrder: PropTypes.func.isRequired,
  getAllMessages: PropTypes.func.isRequired,
  messageOrderNotFound: PropTypes.bool.isRequired,
  lastFetch: PropTypes.any,
  saveListCounter: PropTypes.number,
  wsResetMessageArray: PropTypes.func.isRequired,
  wsResetOrderList: PropTypes.func.isRequired,
  isWsOrderListRecived: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event.event,
  loading: state.messages.loading,
  allMessage: state.messages.allMessage,
  presenterMessageList: state.messages.presenterMessageList,
  messageOrderNotFound: state.messages.messageOrderNotFound,
  userAccessToken: state.auth.token,
  auth: state.auth,
  lastFetch: state.messages.lastUpdate,
  isWsOrderListRecived: state.websocket.isWsOrderListRecived,
  saveListCounter: state.messages.saveListCounter,
});

export default connect(mapStateToProps, {
  getMessageOrder,
  getAllMessages,
  wsResetMessageArray,
  wsResetOrderList,
})(PresenterMessages);
