export const fullDate = (data) => {
  const newDate = new Date(data).toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  // UK date format -> start
  let temp = newDate.split(',');
  let tempDate = temp[0].split('/')
  let tempTime = temp[1]
  let tempDate2 = tempDate[0] + '/' + tempDate[1] + '/' +tempDate[2]
  // UK date format -> end

  // return newDate.split(',').join(' ');
  return tempDate2 + '' + tempTime;
};

export const hourMinuteDate = (data) => {
  if (data === null) {
    const newDate = new Date().toLocaleString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return newDate.split(',').join(' ');
  } else {
    const newDate = new Date(data).toLocaleString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
    return newDate.split(',').join(' ');
  }
};

export const dayMonthYear = (data) => {
  const newDate = new Date(data).toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return newDate.split(',').join(' ');
};
