import { toast } from 'react-hot-toast';
import { ADD_NOTIFICATION, CLEAR_NOTIFICATION, MARK_ALL_AS_READ } from '../constants/notification';
import {
    SET_ACTIVE_ICON,
    REMOVE_ACTIVE_ICON,
  } from '../constants/utilsConstants';
  
  const initialState = {
    allNotifications:[]
  };
  
  function notification(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case ADD_NOTIFICATION:
        // let mostRecentNotification =  state.allNotifications.slice(-1) ;
        // console.log('From reducer', mostRecentNotification)

        //  state.allNotifications.length>0 &&  toast.success(mostRecentNotification[0].title, {
        //     icon:false,
        //     style: {
        //      background:'green',
        //       color: 'white',
        //     }
        //   })

        // toast.success(payload.title, {
        //     icon:false,
        //     style: {
        //      background:'green',
        //       color: 'white',
        //     }
        //   })

        return { 
                ...state,
                allNotifications:[...state.allNotifications , payload]
        };

      case CLEAR_NOTIFICATION:
        return { 
            ...state,
            allNotifications:[]
    };

    case MARK_ALL_AS_READ:
        for (let i = 0; i < state.allNotifications.length; i++) {
            state.allNotifications[i].read = true;
          }
        return { 
            ...state,
            // allNotifications:[]
    };
    
    default:
        return state;
    }
  }
  
  export default notification;
  