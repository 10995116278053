import { SET_LOADING } from '../constants/utilsConstants';
import {
  GET_REGISTRANTS,
  GET_ALL_REGISTRANTS,
  REGISTRANTS_FAIL,
  CLEAR_REGISTRANTS,
} from '../constants/eventRegistrantsConstants';

const initialState = {
  registrantsList: null,
  registrantsCSV: null,
  totalRegistrants: null,
  lastUpdate: null,
  loading: true,
  error: {},
};

function registrantsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REGISTRANTS:
      return {
        ...state,
        registrantsList: payload.registrants,
        totalRegistrants: payload.total,
        lastUpdate: new Date(),
        loading: false,
      };
    case GET_ALL_REGISTRANTS:
      return {
        ...state,
        registrantsCSV: payload.registrants,
        loading: false,
      };
    case REGISTRANTS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_REGISTRANTS:
      return {
        ...state,
        registrantsList: null,
        registrantsCSV: null,
        totalRegistrants: null,
        lastUpdate: null,
        error: {},
        loading: true,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export default registrantsReducer;
