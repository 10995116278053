// import React from 'react'

// MUI
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const BRRSwitch = styled(Switch)(({ theme }) => ({
  transform: 'rotate(180deg)',
  width: 120,
  height: 40,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 58,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(60px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(58px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#177ddc' : 'var(--clr-btn-cta)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 58,
    height: 36,
    borderRadius: 3,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 5,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#494949',
    boxSizing: 'border-box',
  },
}));

export default BRRSwitch;
