export const GET_CP_USERS = 'GET_CP_USERS';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_LOADING = 'SET_LOADING';
 

export const DELETE_CP_USER = 'DELETE_CP_USER'
export const ERROR_DELETE_CP_USER = 'ERROR_DELETE_CP_USER'


//SET user id when editing
export const SET_EDIT_USER_ID = 'SET_EDIT_USER_ID';

//Reset user id
export const RESET_EDIT_USER_ID = 'RESET_EDIT_USER_ID';

export const SET_EDIT_USER_NAME = 'SET_EDIT_USER_NAME'
export const SET_EDIT_USER_EMAIL = 'SET_EDIT_USER_EMAIL'
export const SET_EDIT_USER_PASSWORD = 'SET_EDIT_USER_PASSWORD'

export const SET_EDIT_CP_ONLY_COMPANY = 'SET_EDIT_CP_ONLY_COMPANY'

export const SET_EDIT_USER_STATUS = 'SET_EDIT_USER_STATUS'

export const SET_EDIT_USER_EVENTS = 'SET_EDIT_USER_EVENTS'

export const SET_EDIT_USER_COMPANIES = 'SET_EDIT_USER_COMPANIES'

export const SET_EDIT_USER_ROLE = 'SET_EDIT_USER_ROLE'

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const RESET_EDIT_USER = "RESET_EDIT_USER"


export const SET_CREATE_USER_FIRST_NAME = 'SET_CREATE_USER_FIRST_NAME'
export const SET_CREATE_USER_LAST_NAME = 'SET_CREATE_USER_LAST_NAME'
export const SET_CREATE_USER_EMAIL = 'SET_CREATE_USER_EMAIL'
export const SET_CREATE_USER_PASSWORD = 'SET_CREATE_USER_PASSWORD'
export const SET_CREATE_USER_COMPANY = 'SET_CREATE_USER_COMPANY'
export const SET_CREATE_USER_COMPANIES = 'SET_CREATE_USER_COMPANIES'
export const SET_CREATE_USER_EVENTS = 'SET_CREATE_USER_EVENTS'
export const SET_CREATE_USER_ROLE = 'SET_CREATE_USER_ROLE'
export const SET_CREATE_USER_STATUS = 'SET_CREATE_USER_STATUS'
export const RESET_CREATE_USER = 'RESET_CREATE_USER'

export const CREATE_NEW_USER = 'CREATE_NEW_USER'

export const ACTIVE_TAB = 'ACTIVE_TAB'





