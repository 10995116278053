import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX
import { logout } from '../../actions/auth';
import { removeActiveIcon } from '../../actions/icon.js';
import { clearEvents } from '../../actions/event';
import { clearRegistrants } from '../../actions/registrants';
import { clearMessages } from '../../actions/messages';

// HOOKS
import useEventTitleFromUrl from '../../hooks/useEventTitleFromUrl';

// COMPONENTS
import Profile from '../profile/Profile';
import CustomIcon from '../UI/CustomIcon';

// MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

// STATIC
import settingIcon from '../../static/icons/event-setting.png';
import { Menu, Notifications, SupervisedUserCircle, SupervisorAccount } from '@mui/icons-material';
import SideDrawer from './SideDrawer';
import { Avatar, ClickAwayListener, keyframes, styled } from '@mui/material';
import logo from '../../static/img/brr-logo.png';
import NotificationsPanel from '../notifications';
import { getActiveParticipantName } from '../../utils/getActiveParticipantName';
import { isMobile } from 'react-device-detect';


const beepAnimation = keyframes`
  0%, 100% {
    color: #7CB332;
  }
  50% {
    color: white;
  }
`;

const BeepingIcon = styled(SupervisedUserCircle)`
  animation: ${beepAnimation} 1s infinite;
  color: white;
`;



const HeaderBar = ({
  auth: { isAuthenticated, user },
  events,
  searchedEvent,
  logout,
  removeActiveIcon,
  clearEvents,
  clearMessages,
  clearRegistrants,
}) => {
  const url = useLocation();
  const [userName, setUserName] = useState({});
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [isEditUrl, setIsEditUrl] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isMobileScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
 const activeParticipantName = useSelector((state)=> state.auth.activeParticipantName)
  const handleTooltipToggle = () => {
    setIsTooltipOpen((prevOpen) => !prevOpen);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const activeParticipant = useSelector((state) => state.auth.activeParticipant)

  const eventInfo = useEventTitleFromUrl(url, events && events);
  const searchedInfo = useEventTitleFromUrl(
    url,
    searchedEvent && searchedEvent.items
  );

  useEffect(() => {
    if (isAuthenticated && user !== null) setUserName(user.name);
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (url.pathname.includes('edit')) {
      return setPageTitle('Event Management');
    } else if (url.pathname.includes('dashboard')) {
      return setPageTitle('Events');
    } else if (url.pathname.includes('cp-management')) {
      return setPageTitle('Client Portal User Management');

    }


  }, [url]);

  console.log('activeParticipantNameactiveParticipantName', activeParticipantName)

 

  useEffect(() => {
    if (searchedEvent !== null && searchedEvent.items.length > 0) {


      setIsEditUrl(searchedInfo.isEditUrl);
      setEventTitle(searchedInfo.eventTitle);
      return;
    }
    if (events !== undefined && events.length > 0) {
      setIsEditUrl(eventInfo.isEditUrl);
      setEventTitle(eventInfo.eventTitle);
      return;
    }
  }, [url, events, searchedEvent, searchedInfo, eventInfo]);

  const handleOpenUserProfile = () => {
    setOpenUserProfile(true);
  };

  const handleCloseUserProfile = () => {
    setOpenUserProfile(false);
    removeActiveIcon();
  };

  const handleLogout = () => {
    clearRegistrants();
    clearMessages();
    clearEvents();
    logout();
  };

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }

  return (
    <AppBar
      position='sticky'
      elevation={3}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.75rem',
        minHeight: '60px',
        left: '0px',
        paddingRight: 'min(5vw, 3rem)',
        paddingLeft: 'min(5vw, 3rem);',
        // color: 'var(--clr-gray80)',
        color:'var(--clr-w)',
        // backgroundColor: 'var(--clr-w)',
        backgroundColor:'var(--clr-gray80)',
        width: '100%',
      }}
      className='headerbar-shadow'
    >
            <Box sx={{display:'flex', gap:'1rem'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

            <SideDrawer/>
             
              </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
          <Link to='/event-dashboard'>
            <Avatar
              alt='BRR'
              variant='square'
              src={logo}
              sx={{height: '50%' }}
            />
          </Link>
        </Box>




      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {/* {isEditUrl ? <CustomIcon iconElement={settingIcon} /> : <EventIcon />} */}
        <Typography
          sx={{
            // pl: '12px',
            mr: '8px',
            fontSize: { md: '1.25rem', sx: '0.9rem' },
            // fontSize: 'clamp(1rem, 0.9194rem + 0.3582vw, 1.25rem)',
            '&::first-letter': { textTransform: 'uppercase' },
          }}
        >
          {pageTitle}
          {isEditUrl && ':'}
        </Typography>
        {isEditUrl && (
          <Typography
            sx={{
              fontSize: { md: '1.25rem', sx: '0.9rem' },
              fontWeight: '700',
            }}
          >
            {eventTitle}
          </Typography>
        )}

        {/* <Typography sx={{ml:'0.6rem', background:'#7CB332', p:'0.4rem', borderRadius:'0.4rem'}}>Jishan is controlling the Q&A</Typography> */}



      </Box>


      </Box>

      {/* <Box sx={{display:'flex', gap:'1rem'}}>
        <Typography>Jishan is controlling the event</Typography>
</Box> */}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>
          {userName.first} {userName.last}
        </Typography>

        <Tooltip title='Open settings'>
          <IconButton
            onClick={handleOpenUserProfile}
            size='large'
            sx={{ color: 'inherit' }}
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>

        {
url.pathname.includes('edit') &&
        <Box sx={{ ml: '10px' }}>

              <NotificationsPanel />
   </Box>
}

  {
url.pathname.includes('edit') &&
   <Box sx={{ ml: '10px' }}>
      

      {
        isMobileScreen || isMobile ?     <IconButton
        size='large'
        sx={{ color: 'inherit' }}
      >


<ClickAwayListener onClickAway={handleTooltipClose}>

      <Tooltip
        open={isTooltipOpen}
        title = {<Typography>{activeParticipantName + ' is controlling the Q&A'}</Typography>}
          arrow
        onClick={handleTooltipClose}
      >
         <BeepingIcon  onClick={handleTooltipToggle}/>
      </Tooltip>
    
  </ClickAwayListener>
      </IconButton>
:    <Tooltip title = {<Typography>{activeParticipantName + ' is controlling the Q&A'}</Typography>} >

       <IconButton
         size='large'
         sx={{ color: 'inherit' }}
       >
 <BeepingIcon/>
       </IconButton>
     </Tooltip>
      }


</Box>
  }


        
        <Box sx={{ ml: '20px' }}>
          <Tooltip title='Logout'>
            <Link
              onClick={handleLogout}
              to='/login'
              style={{ display: 'flex', color: 'inherit' }}
            >
              <LogoutIcon />
            </Link>
          </Tooltip>
        </Box>

        
      </Box>




      <Profile open={openUserProfile} close={handleCloseUserProfile} />
    </AppBar>
  );
};

HeaderBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  events: PropTypes.array,
  searchedEvent: PropTypes.object,
  removeActiveIcon: PropTypes.func.isRequired,
  clearEvents: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearRegistrants: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.event.events.items,
  searchedEvent: state.event.searchedEvent,
});

export default connect(mapStateToProps, {
  logout,
  removeActiveIcon,
  clearEvents,
  clearMessages,
  clearRegistrants,
})(HeaderBar);
