import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { PeopleAlt } from '@mui/icons-material';

const SidebarLink = ({
  linkTo,
  marginTopMin = '50px',
  marginTopMax = '20vh',
  text,
  linkIcon,
  active = false,
  doSomething,
}) => {
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (linkIcon === 'events') setIcon(<EventIcon />);
    if (linkIcon === 'profile') setIcon(<ManageAccountsIcon />);
    if (linkIcon === 'user') setIcon(<PeopleAlt />);


    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '0.2rem 0',
        marginTop: `min(${marginTopMax}, ${marginTopMin})`,
      }}
      className={active ? 'activeLink' : undefined}
    >
      <Link
        to={`/${linkTo}`}
        onClick={doSomething}
        style={{ color: 'inherit' }}
        className={active ? 'disabled-link' : ''}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: active ? '0.6' : '1',
          }}
        >
          {icon}
          <Typography
            variant='caption'
            sx={{
              lineHeight: 1,
              marginTop: '0.3rem',
              textAlign: 'center',
              '&::first-letter': { textTransform: 'uppercase' },
            }}
          >
            {text}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default SidebarLink;
