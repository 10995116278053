import React, { useContext, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// WEBSOCKET CONTEXT
import { PrimusContext } from '../../websocket/PrimusWs';
import { PrimusContextPV } from '../../websocket/PrimusWsPassive';

// REDUX
import { updateMessageStatus } from '../../../actions/messages';

// UTILS
import { selectElColors } from '../../../utils/selectMsgStatusColor';

// MUI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Flag } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  table: {
    '& p': {
      paddingBottom: '0.25rem',
      fontSize: 'var(--fs-message)', 
    }
  },
}));

const QuestionsTable = ({
  userAccessToken,
  userID,
  eventID,
  messages,
  status,
  updateMessageStatus,
  presenterView,
  socketWc
}) => {

  const primusMethods = useContext(PrimusContext);
  const primusMethodsPV = useContext(PrimusContextPV);
  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const [newStatus, setNewStatus] = useState([]);
  const [statusSetted, setStatusSetted] = useState(false);
  const [msgList, setMsgList] = useState([]);

  useEffect(() => {
    
      //some token verification
      // console.log('primus passive methods', primusMethodsPV)
      // console.log('primus  methods', primusMethods)
      // console.log('Status settled', statusSetted)
  }, []);

  useEffect(() => {
    const areEquals = JSON.stringify(messages) === JSON.stringify(msgList);

    if (!areEquals) {
      setMsgList(messages);
      setNewStatus(
        messages.map((item) => {
          return {
            id: item._id,
            status: item.status,
          };
        })
      );
      setStatusSetted(true);
    }
  }, [messages, msgList]);

  const handleChangeStatus = (e, id) => {
    try {
      let current = newStatus.filter((el) => el.id === id);

      if (e.target.checked) {
     
      // presenterView ? primusMethodsPV.sendOrderList(userID, 'orderList', eventID)   :primusMethods.sendOrderList(userID, 'orderList', eventID) 
      //  presenterView ? primusMethodsPV.sendOrderListForPresenter(userID, 'MessageApproved', eventID, 'presenter') : primusMethods.sendOrderListForPresenter(userID, 'MessageApproved', eventID, 'presenter')
        
      let data = {
        from:userID,
        eventID:eventID,
        userID:userID
      }
      presenterView  ? socketWc.emit('messageOrder',data)   : socket_cp.emit('messageOrder',data)

        current[0].status = 'replied';
        setNewStatus((newStatus) =>
          newStatus.map((el) =>
            el.id === id ? { ...el, [e.target.name]: current[0].status } : el
          )
        );
        updateMessageStatus(userAccessToken, id, current[0].status);

        // presenterView ?  primusMethodsPV.sendMessageStatusChanged(
        //   userID,
        //   current[0].status,
        //   eventID,
        //   id
        // ):  primusMethods.sendMessageStatusChanged(
        //   userID,
        //   current[0].status,
        //   eventID,
        //   id
        // );


      } else {
        current[0].status = 'allowed';
        setNewStatus((newStatus) =>
          newStatus.map((el) =>
            el.id === id ? { ...el, [e.target.name]: current[0].status } : el
          )
        );
        updateMessageStatus(userAccessToken, id, current[0].status);

        // presenterView ? primusMethodsPV.sendMessageStatusChanged(
        //   userID,
        //   current[0].status,
        //   eventID,
        //   id
        // ) :primusMethods.sendMessageStatusChanged(
        //   userID,
        //   current[0].status,
        //   eventID,
        //   id
        // );



        let data = {
          from:userID,
          eventID:eventID,
          userID:userID
        }
        presenterView  ? socketWc.emit('messageOrder',data)   :   socket_cp.emit('messageOrder',data)
      }
    } catch (error) {
      console.log('??????????????????????', error)
    }

  };

  const classes = useStyles();

  return (
    statusSetted && (
      <>
            {
    status === 'replied' && (
      <Typography
      variant='h4'
      color='var(--clr-gray80)'
      sx={{ textTransform: 'capitalize',m:'1rem' }}
    >
      Answered
    </Typography>
    )
    
   }

{
    status === 'approved' && (
      <Typography
      variant='h4'
      color='var(--clr-gray80)'
      sx={{ textTransform: 'capitalize',m:'1rem' }}
    >
      Approved
    </Typography>
    )
    
   }
        <TableContainer>
          
          <Table className={classes.table}>
            <TableHead>
            {
       status === 'replied' ? (
      <></>
    ) :(
      <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      
      <TableCell
        align='center'
        sx={{
          width: '120px',
          paddingRight: { md: '3.25rem', sm: '1.5rem' },
          color: 'var(--clr-gray60)',
          fontSize: '1.2rem',
        }}
      >
         {

!presenterView &&
        <Typography>Status</Typography>
         }
        </TableCell>
    </TableRow>
    )
   }
 


            </TableHead>
            <TableBody>
              {msgList.map((message) => (
                <TableRow
                  hover
                  key={message._id}
                  sx={{
                    verticalAlign: 'top',
                  }}
                >
                  <TableCell
                    align='right'
                    sx={{
                      width: { md: '200px', sm: '140px' },
                      paddingRight: '0px',
                      '& p': {
                        fontWeight: '900',
                      },
                     
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        width: '20px',
                        height: '100%',
                        backgroundColor: `${
                          selectElColors(
                            newStatus.find((el) => el.id === message._id)
                              .status,
                            message._id
                          ).bg
                        }`,
                      },
                    }}
                  > 
                  {

            message.anonymous && <Flag sx={{color:"#eb4d4b"}}/> 
                  }




                    {message.regid && message.regid.fields ? (
                      <>
                        {
                          Object.keys(JSON.parse(message.regid.fields)).map(v => (

                            <Typography sx={{ fontWeight: 500, textTransform: 'capitalize' }}>
                              {v}
                            </Typography>
                          ))
                        }
                        <Typography>Message:</Typography>
                      </>
                    ) :
                      <>
                        <Typography>Name:</Typography>
                        <Typography>
                          Company:
                        </Typography>
                        <Typography>Message:</Typography>
                      </>

                    }


                  </TableCell>
                  <TableCell>
                  {
                            message.anonymous &&
                          <Box display={'flex'} justifyContent={'start'} flexDirection={'row'}>
                          
                            <Typography sx={{color:"#eb4d4b"}}>Anonymous question</Typography>

                          </Box>
                          }

                    {message.regid && message.regid.fields ? (
                      <>
                        {
                          Object.keys(JSON.parse(message.regid.fields)).map(v => (

                            <Typography
                              sx={{
                                width: 'max-content',
                                fontWeight: '500',
                              }}
                            >


                              {JSON.parse(message.regid.fields)[v] ?

                                JSON.parse(message.regid.fields)[v]

                                : 'Na'}

                            </Typography>
                          ))


                        }
                        <Typography>
                          {message.content}
                        </Typography>
                      </>
                    ) :

                      <>
                        <Typography>
                          {message.name === undefined || message.name === ''
                            ? 'NA'
                            : message.name}
                        </Typography>
                        <Typography
                          sx={{
                            width: 'max-content',
                            color: 'var(--clr-gray40)',
                            fontWeight: '500',
                          }}
                        >
                          {message.company === undefined || message.company === ''
                            ? 'NA'
                            : message.company}
                        </Typography>
                        <Typography
                          sx={{
                            maxWidth: { xl: '85%' },
                          }}
                        >
                          {message.content}
                        </Typography>
                      </>

                    }



            





                    {message.notes && message.notes !== '' && (
                      <Box
                        sx={{
                          position: 'relative',
                          maxWidth: { xl: '85%' },
                          '& > svg': {
                            position: 'absolute',
                            width: '1.25rem',
                            height: '1.25rem',
                            marginTop: '0.375rem',
                          },
                          '& p': {
                            marginLeft: '1.75rem',
                          },
                        }}
                      >
                        <Divider
                          light
                          sx={{
                            marginTop: '1rem',
                            marginBottom: '0.35rem',
                          }}
                        />
                        <InsertCommentIcon color='warning' />
                        <Typography>{message.notes}</Typography>
                      </Box>
                    )}
                  </TableCell>

                     
                  <TableCell
                    align='center'
                    sx={{
                      width: '120px',
                      paddingRight: { md: '3.25rem', sm: '1.5rem' },
                      verticalAlign: 'middle',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        minWidth: 120,
                        '& > span:last-child': {
                          color: 'var(--clr-gray80)',
                        },
                      }}
                      label='Mark as read'
                      labelPlacement='bottom'
                      control={
                        <Checkbox
                          checked={
                            newStatus.find((el) => el.id === message._id)
                              .status === 'replied'
                          }
                          name='status'
                          onChange={(e) => handleChangeStatus(e, message._id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          sx={{
                            color: 'var(--clr-gray40)',
                            '& svg': {
                              width: '30px',
                              height: '30px',
                            },
                            '&.Mui-checked': {
                              color: `${
                                selectElColors(
                                  newStatus.find((el) => el.id === message._id)
                                    .status,
                                  message._id
                                ).bg
                              }`,
                            },
                          }}
                        />
                      }
                    />
                  </TableCell>
                
                    
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  );
};

QuestionsTable.propTypes = {
  updateMessageStatus: PropTypes.func.isRequired,
};

export default connect(null, { updateMessageStatus })(QuestionsTable);
