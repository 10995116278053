import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PrimusProvider, { PrimusContext } from '../websocket/PrimusWs';

// REDUX
import { getEvent } from '../../actions/event';

// COMPONENTS
import Spinner from '../UI/Spinner';
import EventDashboardHeighAccessLevel from './EventDashboardHeighAccessLevel';
import EventDashboardLowAccessLevel from './EventDashboardLowAccessLevel';
import PrimusWs from '../websocket/PrimusWs';
import { wsConnected } from '../../actions/websocket';
import { Box, createTheme, Paper, ThemeProvider, Typography } from '@mui/material';
import ErrorPopUp from '../UI/ErrorPopUp';
import Draggable from 'react-draggable';
import SocketContainer from '../websocket/SocketContainer';
import io from 'socket.io-client';
import { lightGreen, yellow } from '@mui/material/colors';


// const socket = io(process.env.REACT_APP_SOCKET_IO_URL);
// const socket_cp = io('http://localhost:1000/clientPortal'); // Update with your server URL
// const socket_cp = io('http://localhost:1000/clientPortal')

const socket_cp = io(`${process.env.REACT_APP_SOCKET_IO_URL}/clientPortal`)


const theme = createTheme({

  typography: {
    event: {
      fontSize: '0.8rem',
    },
    // Disable h3 variant
    h3: undefined,
  },
});

theme.typography.event = {
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
}

const Event = ({ loading, getEvent, event, auth: { user } }) => {
  const primusMethods = useContext(PrimusContext);

  const isWsAppendedToDown = useSelector((state) => state.websocket.isWsStatusReady);

  const dispatch = useDispatch()
  let eventParamsId = useParams();
  const [userAccessLevel, setUserAccessLevel] = useState(0);
  const [chatOpen, setChatOpen] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 50, y: 50 });

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };
  const handleDrag = (_, { x, y }) => {
    const boundaryTop = 100;
    const boundaryLeft = 100;
    const boundaryRight = window.innerWidth - 100; // Adjust as needed

    // Limit the chat bubble's movement within the defined boundaries
    const constrainedX = Math.max(boundaryLeft, Math.min(boundaryRight, x));
    const constrainedY = Math.max(boundaryTop, y);

    setBubblePosition({ x: constrainedX, y: constrainedY });
  
  };

  useEffect(() => {
    if (user) setUserAccessLevel(user.level);
  }, [user]);

  

  useEffect(() => {
    if (eventParamsId !== undefined || eventParamsId !== null)
      getEvent(eventParamsId.id);
      dispatch(wsConnected(eventParamsId.id))
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    console.log('isWsAppendedToDown', isWsAppendedToDown);
    //if false then add primus connection
    // eslint-disable-next-line
},[])
const chatBubbleSize = 80;
const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

let messageTop = bubblePosition.y - chatBubbleSize / 2;
if (messageTop < 0) {
  messageTop = bubblePosition.y + chatBubbleSize / 2;
}

let messageBottom = windowHeight - bubblePosition.y - chatBubbleSize / 2;
if (messageBottom < 0) {
  messageBottom = chatBubbleSize / 2;
}

let messageRight = windowWidth - bubblePosition.x - chatBubbleSize / 2;
if (messageRight < 0) {
  messageRight = chatBubbleSize / 2;
}

let messageLeft = bubblePosition.x - chatBubbleSize / 2;
if (messageLeft < 0) {
  messageLeft = bubblePosition.x + chatBubbleSize / 2;
}


  return (
    <>
      <ThemeProvider theme={theme}>
        <ErrorPopUp />
        {loading || event === null || userAccessLevel === 0 ? (
          <Spinner />
        ) : userAccessLevel === 1 ? (
          // <PrimusWs>
            <SocketContainer socket_cp={socket_cp}>

              <EventDashboardLowAccessLevel socket_cp={socket_cp} />
            </SocketContainer>
          // </PrimusWs>
        ) : (
          // <PrimusWs>
            <SocketContainer socket_cp={socket_cp}>
              <EventDashboardHeighAccessLevel socket_cp={socket_cp} />
            </SocketContainer>

          // </PrimusWs>
        )}
      </ThemeProvider>
    </>
  );
};

Event.propTypes = {
  loading: PropTypes.bool,
  getEvent: PropTypes.func.isRequired,
  event: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.event.loading,
  event: state.event.event,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getEvent,
})(Event);
