export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const MESSAGES_FAIL = 'MESSAGES_FAIL';
export const NO_MESSAGES = 'NO_MESSAGES';


export const CUSTOM_FIELDS = 'CUSTOM_FIELDS';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS';
export const UPDATE_MESSAGE_STATUS_IN_STORE = 'UPDATE_MESSAGE_STATUS_IN_STORE';
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL';
export const ADD_MESSAGE_NOTE = 'ADD_MESSAGE_NOTE';

export const CREATE_PRESENTER_VIEW = 'CREATE_PRESENTER_VIEW';
export const UPDATE_PRESENTER_VIEW = 'UPDATE_PRESENTER_VIEW';
export const REBUILD_PRESENTER_VIEW = 'REBUILD_PRESENTER_VIEW';
export const REORDER_PRESENTER_VIEW = 'REORDER_PRESENTER_VIEW';
//REBUILD PRESENTER VIEW FROM DATABSE
export const FORCE_REBUILD_PRESENTER_VIEW = 'FORCE_REBUILD_PRESENTER_VIEW';


export const MESSAGE_ORDER = 'MESSAGE_ORDER';
export const IS_ORDER_LIST_SAVED = 'IS_ORDER_LIST_SAVED';
export const SAVE_LIST_COUNTER = 'SAVE_LIST_COUNTER';
export const UPDATE_MESSAGE_ORDER = 'UPDATE_MESSAGE_ORDER';
export const MESSAGE_ORDER_NOT_FOUND = 'MESSAGE_ORDER_NOT_FOUND';
export const CLEAR_MESSAGE_ORDER = 'CLEAR_MESSAGE_ORDER';

export const GET_MESSAGES_CSV_FILE = 'GET_MESSAGES_CSV_FILE';
export const CLEAR_MESSAGES_CSV = 'CLEAR_MESSAGES_CSV';


export const FINAL_PUSH_ORDER = 'FINAL_PUSH_ORDER'
