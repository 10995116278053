import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, Grid, Paper, Stack, styled, TextField } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deleteMessage,
  postNewSplitMessage,
  addMessageLogs,
} from "../../../../actions/splitQuestions";
import { updateMessageStatus } from "../../../../actions/messages";
import { PrimusContext } from "../../../websocket/PrimusWs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "70%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 8000,
  margin: "0.6rem",
  borderRadius: '20px'
};

const SplitButton = styled(Button)(({ theme }) => ({
  // marginTop: '0.2rem',
  backgroundColor: "var(--clr-gray40)",
  color: "var(--clr-gray10)",
  "&:hover": {
    backgroundColor: "var(--clr-gray80)",
  },
}));

const SplitQuestionModal = ({ open, handleClose, msg, setOpen }) => {


  const primusMethods = useContext(PrimusContext);

  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const access_token = useSelector((state) => state.auth.token);
  const userID = useSelector((state) => state.auth.loggedInUserID);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("From msg modal", msg);
  }, []);

  const [totalSplits, setTotalSPlits] = useState(2);

  //Editable textarea 
  const [editableText, setEditableText] = useState('')
  const [inputValue, setInputValue] = React.useState([]);
  const [showSlipButtons, setShowSliptButtons] = useState(false)

  const [selectedValue, setSelectedValue] = React.useState("default");

  const makeSplits = (n) => {
    setTotalSPlits(n);
    // setInputValue([]);
  };
  function changeInputValue(element, index, value) {
    const newAddedInput = { ...inputValue[index], [element]: value };
    const newAddedInputs = [...inputValue];
    newAddedInputs[index] = newAddedInput;
    setInputValue(newAddedInputs);
    console.log("each values from modal", newAddedInputs);
  }

  useEffect(() => {
    console.log("each values from modal", inputValue);
  }, [inputValue]);

  useEffect(() => {
    setEditableText(msg.content)
  }, [msg])

  const saveAndRemove = () => {
    console.log("Save and remove", inputValue, msg);
    let eventid = msg.event._id;
    // let findId = msg.regid.findId
    let name = msg.name;
    let email = msg.email;

    let company = msg.company;
    let organisation = msg.company;

    let status = msg.status;
    let createTime = msg.createTime;
    let content = msg.content;

    //loop through each message and post a new question

    inputValue.map((each, i) => {
      console.log(
        "Save and keep",
        "eventid",
        "findid",
        each[`message${i + 1}`],
        eventid,
        email,
        name,
        organisation,
        status
      );
      dispatch(
        postNewSplitMessage(
          each[`message${i + 1}`],
          email,
          eventid,
          name,
          organisation,
          status
        )
      );
    });

    dispatch(
      addMessageLogs(eventid, email, name, company, createTime, content)
    );
    dispatch(deleteMessage(msg._id, eventid));
    setOpen(false);

    try {
      // primusMethods.messageEdited(userID, 'messageEdited', eventid)
      // Message edited -> Socket.io


      let data = {
        messageID:msg._id,
        userID:userID,
        eventID:eventid,
        messageContent:{from:msg.content,edited:inputValue}
      }
      socket_cp.emit('messageEdited', data)
    } catch (error) {
      console.log('primus error', error)
    }

  };

  const saveAndKeep = () => {
    let eventid = msg.event._id;
    // let findId = msg.regid.findId
    let name = msg.name;
    let email = msg.email;

    let organisation = msg.company;
    let status = msg.status;
    let createTime = msg.createTime;

    //loop through each message and post a new question

    inputValue.map((each, i) => {
      console.log(
        "Save and keep",
        "eventid",
        "findid",
        each[`message${i + 1}`],
        eventid,
        email,
        name,
        organisation,
        status
      );
      dispatch(
        postNewSplitMessage(
          each[`message${i + 1}`],
          email,
          eventid,
          name,
          organisation,
          status
        )
      );
    });
    let data = {
      messageID:msg._id,
      userID:userID,
      eventID:eventid,
      messageContent:{from:msg.content,edited:inputValue}
    }
    socket_cp.emit('messageEdited', data)
    setOpen(false);
  };

  let handleChngeEditable = (value) => {

    setEditableText(value)
  }

  let handleShowSlipButton = (value) => {
    setShowSliptButtons(value)
  }
  let handleClickEdit = () => {
    let eventid = msg.event._id;
    // let findId = msg.regid.findId
    let name = msg.name;
    let email = msg.email;

    let organisation = msg.company;
    let status = msg.status;
    let createTime = msg.createTime;
    let content = msg.content;

    //Add new message
    dispatch(
      postNewSplitMessage(
        editableText,
        email,
        eventid,
        name,
        organisation,
        'new',
        'edited'
      )
    )

    //Set message status to edited
    dispatch(
      updateMessageStatus(
        access_token,
        msg._id,
        'edited'
      )
    );

    //Save to logs
    dispatch(
      addMessageLogs(eventid, email, name, organisation, createTime, content)
    );

    try {
      // primusMethods.messageEdited(userID, 'messageEdited', eventid)
      let data = {
        messageID:msg._id,
        userID:userID,
        eventID:eventid,
        
        messageContent:{from:msg.content,edited:editableText}
      }
      socket_cp.emit('messageEdited', data)
    } catch (error) {
      console.log('primus error', error)
    }


  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


          <Stack direction={'row'} justifyContent={'center'}>
            <Paper sx={{ width: '30%', height: '200px', background: "white" }}>
              <Box m={1} sx={{ display: 'flex', flexDirection: 'column' }} justifyContent={'center'}>

                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  sx={{ fontSize: "1rem", backgroundColor: '#2A90EF', p: '1rem', color: 'white' }}

                >
                  Message details
                </Typography>

                <Box m={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} justifyContent={'center'}>

                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    sx={{ fontSize: "1rem", p: '0.4rem' }}

                  >
                    Name - {msg.name === undefined ? "NA" : msg.name}
                  </Typography>

                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    sx={{ fontSize: "1rem", p: '0.4rem' }}

                  >
                    Email - {msg.email === undefined ? "NA" : msg.email}
                  </Typography>

                  <Typography
                    id="modal-modal-title"
                    variant="h4"
                    sx={{ fontSize: "1rem", p: '0.4rem' }}

                  >
                    Company -{" "}
                    {msg.company === undefined || msg.company === ""
                      ? "NA"
                      : msg.company}
                  </Typography>

                </Box>
              </Box>

            </Paper>

            <Paper sx={{ width: '70%', height: 'auto', background: "white" }}>
              <Box m={1} justifyContent={'center'}>

                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  sx={{ fontSize: "1rem", backgroundColor: '#2A90EF', p: '1rem', color: 'white' }}
                  p={1}
                >
                  Message Content
                </Typography>

                <TextField
                  sx={{ width: '100%', m: '0.5rem' }}
                  autoFocus={true}
                  id="split-question"
                  placeholder={`Message`}
                  label="Message"
                  multiline
                  maxRows={4}
                  value={editableText}
                  onChange={(e) => {
                    handleChngeEditable(e.target.value)
                  }}
                />

              </Box>

            </Paper>

          </Stack>
          {
            !showSlipButtons &&
            <Stack direction={'row'} justifyContent={'center'}>
              <Button variant="contained" sx={{ width: 'auto', m: '0.5rem', background: '#7CB342' }} onClick={handleClickEdit}> Save</Button>

            </Stack>
          }


          <Stack>
            {

              !showSlipButtons && <Typography sx={{ textAlign: 'center' }}>Message too long? You can split message by clicking <Chip label="here" onClick={() => handleShowSlipButton(true)} />
              </Typography>
            }
          </Stack>


          {
            showSlipButtons &&
            <>


              <Stack
                direction={"row"}
                sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}
                spacing={2}
                m={2}
              >

                <Typography>
                  Number of Splits :
                </Typography>


                <SplitButton
                  variant="contained"
                  onClick={() => makeSplits(2)}
                  sx={{ background: totalSplits === 2 && "var(--clr-primary)" }}
                >
                  2
                </SplitButton>
                <SplitButton
                  variant="contained"
                  onClick={() => makeSplits(3)}
                  sx={{ background: totalSplits === 3 && "var(--clr-primary)" }}
                >
                  3
                </SplitButton>
                <SplitButton
                  variant="contained"
                  onClick={() => makeSplits(4)}
                  sx={{ background: totalSplits === 4 && "var(--clr-primary)" }}
                >
                  4
                </SplitButton>
                <SplitButton
                  variant="contained"
                  onClick={() => makeSplits(5)}
                  sx={{ background: totalSplits === 5 && "var(--clr-primary)" }}
                >
                  5
                </SplitButton>
              </Stack>


              <Stack
                sx={{ display: "flex", justifyContent: "center" }}
                spacing={2}
                m={2}
              >
                {[...Array(totalSplits)].map((e, i) => (
                  <TextField
                    id="split-question"
                    placeholder={`Message ${i + 1}`}
                    // label={`Message ${i+1}`}
                    multiline
                    maxRows={4}
                    value={
                      inputValue.length > 0
                        ? inputValue[i] && inputValue[i].message
                        : ""
                    }
                    onChange={(e) => {
                      changeInputValue(`message${i + 1}`, i, e.target.value);
                    }}
                  />
                ))}
              </Stack>


              <Stack
                direction={"row"}
                sx={{ display: "flex", justifyContent: "center" }}
                spacing={2}
                m={2}
              >
                <Button variant="contained" onClick={() => saveAndRemove()} sx={{ fontSize: { sm: '0.6rem', md: '0.8rem', lg: '0.8rem', xl: '1rem' } }}>
                  Save and remove original message
                </Button>
                <Button variant="contained" onClick={() => saveAndKeep()} sx={{ fontSize: { sm: '0.6rem', md: '0.8rem', lg: '0.8rem', xl: '1rem' } }}>
                  Save and keep original message
                </Button>
                <Button variant="contained" color="error" onClick={() => handleShowSlipButton(false)} sx={{ fontSize: { sm: '0.6rem', md: '0.8rem', lg: '0.8rem', xl: '1rem' } }}>
                  Cancel Split
                </Button>
              </Stack>

            </>
          }
        </Box>
      </Modal>
    </div>
  );
};

export default connect()(SplitQuestionModal);
