import React from 'react';
import { Link } from 'react-router-dom';

// MUI
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#fff',
    border: '2px solid var(--clr-info40)',
    color: '#4A4A4A',
    fontSize: '12px',
  },
}));

const EditEventBtn = ({ eventId, accessLevel, backgroundClr }) => {
  const classes = useStyles();

  return (
    <Link to={`edit/${eventId}`} style={{ color: 'inherit' }}>
      <Tooltip
        title='Manage Event'
        placement='left-start'
        classes={{ tooltip: classes.tooltip }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-10, -10],
              },
            },
          ],
        }}
      >
        <Button
          variant='contained'
          color='inherit'
          sx={{
            minWidth: '30px',
            maxWidth: '30px',
            maxHeight: '70px',
            height: '70px',
            backgroundColor: `${backgroundClr}`,
            '&:hover': {
              color: 'var(--clr-w)',
              backgroundColor: `${backgroundClr}d9`,
            },
          }}
        >
          <MoreHorizIcon />
        </Button>
      </Tooltip>
    </Link>
  );
};

export default EditEventBtn;
