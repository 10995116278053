import {
  WS_APPENDED_TO_DOM,
  WEBSOCKET_DISCONNECT,
  WS_NEW_MESSAGE_RECIVED,
  WS_RESET_MESSAGE_ARRAY,
  WS_APPROVED_MESSAGE_RECIVED,
  WS_ADD_APPROVED_MESSAGE,
  WS_CHANGED_MESSAGE_STATUS,
  WS_ORDER_LIST_RECIVED,
  WS_RESET_ORDER_LIST,
  WS_CONNECTED,
  WS_RESET_CONNECTION,
  WS_LIVE_USERS,
  SET_DIALOG_BOX_CONTROL,
  SET_DIALOG_BOX_TAKING_CONTROL,
  RESET_WS_LIVE_USERS,
  SET_ACTIVE_MOD,
  WS_LIVE_USERS_BY_EVENT,
  SOCKETIO_CP_CONNECTED
} from '../constants/websocketConstants';

const initialState = {
  isWsStatusReady: false,
  wsConnection:false,
  wsConnectedCount:0,
  wsConnectedEvents:[],
  isWsDisconnected: false,
  isWsOrderListRecived: false,
  wsNewMessagesRecived: [],
  wsApprovedMessagesRecived: [],
  wsApprovedMessages: [],
  wsChangedMessageStatus: [],
  //Live users
  wsMainLiveUser:'',
  activeMod:'',
  wsLiveUsers:[],
  showDialopBoxControl:false,
  showDialopBoxTakingControl:false,
  liveUsersByEvent:[],

};

function websocket(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {


  

    case WS_APPENDED_TO_DOM:
      return {
        ...state,
        isWsStatusReady: true,
      };

    case WS_CONNECTED:
      return {
        ...state,
        wsConnection:payload
      }

    case WS_RESET_CONNECTION:
      return {
        ...state,
        wsConnectedCount:0
      }
    case WEBSOCKET_DISCONNECT:
      return {
        ...state,
        isWsDisconnected: true,
      };
    case WS_NEW_MESSAGE_RECIVED:
      return {
        ...state,
        wsNewMessagesRecived: [...state.wsNewMessagesRecived, payload],
      };
    case WS_RESET_MESSAGE_ARRAY:
      return {
        ...state,
        wsNewMessagesRecived: [],
        wsApprovedMessagesRecived: [],
        wsChangedMessageStatus: [],
      };
    case WS_APPROVED_MESSAGE_RECIVED:
      return {
        ...state,
        wsApprovedMessagesRecived: [
          ...state.wsApprovedMessagesRecived,
          payload,
        ],
      };
    case WS_ADD_APPROVED_MESSAGE:
      return {
        ...state,
        wsApprovedMessages: [...state.wsApprovedMessages, payload],
      };
    case WS_CHANGED_MESSAGE_STATUS:
      return {
        ...state,
        wsChangedMessageStatus: [...state.wsChangedMessageStatus, payload],
      };
    case WS_ORDER_LIST_RECIVED:
      return {
        ...state,
        isWsOrderListRecived: payload,
      };
    case WS_RESET_ORDER_LIST:
      return {
        ...state,
        isWsOrderListRecived: false,
      };


      // Live users 

      case WS_LIVE_USERS:
        return {
          ...state,
          wsLiveUsers:payload

        }

        case WS_LIVE_USERS_BY_EVENT:

        

          return {
            ...state,
            liveUsersByEvent:payload
  
          }
          

        case SET_ACTIVE_MOD:
          return {
            ...state,
            activeMod:payload
          }

        case RESET_WS_LIVE_USERS:
          return {
            ...state,
            wsLiveUsers:[]
  
          }

        case SET_DIALOG_BOX_CONTROL:
          return {
            ...state,
            showDialopBoxControl:payload
          }

          case SET_DIALOG_BOX_TAKING_CONTROL:
            return {
              ...state,
              showDialopBoxTakingControl:payload
            }
    default:
      return state;
  }
}

export default websocket;
