import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrimusWs, { PrimusContext } from '../websocket/PrimusWs';

// REDUX
import { getRegistrants } from '../../actions/registrants';
import { getMessages, getAllMessages } from '../../actions/messages';
import { clearRegistrants } from '../../actions/registrants';
import { clearMessages } from '../../actions/messages';
import { clearEvent, clearSearchedEvent, setGlobalTabValue } from '../../actions/event';

// MUI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { lightGreen } from '@mui/material/colors';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Menu, MenuItem, Typography, IconButton, Badge } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';



// COMPONENTS
import BackToEventsListBtn from '../UI/inputs/BackToEventsListBtn';
import TabPanel from '../UI/TabPanel';
import EventRegistrants from './registrants/EventRegistrants';
import EventMessages from './questions/EventMessages';
import BRRBadge from '../UI/inputs/BRRBadge';
import { Chat, ControlCamera, KeyboardArrowDown, TouchApp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogPopUpControl, resetWsLiveUsers, setDialogPopUpTakingControl } from '../../actions/websocket';
import { setActiveParticipantName, setActivePartipant } from '../../actions/auth';
import Spinner from '../UI/Spinner';
import { fetchAndAppendChats, resetChatNotificationCount, setChatMenu } from '../../actions/chat';
import ChatMessages from './chat/ChatMessages';
import useEventMessages from '../../hooks/useEventMessages';
//import { setActivePartipant } from '../../actions/auth';



//Dialog pop up transition -> start
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//Dialog pop up transition -> end


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EventDashboardHeighAccessLevel = ({
  token,
  event,
  getRegistrants,
  getMessages,
  getAllMessages,
  clearRegistrants,
  clearMessages,
  clearEvent,
  clearSearchedEvent,
  wsNewMessagesRecived,
  wsApprovedMessagesRecived,
  wsChangedMessageStatus,
  wsConnection,
  wsLiveUsers,
  globalTabValue,
  auth: { user },
  socket,
  socket_cp
}) => {





  const dispatch = useDispatch();
  const primusMethods = useContext(PrimusContext);
  const [tabValue, setTabValue] = useState(0);
  const bgColorNewMessage = lightGreen[500];
  const bgColorApprovedMsg = lightGreen[500];



  const liveUsersByEvent = useSelector((state) => state.websocket.liveUsersByEvent);


  const liveUsers = useSelector((state) => state.websocket.wsLiveUsers);
  const auth = useSelector((state) => state.auth);
  const chatNotificationCount = useSelector((state) => state.chatReducer.chatNotificationCount);

  const activeParticipant = useSelector((state) => state.auth.activeParticipant)
  // dailog pop up logic -> start
  const showDialopBoxControl = useSelector((state) => state.websocket.showDialopBoxControl);
  const showDialopBoxTakingControl = useSelector((state) => state.websocket.showDialopBoxTakingControl);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl_chat, setAnchorEl_chat] = React.useState(null);
  const openOption_chat = Boolean(anchorEl_chat);

  const [takingControl, setTakingControl] = useState('Another moderator has requested that you moderate this event. Accepting this request will enable you to manage the Q&A.')

  // QA Controlled by
  const [QAControlledBy, setQAControlledBy] = useState('')
  const [activeModUserID, setActiveModUserID] = useState(false)
  
  const [isMessagePresentComponentLevel, setIsMessagePresentComponentLevel] = useState(false)
  const messages = useSelector((state)=> state.messages.allMessage?.messages?.items)

  const [isMessagePresent, loadingMessages] = useEventMessages(messages)


  useEffect(()=>{
    setIsMessagePresentComponentLevel(isMessagePresent)
  },[isMessagePresent])

  //Message




  console.log('isMessagePresentisMessagePresent', isMessagePresent)

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    dispatch(setDialogPopUpControl(false))
    dispatch(setDialogPopUpTakingControl(false))
    setOpenDialog(false);
  };

  // dailog pop up logic -> end
  const handleClickOption_chat = (event) => {
    dispatch(setChatMenu(true))
    dispatch(resetChatNotificationCount())
    setAnchorEl_chat(event.currentTarget);
  };
  const handleCloseOption_chat = () => {
    dispatch(setChatMenu(false))
    // dispatch(resetChats())
    setAnchorEl_chat(null);
  };


    //unsubscribe message event
    useEffect(()=>{
      socket_cp.emit('joinEvent',  event.items[0]._id, auth.loggedInUserID)
  
        return ()=>{
          // socket.off('message')
          socket_cp.off('joinEvent')
          socket_cp.off('message')
  
        }
    },[])



  useEffect(() => {
    // console.log('live ??????????????????//', activeParticipant)
    // if(tabValue === 0 && wsLiveUsers.length>0 && event.items[0].webcast.feedbackEnabled) {
    //   setLoading(false);
    //   getAllMessages(1, event.items[0]._id);
    //   clearRegistrants();
    //      setTabValue(1)   
    // } else {
    //   setTabValue(0)   

    // }



    getRegistrants(
      1,
      20,
      token,
      event.items[0]._id,
      event.items[0].managerId,
      true
    );
    getAllMessages(1, event.items[0]._id);

    dispatch(setGlobalTabValue(0))
    dispatch(fetchAndAppendChats(event.items[0]._id))


    // eslint-disable-next-line
  }, []);






  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => { clearTimeout(timer) };
  }, []);

  // useEffect(() => {
  //   try {

  //     let userName = `${user.name.first} ${user.name.last}`

  //     if (Object.keys(primusMethods).length > 0) {

  //       primusMethods.LiveParticipantRequest(event.items[0]._id, user._id, userName)
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }, [primusMethods]);







  const handleChange = (e, newValue) => {
    if (globalTabValue === 0) {
      getRegistrants(
        1,
        20,
        token,
        event.items[0]._id,
        event.items[0].managerId,
        true
      );
      clearMessages();
    }
    if (newValue === 1) {
      // getMessages(1, event.items[0]._id);
      getAllMessages(1, event.items[0]._id);
      clearRegistrants();
    }
    // setTabValue(newValue);
    dispatch(setGlobalTabValue(newValue))
  };


  useEffect(() => {

    if (globalTabValue === 1) {
      setLoading(false);
      getAllMessages(1, event.items[0]._id);
      clearRegistrants();
      setTabValue(globalTabValue)
    } else if (globalTabValue === 0) {

      getRegistrants(
        1,
        20,
        token,
        event.items[0]._id,
        event.items[0].managerId,
        true)
      setTabValue(globalTabValue)

    }
  }, [globalTabValue])

  //  @desc Close primus connection on unmount
  // useEffect(() => {
  //   console.log(PrimusWs)
  //   return function cleanup() {
  //     if (Object.keys(primusMethods).length > 0) {
  //       primusMethods.closeWsConnection();
  //     }
  //   };
  // }, [primusMethods]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClickLive = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleClick = () => {
    clearRegistrants();
    clearMessages();
    clearEvent();
    clearSearchedEvent();
    dispatch(resetWsLiveUsers())


  };


  // Participants on click 
  const handleParticipant = (userID) => {
    console.log('Participant on click', userID)

    if(userID === user._id) {
      console.log('taking controll', userID, user._id)

      setTakingControl('Taking control will enable you to manage the Q&A')
    }

    // on click -> if userID === Current UserID -> taking control
    try {
      socket_cp.emit('sendControlRequest', event.items[0]._id, userID,user._id )
      // primusMethods.LiveParticipantRequestControl(event.items[0]._id, userID, user._id)
      setAnchorEl(null);

    } catch (error) {
      console.log(error)
    }

  }

  // confirm participant take control 

  const handleClickParticipantDialog = () => {

    try {
      socket_cp.emit('acceptControlRequest', event.items[0]._id, user._id)
      // primusMethods.LiveParticipantAcceptedControl(event.items[0]._id, user._id)
      // dispatch(setActivePartipant(event.items[0]._id, user._id))
      dispatch(setDialogPopUpControl(false))
      dispatch(setDialogPopUpTakingControl(false))

    } catch (error) {

    }
  }


  useEffect(() => {
    console.log('mod highAccess', auth)

      if(liveUsersByEvent.length>0) {
   let active = liveUsersByEvent.find((eachUser)=>{
            return   eachUser.inControl === true

      })

      if(typeof(active) !== 'undefined' && typeof(active) !== undefined && active.userID === user._id) {
        setActiveModUserID(true)
      } 
      if(typeof(active) !== 'undefined' && typeof(active) !== undefined && active.userID !== user._id) {
        setActiveModUserID(false)
      } 
      typeof(active) !== 'undefined' && typeof(active) !== undefined && setQAControlledBy(active.name)

      // setQAControlledBy(active[0])
      typeof(active) !== 'undefined' && typeof(active) !== undefined && setQAControlledBy(active.name)
      // typeof(active) !== 'undefined' && typeof(active) !== undefined && setActiveModUserID(active.userID)

      typeof(active) !== 'undefined' && typeof(active) !== undefined && dispatch(setActiveParticipantName(active.name))

      console.log('Active mod qacontroll', active)
      }
  

  }, [liveUsers, activeParticipant, QAControlledBy, liveUsersByEvent])

  useEffect(()=>{
    console.log('liveUsersByEvent in event with highAccess', liveUsersByEvent)

  },[liveUsersByEvent])


  

  return (
    <>
      <>

        <Box
          sx={{ display: 'flex', flexDirection: 'column' }}
          id='eventDashboardContainer'
        >
          <div>
            {/* <Button variant="outlined" onClick={handleClickOpenDialog}>
        Slide in alert dialog
      </Button> */}
            <Dialog
              sx={{ zIndex: 2000 }}
              open={showDialopBoxControl}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDialog}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Another moderator has requested that you moderate this event. Accepting this request will enable you to manage the Q&A."}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Would you like to take control?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleClickParticipantDialog}>Accept</Button>
              </DialogActions>
            </Dialog>


            {/* Taking controll  */}

            <Dialog
              sx={{ zIndex: 2000 }}
              open={showDialopBoxTakingControl}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseDialog}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Taking control will enable you to manage the Q&A"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Are you sure you wish to take control?
                                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleClickParticipantDialog}>Accept</Button>
              </DialogActions>
            </Dialog>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>




            <BackToEventsListBtn handleClick={handleClick} />


      {

       !loading &&  <Box sx={{display:'flex',gap:'0.5rem'}}>

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickLive}
                endIcon={<KeyboardArrowDown />}
                variant='contained'
                sx={{
                  minWidth: 'max-content',
                  borderRadius:'10px',
                  padding: '0.8rem 1.5rem',
                  textTransform: 'capitalize',
                  alignSelf: 'center',
                  marginBottom: 'max(3rem, 4vh)',
                  backgroundColor: '#7CB332',
                  '&:hover': {
                    backgroundColor: '#aed581',
                    '& h6': {
                      color: 'var(--clr-w)',
                    },
                  },
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    color: 'var(--clr-w)',
                    transition: 'color 350ms ease',
                    lineHeight: '1',
                    fontSize: '0.9rem'
                  }}
                >

                  {
                     loading ? 'Loading' : QAControlledBy + ' is controlling the Q&A'
                  }

                  {/* {QAControlledBy ? QAControlledBy + ' is controlling the Q&A' : 'Loading'} */}
                </Typography>









              </Button>

         { 
         
         
        //  activeParticipant !== user._id && 
        !activeModUserID &&
          <Button
          id="basic-button"
          onClick={()=>handleParticipant(user._id)}
          endIcon={<TouchApp/>}
          variant='contained'
          sx={{
            minWidth: 'max-content',
            borderRadius:'10px',
            padding: '0.8rem 1.5rem',
            textTransform: 'capitalize',
            alignSelf: 'center',
            marginBottom: 'max(3rem, 4vh)',
            backgroundColor: '#7CB332',
            '&:hover': {
              backgroundColor: '#aed581',
              '& h6': {
                color: 'var(--clr-w)',
              },
            },
          }}
        >
          <Typography
            variant='h6'
            sx={{
              color: 'var(--clr-w)',
              transition: 'color 350ms ease',
              lineHeight: '1',
              fontSize: '0.9rem'
            }}
          >

          Take control
          </Typography>









        </Button>
     
         }
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {/* live users stored in redux state */}
                {
                  liveUsersByEvent.map((eachLiveUsers) => (

                    (eachLiveUsers.userID === user._id) && (eachLiveUsers.inControl === true) ?
                      <MenuItem disabled onClick={() => handleParticipant(eachLiveUsers.userID)}>
                        {
                          loading ? 'Loading' : (
                            <>
                              {eachLiveUsers.name} {(eachLiveUsers.inControl === true) && <MicIcon />}
                            </>
                          )
                        }


                      </MenuItem> : 
                      
                      <MenuItem onClick={() => handleParticipant(eachLiveUsers.userID)}>
                        {
                          loading ? 'Loading' : (
                            <>
                              {eachLiveUsers.name} {(eachLiveUsers.inControl === true) && <MicIcon />}
                            </>
                          )
                        }


                      </MenuItem>

                  )
                  )
                }



              </Menu>


              <Button
          id="basic-button"
          aria-controls={openOption_chat ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openOption_chat ? "true" : undefined}
          onClick={handleClickOption_chat}
          variant='contained'
          sx={{
            minWidth: 'max-content',
            borderRadius:'10px',
            padding: '0.8rem 1.5rem',
            textTransform: 'capitalize',
            alignSelf: 'center',
            marginBottom: 'max(3rem, 4vh)',
            // backgroundColor: 'rgba(249,90,49,0.9)',
            background:'rgba(92 ,133, 37,0.9)',
            '&:hover': {
              background: 'rgba(92, 137, 37,1)',
              

              '& h6': {
                color: 'var(--clr-w)',
              },
            },
          }}
        >
                <Badge badgeContent={
            chatNotificationCount >0 ? <span style={{color:'black', background:'yellow',height:20 ,width:20, borderRadius:'50%', textAlign:'center'}}>
                <p style={{marginTop:5}}>  
                {chatNotificationCount > 0 ? chatNotificationCount  : 0}
                </p>
                
                </span>
            : 0 
        } >

            <Chat/>
            </Badge>
        </Button>

        <Menu
              style={{paddingTop:0,m:0}}
              // id="basic-menu"
              anchorEl={anchorEl_chat}
              open={openOption_chat}
              onClose={handleCloseOption_chat}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                style:{
                  paddingTop:0
                }
              }}
              PaperProps={{
                style: {
                    transform: 'translateX(-5%) translateY(0.4rem)',
                    borderRadius: '5px',
                    overflowY:'scroll',
                    padding:0
                }
            }}
              
            >

      <ChatMessages socket={socket} socket_cp={socket_cp} close={handleCloseOption_chat}/>
            </Menu>


            </Box>
}
          </Box>


          <Box
            sx={{ width: '100%', backgroundColor: 'var(--clr-w)', boxShadow: 2 }}
          >
            <Box
              sx={{
                borderColor: 'divider',
                backgroundColor: 'var(--clr-w)',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label='tabs'
                sx={{
                  color: 'var(--clr-gray80)',
                  overflow: 'visible',
                  '& div:first-of-type': { overflow: 'visible !important' },
                }}
              >
                <Tab
                  label='Registrants'
                  {...a11yProps(0)}
                  sx={{
                    minWidth: '300px',
                    color: 'var(--clr-gray80)',
                    fontSize: '1.2rem',
                    textTransform: 'capitalize',
                  }}
                />
                { (event.items[0].webcast.feedbackEnabled  || isMessagePresent) && (
                  <Tab
                    label={
                      <>
                        <BRRBadge
                          badgeContent={wsNewMessagesRecived.length}
                          background={bgColorNewMessage}
                        />
                        {/* <BRRBadge
                    badgeContent={wsApprovedMessagesRecived.length}
                    background={bgColorApprovedMsg}
                    /> */}
                        <BRRBadge
                          badgeContent={
                            wsChangedMessageStatus.length ||
                            wsApprovedMessagesRecived.length
                          }
                          text='Q&A'
                          right={-40}
                          color={'#5A5300'}
                          background={'#FFEB3B'}
                        />
                      </>
                    }
                    {...a11yProps(1)}
                    sx={{
                      minWidth: '300px',
                      color: 'var(--clr-gray80)',
                      fontSize: '1.2rem',
                      textTransform: 'capitalize',
                      overflow: 'visible',
                    }}
                  />
                )}
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <EventRegistrants />
            </TabPanel>

            {/* { 

            (event.items[0].webcast.feedbackEnabled || showQna )  && (
              <TabPanel value={tabValue} index={1} style={{ position: 'relative' }}>
                <EventMessages  socket={socket} QAControlledBy={QAControlledBy}/>
              </TabPanel>
            )
            
            
            } */}



{
     (event.items[0].webcast.feedbackEnabled  || isMessagePresent) && (
    <TabPanel value={tabValue} index={1} style={{ position: 'relative' }}>
      <EventMessages socket={socket} QAControlledBy={QAControlledBy}/>
    </TabPanel>
  )
}



          </Box>
        </Box>
      </>
    </>
  );
};

EventDashboardHeighAccessLevel.propTypes = {
  getRegistrants: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
  getAllMessages: PropTypes.func.isRequired,
  clearRegistrants: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  clearEvent: PropTypes.func.isRequired,
  clearSearchedEvent: PropTypes.func.isRequired,
  wsNewMessagesRecived: PropTypes.array,
  wsApprovedMessagesRecived: PropTypes.array,
  wsChangedMessageStatus: PropTypes.array,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event.event,
  token: state.auth.token,
  wsNewMessagesRecived: state.websocket.wsNewMessagesRecived,
  wsApprovedMessagesRecived: state.websocket.wsApprovedMessagesRecived,
  wsChangedMessageStatus: state.websocket.wsChangedMessageStatus,
  wsConnection: state.websocket.wsConnection,
  wsLiveUsers: state.websocket.wsLiveUsers,
  globalTabValue: state.event.globalTabValue

});

export default connect(mapStateToProps, {
  getRegistrants,
  getMessages,
  getAllMessages,
  clearRegistrants,
  clearMessages,
  clearEvent,
  clearSearchedEvent
})(EventDashboardHeighAccessLevel);
