import React from 'react';
import { Link } from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const BackToEventsListBtn = ({ handleClick }) => {
  return (
    <Button
      component={Link}
      to='/event-dashboard'
      onClick={handleClick}
      variant='contained'
      sx={{
        minWidth: 'max-content',
        width: '360px',
        padding: '1rem 1.5rem',
        borderRadius: '80px',
        textTransform: 'capitalize',
        alignSelf: 'center',
        marginBottom: 'max(3rem, 4vh)',
        backgroundColor: 'var(--clr-w)',
        '&:hover': {
          backgroundColor: 'var(--clr-info)',
          '& h6': {
            color: 'var(--clr-w)',
          },
        },
      }}
    >
      <Typography
        variant='h6'
        sx={{
          color: 'var(--clr-gray80)',
          transition: 'color 350ms ease',
          lineHeight: '1',
          fontSize:'1rem'
        }}
      >
        Back to events list
      </Typography>
    </Button>
  );
};

export default BackToEventsListBtn;
