export const POST_NEW_SPLIT_MESSAGE = 'POST_NEW_SPLIT_MESSAGE';
export const SUCCESS_SPLIT_MESSAGE = 'SUCCESS_SPLIT_MESSAGE';
export const ERROR_SPLIT_MESSAGE = 'ERROR_SPLIT_MESSAGE';

export const ADD_QUESTION = 'ADD_QUESTION';
export const ADD_LOGS = 'ADD_LOGS';


export const LOADING_SPLIT_QUESTION ='LOADING_SPLIT_QUESTION'


