import {
  SET_ALERT,
  REMOVE_ALERT,
  REMOVE_ALL_ALERT,
} from '../constants/utilsConstants';

const initialState = [];

function alertReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    case REMOVE_ALL_ALERT:
      return [];
    default:
      return state;
  }
}

export default alertReducer;
