const tableColumnSectionWithSplit = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      minWidth: 'max-content',
      width: '16%',
    },
    {
      id: 'company',
      label: 'Company',
      align: 'left',
      minWidth: 'max-content',
      width: '10%',
    },
    {
      id: 'message',
      label: 'Message',
      align: 'left',
      width: 'max-content',
      minWidth: '300px',
    },
    { id: 'option', label: 'Option', align: 'center', width: '200px' },

    { id: 'date', label: 'Date', align: 'center', width: '200px' },
    { id: 'status', label: 'Status', align: 'center', width: '160px' },
  ];
  
  export default tableColumnSectionWithSplit;
  