import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

// STATIC
import qaIcon from '../../static/icons/qaIcon.svg';

const NotFoundData = ({ message }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
      }}
    >
      <Typography
        variant='h5'
        sx={{
          color: 'var(--clr-danger)',
          fontWeight: 'bold',
        }}
      >
        {message}
      </Typography>
      <CardMedia
        component='img'
        height='200'
        src={qaIcon}
        alt='q&a'
        sx={{
          width: '200px',
          marginTop: '3rem',
          filter: 'opacity(50%)',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default NotFoundData;
