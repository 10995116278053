export const WS_APPENDED_TO_DOM = 'WS_APPENDED_TO_DOM';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WS_RESET_CONNECTION = 'WS_RESET_CONNECTION'

export const WS_NEW_MESSAGE_RECIVED = 'WS_NEW_MESSAGE_RECIVED';
export const WS_RESET_MESSAGE_ARRAY = 'WS_RESET_MESSAGE_ARRAY';

export const WS_APPROVED_MESSAGE_RECIVED = 'WS_APPROVED_MESSAGE_RECIVED';
export const WS_ADD_APPROVED_MESSAGE = 'WS_ADD_APPROVED_MESSAGE';
export const WS_CHANGED_MESSAGE_STATUS = 'WS_CHANGED_MESSAGE_STATUS';

export const WS_ORDER_LIST_RECIVED = 'WS_ORDER_LIST_RECIVED';
export const WS_RESET_ORDER_LIST = 'WS_RESET_ORDER_LIST';


//Live users

export const WS_LIVE_USERS = 'WS_LIVE_USERS'
export const RESET_WS_LIVE_USERS = 'RESET_WS_LIVE_USERS'
export const SET_ACTIVE_MOD = 'SET_ACTIVE_MOD' 


// Dialog pop 
export const SET_DIALOG_BOX_CONTROL = 'SET_DIALOG_BOX_CONTROL' 


//taking controll
export const SET_DIALOG_BOX_TAKING_CONTROL = 'SET_DIALOG_BOX_TAKING_CONTROL' 

export const WS_LIVE_USERS_BY_EVENT = 'WS_LIVE_USERS_BY_EVENT' 




