import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { getMessageOrder, getAllMessages, resetLoading } from '../../../actions/messages';
import {
  wsConnected,
  wsOrderListRecived,
  wsResetMessageArray,
  wsResetOrderList,
} from '../../../actions/websocket';

// UTILS
import { hourMinuteDate } from '../../../utils/convertDateAndTime';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { yellow } from '@mui/material/colors';

// COMPONENTS
import Spinner from '../../UI/Spinner';
import TooltipAlert from '../../UI/TooltipAlert';
import QuestionsTable from './QuestionsTable';
import NotFoundData from '../NotFoundData';

import '../utilsEvent.css';
import { AppBar, Divider, Grid, IconButton, Toolbar } from '@mui/material';
import { getEvent } from '../../../actions/event';
import PrimusWs from '../../websocket/PrimusWs';
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import PrimusWsPassive from '../../websocket/PrimusWsPassive.js';

import { setPassivePresenterDetails } from '../../../actions/auth';
import { AccountCircle } from '@mui/icons-material';
// import PrimusWsPassive from '../../websocket/PrimusWsPassive.js';
// PrimusWsPassive
import io from 'socket.io-client';
import { addNotification } from '../../../actions/notification.js';
import { socketioDissconnected, socketioError } from '../../../actions/socketio.js';
import submitLogs from '../../../config/AWSGlobalLog.js';

const tooltipStatusChanged =
  'Message list has been updated! Click reload to update';

const PresenterView = ({
  event,
  loading,
  allMessage: { messages, lastUpdate },
  presenterMessageList,
  getMessageOrder,
  userAccessToken,
  auth: { user },
  getAllMessages,
  messageOrderNotFound,
  lastFetch,
  isWsOrderListRecived,
  saveListCounter,
  wsResetMessageArray,
  wsResetOrderList,
  getEvent,
  addNotification
}) => {
  // @desc -  Tooltip
  //          State start
  let { token } = useParams();

  const bgColorNewStatusMessage = yellow[500];
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipBgColor, setTooltipBgColor] = useState('#FFEB3B');
  const [tooltipTextColor, setTooltipTextColor] = useState('#fff');
  const [openTooltip, setOpenTooltip] = useState(0);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  //          State end

  const [localMessages, setLocalMessages] = useState([]);
  const [localMessages1, setLocalMessages1] = useState([]);

  const [oldUpdate, setOldUpdate] = useState(null);

  const [eventFetched, setEventFetched] = useState(false)

  //token
  const [decodedToken, setDecodedToken] = useState('')
  const [tokenVerified, setTokenVerified] = useState(false)


  const [localLoading, setLocalLoading] = useState(true)
  const [socketWc, setSocketWc] = useState(null);


  const didMount = useRef(true);

  const dispatch = useDispatch();




  useEffect(() => {

    console.log('REACT_RERENDER  socket_cpPassive loaded',socketWc)

    const socket_cp = io(`${process.env.REACT_APP_SOCKET_IO_URL}/clientPortal`)
    setSocketWc(socket_cp)

    return () => {
    
      };
  }, []);


  useEffect(() => {
    if (socketWc && tokenVerified && eventFetched) {
      let userAccessLevel = 1
      console.log('socket_cpPassive eventFetched', socketWc)

      socketWc.on('connect', () => {
        socketWc.emit('joinEventPresenter', decodedToken.eventID, decodedToken.accountDetails._id)

        addNotification({
          title: `Connected`,
          time: new Date().toLocaleTimeString(),
          read: false
        })
        submitLogs(
          JSON.stringify({
            status: 'Presenter View User connected',
            message: 'Presenter View User connected'
          }))

      })



      //Message status -> Dont populate live load when status is allowed
      socketWc.on('messageStatus', (data) => {

        if (data.status !== 'allowed') {
          console.log('statusstatusstatusstatus', data)
          getAllMessages(1, decodedToken.eventID, 6, true)
          getAllMessages(1, decodedToken.eventID, 6, true)

        }

      })



      //Signal for presenter to load the list (New list dispatched)
      socketWc.on('messageOrderForPresenter', (data) => {
        if (userAccessLevel === 1) {
          console.log('For presenter messageOrderForPresenter', data)
          wsOrderListRecived(true)
          setTooltipBgColor(bgColorNewStatusMessage);
          setTooltipTextColor('#5A5300');
          setTooltipMessage(tooltipStatusChanged);
          setTooltipIndex(1);
          setOpenTooltip(1);
          // return dispatch(setAlert("List has been updated", "success", 3000));
        }

      })

      //Notes -> New note
      socketWc.on('notesAdded', (data) => {
        console.log('notesAdded', data);
        getAllMessages(1, decodedToken.eventID, 6, true)
        getMessageOrder(decodedToken.eventID)
        getAllMessages(1, decodedToken.eventID, 6, true)

        console.log('Note Added primusDebug', data);
        //  (setAlert("Notes has been added", "success", 3000));

        (addNotification({
          title: `Notes has been added`,
          time: new Date().toLocaleTimeString(),
          read: false
        }))
      })

      //Notes -> New note
      socketWc.on('notesEdited', (data) => {
        console.log('notesEdited', data);
        getAllMessages(1, decodedToken.eventID, 6, true)
        getMessageOrder(decodedToken.eventID)
        getAllMessages(1, decodedToken.eventID, 6, true)

        //  (setAlert("Notes has been edited", "success", 3000));
        addNotification({
          title: `Notes has been edited`,
          time: new Date().toLocaleTimeString(),
          read: false
        })

      })

      socketWc.on("disconnect", () => {
        dispatch(socketioDissconnected())
        dispatch(socketioError('Disconnected'))
        console.log("851 Recoverd disconnect")
      });

      socketWc.on('connect_error', (error) => {
        console.error('Socket.io connection error:', error);
        submitLogs(
          JSON.stringify({
            status: 'ERROR - connect_error',
            message: 'Prsenter View SOCKET.IO connect_error',
            error: error
          }))

        dispatch(socketioDissconnected())
        dispatch(socketioError(error))

      });


    }
    return () => {
      socketWc && socketWc.off('disconnect')
      socketWc && socketWc.off('connect_error')


      socketWc && socketWc.off('messageStatus')
      socketWc && socketWc.off('messageOrderForPresenter')
      socketWc && socketWc.off('notesAdded')
      socketWc && socketWc.off('notesEdited')

    }
  }, [socketWc, tokenVerified, eventFetched])




  const verifyToken = () => {
    try {
      let decodeToken = jwt_decode(token);
      let currentDate = new Date()
      // currentDate.setDate(currentDate.getDate()+10);
      console.log('currentDate', currentDate)
      console.log('start', new Date(decodeToken.startDate))
      console.log('end', new Date(decodeToken.endDate))

      if(currentDate >= new Date(decodeToken.endDate)) {
        setTokenVerified(false)

      }

      if(currentDate >= new Date(decodeToken.startDate) && currentDate <= new Date(decodeToken.endDate)) {
        decodeToken.isVerified && setTokenVerified(true)

      }

      setDecodedToken(decodeToken)
      console.log('Whats in token', decodeToken)
    } catch (error) {
      setTokenVerified(false)
      console.log('Error', error)
    }


  }



  const pollEvents = async () => {

    setTimeout(() => {

      getAllMessages(1, decodedToken.eventID, 1);
      
    }, 5000);

  }


  useEffect(() => {
    verifyToken()
    console.log('Params', decodedToken.isVerified)
    // decodedToken.isVerified && setTokenVerified(true)
  }, [])


  useEffect(() => {
    if (tokenVerified) {
      console.log('is verified', decodedToken)
      getEvent(decodedToken.eventID);
      dispatch(wsConnected(decodedToken.eventID))
      wsResetMessageArray();
      // eslint-disable-next-line

      dispatch(resetLoading())
      setOpenTooltip(0);
      setTooltipIndex(null);
      wsResetMessageArray();
      wsResetOrderList();
      setEventFetched(true)

      dispatch(setPassivePresenterDetails({ userName: decodedToken.accountDetails.firstName, _id: decodedToken.accountDetails._id }))
    }
    // pollEvents()
  }, [tokenVerified, decodedToken]);



  useEffect(() => {
    if (eventFetched) {
      pollEvents()
   
      // getAllMessages(1, decodedToken.eventID, 1);
    }
  }, [eventFetched])

  useEffect(() => {
    if (messages) {
      if (messages.items.length > 1 && saveListCounter === 0) {
        getMessageOrder(decodedToken.eventID, true);
      }

      if (oldUpdate === null || oldUpdate < lastUpdate) {
        setOldUpdate(lastUpdate);
        console.log('Here')
        getMessageOrder(decodedToken.eventID, true);
      }
    }

    // eslint-disable-next-line
  }, [messages, lastUpdate]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && presenterMessageList.success) {
      const allowedMsgArr = messages.items.filter(
        (el) => el.status === 'replied'
      );
      console.log('All message', messages)
      setLocalMessages1(allowedMsgArr)
      setLocalMessages(presenterMessageList.messages);
      setLocalLoading(false)
    }


    return () => {
      isMounted = false;
    };
  }, [presenterMessageList]);

  // @desc Tooltip Message
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }
    if (isWsOrderListRecived) {
      setTooltipBgColor(bgColorNewStatusMessage);
      setTooltipTextColor('#5A5300');
      setTooltipMessage(tooltipStatusChanged);
      setTooltipIndex(1);
      setOpenTooltip(1);
    }
    // eslint-disable-next-line
  }, [isWsOrderListRecived]);



  const handleReload = () => {
    getAllMessages(1, decodedToken.eventID, 1);
    setOpenTooltip(0);
    setTooltipIndex(null);
    wsResetMessageArray();
    wsResetOrderList();
  };



  // const handleReload = () => {
  //   getAllMessages(1, decodedToken.eventID, 1);
  //   setOldUpdate(lastUpdate);
  //   getMessageOrder(decodedToken.eventID, true);
  //   setOpenTooltip(0);
  //   setTooltipIndex(null);
  //   wsResetMessageArray();
  //   wsResetOrderList();
  // };

  return (




    tokenVerified && eventFetched ? (
    <>
    
      <PrimusWsPassive>

        <AppBar

          position="sticky"
          elevation={4}
          style={{
            // position: "fixed",
            top: 0,
            left: 0,
            margin: 0,
            background: "#2d3436",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            size='large'
            sx={{ color: 'inherit' }}
          >
            <AccountCircle />
          </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {decodedToken.eventTitle}  <span>(Presenter View)</span>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: { md: '1.5rem 3.75rem', sm: '1.5rem' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body2' color={'var(--clr-gray60)'}>
                  {messageOrderNotFound ? (
                    <>Last Update {hourMinuteDate(lastFetch)}</>
                  ) : (
                    <>Last Update {hourMinuteDate(lastUpdate ? lastUpdate : lastFetch)}</>
                  )}
                </Typography>

                <TooltipAlert
                  title={tooltipMessage}
                  placement='left'
                  open={openTooltip === tooltipIndex}
                  leaveDelay={500}
                  enterNextDelay={2000}
                  sx={{
                    zIndex: 9999,
                    '& .MuiTooltip-tooltip': {
                      color: `${tooltipTextColor}`,
                      backgroundColor: `${tooltipBgColor}`,
                    },
                    '& .MuiTooltip-arrow': {
                      color: `${tooltipBgColor}`,
                    },
                  }}
                >
                  <LoadingButton
                    disabled={openTooltip === 0 && messageOrderNotFound}
                    onClick={handleReload}
                    loading={loading}
                    endIcon={<AutorenewIcon />}
                    loadingPosition='end'
                    variant='contained'
                    sx={{
                      marginLeft: '1rem',
                      backgroundColor: 'var(--clr-info)',
                      '&:hover': {
                        backgroundColor: 'var(--clr-info60)',
                      },
                    }}
                  >
                    Reload
                  </LoadingButton>
                </TooltipAlert>
              </Box>
            </Box>

          </Toolbar>
        </AppBar>


      <Grid container display={'flex'} justifyContent={'center'}>

<Grid item xs={10} md={10} lg={10}>


    

        { (loading  || localLoading  ) ? (
          <Box sx={{ display: 'flex', flexDirection:'column',alignItems: 'center', marginTop:'20%' }}>

            <Spinner />
            <Typography>
            Please wait while we load your content... 
            </Typography>
          </Box>
        ) : (
                
          <>
            {

              localMessages.length > 0 && (
                <Box className='fade-in-box'>
                  <QuestionsTable
                    userAccessToken={decodedToken.accountDetails.access_token}
                    userID={decodedToken.accountDetails._id}
                    eventID={decodedToken.eventID}
                    messages={localMessages}
                    userLevel={1}
                    status='approved'
                    socketWc={socketWc}
                    presenterView={true}
                    setOpenTooltip={setOpenTooltip}
                    setTooltipIndex={setTooltipIndex}
                  />
                  <Divider />

                </Box>
              )
            }

            {

              localMessages1.length > 0 && (
                <Box className='fade-in-box'>
                  <QuestionsTable
                    status='replied'
                    userAccessToken={decodedToken.accountDetails.access_token}
                    userID={decodedToken.accountDetails._id}
                    eventID={decodedToken.eventID}
                    messages={localMessages1}
                    userLevel={1}
                    presenterView={true}
                    socketWc={socketWc}

                  />
                  <Divider />

                </Box>
              )
            }

{   localMessages.length < 1 && localMessages1.length < 1 && (
        <NotFoundData message={'Could not find any messages'} />
      )}



          </>




        )
        }
</Grid>

</Grid>


      </PrimusWsPassive>



    </>

    ) : (
            <Box>
              Not verified
            </Box>
    )




  );
};

PresenterView.propTypes = {
  event: PropTypes.object,
  loading: PropTypes.bool,
  allMessage: PropTypes.object,
  presenterMessageList: PropTypes.object.isRequired,
  auth: PropTypes.object,
  getMessageOrder: PropTypes.func.isRequired,
  getAllMessages: PropTypes.func.isRequired,
  messageOrderNotFound: PropTypes.bool.isRequired,
  lastFetch: PropTypes.any,
  saveListCounter: PropTypes.number,
  wsResetMessageArray: PropTypes.func.isRequired,
  wsResetOrderList: PropTypes.func.isRequired,
  wsOrderListRecived:PropTypes.func.isRequired,
  isWsOrderListRecived: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event.event,
  loading: state.messages.loading,
  allMessage: state.messages.allMessage,
  presenterMessageList: state.messages.presenterMessageList,
  messageOrderNotFound: state.messages.messageOrderNotFound,
  userAccessToken: state.auth.token,
  auth: state.auth,
  lastFetch: state.messages.lastUpdate,
  isWsOrderListRecived: state.websocket.isWsOrderListRecived,
  saveListCounter: state.messages.saveListCounter,
});

export default connect(mapStateToProps, {
  getMessageOrder,
  getAllMessages,
  wsResetMessageArray,
  wsResetOrderList,
  wsOrderListRecived,
  getEvent,
  addNotification
})(PresenterView);
