export function formatEventStatus(str) {
  let formattedText = '';

  if (!str.includes('_')) return str;
  else formattedText = str.split('_').join(' ');

  return formattedText;
}

export function colorEventStatus(str) {
  let color = '';

  if (str !== 'enabled' || str !== 'disbled') color = 'primary';
  if (str === 'enabled') color = 'success';
  if (str === 'disabled') color = 'error';

  return color;
}
