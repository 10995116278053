import { SET_LOADING } from '../constants/utilsConstants';
import {
  GET_REGISTRANTS,
  GET_ALL_REGISTRANTS,
  REGISTRANTS_FAIL,
  CLEAR_REGISTRANTS,
} from '../constants/eventRegistrantsConstants';
import { ADD_QUESTION, ERROR_SPLIT_MESSAGE, SUCCESS_SPLIT_MESSAGE } from '../constants/splitMessageConstant';

const initialState = {
   splitQuestion:'',
  loading: false,
  error: {},
};

function splitMessage(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUCCESS_SPLIT_MESSAGE:
      return {
        ...state,
        loading: false,
        error:{}
      };

      case ADD_QUESTION:
        return {
          ...state,
          loading: false,
          error:{}
        };


    case ERROR_SPLIT_MESSAGE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
}

export default splitMessage;
