import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMobileOrientation } from 'react-device-detect';
import PropTypes from 'prop-types';

// REDUX
import { setAlert } from '../../actions/alert';
import { clearPasswordReset, login } from '../../actions/auth';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';

// COMPONENTS
import AuthFooter from './AuthFooter';
import AuthHeader from './AuthHeader';
import Description from './Description';
import SelectGroupModal from './SelectGroupModal';

import './Auth.css';
import './utilsAuth.css';
import ResetPasswordModal from './ResetPasswordModal';

const Login = ({ setAlert, login, isAuthenticated, openSelectGroupModal }) => {
  const dispatch = useDispatch()
  const { isPortrait } = useMobileOrientation();
  const [openModal, setOpenModal] = useState(false);
  const [showPsw, setShowPsw] = useState();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  useEffect(() => {
    if (openSelectGroupModal) setOpenModal(true);
  }, [openSelectGroupModal]);

  // console.log('openSelectGroupModal 1', openSelectGroupModal);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(clearPasswordReset())
    
  }

  // const handleCloseModal = (event, reason) => {
  //   // if (reason === 'backdropClick') return
  //   setOpenModal(false);
  // };

  // REDIRECT IF LOGGED IN
  if (isAuthenticated) {
    return <Navigate to='/event-dashboard' />;
  }

  return (
    <>
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <AuthHeader />

        <Box className='login-container'>
          {isPortrait && <Description />}
          <Stack
            spacing={7}
            className={isPortrait ? 'login__form-container' : undefined}
            sx={{ maxWidth: '460px', paddingTop: '3rem' }}
          >
            <Typography variant='h5' component='h5' sx={{ fontWeight: '700' }}>
              Login
            </Typography>
            <ValidatorForm
              className={isPortrait ? 'login__form-group' : undefined}
              onSubmit={handleSubmit}
              onError={(errors) => console.log(errors)}
            >
              <Stack spacing={7}>
                <TextValidator
                  placeholder='Email'
                  name='email'
                  value={email}
                  onChange={(e) => handleChange(e)}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'this field is required',
                    'email is not valid',
                  ]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <MailOutlineOutlinedIcon
                          sx={{ color: 'var(--clr-gray40)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  className='input-auth'
                />
                <TextValidator
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={(e) => handleChange(e)}
                  type={showPsw ? 'text' : 'password'}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LockIcon sx={{ color: 'var(--clr-gray40)' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPsw ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  className='input-auth'
                />
                
                <Button
                  type='submit'
                  variant='contained'
                  sx={[
                    {
                      textTransform: 'capitalize',
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      bgcolor: 'var(--clr-btn-cta)',
                      '&:hover': {
                        bgcolor: 'var(--clr-btn-cta-hover)',
                      },
                    },
                  ]}
                >
                  Login
                </Button>


              

              </Stack>
            </ValidatorForm>
            
          </Stack>
          <Typography sx={{mt:'1rem',color:'#3498db'}}>
                   
                  <Link to='' onClick={handleOpen}>
                  Forgot password?
                  </Link>  


                  <ResetPasswordModal open={open} handleClose={handleClose}/>



                  
                  </Typography>
        </Box>

        <AuthFooter />
      </Box>

      {openModal && <SelectGroupModal open={openModal} />}
    </>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  openSelectGroupModal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  openSelectGroupModal: state.auth.openSelectGroupModal,
});

export default connect(mapStateToProps, { setAlert, login })(Login);
