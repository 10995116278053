import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { setChatNotificationCount, setChats } from "../../actions/chat";
import { resetSocketioError, socketioConnected, socketioDissconnected, socketioError, socketio_cp_connected, socketio_cp_dissconnected } from "../../actions/socketio";
import submitLogs from "../../config/AWSGlobalLog";
import { setActivePartipant } from "../../actions/auth";
import { setDialogPopUpControl, setDialogPopUpTakingControl, wsAddNewMessage, wsLiveUsersByEvent, wsOrderListRecived } from "../../actions/websocket";
import { getAllMessages, getMessageOrder } from "../../actions/messages";
import { addNotification } from "../../actions/notification";
import { setAlert } from "../../actions/alert";

const SocketContainer = createContext(null);

export { SocketContainer };

export default ({ children, socket_cp }) => {
  // BUILD PRIMUS QUERY STRING ---- START
  const event = useSelector((state) => state.event.event);
  const userId = useSelector((state) => state.auth.loggedInUserID);
  const chatMenu = useSelector((state) => state.socketio.chatMenu);
  const issocketioConnected = useSelector((state) => state.socketio.isSocketioConnected);
  const userFirstName = useSelector((state) => state.auth.user.name.first);
  const userLastName = useSelector((state) => state.auth.user.name.last);
  //Count for websocket
  const userAccessLevel = useSelector((state) => state.auth.user.level);


  const activeParticipant = useSelector(
    (state) => state.auth.activeParticipant
  );


  let eventId;
  if (event !== null) {
    eventId = event.items[0]._id;
  }

  // useEffect(()=>{
  //   document.addEventListener('visibilitychange', () => {
  //     if (document.visibilityState === 'visible') {
  //       console.log('851 Screen turned on, reconnecting to server...', issocketioConnected);
  //     }
  //   });
  // },[])



const dispatch = useDispatch();





// useEffect(()=>{
//   console.log('26april event and userID',eventId, userId)
//   // if(eventId && userId) {
//       socket_cp.on('connect', () => {
//     dispatch(resetSocketioError())
  
//   socket_cp.emit('joinEvent', eventId, userId)


//     if (socket_cp.recovered) {
//     // socket_cp.emit('joinEvent', eventId, userId)
//   }

//   console.log("851 WHO IS CONNECTED",userId)
//   submitLogs(
//     JSON.stringify({
//       status: 'User connected',
//       user: {
//         userID: userId,
//         name: userFirstName + ' ' + userLastName
//       },
//       message: 'User connected'
//     }))
//   console.log('connected')
// });
//   // }



// },[])



  useEffect(() => {
    console.log('26april issocketioConnected', issocketioConnected)
  }, [issocketioConnected])


  useEffect(() => {
    
    
    if (socket_cp) {
      





      dispatch(socketioConnected())


      socket_cp.on("disconnect", () => {
        dispatch(socketioDissconnected())
        dispatch(socketioError('Disconnected'))
        console.log("851 Recoverd disconnect")
      });

      socket_cp.on('connect_error', (error) => {
        console.error('Socket.io connection error:', error);

        submitLogs(
          JSON.stringify({
            status: 'ERROR - connect_error',
            user: {
              userID: userId,
              name: userFirstName + ' ' + userLastName
            },
            message: 'SOCKET.IO connect_error',
            error: error
          }))

        dispatch(socketioDissconnected())
        dispatch(socketioError(error))

      });


      socket_cp.on("disconnect", () => {
        console.log('Socket.io connection error Reason ---->>>>>>>:', '');
        submitLogs(
          JSON.stringify({
            status: 'ERROR - disconnect',
            user: {
              userID: userId,
              name: userFirstName + ' ' + userLastName
            },
            message: 'SOCKET.IO disconnect',
            error: ''
          }))

        dispatch(socketioDissconnected())
        dispatch(socketioError('SOCKET.IO disconnect'))


      });

    }
    return () => {
      // socket_cp.emit('leaveRoom', eventId, userId);
      socket_cp.emit('leaveEvent', eventId, userId);

      dispatch(socketioDissconnected())

      try {
        // socket.disconnect();
        console.log("Disconneting from socket.io");

      } catch (error) {
        dispatch(socketioError(error))

        submitLogs(
          JSON.stringify({
            status: 'ERROR - disconnect-leaveRoom',
            user: {
              userID: userId,
              name: userFirstName + ' ' + userLastName
            },
            message: 'SOCKET.IO disconnect-leaveRoom',
            error: error
          }))

        console.error('Error disconnecting socket:', error);
      }
    }

  }, []);


  useEffect(() => {
    // if(issocketioConnected) {
    dispatch(socketio_cp_connected(socket_cp))
    dispatch(socketioConnected())

    try {
      socket_cp.on('newMessageWebcast', (data) => {
        //Check userID
        if (data && data.from !== userId) {
          dispatch(wsAddNewMessage(data));
        }
        console.log("Mesaage from webcasting to client portal", data)
      })

      socket_cp.on('messageEdited', (data) => {
        dispatch(getAllMessages(1, eventId, 6, true))
        dispatch(getMessageOrder(eventId))
        console.log('message edited primusDebug', data);
        //  dispatch(setAlert("Message has been edited", "success", 3000));

        dispatch(addNotification({
          title: `Message has been edited`,
          time: new Date().toLocaleTimeString(),
          read: false
        }))
      })

      //Notes -> New note

      socket_cp.on('notesAdded', (data) => {
        console.log('notesAdded', data);
        dispatch(getAllMessages(1, eventId, 6, true))
        dispatch(getAllMessages(1, eventId, 6, true))

        // dispatch(getMessageOrder(eventId))
        console.log('Note Added primusDebug', data);
        //  dispatch(setAlert("Notes has been added", "success", 3000));

        dispatch(addNotification({
          title: `Notes has been added`,
          time: new Date().toLocaleTimeString(),
          read: false
        }))
      })

      //Notes -> New note

      socket_cp.on('notesEdited', (data) => {
        console.log('notesEdited', data);
        dispatch(getAllMessages(1, eventId, 6, true))
        dispatch(getAllMessages(1, eventId, 6, true))

        // dispatch(getMessageOrder(eventId))
        //  dispatch(setAlert("Notes has been edited", "success", 3000));
        dispatch(addNotification({
          title: `Notes has been edited`,
          time: new Date().toLocaleTimeString(),
          read: false
        }))

      })



      socket_cp.on('message', (data) => {
        console.log('From CP Channel chat message', data)
        dispatch(setChats(data))

        if (data.sender !== userId) {
          if (!chatMenu) {
            console.log('sender?', data.sender, 'chatMenu', chatMenu)
            dispatch(setChatNotificationCount())
          }
        }

        // setChats(prev => ([...prev , data]))
        console.log('From server', data)
      })


      socket_cp.on('userLeft', (data) => {
        if (data.userID === activeParticipant) {
          if (data.liveUsers.length > 1) {
            console.log('userLeft_now_2', data.liveUsers)
            // dispatch(setActivePartipant(eventId, data.liveUsers[0].userID ))
          }
        }
        console.log('CP user left', data)

      })

      socket_cp.on('liveUsersUpdateByEvent', (data) => {
        //if only 1 user 
        if (data.length === 1) {
          // dispatch(setActivePartipant(eventId, data[0].userID))
        }
        //Active mod left
        console.log('liveUsersByEvent CP live users', data)

        const whoInControl = data.find((user) => user.inControl)
        if (whoInControl) {
          dispatch(setActivePartipant(eventId, whoInControl.userID))
        }

        console.log('whoInControl', whoInControl)
        dispatch(wsLiveUsersByEvent(data))

      })


      socket_cp.on('sendControlRequest', (data) => {
        if (data.userId === userId) {

          if (data.userId === data.from) {
            console.log('taking control??', data.userId, userId)
            dispatch(setDialogPopUpTakingControl(true))

          }
          else {
            dispatch(setDialogPopUpControl(true));

          }
        }
        console.log('live request change control', data)
      })



      socket_cp.on('acceptedControlRequest', (data) => {
        // make active 
        // dispatch(setActivePartipant(eventId,data.userId))
        console.log('Cp socket ------ Accepted the control', data)
      })


      socket_cp.on('messageStatus', (data) => {
        console.log('Message Status ping pong -->> ', data)

        if (userAccessLevel > 1 && data.userID !== userId) {  

            //check if status is approved then only get message oreder
            if(data.status === 'allowed') {
              dispatch(getAllMessages(1, eventId, 6, true))
              dispatch(getMessageOrder(eventId))
            } else {
              dispatch(getAllMessages(1, eventId, 6, true))
            }
          // dispatch(getMessageOrder(eventId)) 
        } else if (userAccessLevel === 1) {
          // Presenter 
          // Get messages and order (Replied || Archived || Answered)
          dispatch(getAllMessages(1, eventId, 6, true))

        }
        //update order and get all messages
      })

      socket_cp.on('messageOrder', (data) => {

        if (userAccessLevel > 1 && data.userID !== userId) {
          console.log('MessageOrder runrunrunrunrun -->> ', data)
          dispatch(getAllMessages(1, eventId, 6, true))
          dispatch(getMessageOrder(eventId))

          dispatch(addNotification({
            title: `Message Order updated`,
            time: new Date().toLocaleTimeString(),
            read: false
          }))
          
          console.log('MessageOrder -->> ', data)
        }
      })


      socket_cp.on('messageOrderForPresenter', (data) => {
        if (userAccessLevel === 1) {
          console.log('For presenter', data)
          dispatch(wsOrderListRecived(true))
          // return dispatch(setAlert("List has been updated", "success", 3000));
        }

      })




      // socket.on('liveUsersUpdate', (data)=>{
      //   console.log('Live user updates', data)
      // })
    } catch (error) {

      submitLogs(
        JSON.stringify({
          status: 'ERROR',
          user: {
            userID: userId,
            name: userFirstName + ' ' + userLastName
          },
          message: 'SOCKET.IO',
          error: error
        }))

      dispatch(socketioError(error))
      console.error('Socket.io connection error:', error);

    }

    // } 

    return () => {
      // socket.off('message')
      socket_cp.off('newMessageWebcast')
      //Notes
      socket_cp.off('notesAdded')
      socket_cp.off('notesEdited')

      //Message Order
      socket_cp.off('messageOrder')

      //Message
      socket_cp.off('message')

      socket_cp.off('messageEdited')

      //Live users
      socket_cp.off('liveUsersUpdate')

      //User left
      socket_cp.off('userLeft')

      //Live users by event
      socket_cp.off('liveUsersUpdateByEvent')
      socket_cp.off('sendControlRequest')
      socket_cp.off('acceptedControlRequest')

      dispatch(wsLiveUsersByEvent([]))
      dispatch(socketio_cp_dissconnected())
      dispatch(resetSocketioError())



      // socket.disconnect()

    }
  }, [issocketioConnected])




  return (
    <SocketContainer.Provider>
      {children}
    </SocketContainer.Provider>
  );
};
