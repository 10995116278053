import React, { useState, useEffect, useRef } from 'react';

// UTILS
import { hourMinuteDate } from '../../../utils/convertDateAndTime';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { lightGreen, yellow } from '@mui/material/colors';

// COMPONENTS
import TooltipAlert from '../TooltipAlert';

const tooltipNewMessage =
  'You have a new message! Click reload to update the list';

const tooltipStatusChanged =
  'The status of a message is changed! Click reload to update the list';

const ReloadBtn = ({
  handleReload,
  loading,
  lastUpdate,
  userAccessLevel,
  newMessage,
  newMessageStatus: { wsChangedMessageStatus, wsApprovedMessagesRecived },
}) => {
  const [openTooltip, setOpenTooltip] = useState(0);
  const [tooltipBgColor, setTooltipBgColor] = useState('#97d411');
  // const [tooltipTextColor, setTooltipTextColor] = useState('#fff');
  const [tooltipMessage, setTooltipMessage] = useState(tooltipNewMessage);
  const [tooltipIndex, setTooltipIndex] = useState(null);

  const bgColorNewMessage = lightGreen[500];
  const bgColorNewStatusMessage = yellow[500];

  const didMount = useRef(true);
  const didMountTwo = useRef(true);

  //  @desc Tooltip New Message
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }
    if (newMessage.length > 0 && userAccessLevel !== 1) {
      setTooltipBgColor(bgColorNewMessage);
      // setTooltipTextColor('#fff');
      setTooltipMessage(tooltipNewMessage);
      setTooltipIndex(1);
      setOpenTooltip(1);
    }

    // eslint-disable-next-line
  }, [newMessage, userAccessLevel]);

  //  @desc Tooltip Message Status Changed
  useEffect(() => {
    if (didMountTwo.current) {
      didMountTwo.current = false;
      return;
    }
    if (
      wsChangedMessageStatus.length > 0 ||
      wsApprovedMessagesRecived.length > 0
    ) {
      setTooltipBgColor(bgColorNewStatusMessage);
      // setTooltipTextColor('#5A5300');
      setTooltipMessage(tooltipStatusChanged);
      setTooltipIndex(2);
      setOpenTooltip(2);
    }

    // eslint-disable-next-line
  }, [wsChangedMessageStatus, wsApprovedMessagesRecived]);

  const handleClick = () => {
    handleReload();
    setOpenTooltip(0);
    setTooltipIndex(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // width: 'max-content',
      }}
    >
      <Typography variant='body1' color={'var(--clr-gray60)'} noWrap>
        Last Update {hourMinuteDate(lastUpdate)}
      </Typography>
      <TooltipAlert
        title={tooltipMessage}
        placement='left'
        open={openTooltip === tooltipIndex}
        // onClose={handleCloseTooltip}
        leaveDelay={500}
        sx={{
          zIndex: 1099,
          '& .MuiTooltip-tooltip': {
            // color: `${tooltipTextColor}`,
            color: 'var(--clr-gray80)',
            backgroundColor: `${tooltipBgColor}`,
          },
          '& .MuiTooltip-arrow': {
            color: `${tooltipBgColor}`,
          },
        }}
      >
        <LoadingButton
          onClick={handleClick}
          loading={loading}
          endIcon={<AutorenewIcon />}
          loadingPosition='end'
          variant='contained'
          sx={{
            marginLeft: '1rem',
            backgroundColor: 'var(--clr-info)',
            '&:hover': {
              backgroundColor: 'var(--clr-info60)',
            },
            zIndex: 1000
          }}
        >
          Reload
        </LoadingButton>
      </TooltipAlert>
    </Box>
  );
};

export default ReloadBtn;
