import React, { useState, useEffect, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import PrimusWs, { PrimusContext } from "../../websocket/PrimusWs";

// REDUX
import {
  updateMessageStatus,
  saveOrderMessage,
  updateMessageOrder,
  reorderPresenterView,
  resetOrderListSaved,
  getAllMessages,
  getMessageOrder,
} from "../../../actions/messages";
// UTILS
import {
  hourMinuteDate,
  dayMonthYear,
} from "../../../utils/convertDateAndTime";
import { selectElColors } from "../../../utils/selectMsgStatusColor";
import questionTableStyles from "./TableStyles";
import tableColumnsSections from "./TableColumnsSections";

// MUI
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// COMPONENTS
import MessageContent from "./MessageContent";
import { Button, Chip, Divider } from "@mui/material";
import SplitButton from "./split/SplitButton";
import tableColumnSectionWithSplit from "./TableColumnSectionWithSplit";
import RegDetails from "../../RegDetails";
import { Flag } from "@mui/icons-material";

const CollapsableSection = ({
  userAccessToken,
  allMessage: { messages },
  CustomField,
  updateMessageStatus,
  presenterMessageList,
  saveListCounter,
  event,
  auth: { user },
  ...rest
}) => {
  const { sectionName, filterName, eventId, userId } = rest;
  
  const [openModule, setOpenModule] = useState(true);
  const [localMessages, setLocalMessages] = useState([]);
  const questionTableClasses = questionTableStyles(rest);
  const primusMethods = useContext(PrimusContext);
  const socket_cp = useSelector((state) => state.socketio.socketioCP);
  const finalPush = useSelector((state) => state.messages.finalPush);

  
  const dispatch = useDispatch()
  // @desc
  // Set messages locally based on prop filterName
  useEffect(() => {
    let isMounted = true;

    if (messages && isMounted) {
      if (filterName) {
        // console.log('Should have to go here !!!!!!!!!!!!!!!!',localMessages)
        return setLocalMessages(
          messages.items.filter((el) => el.status === filterName.toLowerCase())
        );
      }
      // console.log('Should have to go here !!!!!!!!!!!!!!!!', localMessages)
    }

    return () => {
      isMounted = false;
    };
  }, [filterName, messages]);

  const handleOpenModule = () => {
    setOpenModule(!openModule);
  };

  useEffect(()=>{
    console.log('Local messages notesDebug', localMessages)
    console.log('socket_cpsocket_cp', socket_cp)

  },[socket_cp])

  useEffect(()=>{
    console.log('finalPushfinalPushfinalPush', finalPush, presenterMessageList.messages)
    if(finalPush) {
      updateMessageOrder(presenterMessageList.messages, eventId)
    }
  },[finalPush])

  useEffect(() => {
    setOpenModule(localMessages.length < 1 ? false : true);
  }, [localMessages]);

  const handleChangeStatus = (e, id) => {
    updateMessageStatus(userAccessToken, id, e.target.value, eventId);
    handleSaveList();
      // data -> {from, messageID, eventID, status, userID}
     // status -> new | approved | archived  
      if(e.target.value === 'approved')  dispatch(updateMessageOrder(presenterMessageList.messages, eventId))
     //Notify mods
    let data = {

      userID:userId,
      messageId:id,
      eventID:eventId,
      status:e.target.value
    }
    socket_cp.emit('messageStatus',data)

    // NOTES:
    // Maybe we need the next code block to send WS messages to other Moderators

    // if (e.target.value === 'allowed') {
    //   let currentMsg = localMessages.messages.items.filter(
    //     (item) => item._id === id
    //   );
    //   currentMsg[0].status = 'allowed';
    //   // primusMethods.sendMessageApproved(
    //   //   user._id,
    //   //   e.target.value,
    //   //   event.items[0]._id
    //   // );
    // }
  };


  useEffect(()=>{
    console.log('presenterMessageList.messages',presenterMessageList.messages)
    // dispatch(updateMessageOrder(presenterMessageList.messages, eventId));
  },[presenterMessageList.messages])

  const handleSaveList = () => {
    try {

      // primusMethods.sendOrderList(userId, "orderListForce", eventId);
      // primusMethods.sendOrderListForPresenter(
      //   userId,
      //   "MessageApproved",
      //   eventId,
      //   "presenter"
      // );

      // const msgOrderIds = localMessages.map((el) => {
      //   if (el.messageId === undefined) el.messageId = el._id;
      //   if (el.orderNumber === undefined) el.orderNumber = 1000;
      //   return el;
      // });
      const msgOrderIds = localMessages.map((el) => {
        if (el.messageId === undefined) el.messageId = el._id;
        if (el.orderNumber === undefined) el.orderNumber = 1000;
        return el;
      });


      // console.log('---------', msgOrderIds)

      // if (saveListCounter === 0) {
      //   console.log('Save counte SAVE', saveListCounter)
      //   saveOrderMessage(msgOrderIds, eventId);

      // } else {
      //   console.log('Save counter update', saveListCounter)

      //   dispatch(updateMessageOrder(msgOrderIds, eventId));
      // }
      // updateMessageOrder(msgOrderIds, eventId)

      // console.log('msgOrder', msgOrderIds)
      // console.log('localmsg', localMessages)
      // console.log('Save counter update', saveListCounter)

      // getAllMessages(1, eventId);
      // getMessageOrder(eventId);

      // updateMessageOrder(presenterMessageList.messages, eventId);

      // console.log('update msg order force', presenterMessageList.messages)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      
      {sectionName === "replied" ? (
        <>
          {/* <Divider   sx={{ borderBottomWidth: 5 }}>
    <Chip label={'Answered'} />
  </Divider> */}

          <Box  className={questionTableClasses.sectionHeader}>
          
          </Box>

          <Box  className={questionTableClasses.sectionTitle}>
          <IconButton  onClick={handleOpenModule} aria-label="expand section">
              {/* {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
            </IconButton>
            <Typography
              variant="h4"
              color="var(--clr-gray80)"
              sx={{ textTransform: "capitalize" }}
            >
              {sectionName}
            </Typography>
          </Box>


          {localMessages.length > 0 ? (
            <TableContainer sx={{ justifyContent: "center" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* {tableColumnsSections.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        variant="head"
                        sx={{
                          color: "var(--clr-gray60)",
                          fontSize: "1.2rem",
                          paddingTop: "max(3rem, 1.5vh)",
                          width: `${column.width}`,
                          // minWidth: `${column.minWidth}`,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))} */}
                     <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:{sm:'26%',md:'26%',lg:'20%'}
                            // width: `${column.width}`,
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Details
                        </TableCell>
                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:'maxContent'
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Message
                        </TableCell>

                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: '160px',
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Status
                        </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localMessages.map((msg) => (
                    <TableRow
                      key={msg._id}
                      hover
                      // className={`${idx === 0 && showClass}`}
                      className={questionTableClasses.tRow}
                    >
                      <TableCell align="left">
                        <Typography variant="event">
                          {msg.name === undefined ? "NA" : msg.name}
                        </Typography>

                        <Tooltip title="Copy email">
                          <Typography
                            onClick={() =>
                              navigator.clipboard.writeText(msg.email)
                            }
                            sx={{
                              color: "var(--clr-gray40)",
                              textDecoration: "underline",
                              "&:hover": {
                                color: "var(--clr-info60)",
                                cursor: "pointer",
                                fontSize: "0.8rem",
                              },
                            }}
                          >
                            {msg.email} 
                          </Typography>
                        </Tooltip>

                        {CustomField.map((eachCustomFields, idx) => (
                          <Typography variant="event" key={idx}>
                            {msg[eachCustomFields]}
                          </Typography>
                        ))}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="event">
                          {msg.company === undefined || msg.company === ""
                            ? "NA"
                            : msg.company}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={
                          {
                            // width: 'max(600px, 20vw)',
                          }
                        }
                      >
                        <MessageContent
                          content={msg.content}
                          notes={msg.notes}
                          messageId={msg._id}
                          eventId={eventId}
                          type={'collapse'}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          "& :first-of-type": {
                            marginBottom: "0.375rem",
                          },
                        }}
                      >
                        <Typography variant="event">
                          {dayMonthYear(msg.createTime)}
                        </Typography>
                        <Typography variant="event">
                          {hourMinuteDate(msg.createTime)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl sx={{ minWidth: 130 }}>
                          <Select
                            name="status"
                            displayEmpty={true}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => handleChangeStatus(e, msg._id)}
                            value={msg.status}
                            MenuProps={{
                              classes: { list: questionTableClasses.list },
                            }}
                            sx={{
                              backgroundColor: `${
                                selectElColors(msg.status, msg._id).bg
                              }`,
                              color: `${
                                selectElColors(msg.status, msg._id).color
                              }`,
                            }}
                          >
                            <MenuItem
                              value="new"
                              className={`${questionTableClasses.new} ${questionTableClasses.menuItemTransition}`}
                              classes={{
                                selected: questionTableClasses.selected,
                              }}
                            >
                              new
                            </MenuItem>
                            <MenuItem
                              value="allowed"
                              className={`${questionTableClasses.allowed} ${questionTableClasses.menuItemTransition}`}
                              classes={{
                                selected: questionTableClasses.selected,
                              }}
                            >
                              to be asked
                            </MenuItem>
                            <MenuItem
                              value="replied"
                              className={`${questionTableClasses.replied} ${questionTableClasses.menuItemTransition}`}
                            >
                              answered
                            </MenuItem>
                            <MenuItem
                              value="archive"
                              className={`${questionTableClasses.archive} ${questionTableClasses.menuItemTransition}`}
                              classes={{
                                selected: questionTableClasses.selected,
                              }}
                            >
                              archive
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                paddingBlock: "4rem",
                paddingInline: "3rem",
              }}
            >
              <Typography variant="h5">No message was found</Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box className={questionTableClasses.sectionHeader}>
          </Box>
          <Box className={questionTableClasses.sectionTitle}>
          <IconButton onClick={handleOpenModule} aria-label="expand section">
              {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography
              variant="h4"
              color="var(--clr-gray80)"
              sx={{ textTransform: "capitalize" }}
            >
              {sectionName}
            </Typography>
  </Box>
       
          <Collapse in={openModule} timeout="auto" unmountOnExit>
            {localMessages.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* {  sectionName === 'new' ?  tableColumnSectionWithSplit.map((column) => ( */}
                    {/* {  sectionName === 'new' ?  tableColumnsSections.map((column) => (

                        <TableCell
                          key={column.id}
                          align={column.align}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: `${column.width}`,
                            minWidth: `${column.minWidth}`,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      )) :  tableColumnsSections.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: `${column.width}`,
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))  } */}
                               <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:{sm:'26%',md:'26%',lg:'20%'}
                            // width: `${column.width}`,
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Details
                        </TableCell>
                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:'maxContent'
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Message
                        </TableCell>

                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: '160px',
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Status
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {localMessages.map((msg) => (
                      <TableRow
                        key={msg._id}
                        hover
                        // className={`${idx === 0 && showClass}`}
                        className={questionTableClasses.tRow}
                      >


                        <TableCell>
                          {msg.regid && msg.regid.fields ? <RegDetails user={msg.regid.fields} date={msg.createTime} dragIcon={false} regId={true} /> : 
                          <RegDetails user={msg} date={msg.createTime} dragIcon={false} regId={false}  CustomField={CustomField}/>}
                        </TableCell>


                        <TableCell
                          align="left"
                          style={
                            {
                              // width: 'max(600px, 20vw)',
                            }
                          }
                        >
                         
                          <MessageContent
                            content={msg.content}
                            notes={msg.notes}
                            messageId={msg._id}
                            eventId={eventId}
                            msg={msg}
                            sectionName={sectionName}
                            type={'collapse'}
                          />

                        </TableCell>
                              {/* { 
                      sectionName === 'new' &&
                                <TableCell
                                align="center"
                                sx={{
                                  "& :first-of-type": {
                                    marginBottom: "0.375rem",
                                  },
                                }}
                                >
                          <SplitButton eachMsg={msg} />
                        </TableCell>
                        }    */}

                        {/* <TableCell
                          align="center"
                          sx={{
                            "& :first-of-type": {
                              marginBottom: "0.375rem",
                            },
                          }}
                        >
                          <Typography variant="event">
                            {dayMonthYear(msg.createTime)}
                          </Typography>
                          <Typography variant="event">
                            {hourMinuteDate(msg.createTime)}
                          </Typography>
                        </TableCell> */}
                        <TableCell align="center">
                          <FormControl sx={{ minWidth: 130 }}>
                            <Select
                              name="status"
                              displayEmpty={true}
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={(e) => handleChangeStatus(e, msg._id)}
                              value={msg.status}
                              MenuProps={{
                                classes: { list: questionTableClasses.list },
                              }}
                              sx={{
                                backgroundColor: `${
                                  selectElColors(msg.status, msg._id).bg
                                }`,
                                color: `${
                                  selectElColors(msg.status, msg._id).color
                                }`,
                              }}
                            >
                              <MenuItem
                                value="new"
                                className={`${questionTableClasses.new} ${questionTableClasses.menuItemTransition}`}
                                classes={{
                                  selected: questionTableClasses.selected,
                                }}
                              >
                                new
                              </MenuItem>
                              <MenuItem
                                value="allowed"
                                className={`${questionTableClasses.allowed} ${questionTableClasses.menuItemTransition}`}
                                classes={{
                                  selected: questionTableClasses.selected,
                                }}
                              >
                                to be asked
                              </MenuItem>
                              <MenuItem
                                value="replied"
                                className={`${questionTableClasses.replied} ${questionTableClasses.menuItemTransition}`}
                              >
                                answered
                              </MenuItem>
                              <MenuItem
                                value="archive"
                                className={`${questionTableClasses.archive} ${questionTableClasses.menuItemTransition}`}
                                classes={{
                                  selected: questionTableClasses.selected,
                                }}
                              >
                                archive
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  paddingBlock: "4rem",
                  paddingInline: "3rem",
                }}
              >
                <Typography variant="h5">No message was found</Typography>
              </Box>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

CollapsableSection.propTypes = {
  userAccessToken: PropTypes.string.isRequired,
  updateMessageStatus: PropTypes.func.isRequired,
  updateMessageOrder: PropTypes.func.isRequired,
  allMessage: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userAccessToken: state.auth.token,
  allMessage: state.messages.allMessage,
  CustomField: state.messages.CustomField,
  presenterMessageList: state.messages.presenterMessageList,
  event: state.event.event,
  auth: state.auth,


});

export default connect(mapStateToProps, {
  updateMessageStatus,
  updateMessageOrder
})(CollapsableSection);
