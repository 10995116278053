//import axios from "axios";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { setActivePartipant } from "../../actions/auth";
import { getAllMessages, getMessageOrder, rebuildPresenterView } from "../../actions/messages";
//import { getEvent } from "../../actions/event";
import { setDialogPopUpControl, setDialogPopUpTakingControl } from "../../actions/websocket";
import { toast } from 'react-toastify';

// REDUX ACTIONS
import {
  wsAddNewMessage,
  wsGetApprovedMessage,
  wsChangeMessageStatus,
  wsOrderListRecived,
  wsConnected,
  wsResetConnection,
  wsLiveUsers,
} from "../../actions/websocket";
import { CheckBox, CheckBoxOutlined } from "@mui/icons-material";
import { getActiveModID } from "./ApiService";
import { Button } from "@mui/material";
import { addNotification } from "../../actions/notification";
import submitLogs from "../../config/AWSGlobalLog";
//import { SET_ACTIVE_PARTICIPANT } from "../../constants/userConstants";

const PrimusContext = createContext(null);

export { PrimusContext };

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => {
  // BUILD PRIMUS QUERY STRING ---- START
  const event = useSelector((state) => state.event.event);
  const userId = useSelector((state) => state.auth.loggedInUserID);
  const userFirstName = useSelector((state) => state.auth.user.name.first);
  const userLastName = useSelector((state) => state.auth.user.name.last);
  const userAccessLevel = useSelector((state) => state.auth.user.level);
  const userName = `${userFirstName} ${userLastName}`;
  const wsChangedMessageStatus = useSelector((state) => state.websocket.wsChangedMessageStatus);
  const wsApprovedMessagesRecived = useSelector((state) => state.websocket.wsApprovedMessagesRecived);


  //Active participant
  const activeParticipant = useSelector(
    (state) => state.auth.activeParticipant
  );
  const liveUsers = useSelector((state) => state.websocket.wsLiveUsers);

  //Count for websocket

  let eventId;
  if (event !== null) {
    eventId = event.items[0]._id;
  }

  const query = `?userId=${userId}&userName=${userName}&event=${eventId}`;
  console.log("Primus context unmounts");
  // BUILD PRIMUS QUERY STRING ---- END
  // let primus = useRef(null);

  const [primus, setPrimus] = useState(null);


  const [primusMethods, setPrimusMethods] = useState({});

  const dispatch = useDispatch();


  useEffect(()=>{
    console.log('New active participant', activeParticipant)
  },[activeParticipant])




  useEffect(() => {
    // create a new Primus instance

    // dispatch(setAlert("Connecting to websocket.", "success", 2000));
    // toast.success('Connecting to websocket.', {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //     icon: true,
    //   theme: "colored",
    // });

    // toast.success(
    //   <div
    //   style={{
    //     height: "100%",
    //     borderLeft: "5px solid green",
    //     display: "flex",
    //     alignItems: "center",
    //     paddingLeft: 5
    //   }}
    // >
    //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
    //   <span style={{ marginLeft: 5 }}>Connecting to websocket</span>
    // </div>
    // )


    setTimeout(() => {
      try {

        const primusInstance = new window.Primus.connect(`${process.env.REACT_APP_PRIMUS_CONNECT_URL}/${query}`,
          {
            strategy: false,
            ping: 10000,
            pong: 15000,
            timeout: 20000,
          });

        console.log('primus instance', primusInstance)



        setPrimus(primusInstance);


      } catch (error) {
        console.log('primus instance error', error)
      }
    }, 1000)



  }, []);











  useEffect(() => {
    let channel = null;
    // let channel1 = null;

    //@ PRIMUS METHODS
    const closeWsConnection = () => {
      dispatch(wsConnected(false));

      primus.end();
    };

    const sendMessageApproved = (userId, status, eventId) => {
      channel.send("MessageApproved", {
        userId: userId,
        status: status,
        id: eventId,
      });
    };

    const messageEdited = (userId, type, eventId) => {
      channel.send("messageEdited", {
        userId: userId,
        type: type,
        id: eventId,
      });
    };


    const newMessage = (eventId, userID) => {
      channel.send('MessageSent', {
        data: {

          status: 'new',
          event_id: eventId,
          userID: userID
        }
      });
    };



    const sendMessageStatusChanged = (userId, status, eventId, messageId) => {
      // console.log('>>>>>>>> msg status changed');
      channel.send("MessageApproved", {
        userId: userId,
        status: status,
        id: eventId,
        messageId: messageId,
      });
    };

    const sendOrderList = (userId, orderList, eventId) => {
      channel.send("MessageApproved", {
        userId: userId,
        id: eventId,
        status: orderList,
      });
    };





    const sendOrderListForPresenter = (userId, orderList, eventId, to) => {
      channel.send("MessageApproved", {
        userId: userId,
        id: eventId,
        status: orderList,
        from: to
      });
    };

    // console.log('Primus current',primus.current)
    //@ PRIMUS CONNECTION

    const LiveParticipantRequest = (eventId, userId, userName) => {
      if (userAccessLevel > 1) {
        channel.send("cpLiveParticipantRequest", {
          id: eventId,
          userId: userId,
          userName: userName,
        });

        // channel.send("cprebuild", {
        //   id: eventId,
        //   userId: userId,
        //   userName: userName,
        // });
      }
    };

    const LiveParticipantRequestControl = (eventId, userId, who) => {
      //check who is controlling
      //if participant is already controlling then dont trigger
      console.log('control takeover', eventId, userId)
      // if (activeParticipant !== userId) {

        channel.send("cpLiveParticipantRequestControl", {
          id: eventId,
          userId: userId,
          who:who
        });
      // }
    };

    const LiveParticipantAcceptedControl = (eventId, userId) => {
      // dispatch(setActivePartipant(eventId, userId));

      channel.send("cpLiveParticipantAcceptedControl", {
        id: eventId,
        userId: userId,
      });
    };

    try {





      // primus.current = new window.Primus.connect(
      //   `${process.env.REACT_APP_PRIMUS_CONNECT_URL}/${query}`,
      //   {
      //     strategy: false,
      //     ping: 10000,
      //     pong: 15000,
      //     timeout: 20000,
      //   }
      // );

      // console.log(typeof(primus.current))
      // if(wsCountEvents.includes(eventId)) {
      //   dispatch(wsConnected(eventId))
      // } else {
      //   dispatch(wsResetConnection())
      // }
      if (primus !== null) {

        primus.on("open", function open() {
          dispatch(wsConnected(true));
          dispatch(addNotification({
            title:`Connected`,
            time: new Date().toLocaleTimeString(),
            read:false
          }))
          // toast.success(
          //   <div
          //   style={{
          //     height: "100%",
          //     borderLeft: "5px solid green",
          //     display: "flex",
          //     alignItems: "center",
          //     paddingLeft: 5
          //   }}
          // >
          //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
          //   <span style={{ marginLeft: 5 }}>Connected</span>
          // </div>
          // )
          
          console.log(
            "%c WS open: WS connection has been established",
            "background: #bada55; color: #333"
          );
        });

        primus.on("connected", function connected(data) {
          dispatch(wsConnected(true));

          console.log("ws connected", data);
        });

        primus.on("error", function error(err) {
          dispatch(
            setAlert(
              "Unable to establish connection. Please refresh this page to try again and If the problem persists please contact support",
              "error",
              3000
              )
              );
          dispatch(wsConnected(false));

          submitLogs(
            JSON.stringify({
                status:'ERROR - PRIMUS',
                message:'PRIMUS',
                data:{
                  eventId, userFirstName, userLastName
                },
                error:err
            })
          )


          console.log("ws error", err.message);
        });

        primus.on("end", function end() {

          submitLogs(
            JSON.stringify({
                status:'ERROR',
                message:'PRIMUS CONNECTION ENDED',
                data:{
                  eventId, userFirstName, userLastName
                }
            })
          )

          //Show Modal
          dispatch(wsConnected(false));

          console.log(
            "%c WS end: The connection has ended ",
            "background: #ff4500; color: white"
          );
        });

        primus.on("close", function end() {
          //dispatch isWsStatusReady to false
          submitLogs(
            JSON.stringify({
                status:'ERROR',
                message:'PRIMUS CONNECTION CLOSED',
                data:{
                  eventId, userFirstName, userLastName
                }
            })
          )

          console.log(
            "%c WS close: We've lost the connection to the ws server ",
            "background: #ffa500; color: white"
          );
        });

        primus.on("offline", function end() {
          
          submitLogs(
            JSON.stringify({
                status:'ERROR',
                message:'PRIMUS CONNECTION OFFLINE',
                data:{
                  eventId, userFirstName, userLastName
                }
            })
          )

          //dispatch isWsStatusReady to false
          dispatch(
            setAlert(
              "Unable to establish connection. Please refresh this page to try again and If the problem persists please contact support",
              "error",
              3000
              )
              );
          console.log(
            "%c WS close: We've lost the connection to the ws server ",
            "background: #ffa500; color: white"
          );
        });

        // channel1 = primus.channel("managerIntComs");

        channel = primus.channel("cpIntComs");

        channel.on("public", function (msg) {
          console.log("ws public", msg);
        });

        //@ EXPORT PRIMUS METHODS
        setPrimusMethods({
          closeWsConnection,
          sendMessageApproved,
          sendMessageStatusChanged,
          sendOrderList,
          sendOrderListForPresenter,
          messageEdited,
          newMessage,
          //Add
          LiveParticipantRequest,
          LiveParticipantRequestControl,
          LiveParticipantAcceptedControl,
        });

      }
    } catch (error) {
      // dispatch(
      //   setAlert(
      //    "Websocket error" + error,
      //     "danger",
      //     3000
      //   )
      // );
      toast.error('"Websocket error" + error', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
          icon: true,
        theme: "colored",
      });

      console.log("PS error", error);
    }

    //@ PRIMUS CHANNEL LISTENERS
    if (channel !== null) {
      //Multiple moderator
      // console.log('whats in primus')
      channel.on("cpLiveParticipantJoined", function (data) {
        console.log("joined", data);
      });

      // channel.on("cpLiveParticipantRequestControl", function (data) {
      //   console.log(data);
      //   //check
      //   if (data.id === eventId && data.userId === userId) {
      //     if(data.userId === data.who) {
      //       console.log('taking control??', data.userId, userId)
      //       dispatch(setDialogPopUpTakingControl(true)) 
      //     } else {

      //       dispatch(setDialogPopUpControl(true));
      //     }
      //   }
      // });

      // channel.on("cpLiveParticipantAcceptedControl", function (data) {
      //   //set active participant
      //   // ------> PUT request to setActivePartipant
      //   console.log('who in charge cpLiveParticipantAcceptedControl', data)
      //   // dispatch(setActivePartipant(eventId,data.userId));
      //   // channel.send -> participant changed (eventid, userid)
      //   channel.send("cpLiveParticipantChanged", {
      //     // id: data.id,
      //     // id: data.id,
      //     // userId: data.userId,

      //     id: eventId,
      //     userId: data.userId,
      //   });


      // });

      // channel.on("cpLiveParticipantChanged", function (data) {
      //   if(data.id === eventId) {

      //     console.log('who in charge cpLiveParticipantChanged', data.userId)

      //   dispatch(setActivePartipant(eventId, data.userId, liveUsers));

      

      //   // var modInControl = liveUsers.find(element => element.userId === data.userId); 
        
      //   //   setTimeout(()=>{
      //   //     console.log('Who is controlling', modInControl)

      //   //   },500)
      //   // toast.success(
      //   //      <div
      //   //      style={{
      //   //        height: "100%",
      //   //        borderLeft: "5px solid green",
      //   //        display: "flex",
      //   //        alignItems: "center",
      //   //        paddingLeft: 5
      //   //      }}
      //   //    >
      //   //      <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //   //      <span style={{ marginLeft: 5 }}>{modInControl.userName} Controlling the Q&A</span>
      //   //    </div>
      //   //    )


      // }
      // });

      // channel.on("cprebuild", function (msg) {
      //   console.log('CP rebuild-----------------------------------------', msg)
      //   // dispatch(getEvent())
      //   //joined?
      //   console.log('issue cprebuild, msg', msg)
      //   if (userAccessLevel > 1) {
      //     console.log(
      //       "Each refresh rebuild -> check user access level ",
      //       userAccessLevel
      //     );
      //     let users = [];
      //     msg.users.map((user) => {
      //       if (user.event === eventId) {

      //         // console.log('spark-----------------', user, msg)

      //         user.sparkId = msg.sparkId; //sparkId

      //         users.push(user);


      //       }
      //     });

      //     if (users.length > 0) {
      //       dispatch(wsLiveUsers(users, eventId));

     
      //         //if the active mod leaves than give other user control
      //         let currentUsers = msg.users
      //         // let _active = event.items[0].webcast.live.active.participant
      //       getActiveModID(eventId).then((res)=>{
      //         let _active = res
      //         console.log('Current active MOD EVENT LEVEL', _active)

              
      //         console.log('Current live users', currentUsers)

      //         let _isPresent = currentUsers.find((user)=> user.userId === _active)
      //         console.log('Active mod Present?', _isPresent )
  
      //         if(typeof(_isPresent)=== 'undefined' || typeof(_isPresent) === undefined) {
      //           console.log('Active mod Present?', currentUsers[0].userId )
  
      //           dispatch(setActivePartipant(eventId, currentUsers[0].userId ))
  
      //         }
  
      //       })



         
            

      //       dispatch(wsLiveUsers(users, eventId));
      //       console.log("Live users", users);
      //     }

      //     if (
      //       users.length === 1 &&
      //       activeParticipant !== users[0].userId
      //     ) {
      //       console.log("Only one user", users[0]);
      //       // set index 0 as active users
      //       // dispatch(getEvent(users[0].event))
      //       dispatch(setActivePartipant(eventId, users[0].userId));
      //       // dispatch({
      //       //   type:SET_ACTIVE_PARTICIPANT,
      //       //   payload:users[0].userId
      //       // })
      //     } 
      //     //Who
      //     //total msg.users

      //     // if(msg.action === 'disconnect') {
      //     //   //if the active mod leaves than give other user control
      //     //   let currentUsers = msg.users
      //     //   console.log('Current active MOD', activeParticipant)

      //     //   let _isPresent = currentUsers.find((user)=> user.userId === activeParticipant)
      //     //   console.log('Active mod Present?', _isPresent )

      //     //   if(typeof(_isPresent)=== 'undefined' || typeof(_isPresent) === undefined) {
      //     //     console.log('Active mod Present?', currentUsers[0].userId )

      //     //     dispatch(setActivePartipant(eventId, currentUsers[0].userId ))
      //     //     dispatch(wsLiveUsers(currentUsers, eventId));

      //     //   }

          
      //     // }
      //   }
      // });

      // channel.on("cpLiveParticipantRequest", function (msg) {
      //   //notify other user

      //   // channel.send(LiveParticipantJoind, id:eventId, userId, userName)
      //   console.log("cp Live participant request", msg);
      //   // Changes to be made
      //   // id-> event ID 
      //   // userId and name from msg object
      //   channel.send("cpLiveParticipantJoined", {
      //     // id: msg.id,
      //     id:eventId,
      //     userId: msg.userId,
      //     userName: msg.userName,
      //   });
      // });

      channel.on("MessageSent", function (msg) {
        console.log('primusdebug', msg)
        if (msg.data.event_id === eventId && msg.data.userID !== userId) {
          console.log('>>>>> NEW MSG  from CLIENT PORTAL CHANNEL<<<<<', msg);
          // dispatch(wsAddNewMessage(msg));
        }
      });

      channel.on("messageEdited", function (msg) {
        console.log('message edited primusDebug', msg, 'local', userId)

        // if (msg.id === eventId && msg.type === 'noteAdded' && msg.userId !== userId) {
        //   if (msg.id === eventId && msg.type === 'noteAdded') {

        //   console.log('primus debug user id', msg, 'local ', userId)
        //   dispatch(getAllMessages(1, eventId, 6, true))
        //   dispatch(getMessageOrder(eventId))
        //   console.log('Note Added primusDebug', msg);
        //   //  dispatch(setAlert("Notes has been added", "success", 3000));

        //   dispatch(addNotification({
        //     title:`Notes has been added`,
        //     time: new Date().toLocaleTimeString(),
        //     read:false
        //   }))

        // }
        if (msg.id === eventId && msg.type === 'noteEdited') {
          console.log('primus debug user id', msg, 'local ', userId)
          console.log('Note edited primusDebug', msg);
          dispatch(getAllMessages(1, eventId, 6, true))
          dispatch(getMessageOrder(eventId))
          //  dispatch(setAlert("Notes has been edited", "success", 3000));
          dispatch(addNotification({
            title:`Notes has been edited`,
            time: new Date().toLocaleTimeString(),
            read:false
          }))

        }

        if (msg.id === eventId && msg.type === 'deleted') {

          dispatch(getAllMessages(1, eventId, 6, true))
          dispatch(getMessageOrder(eventId))
          //  dispatch(setAlert("Notes has been edited", "success", 3000));
          dispatch(addNotification({
            title:`Notes has been deleted`,
            time: new Date().toLocaleTimeString(),
            read:false
          }))


        }



        // if (msg.id === eventId && msg.type === 'messageEdited' && msg.userId !== userId) {
        //   console.log('primus debug user id', msg, 'local ', userId)
        //   dispatch(getAllMessages(1, eventId, 6, true))
        //   dispatch(getMessageOrder(eventId))
        //   console.log('message edited primusDebug', msg);
        //   //  dispatch(setAlert("Message has been edited", "success", 3000));

        //   dispatch(addNotification({
        //     title:`Message has been edited`,
        //     time: new Date().toLocaleTimeString(),
        //     read:false
        //   }))
   
        // }
      });


      channel.on("notesAdded", function (msg) {
        if (msg.event_id === eventId) {
          console.log('>>>>> notes added from CLIENT PORTAL CHANNEL<<<<<', msg);
          // dispatch(wsAddNewMessage(msg));
        }
      });

      // channel1.on("MessageSent", function (msg) {
      //   if (msg.data.event_id === eventId) {
      //     console.log('>>>>> NEW MSG from MANAGER  <<<<<', msg);
      //     dispatch(wsAddNewMessage(msg));
      //   }
      // });

      // channel.on("MessageApproved", function (msg) {
      //   console.log(msg)
      //   if (msg.id === eventId && msg.userId !== userId) {
      //     if (msg.status === "allowed") {
      //       console.log('<<<<< msg status changed allowed >>>>>', msg);

      //       dispatch(getAllMessages(1, eventId, 6, true))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(getMessageOrder(eventId))

      //       // return dispatch(setAlert("Messages has been updated", "success", 3000));

      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>Messages has been updated</span>
      //       // </div>
      //       // )

      //       // return dispatch(wsGetApprovedMessage(msg));
      //     }
      //     if (msg.status === "replied") {
      //       console.log('<<<<< msg status changed replied>>>>>', msg);
      //       dispatch(getAllMessages(1, eventId, 6, true))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // return dispatch(setAlert("Messages has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>Messages has been updated</span>
      //       // </div>
      //       // )
      //       // return dispatch(wsGetApprovedMessage(msg));
      //       // return dispatch(wsChangeMessageStatus(msg));
      //     }

      //     if (msg.status === "archive") {
      //       console.log('<<<<< msg status changed archive >>>>>', msg);

      //       dispatch(getAllMessages(1, eventId, 6, true))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // return dispatch(setAlert("Messages has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>Messages has been updated</span>
      //       // </div>
      //       // )
      //       // return dispatch(wsGetApprovedMessage(msg));
      //     }
      //     if (msg.from === "presenter" && userAccessLevel === 1 && msg.status === 'MessageStatus') {
      //       console.log('Presenter status', msg.status)
      //       dispatch(getAllMessages(1, eventId, 6, true))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // dispatch(wsOrderListRecived(true))
      //       // return dispatch(setAlert("List has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>List has been updated</span>
      //       // </div>
      //       // )
      //     }

      //     if (msg.from === "presenter" && userAccessLevel === 1 && msg.status === 'MessageApproved') {
      //       console.log('From presenter', msg.status)
      //       dispatch(wsOrderListRecived(true))
      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // return dispatch(setAlert("List has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>List has been updated</span>
      //       // </div>
      //       // )
      //     }


      //     if (msg.status === "orderList" && userAccessLevel > 1) {
      //       dispatch(getAllMessages(1, eventId, 6, false))

      //       dispatch(getMessageOrder(eventId))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(addNotification({
      //         title:`Message order updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // return dispatch(setAlert("Messages has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>Messages has been updated</span>
      //       // </div>
      //       // )
      //     }

      //     if (msg.status === "orderListForce" && userAccessLevel > 1) {
      //       dispatch(getAllMessages(1, eventId, 6, true))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(getMessageOrder(eventId))
      //       dispatch(addNotification({
      //         title:`Message status updated`,
      //         time: new Date().toLocaleTimeString(),
      //         read:false
      //       }))
      //       // return dispatch(setAlert("Messages has been updated", "success", 3000));
      //       // toast.success(
      //       //   <div
      //       //   style={{
      //       //     height: "100%",
      //       //     borderLeft: "5px solid green",
      //       //     display: "flex",
      //       //     alignItems: "center",
      //       //     paddingLeft: 5
      //       //   }}
      //       // >
      //       //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //       //   <span style={{ marginLeft: 5 }}>Messages has been updated</span>
      //       // </div>
      //       // )
      //     }


      //   }
      // });




      // NOTE:
      // does not recive the message
      channel.on("ApprovedMessageList", function (msg) {
        console.log("ApprovedMessageList", msg);
      });

      // channel.on('MessageStatusChanged', function (msg) {
      //   console.log('<<<<<<< MSG STATUS CHANGED >>>>>>>>', msg);
      //   if (msg.id === eventId && msg.userId !== userId) {
      //     if (msg.status === 'replied' || msg.status === 'archive') {
      //       dispatch(wsChangeMessageStatus(msg));
      //     }
      //   }
      // });
    }
    // eslint-disable-next-line
  }, [primus, setPrimus]);

  return (
    <PrimusContext.Provider value={primusMethods}>
      {children}
    </PrimusContext.Provider>
  );
};
