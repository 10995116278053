import axios from "axios";
import { RESET_SOCKET_IO_ERROR, SOCKETIO_CP_CONNECTED, SOCKETIO_CP_DISCONNECTED, SOCKET_IO_CONNECTED, SOCKET_IO_DISSCONNECTED, SOCKET_IO_ERROR } from "../constants/socketio";




//Socketio client portal namespace

export const socketio_cp_connected = (socket)=>(dispatch) =>{
  dispatch({
    type: SOCKETIO_CP_CONNECTED,
    payload:socket
  });
}

export const socketio_cp_dissconnected = ()=>(dispatch) =>{
  dispatch({
    type: SOCKETIO_CP_DISCONNECTED
  });
}



export const socketioConnected = ()=>(dispatch)=>{
    dispatch({
      type:SOCKET_IO_CONNECTED,
      payload:true
    })
  }



  export const socketioDissconnected = ()=>(dispatch)=>{
    dispatch({
      type:SOCKET_IO_DISSCONNECTED,
      payload:false
    })
  }


  export const socketioError = (error)=>(dispatch)=>{
    dispatch({
      type:SOCKET_IO_ERROR,
      payload:error
    })
  }

  export const resetSocketioError = ()=>(dispatch)=>{
    dispatch({
      type:RESET_SOCKET_IO_ERROR
    })
  }