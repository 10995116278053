export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const SEARCH_EVENT = 'SEARCH_EVENT';
export const SERACH_EVENT_IN_STORE = 'SEARCH_EVENT_IN_STORE';
export const EVENT_ERROR = 'EVENT_ERROR';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const CLEAR_SEARCHED_EVENT = 'CLEAR_SEARCHED_EVENT';


export const SET_TAB_VALUE_GLOBAL = 'SET_TAB_VALUE_GLOBAL'