import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

// REDUX
import { removeActiveIcon } from '../../actions/icon.js';
import { clearRegistrants } from '../../actions/registrants';
import { clearSearchedEvent } from '../../actions/event.js';

// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

// COMPONENTS
// import SidebarLink from './SidebarLink';
import SidebarLink from '../UI/inputs/SidebarLink';
import Profile from '../profile/Profile';

import logo from '../../static/img/brr-logo.png';
import './Layout.css';


const Sidebar = ({
  isIconActive,
  removeActiveIcon,
  clearRegistrants,
  clearSearchedEvent,
}) => {

  const [openUserProfile, setOpenUserProfile] = useState(false);


    const auth = useSelector((state) => state.auth)

    console.log('cp auth---------------------------', auth)

  const url = useLocation();
  const pathName = url.pathname.slice(1);

  const handleOpenUserProfile = () => {
    setOpenUserProfile(true);
  };

  const handleCloseUserProfile = () => {
    setOpenUserProfile(false);
    removeActiveIcon();
  };

  const handleClearSomeState = () => {
    clearRegistrants();
    clearSearchedEvent();
  };

  return (
    <AppBar
      position='sticky'
      sx={{
        width: '85px',
        height: `${isMobileOnly ? '100%' : '100%'}`,
        position: 'sticky',
        minHeight:'100vh',
        top: '0px',
        left: '0px',
        paddingRight: '0px !important',
        background: 'var(--clr-gray80)',
        zIndex: 1101,
      }}
      className='sidebar-shadow'
    >
      <Toolbar disableGutters sx={{ flexDirection: 'column' }}>
        <Box sx={{ padding: '14px', marginBottom: 'min(20vh, 140px)' }}>
          <Link to='/event-dashboard'>
            <Avatar
              alt='BRR'
              variant='square'
              src={logo}
              sx={{ width: '100%', height: '100%' }}
            />
          </Link>
        </Box>

        <SidebarLink
          text='events'
          linkTo='event-dashboard'
          linkIcon='events'
          active={pathName === 'event-dashboard' && true}
          doSomething={handleClearSomeState}
        />
        <SidebarLink
          text='profile'
          linkTo={pathName}
          linkIcon='profile'
          active={isIconActive}
          doSomething={handleOpenUserProfile}
        />

{
  auth.user.level === 6 && <SidebarLink
  text='Users'
  linkTo={'cp-management'}
  linkIcon='user'
  active={pathName === 'cp-management' && true}
  // doSomething={handleOpenUserProfile}
/>

}

        <Profile open={openUserProfile} close={handleCloseUserProfile} />
      </Toolbar>
    </AppBar>
  );
};

Sidebar.propTypes = {
  isIconActive: PropTypes.bool,
  removeActiveIcon: PropTypes.func.isRequired,
  clearRegistrants: PropTypes.func.isRequired,
  clearSearchedEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isIconActive: state.icon.isIconActive,
});

export default connect(mapStateToProps, {
  removeActiveIcon,
  clearRegistrants,
  clearSearchedEvent,
})(Sidebar);
