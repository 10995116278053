import {
  SET_ACTIVE_ICON,
  REMOVE_ACTIVE_ICON,
} from '../constants/utilsConstants';

export const setActiveIcon = () => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_ICON,
  });
};

export const removeActiveIcon = () => (dispatch) => {
  dispatch({
    type: REMOVE_ACTIVE_ICON,
  });
};
