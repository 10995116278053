import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { setCreateUserEvents, setEditUserEvents } from "../../../actions/cpManagement";
import { useDispatch, useSelector } from "react-redux";



const AssignEvents = ({defaultEvents, actionType})=>{
    const dispatch = useDispatch()
    const cpUserEdit = useSelector((state) => state.cpManagementReducer.cpUserEdit)
    const cpUserCreate = useSelector((state) => state.cpManagementReducer.cpUserCreate)

    const [openCompany, setOpenCompany] = React.useState(false);
    const [optionsForCompany, setOptionsForCompany] = React.useState([]);
    const [valueForCompany, setValueForCompany] = React.useState(null);
    const [inputValueForCompany, setInputValueForCompany] = React.useState("");
  
    const [openEvent, setOpenEvent] = React.useState(false);
    const [optionsForEvent, setOptionsForEvent] = React.useState([]);
    const [valueForEvent, setValueForEvent] = React.useState(null);
    const [inputValueForEvent, setInputValueForEvent] = React.useState("");


    const [_events, set_events] = useState([])


    const [events, setEvents] = useState([])

      
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    //   const loading = open && options.length === 0;
  
    // useEffect(() => {
    //   if (globalFilter === "event") {
    //     setInputValueForCompany("");
    //   }
    // }, [globalFilter]);
  
    React.useEffect(() => {
      let active = true;
  
      if (!autoCompleteLoading) {
        return undefined;
      }
  
      return () => {
        active = false;
      };
    }, [autoCompleteLoading]);
  
    //Company
    React.useEffect(() => {
      if (!openCompany) {
        setOptionsForCompany([]);
      }
    }, [openCompany]);


    
    //Check if the company field is empty

    useEffect(()=>{
        if(cpUserEdit.companies.length < 1) {
          //set event field to empty
          // dispatch(setEditUserEvents([]))
          set_events([])

        }
    }, [cpUserEdit])



  
    //Event
    React.useEffect(() => {
      if (!openEvent) {
        setOptionsForEvent([]);
      }
    }, [openCompany]);


    const getEventByKeyStroke = async(name)=>{
        try {
            // https://api.brrmedia.co.uk/company/search/1/5/?name_or_code=tesco&orderBy=name&token=5726ffec6071cb6ec47ac8f9
            // /event/search/1/10/?token=587cf0af60c7bd46e301b2fc&companies=623363491c349d634ccb29b3,531e609d3665390e1e0031e9&idOr=jishan
            console.log('Companies--------- assign event', cpUserEdit.companies.map((each)=> each._id).toString() )
            let companiesToStringCreate = cpUserCreate.companies.map((each)=> each._id).toString()
            let companiesToStringEdit = cpUserEdit.companies.map((each)=> each._id).toString()


            // const res = await axios.get(
            //   `${process.env.REACT_APP_API_BASE_URL}/event/search/1/10/?orderBy=-releaseTime&token=5726ffec6071cb6ec47ac8f9&idOr=${name}`
            // );

            if(actionType === 'add') {
              console.log('Add user', companiesToStringCreate, name)

              const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/event/search/1/10/?token=${process.env.REACT_APP_API_TOKEN}&companies=${companiesToStringCreate}&idOr=${name}`
                );
                console.log('Add user events', res.data)
                setEvents(res.data.items)

            } else {
              const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/event/search/1/10/?token=${process.env.REACT_APP_API_TOKEN}&companies=${companiesToStringEdit}&idOr=${name}`
                );
                setEvents(res.data.items)
            }

    
              

          } catch (error) {
            console.log(error);
          
          }
    }
  
    useEffect(() => {
      console.log('Add user events initial',inputValueForEvent)
        if(inputValueForEvent.length > 0) {

          getEventByKeyStroke(inputValueForEvent);
        }
    }, [inputValueForEvent]);

    // useEffect(()=>{
    //     if(cpUserCreate.companies.length >0) {
    //       console.log('initial events', 'inputvalue?', 'companyID', cpUserCreate.companies, 'value of event box', events)
    //       getEventByKeyStroke(inputValueForEvent);
    //     }
    // },[cpUserCreate])


    useEffect(()=>{
        console.log('default events', events)
        actionType !== 'add' &&   set_events(defaultEvents)
        actionType !== 'add' &&  dispatch(setEditUserEvents(defaultEvents))

    },[defaultEvents])

    console.log('Default events', defaultEvents)



    return (
        <>
        <Autocomplete
        // fullWidth
        disabled={actionType === 'add' ? (cpUserCreate.companies.length>0 ? false : true)  : (cpUserEdit.companies.length>0 ? false : true)}
        // disabled={cpUserEdit.companies.length>0 ? false : true}
        multiple
          id="event"
          sx={{ m: 1,background:'#ecf0f1' }}
          open={openEvent}
          onOpen={() => {
            setOpenEvent(true);
            getEventByKeyStroke(inputValueForEvent)
          }}
          onClose={() => {
            setOpenEvent(false);
          }}
          filterOptions={(x) => x}
        //   defaultValue={defaultEvents}
        value={_events}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option?.title}
          options={events}
          loading={autoCompleteLoading}
          onChange={(event, newValue) => {
            console.log('List if [[[[[events]]]]]', newValue)
            set_events(newValue)


            if(actionType === 'add') {
              dispatch(setCreateUserEvents(newValue))

            } else {
              dispatch(setEditUserEvents(newValue))

            }
            setOptionsForEvent(
              newValue ? [newValue, ...optionsForEvent] : optionsForEvent
            );
            if (newValue) {

              // console.log(newValue.title)
            //   props.dispatch(setCompanyNameInTitle(newValue._id))
            //   props.dispatch(setGlobalFilter("event", newValue.title));
            //   props.dispatch(setLoading())
              setLoading(false)

            //   props.dispatch(getStatByID(newValue._id))
            //   props.dispatch(getLiveUsersByEventID(newValue._id));
              // props.dispatch(getRegistrantDetails(newValue._id));

            }

            // Event selected in the text box-> trigger and display events accordingly
            //set Filter to true in redux store and display the result
          }}
          onInputChange={(event, newInputValue) => {

            //Dispatch action
            //   console.log("On each key stroke", newInputValue);
            if (newInputValue.length <= 0) {
            //   props.dispatch(setGlobalFilter(null, null));
            }
            setInputValueForEvent(newInputValue);
            console.log('List if events', newInputValue)

          }}
          renderInput={(params) => (
            <TextField

              {...params}
            //   label="Event title"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoCompleteLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        
        </>
        
        
    )

}

export default AssignEvents;