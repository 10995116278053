//Add websocket error handling


export const SET_LIVE_CHAT_USER = 'SET_LIVE_CHAT_USER'
export const GET_CHATS = 'GET_CHATS'
export const SET_CHATS = 'SET_CHATS'
export const FETCH_AND_APPEND_CHATS = 'FETCH_AND_APPEND_CHATS'


export const RESET_CHATS = 'RESET_CHATS'

export const SET_CHAT_MENU = 'SET_CHAT_MENU'
export const SET_CHAT_NOTIFICATION_COUNT = 'SET_CHAT_NOTIFICATION_COUNT'
export const RESET_CHAT_NOTIFICATION_COUNT = 'RESET_CHAT_NOTIFICATION_COUNT'

