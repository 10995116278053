import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, SvgIcon, Switch, TextField, createTheme } from '@mui/material';
import { BusinessCenter, Close, CopyAll, MailOutlineOutlined, Person } from '@mui/icons-material';
import AssignCompanies from './AssignCompanies';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useState } from 'react';
import AssignEvents from './AssignEvents';
import { resetEditUserID, setEditUserCPOnlyCompany, setEditUserEmail, setEditUserName, setEditUserPassword, setEditUserRole, setEditUserStatus, updaateUserLevel, updateUser, updateUserAccount } from '../../../actions/cpManagement';
import styled from '@emotion/styled';


const theme = createTheme({
    typography: {
      event: {
        fontSize: '0.8rem',
        textAlign:'right',
        width:'10vw',
        // background:'blue'
      },
      // Disable h3 variant
      h3: undefined,
    },
  });
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    height: '90vh',
    overflowY:'scroll',
    bgcolor: 'background.paper',
    boxShadow: 94,
    p: "0 2rem 2rem 2rem",
    borderRadius:'1rem'
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 52,
    height: 36,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:  '#7CB332',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:'gray'
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 32,
      height: 32,
    },
    '& .MuiSwitch-track': {
      borderRadius: 36 / 2,
      backgroundColor: '#39393D',
      opacity: 1
    },
  }));

export default function EditUser({ handleClose, open, user }) {

    const access_token = useSelector((state) => state.auth.token)
    const cpUserEdit = useSelector((state) => state.cpManagementReducer.cpUserEdit)
    const activeTabName = useSelector((state) => state.cpManagementReducer.activeTabName)

    const dispatch = useDispatch()

    const [defaultCompanies, setDeaultCompanies] = useState([])
    const [defaultEvents, setDefaultEvents] = useState([])

    const [errors, setErrors] = useState({
        active:false,
        company:false,
        events:false
    })


    const [editUserDetails, setEditUserDetails] = useState({
        name:user.name,
        email:user.email,
        company:user.clientPortalOnlyCompany
    })


    const [status, setStatus] = React.useState('');
    const handleChange = (event) => {
        setStatus(event.target.value);

        dispatch(setEditUserRole(event.target.value))


      };

    const [userStatus, setUserStatus] = useState(true)
    const handleChangeStatus = (event)=>{
        setUserStatus(event.target.checked)
        dispatch(setEditUserStatus(event.target.checked))

    }

    const fetchUserDetails = async (id) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/account/${id}/?access_token=${access_token}`)
            console.log('fetch-----------------------------', res.data.items[0].events)
            setDeaultCompanies(res.data.items[0].company)
            setDefaultEvents(res.data.items[0].events)

        } catch (error) {
            console.log('error', error)
        }

    }


    const updateLevel = (row, level)=>{
        dispatch(updaateUserLevel(row.id, level))

    }
    React.useEffect(() => {
        console.log('clientPortalOnlyCompany ------', user, user.hasOwnProperty('clientPortalOnlyCompany'))
        fetchUserDetails(user.id)
        setStatus(user.role === 'moderator' ? 2 : 1)
        dispatch(setEditUserStatus(user.active))
        dispatch(setEditUserName(user.name))
        dispatch(setEditUserEmail(user.email))
        dispatch(setEditUserStatus(user.active))
        dispatch(setEditUserRole(user.role))
        dispatch(setEditUserRole(user.role === 'moderator' ? 2 : 1))
        dispatch(setEditUserCPOnlyCompany(user.hasOwnProperty('clientPortalOnlyCompany') ? user.clientPortalOnlyCompany : 'NO'))
        
        setEditUserDetails({...editUserDetails,email:user.email})
        setEditUserDetails({...editUserDetails,name:user.name})
        setEditUserDetails({...editUserDetails,company:user.clientPortalOnlyCompany})



    }, [user,  editUserDetails.name, editUserDetails.email, editUserDetails.company ])

    const handleCloseWithReset = () => {
        console.log('closing----------------', 'handleRes')
        handleClose()
        dispatch(resetEditUserID())
        // setDeaultCompanies([])
        // setDefaultEvents([])
        setErrors({
            company:false,
            events:false,
            active:false
        })
    }


    const updateUser = () => {
        if (activeTabName === 'pendingApproval') {
            if (cpUserEdit.companies.length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    company: true
                }));
            }
            if (cpUserEdit.events.length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    events: true
                }));
            }
            if (!cpUserEdit.status) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    active: true
                }));
            }
            if (cpUserEdit.companies.length > 0 && cpUserEdit.events.length > 0 && cpUserEdit.status) {
                dispatch(updateUserAccount(cpUserEdit, 'approved'))
                setErrors({
                    company: false,
                    events: false,
                    active: false
                })
                handleCloseWithReset()
            }
        }
        if (activeTabName === 'approvedTab') {

            if (cpUserEdit.companies.length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    company: true
                }));
            }
            if (cpUserEdit.events.length === 0) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    events: true
                }));
            }

            if (cpUserEdit.companies.length > 0 && cpUserEdit.events.length > 0) {

                if (user.companys.length !== cpUserEdit.companies.length || user.events.length !== cpUserEdit.events.length
                    || user.email !== cpUserEdit.email || user.name !== cpUserEdit.name || user.clientPortalOnlyCompany !== cpUserEdit.cPonlyCompany || 
                    cpUserEdit.password.length>0
                    ) { 
                    console.log('approved_tab current user', user)
                    console.log('approved_tab current user', cpUserEdit)
                    dispatch(updateUserAccount(cpUserEdit, 'edited'))
                    setErrors({
                        company: false,
                        events: false
                    })
                    handleCloseWithReset()
                } else {
                    dispatch(updateUserAccount(cpUserEdit, 'noChange'))
                    setErrors({
                        company: false,
                        events: false
                    })
                    handleCloseWithReset()
                }


         

                // dispatch(updateUserAccount(cpUserEdit, 'edited'))
                // setErrors({
                //     company:false,
                //     events:false
                // })
                // handleCloseWithReset()
            }


        }


    }


    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseWithReset}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: '0.2rem', background: '', p: 2 }}>
                        <Typography sx={{ fontSize: '2rem', color: 'black' }}>
                            Edit User Details
                        </Typography>
                        <IconButton sx={{ color: 'black', background: '#ecf0f1' }} onClick={handleCloseWithReset}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Divider />


                    <Box sx={{ border: '', m: '0.4rem', p: '0.4rem 0.4rem 0 0' }} >
                    <Stack direction={'row'} m={0} sx={{ display: 'flex', justifyContent: 'center', alignContent:'center', alignItems:'center', gap:'1rem' }}>

<Typography sx={{ fontSize: '1.1rem' }}>
    Active
</Typography>

<FormGroup>
        <FormControlLabel
          control={
            <IOSSwitch checked={cpUserEdit.status} onChange={handleChangeStatus} name="status" />
          }
        //   label="Gilad Gray"
        />
        </FormGroup>
        {
                                
                                errors.active &&  <span><p style={{color:'tomato'}}>Make active</p></span>

                            }
</Stack>
                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                            <Typography variant='event'>
                                Name
                            </Typography>

                        </Stack>
                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                            <TextField
                            // disabled
                                sx={{ background: '#ecf0f1' }}
                                fullWidth id="outlined-basic" variant="outlined" 
                                value={cpUserEdit.name} 
                                // onChange={(e)=> setEditUserDetails({...editUserDetails, name:e.target.value})}
                                onChange={(e)=> dispatch(setEditUserName(e.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SvgIcon>
                                                <Person />
                                            </SvgIcon>
                                        </InputAdornment>
                                    ),
                                }}/>


                        </Stack>




                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <Typography variant='event'>
                                Email
                            </Typography>

                        </Stack>
                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                            <TextField
                            // disabled
                            
                                sx={{ background: '#ecf0f1' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <MailOutlineOutlined
                                                sx={{ color: 'var(--clr-gray40)' }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <CopyAll />
                                //         </InputAdornment>
                                //     )
                                // }}
                                fullWidth id="outlined-basic" variant="outlined" 
                                // value={user.email} 
                                value={cpUserEdit.email} 
                                // onChange={(e)=> setEditUserDetails({...editUserDetails, email:e.target.value})}
                                onChange={(e)=> dispatch(setEditUserEmail(e.target.value))}

                                />


                        </Stack>


                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <Typography variant='event'>
    Company
</Typography>

</Stack>
<Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

<TextField
// disabled
    sx={{ background: '#ecf0f1' }}
    InputProps={{
        startAdornment: (
            <InputAdornment position='start'>
                <SvgIcon>
                    <BusinessCenter />
                </SvgIcon>
            </InputAdornment>
        )}}
    // InputProps={{
    //     endAdornment: (
    //         <InputAdornment position="end">
    //             <CopyAll />
    //         </InputAdornment>
    //     )
    // }}
    
    fullWidth id="outlined-basic" variant="outlined" 
    value={cpUserEdit.cPonlyCompany} 
    onChange={(e)=> dispatch(setEditUserCPOnlyCompany(e.target.value))}
    // value={user.clientPortalOnlyCompany}
    
    />


</Stack>

<Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

<Typography variant='event'>
    Password (Leave empty)
</Typography>

</Stack>

<Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

<TextField
// disabled
    sx={{ background: '#ecf0f1' }}
    fullWidth id="outlined-basic" variant="outlined" 
    value={cpUserEdit.password} 
    // onChange={(e)=> setEditUserDetails({...editUserDetails, name:e.target.value})}
    onChange={(e)=> dispatch(setEditUserPassword(e.target.value))}
    InputProps={{
        startAdornment: (
            <InputAdornment position='start'>
                <SvgIcon>
                    <Person />
                </SvgIcon>
            </InputAdornment>
        ),
    }}/>


</Stack>



                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <Typography variant='event'>
                               Assign Company 
                               
                            {
                                
                            // errors.company || cpUserEdit.companies.length ===0 &&    <span><p style={{color:'tomato'}}>Please add company</p></span>
                            errors.company &&    <span><p style={{color:'tomato'}}>Please add company</p></span>

                            }   
                            
                            </Typography>

                        </Stack>

                        {

                            defaultCompanies.length >= 0 && <AssignCompanies defaultCompanies={defaultCompanies} />
                        }


                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <Typography variant='event'>
                            Assign Events
                            
                            {
                                
                                errors.events &&  <span><p style={{color:'tomato'}}>Please add company</p></span>

                            }

                            </Typography>

                        </Stack>

                        {

                            defaultEvents.length >= 0 && <AssignEvents defaultEvents={defaultEvents} />
                        }



                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <Typography variant='event'>
                                User Level
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} m={1} sx={{ display: 'flex', justifyContent: '' }}>

                        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">level</InputLabel>
        <Select
          sx={{ background: '#ecf0f1' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Select user level"
          onChange={handleChange}
        >
          <MenuItem value={1}>Presenter</MenuItem>
          <MenuItem value={2}>Moderator</MenuItem>

        </Select>
      </FormControl>
               
</Stack>








                        <Stack direction={'row'} m={2} sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>

                            <Button variant='contained'
                                onClick={handleCloseWithReset}
                                sx={{
                                    background: 'white',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#aed581'
                                    }

                                }}> Cancel </Button>
                            <Button variant='contained' sx={{
                                background: '#7CB332', color: 'white',
                                '&:hover': {
                                    backgroundColor: '#aed581'
                                }
                            }}   onClick={updateUser}  > Update </Button>


                        </Stack>

                    </Box>


                </Box>
            </Modal>
        </div>
    );
}