import React from 'react';
import { Route, Routes } from 'react-router-dom';

// COMPONENTS
import Events from '../event/Events';
import Event from '../event/Event';

const EventsDashboard = () => {
  return (
    <Routes>
      <Route path='/' element={<Events />} />
      <Route path='edit/:id' element={<Event />} />
    </Routes>
  );
};

export default EventsDashboard;
