import React from 'react';
import { styled } from '@mui/material/styles';

// MUI
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import bgAuthAd from '../../../static/img/bg-auth-adv.png';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  overflow: 'hidden',
  // [theme.breakpoints.down('sm')]: {
  //   width: '100% !important', // Overrides inline-style
  //   height: 100,
  // },
  // [theme.breakpoints.only('xs')]: {
  //   outline: '5px solid hotpink',
  // },
  '.titleLastTwoWords': {
    color: 'var(--clr-brr)',
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.2,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root:last-child': {
      backgroundColor: 'var(--clr-brr)',
    },
    '& .imageSrc': {
      transform: 'scale(1.5)',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: 'transform 1s',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.3,
  transition: theme.transitions.create('opacity'),
}));

const TextAsBtn = styled(Typography)(({ theme }) => ({
  backgroundColor: 'var(--clr-brr)',
  transition: theme.transitions.create('background-color'),
}));

const AuthAdBtn = () => {
  return (
    <Box sx={{ display: 'flex', minWidth: 300, width: '100%' }}>
      <ImageButton
        focusRipple
        style={{
          width: '100%',
        }}
      >
        <Link
          href='https://www.brrmedia.co.uk/our-services/'
          target='_blank'
          rel='noopener'
        >
          <ImageSrc
            style={{ backgroundImage: `url(${bgAuthAd})` }}
            className='imageSrc'
          />
          <ImageBackdrop className='MuiImageBackdrop-root' />
          <Image>
            <Typography
              component='span'
              variant='h5'
              color='inherit'
              sx={{
                position: 'relative',
                p: 4,
                pt: 0,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              Discover <span className='titleLastTwoWords'>our services</span>
            </Typography>
            <Typography
              component='span'
              variant='subtitle1'
              color='inherit'
              sx={{
                position: 'relative',
                p: 0,
                pl: 4,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              Find out how we can help tell your story
            </Typography>
            <TextAsBtn
              component='span'
              variant='button'
              color='inherit'
              sx={{
                position: 'relative',
                p: '8px 16px',
                mt: 2,
                ml: 4,
                backgroundColor: 'rgba(0,0,0,0.2)',
                border: '1px solid var(--clr-brr)',
                borderRadius: 1,
              }}
              className='textBtnStyle'
            >
              Discover
            </TextAsBtn>
          </Image>
        </Link>
      </ImageButton>
    </Box>
  );
};

export default AuthAdBtn;
