//import axios from "axios";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { setActivePartipant } from "../../actions/auth";
import { getAllMessages, getMessageOrder, rebuildPresenterView } from "../../actions/messages";
//import { getEvent } from "../../actions/event";
import { setDialogPopUpControl } from "../../actions/websocket";

// REDUX ACTIONS
import {
  wsAddNewMessage,
  wsGetApprovedMessage,
  wsChangeMessageStatus,
  wsOrderListRecived,
  wsConnected,
  wsResetConnection,
  wsLiveUsers,
} from "../../actions/websocket";
//import { SET_ACTIVE_PARTICIPANT } from "../../constants/userConstants";
import { toast } from 'react-toastify';
import { CheckBoxOutlined, CheckOutlined } from "@mui/icons-material";
import { addNotification } from "../../actions/notification";

const PrimusContextPV = createContext(null);

export { PrimusContextPV };

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => {
  // BUILD PRIMUS QUERY STRING ---- START
  const event = useSelector((state) => state.event.event);
  const userId = useSelector((state) => state.auth.passivePresenter.loggedInUserID);
  
  // const userFirstName = useSelector((state) => state.auth.user.name.first);
  // const userLastName = useSelector((state) => state.auth.user.name.last);
  // const userAccessLevel = useSelector((state) => state.auth.user.level);
  console.log('child', children)
  const userAccessLevel = 1
  const userName = `${'passive'} ${'presenter'}`;
  const wsChangedMessageStatus = useSelector((state) => state.websocket.wsChangedMessageStatus);
  const wsApprovedMessagesRecived = useSelector((state) => state.websocket.wsApprovedMessagesRecived);

  // console.log('props from websocket', props)
  //Active participant
  const activeParticipant = useSelector(
    (state) => state.auth.activeParticipant
  );
  const liveUsers = useSelector((state) => state.websocket.wsLiveUsers);

  //Count for websocket

  let eventId;
  if (event !== null) {
    eventId = event.items[0]._id;
  }

  const query = `?userId=${userId}&userName=${userName}&event=${eventId}`;
  // console.log("event id", eventId, "query", query);
  // BUILD PRIMUS QUERY STRING ---- END
  // let primus = useRef(null);

  const [primus, setPrimus] = useState(null);


  const [primusMethods, setPrimusMethods] = useState({});
  
  const dispatch = useDispatch();
  




  useEffect(() => {
    // create a new Primus instance

    // dispatch(setAlert("Connecting to websocket.", "success", 2000));

    dispatch(addNotification({
      title:`Connecting`,
      time: new Date().toLocaleTimeString(),
      read:false
    }))
      // toast.success(
      //   <div
      //   style={{
      //     height: "100%",
      //     borderLeft: "5px solid green",
      //     display: "flex",
      //     alignItems: "center",
      //     paddingLeft: 5
      //   }}
      // >
      //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //   <span style={{ marginLeft: 5 }}>Connecting to websocket.</span>
      // </div>
      // )
    setTimeout(()=>{
      try {

        const primusInstance = new window.Primus.connect(`${process.env.REACT_APP_PRIMUS_CONNECT_URL}/${query}`,
        {
          strategy: false,
          ping: 10000,
          pong: 15000,
          timeout: 20000,
        });
        
        console.log('primus instance',primusInstance)
    
    
    
        setPrimus(primusInstance);
    
    
      } catch (error) {
        console.log('primus instance error', error)
      }
    }, 1000)


 
  }, []);











  useEffect(() => {
    let channel = null;
    // let channel1 = null;

    //@ PRIMUS METHODS
    const closeWsConnection = () => {
      dispatch(wsConnected(false));

      primus.end();
    };

    const sendMessageApproved = (userId, status, eventId) => {
      channel.send("MessageApproved", {
        userId: userId,
        status: status,
        id: eventId,
      });
    };



    const messageEdited = (userId, type, eventId) => {
      channel.send("messageEdited", {
        userId: userId,
        type: type,
        id: eventId,
      });
    };


    const newMessage = (eventId, userID) => {
      channel.send('MessageSent', {
        data:{

          status: 'new', 
          event_id: eventId,
          userID:userID
        }
        });
    };

    

    const sendMessageStatusChanged = (userId, status, eventId, messageId) => {
      // console.log('>>>>>>>> msg status changed');
      channel.send("MessageApproved", {
        userId: userId,
        status: status,
        id: eventId,
        messageId: messageId,
      });
    };

    const sendOrderList = (userId, orderList, eventId) => {
      channel.send("MessageApproved", {
        userId: userId,
        id: eventId,
        status: orderList,
      });
    };


    const sendOrderListForPresenter = (userId, orderList, eventId, to) => {
      channel.send("MessageApproved", {
        userId: userId,
        id: eventId,
        status: orderList,
        from:to
      });
    };

    // console.log('Primus current',primus.current)
    //@ PRIMUS CONNECTION

    const LiveParticipantRequest = (eventId, userId, userName) => {
      if (userAccessLevel > 1) {
        channel.send("cpLiveParticipantRequest", {
          id: eventId,
          userId: userId,
          userName: userName,
        });

        // channel.send("cprebuild", {
        //   id: eventId,
        //   userId: userId,
        //   userName: userName,
        // });
      }
    };

    const LiveParticipantRequestControl = (eventId, userId) => {
      channel.send("cpLiveParticipantRequestControl", {
        id: eventId,
        userId: userId,
      });
    };

    const LiveParticipantAcceptedControl = (eventId, userId) => {
      channel.send("cpLiveParticipantAcceptedControl", {
        id: eventId,
        userId: userId,
      });
    };

    try {





        // primus.current = new window.Primus.connect(
        //   `${process.env.REACT_APP_PRIMUS_CONNECT_URL}/${query}`,
        //   {
        //     strategy: false,
        //     ping: 10000,
        //     pong: 15000,
        //     timeout: 20000,
        //   }
        // );

        // console.log(typeof(primus.current))
        // if(wsCountEvents.includes(eventId)) {
        //   dispatch(wsConnected(eventId))
        // } else {
        //   dispatch(wsResetConnection())
        // }
if(primus!== null) {

  primus.on("open", function open() {
    dispatch(wsConnected(true));
    
    console.log(
      "%c WS open: WS connection has been established",
      "background: #bada55; color: #333"
      );
    });

    primus.on("connected", function connected(data) {
      dispatch(wsConnected(true));
      
      console.log("ws connected", data);
    });
    
    primus.on("error", function error(err) {
      // dispatch(
      //   setAlert(
      //     "Websocket error " + err.message,
      //     "error",
      //     3000
      //     )
      //     );


      toast.error(
        <div
        style={{
          height: "100%",
          borderLeft: "5px solid green",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
      >
        <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        <span style={{ marginLeft: 5 }}>Connecting to websocket.</span>
      </div>
      )
          console.log("ws error", err.message);
        });
        
        primus.on("end", function end() {
          console.log(
            "%c WS end: The connection has ended ",
            "background: #ff4500; color: white"
            );
          });
          
          primus.on("close", function end() {
            //dispatch isWsStatusReady to false
            
            console.log(
              "%c WS close: We've lost the connection to the ws server ",
              "background: #ffa500; color: white"
              );
            });
            
            // channel1 = primus.channel("managerIntComs");
            
            channel = primus.channel("cpIntComs");
            
            channel.on("public", function (msg) {
              console.log("ws public", msg);
            });
            
            //@ EXPORT PRIMUS METHODS
            setPrimusMethods({
              closeWsConnection,
              sendMessageApproved,
              sendMessageStatusChanged,
              sendOrderList,
              sendOrderListForPresenter,
              messageEdited,
              newMessage,
              //Add
              LiveParticipantRequest,
              LiveParticipantRequestControl,
              LiveParticipantAcceptedControl,
            });
            
          }
          } catch (error) {
      // dispatch(
      //   setAlert(
      //    "Websocket error" + error,
      //     "danger",
      //     3000
      //   )
      // );
      toast.error('"Websocket error" + error', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
          icon: true,
        theme: "colored",
        });
      console.log("PS error", error);
    }

    //@ PRIMUS CHANNEL LISTENERS
    if (channel !== null) {
      //Multiple moderator

      channel.on("cpLiveParticipantJoined", function (data) {
        console.log("joined", data);
      });

      channel.on("cpLiveParticipantRequestControl", function (data) {
        console.log(data);
        //check
        if (data.id === eventId && data.userId === userId) {
          dispatch(setDialogPopUpControl(true));
        }
      });

      channel.on("cpLiveParticipantAcceptedControl", function (data) {
        //set active participant
        // ------> PUT request to setActivePartipant
        dispatch(setActivePartipant(data.id, data.userId));
        // channel.send -> participant changed (eventid, userid)
        channel.send("cpLiveParticipantChanged", {
          id: data.id,
          userId: data.userId,
        });
      });


  
      //live participant request
      channel.on("cpLiveParticipantRequest", function (msg) {
        //notify other user

        // channel.send(LiveParticipantJoind, id:eventId, userId, userName)
        console.log("cp Live participant request", msg);

        channel.send("cpLiveParticipantJoined", {
          id: msg.id,
          userId: msg.userId,
          userName: msg.userName,
        });
      });

      channel.on("MessageSent", function (msg) {
        console.log('primusdebug', msg)
        if (msg.data.event_id === eventId && msg.data.userID !== userId) {
          console.log('>>>>> NEW MSG  from CLIENT PORTAL CHANNEL<<<<<', msg);
          dispatch(wsAddNewMessage(msg));
        }
      });

      channel.on("messageEdited", function (msg) {
        console.log('message edited primusDebug', msg, 'local', userId)

        if (msg.id === eventId  && msg.type ==='noteAdded' && msg.userId !== userId) {
          console.log('primus debug user id', msg , 'local ', userId)
          dispatch(getAllMessages(1, eventId,6,true))
          dispatch(getMessageOrder(eventId))
          console.log('Note Added primusDebug', msg);
          //  dispatch(setAlert("Notes has been added", "success", 3000));
          dispatch(addNotification({
            title:`Notes has been added`,
            time: new Date().toLocaleTimeString(),
            read:false
          }))



          // dispatch(wsAddNewMessage(msg));
        }
        if (msg.id === eventId  && msg.type==='noteEdited' && msg.userId !== userId) {
          console.log('primus debug user id', msg , 'local ', userId)
          console.log('Note edited primusDebug', msg);
          dispatch(getAllMessages(1, eventId,6,true))
            dispatch(getMessageOrder(eventId))
          //  dispatch(setAlert("Notes has been edited", "success", 3000));

          dispatch(addNotification({
            title:`Notes has been edited`,
            time: new Date().toLocaleTimeString(),
            read:false
          }))
    
        
          // dispatch(wsAddNewMessage(msg));
        }
        if (msg.id === eventId  && msg.type ==='messageEdited' && msg.userId !== userId) {
          console.log('primus debug user id', msg , 'local ', userId)
          dispatch(getAllMessages(1, eventId,6,true))
          dispatch(getMessageOrder(eventId))
          console.log('message edited primusDebug', msg);
          //  dispatch(setAlert("Message has been edited", "success", 3000));


      toast.success(
        <div
        style={{
          height: "100%",
          borderLeft: "5px solid green",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
      >
        <CheckBoxOutlined color={"white"} height="25px" width="25px" />
        <span style={{ marginLeft: 5 }}>Message has been added</span>
      </div>
      )
          // dispatch(wsAddNewMessage(msg));
        }
        
      });


      channel.on("notesAdded", function (msg) {
        if (msg.event_id === eventId) {
          console.log('>>>>> notes added from CLIENT PORTAL CHANNEL<<<<<', msg);
          // dispatch(wsAddNewMessage(msg));
        }
      });

      // channel1.on("MessageSent", function (msg) {
      //   if (msg.data.event_id === eventId) {
      //     console.log('>>>>> NEW MSG from MANAGER  <<<<<', msg);
      //     dispatch(wsAddNewMessage(msg));
      //   }
      // });

      channel.on("MessageApproved", function (msg) {
        console.log(msg)
        if (msg.id === eventId) {
          if (msg.status === "allowed") {
            console.log('<<<<< msg status changed allowed >>>>>', msg);

            dispatch(getAllMessages(1, eventId,6,true))
            dispatch(getMessageOrder(eventId))
            dispatch(getMessageOrder(eventId))
            // return dispatch(setAlert("Messages has been updated", "success", 3000));


              
      // toast.success(
      //   <div
      //   style={{
      //     height: "100%",
      //     borderLeft: "5px solid green",
      //     display: "flex",
      //     alignItems: "center",
      //     paddingLeft: 5
      //   }}
      // >
      //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
      //   <span style={{ marginLeft: 5 }}>Message has been updated</span>
      // </div>
      // )
            
            // return dispatch(wsGetApprovedMessage(msg));
          }
          if (msg.status === "replied" ) {
            console.log('<<<<< msg status changed replied>>>>>', msg);
            dispatch(getAllMessages(1, eventId, 6, true))
            dispatch(getMessageOrder(eventId))
            dispatch(getMessageOrder(eventId))
            // return dispatch(setAlert("Messages has been updated", "success", 3000));
            // toast.success(
            //   <div
            //   style={{
            //     height: "100%",
            //     borderLeft: "5px solid green",
            //     display: "flex",
            //     alignItems: "center",
            //     paddingLeft: 5
            //   }}
            // >
            //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
            //   <span style={{ marginLeft: 5 }}>Message has been updated</span>
            // </div>
            // )
            // return dispatch(wsGetApprovedMessage(msg));
            // return dispatch(wsChangeMessageStatus(msg));
          }

          if (msg.status === "archive") {
            console.log('<<<<< msg status changed archive >>>>>', msg);

            dispatch(getAllMessages(1, eventId, 6, true))
            dispatch(getMessageOrder(eventId))
            dispatch(getMessageOrder(eventId))

            // return dispatch(setAlert("Messages has been updated", "success", 3000));
            // toast.success(
            //   <div
            //   style={{
            //     height: "100%",
            //     borderLeft: "5px solid green",
            //     display: "flex",
            //     alignItems: "center",
            //     paddingLeft: 5
            //   }}
            // >
            //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
            //   <span style={{ marginLeft: 5 }}>Message has been updated</span>
            // </div>
            // )
            // return dispatch(wsGetApprovedMessage(msg));
          }
          if (msg.from === "presenter" && userAccessLevel === 1 && msg.status === 'MessageStatus') {
            console.log('Presenter status',  msg.status)
            dispatch(getAllMessages(1, eventId, 6, true))
            dispatch(getMessageOrder(eventId))
          // dispatch(wsOrderListRecived(true))
        //   return dispatch(setAlert("List has been updated", "success", 3000));
        }

          if (msg.from === "presenter" && userAccessLevel === 1 && msg.status === 'MessageApproved') {
              console.log('From presenter',  msg.status)
            dispatch(wsOrderListRecived(true))
            // return dispatch(setAlert("List has been updated", "success", 3000));
          }
  





          if (msg.status === "orderList" && userAccessLevel > 1) {
            dispatch(getAllMessages(1, eventId, 6, false))
            
            dispatch(getMessageOrder(eventId))
            dispatch(getMessageOrder(eventId))
                        
            // return dispatch(setAlert("Messages has been updated", "success", 3000));
            // toast.success(
            //   <div
            //   style={{
            //     height: "100%",
            //     borderLeft: "5px solid green",
            //     display: "flex",
            //     alignItems: "center",
            //     paddingLeft: 5
            //   }}
            // >
            //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
            //   <span style={{ marginLeft: 5 }}>Message has been updated</span>
            // </div>
            // )
          }

          if (msg.status === "orderListForce" && userAccessLevel > 1) {
            dispatch(getAllMessages(1, eventId, 6, true))
            dispatch(getMessageOrder(eventId))
            dispatch(getMessageOrder(eventId))
                        
            // return dispatch(setAlert("Messages has been updated", "success", 3000));
            // toast.success(
            //   <div
            //   style={{
            //     height: "100%",
            //     borderLeft: "5px solid green",
            //     display: "flex",
            //     alignItems: "center",
            //     paddingLeft: 5
            //   }}
            // >
            //   <CheckBoxOutlined color={"white"} height="25px" width="25px" />
            //   <span style={{ marginLeft: 5 }}>Message has been updated</span>
            // </div>
            // )
          }


        }
      });

      // NOTE:
      // does not recive the message
      channel.on("ApprovedMessageList", function (msg) {
        console.log("ApprovedMessageList", msg);
      });

      // channel.on('MessageStatusChanged', function (msg) {
      //   console.log('<<<<<<< MSG STATUS CHANGED >>>>>>>>', msg);
      //   if (msg.id === eventId && msg.userId !== userId) {
      //     if (msg.status === 'replied' || msg.status === 'archive') {
      //       dispatch(wsChangeMessageStatus(msg));
      //     }
      //   }
      // });
    }
    // eslint-disable-next-line
  }, [primus,setPrimus]);

  return (
    <PrimusContextPV.Provider value={primusMethods}>
      {children}
    </PrimusContextPV.Provider>
  );
};
