import { v4 as uuidv4 } from 'uuid';
import {
  SET_ALERT,
  REMOVE_ALERT,
  REMOVE_ALL_ALERT,
} from '../constants/utilsConstants';

export const setAlert =
  (msg, alertType, timeout = 30000, marginTop) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id, marginTop },
    });

    // if (timeout === 30000)
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };

export const removeAlert = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ALERT, payload: id });
};

export const removeAllAlert = () => (dispatch) => {
  dispatch({ type: REMOVE_ALL_ALERT });
};
