import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { updateMessageStatus,updateMessageOrder } from '../../../actions/messages';

// MUI
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

// COMPONENTS
import MessageContent from './MessageContent';

// UTILS
import { selectElColors } from '../../../utils/selectMsgStatusColor';
import {
  hourMinuteDate,
  dayMonthYear,
} from '../../../utils/convertDateAndTime';
import questionTableStyles from './TableStyles';
import tableColumnsSections from './TableColumnsSections';
import RegDetails from '../../RegDetails';

const FilteredQuestionsTable = ({
  userAccessToken,
  presenterMessageList,
  allMessage: { messages },
  updateMessageStatus,
  CustomField,
  event,
  auth: { user },
  ...rest
}) => {
  const { sectionName, filterName, userId } = rest;

  const eventId = useSelector((state) => state.event.event.items[0]._id);
  const socket_cp = useSelector((state) => state.socketio.socketioCP);

  const [localMessages, setLocalMessages] = useState([]);
  const questionTableClasses = questionTableStyles(rest);
  const activeParticipant = useSelector((state) => state.auth.activeParticipant);
  const dispatch = useDispatch()

  // @desc
  // Set messages locally based on prop sectionName
  useEffect(() => {
    // console.log(rest)
    if (messages) {
      if (sectionName) {
        return setLocalMessages(
          messages.items.filter((el) => el.status === sectionName.toLowerCase())
        );
      } else {
        return setLocalMessages(
          messages.items.filter((el) => el.status === sectionName.toLowerCase())
        );
      }
    }
  }, [sectionName, messages]);

  const handleChangeStatus = (e, id) => {
    updateMessageStatus(userAccessToken, id, e.target.value);

    // data -> {from, messageID, eventID, status, userID}
   // status -> new | approved | archived  
   dispatch(updateMessageOrder(presenterMessageList.messages, eventId));


   //Notify mods
  let data = {

    userID:userId,
    messageId:id,
    eventID:eventId,
    status:e.target.value
  }
  socket_cp.emit('messageStatus',data)




    // NOTES:
    // Maybe we need the next code block to send WS messages to other Moderators

    // if (e.target.value === 'allowed') {
    //   let currentMsg = allMessage.messages.items.filter(
    //     (item) => item._id === id
    //   );
    //   currentMsg[0].status = 'allowed';
    //   // primusMethods.sendMessageApproved(
    //   //   user._id,
    //   //   e.target.value,
    //   //   event.items[0]._id
    //   // );
    // }
  };

  return (
    <>
            <div
              className="blackBox"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                opacity: "0.5",
                zIndex: 500,
                display: `${
                  activeParticipant === user._id
                    ? "none"
                    : "block"
                }`,
              }}
            ></div>
      {localMessages.length < 1 ? (
        <Typography
          variant='h5'
          gutterBottom
          sx={{
            padding: '1.5rem 2rem',
            marginBottom: '0px',
            textAlign: 'center',
            color: 'var(--clr-danger)',
          }}
        >
          No messages have been added to the list
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/* {tableColumnsSections.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    variant='head'
                    sx={{
                      color: 'var(--clr-gray60)',
                      fontSize: '1.2rem',
                      paddingTop: 'max(3rem, 1.5vh)',
                      width: `${column.width}`,
                      // minWidth: `${column.minWidth}`,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))} */}
                                     <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:{sm:'26%',md:'26%',lg:'20%'}
                            // width: `${column.width}`,
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Details
                        </TableCell>
                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width:'maxContent'
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Message
                        </TableCell>

                        <TableCell
                          align={'left'}
                          variant="head"
                          sx={{
                            color: "var(--clr-gray60)",
                            fontSize: "1.2rem",
                            paddingTop: "max(3rem, 1.5vh)",
                            width: '160px',
                            // minWidth: `${column.minWidth}`,
                          }}
                        >
                         Status
                        </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localMessages.map((msg, idx) => (
                <TableRow
                  hover
                  key={msg._id}
                  className={questionTableClasses.tRow}
                >
                  {/* <TableCell align='left'>
                    <Typography variant="event">{msg.name || 'NA'}</Typography>
                    <Tooltip title='Copy email'>
                      <Typography
                        onClick={() => navigator.clipboard.writeText(msg.email)}
                        sx={{
                          color: 'var(--clr-gray40)',
                          textDecoration: 'underline',
                          '&:hover': {
                            color: 'var(--clr-info60)',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {msg.email}
                      </Typography>
                    </Tooltip>

                    {CustomField.map((eachCustomFields) => (
                      <Typography variant="event">{msg[eachCustomFields]}</Typography>
                    ))}
                  </TableCell> */}

<TableCell>
                          {msg.regid && msg.regid.fields ? <RegDetails user={msg.regid.fields} date={msg.createTime} dragIcon={false} regId={true} /> : 
                          <RegDetails user={msg} date={msg.createTime} dragIcon={false} regId={false}  CustomField={CustomField}/>}
                        </TableCell>

                  {/* <TableCell align='left'>
                    <Typography variant="event">{msg.company || 'NA'}</Typography>
                  </TableCell> */}
                  <TableCell
                    align='left'
                    style={{
                      // width: 'max(600px, 20vw)',
                    }}
                  >
                    <MessageContent
                      content={msg.content}
                      notes={msg.notes}
                      eventId={eventId}
                      messageId={msg._id}
                      msg={msg}
                      sectionName={sectionName}
                    />
                  </TableCell>
                  {/* <TableCell align='center'>
                    <Typography variant="event">{dayMonthYear(msg.createTime)}</Typography>
                    <Typography variant="event">{hourMinuteDate(msg.createTime)}</Typography>
                  </TableCell> */}
                  <TableCell align='center'>
                    <FormControl sx={{ minWidth: 130 }}>
                      <Select
                        name='status'
                        displayEmpty={true}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => handleChangeStatus(e, msg._id)}
                        value={msg.status}
                        MenuProps={{
                          classes: { list: questionTableClasses.list },
                        }}
                        sx={{
                          backgroundColor: `${
                            selectElColors(msg.status, msg._id).bg
                          }`,
                          color: `${selectElColors(msg.status, msg._id).color}`,
                        }}
                      >
                        <MenuItem
                          value='new'
                          className={`${questionTableClasses.new} ${questionTableClasses.menuItemTransition}`}
                          classes={{ selected: questionTableClasses.selected }}
                        >
                          new
                        </MenuItem>
                        <MenuItem
                          value='allowed'
                          className={`${questionTableClasses.allowed} ${questionTableClasses.menuItemTransition}`}
                          classes={{ selected: questionTableClasses.selected }}
                        >
                          to be asked
                        </MenuItem>
                        <MenuItem
                          value='replied'
                          className={`${questionTableClasses.replied} ${questionTableClasses.menuItemTransition}`}
                        >
                          answered
                        </MenuItem>
                        <MenuItem
                          value='archive'
                          className={`${questionTableClasses.archive} ${questionTableClasses.menuItemTransition}`}
                          classes={{ selected: questionTableClasses.selected }}
                        >
                          archive
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

FilteredQuestionsTable.propTypes = {
  userAccessToken: PropTypes.string.isRequired,
  updateMessageStatus: PropTypes.func.isRequired,
  allMessage: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userAccessToken: state.auth.token,
  allMessage: state.messages.allMessage,
  CustomField: state.messages.CustomField,
  presenterMessageList: state.messages.presenterMessageList,
  event: state.event.event,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateMessageStatus,
})(FilteredQuestionsTable);
