import React from 'react';

import Icon from '@mui/material/Icon';

const CustomIcon = ({ iconElement }) => {
  return (
    <Icon sx={{ display: 'flex' }}>
      <img src={iconElement} alt='Custom Icon' />
    </Icon>
  );
};

export default CustomIcon;
