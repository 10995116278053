import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { useMobileOrientation } from 'react-device-detect';
import PropTypes from 'prop-types';

// REDUX
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';

// STATIC
import { marketArray } from '../../static/marketList';

// MUI
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';

// ICONS & IMGS
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import { ReactComponent as NameIcon } from '../../static/icons/name_icon2.svg';

// COMPONENTS
import AuthFooter from './AuthFooter';
import AuthHeader from './AuthHeader';
import BRRSwitch from '../UI/inputs/BRRSwitch';
import Spinner from '../UI/Spinner';

import './utilsAuth.css';
import { FourK } from '@mui/icons-material';

const Register = ({
  auth: { registration, loading },
  alert,
  setAlert,
  register,
}) => {
  const { isPortrait } = useMobileOrientation();
  const [market, setMarket] = useState('');
  const [checked, setChecked] = useState(true);
  const [hideMarketFields, setHideMarketFields] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [displayForm, setDisplayForm] = useState(true);
  const [formData, setFormData] = useState({
    firstname:'',
    lastname:'',
    email: '',
    phoneNumber: '',
    active: false,
    message: '',
    password:'',
    confirmPassword:'',
    clientPortalOnlyCompany:''

  });

  const [customError, setCustomError] = useState('')

  const { fullName,firstname, lastname, email, phoneNumber, uniqueCode, message, password, confirmPassword,active,clientPortalOnlyCompany } =
    formData;



  ValidatorForm.addValidationRule('isFullName', (value) => {
    let item = value.split(" ");
    if(item.length === 2) {
      return true
    } else {
      return false
    }
});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleListed = (e) => {
    setChecked((prev) => !prev);
    setHideMarketFields((prev) => !prev);
    setFormData({ ...formData, isListed: e.target.checked });
  };

  const handleMarketChange = (e) => {
    setMarket(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    register(formData);
    console.log('ON SUBMIT', formData);
  };

  useEffect(() => {
    if (alert.length > 0) {
      let timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);

  const   validatorListenerData = ()=>{
    let valid = !/\s/.test(fullName)
    if(valid) {
      setCustomError('Name must have firstname and lastname seperated by a space')
      setIsSubmitted(false);
    } else {
      setCustomError('')
      // setIsSubmitted(true)
    }
 
  }

  return (
    <>
      {isSubmitted && loading ? (
        <Spinner />
      ) : registration !== null && registration ? (
        <Navigate to='/contact-us/thank-you' />
      ) : (
        !isSubmitted && (
          <Box className='register-container'>
            <AuthHeader />
            <Box className='register-container__form'>
              {isPortrait && (
                <Typography
                  variant='h5'
                  component='h5'
                  sx={{
                    fontWeight: '700',
                    textAlign: 'center',
                    marginBottom: '2rem',
                  }}
                >
                  Register
                </Typography>
              )}
              <ValidatorForm
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
              >
                <Stack spacing={7} sx={{ maxWidth: '460px' }}>
                  <TextValidator
                    placeholder='First Name'
                    name='firstname'
                    value={firstname}
                    onChange={(e) => handleChange(e)}
                    validatorListener={validatorListenerData}

                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SvgIcon>
                            <NameIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  />




<TextValidator
                    placeholder='Last Name'
                    name='lastname'
                    value={lastname}
                    onChange={(e) => handleChange(e)}
                    validatorListener={validatorListenerData}

                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SvgIcon>
                            <NameIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  />        

                  <TextValidator
                    placeholder='Email'
                    name='email'
                    value={email}
                    onChange={(e) => handleChange(e)}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'this field is required',
                      'email is not valid',
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailOutlineOutlinedIcon
                            sx={{ color: 'var(--clr-gray40)' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  />


<TextValidator
                    placeholder='Password'
                    type='password'
                    name='password'
                    value={password}
                    onChange={(e) => handleChange(e)}
                    validators={['required', "minStringLength: 6"]}
                    errorMessages={[
                      'this field is required',
                      'Weak password, Required minimum 6 ',
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailOutlineOutlinedIcon
                            sx={{ color: 'var(--clr-gray40)' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  />



<TextValidator
                    placeholder='Company'
                    name='clientPortalOnlyCompany'
                    value={clientPortalOnlyCompany}
                    onChange={(e) => handleChange(e)}

                    // validators={['required']}
                    errorMessages={['this field is required']}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SvgIcon>
                            <BusinessIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  />  

                  {/* <TextValidator
                    placeholder='Company Name'
                    name='company'
                    value={company}
                    onChange={(e) => handleChange(e)}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <BusinessIcon sx={{ color: 'var(--clr-gray40)' }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  /> */}
                  {/* <TextValidator
                    placeholder='Phone Number'
                    name='phoneNumber'
                    value={phoneNumber}
                    onChange={(e) => handleChange(e)}
                    validators={[
                      'required',
                      'matchRegexp:^\\s*(?:\\+?(\\d{1,3}))?([-. (]*(\\d{3})[-. )]*)?((\\d{3})[-. ]*(\\d{2,4})(?:[-.x ]*(\\d+))?)\\s*$',
                    ]}
                    errorMessages={[
                      'this field is required',
                      'phone number is not valid',
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PhoneIcon sx={{ color: 'var(--clr-gray40)' }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    className='input-auth'
                  /> */}
                  {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='body1'>
                      Is the company listed?
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        marginRight: { xs: 0, md: 3 },
                      }}
                    >
                      <Typography variant='body1'>Yes</Typography>
                      <BRRSwitch
                        checked={checked}
                        onChange={handleListed}
                        inputProps={{ 'aria-label': 'is listed' }}
                      />
                      <Typography variant='body1'>No</Typography>
                    </Box>
                  </Box> */}
{/* 
                  <Collapse
                    in={!hideMarketFields}
                    timeout={{ enter: 270, exit: 300 }}
                    easing={{
                      enter: 'cubic-bezier(0.4, 0.0, 1, 1)',
                      exit: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
                    }}
                    onExited={() => setDisplayForm(false)}
                    onEnter={() => setDisplayForm(true)}
                  >
                    {displayForm && (
                      <Stack spacing={7} key='stack'>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end',
                            gap: '2rem',
                          }}
                        >
                          <Typography
                            variant='body1'
                            sx={{
                              marginTop: { xs: 0, md: 2 },
                            }}
                          >
                            Market
                          </Typography>
                          <Box
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <SelectValidator
                              className='input-auth'
                              name='selectedMarket'
                              value={market}
                              onChange={handleMarketChange}
                              fullWidth
                              validators={['required']}
                              errorMessages={['this field is required']}
                              SelectProps={{
                                displayEmpty: true,
                              }}
                            >
                              <MenuItem value=''>
                                <em>Select</em>
                              </MenuItem>
                              {marketArray.map((name) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end',
                            gap: '2rem',
                          }}
                        >
                          <Typography
                            variant='body1'
                            sx={{ marginTop: { xs: 0, md: 2 } }}
                          >
                            LSE/Unique Code
                          </Typography>
                          <TextValidator
                            placeholder='LSE/Unique Code'
                            name='uniqueCode'
                            value={uniqueCode}
                            onChange={(e) => handleChange(e)}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            fullWidth
                            className='input-auth'
                          />
                        </Box>
                      </Stack>
                    )}
                  </Collapse>

                  <TextField
                    placeholder='write here your message...'
                    multiline
                    rows={10}
                    name='message'
                    value={message}
                    onChange={(e) => handleChange(e)}
                    id='filled-multiline-static'
                    className='input-auth'
                  /> */}
                  <Button
                    type='submit'
                    variant='contained'
                    sx={[
                      {
                        textTransform: 'capitalize',
                        fontSize: '1.5rem',
                        fontWeight: '700',
                        bgcolor: 'var(--clr-btn-cta)',
                        '&:hover': {
                          bgcolor: 'var(--clr-btn-cta-hover)',
                        },
                      },
                    ]}
                  >
                    Send
                  </Button>
                </Stack>
              </ValidatorForm>
            </Box>
            <AuthFooter />
          </Box>
        )
      )}
    </>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  alert: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alert: state.alert,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
