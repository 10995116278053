import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// FONTS
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/400.css";

// let debugging = true;
let debugging =false;

if (!debugging) {
  window.console.log = () => null;
  window.console.warn = () => null;
  window.console.info = () => null;
  window.console.error = () => null;
}

ReactDOM.render(
  <BrowserRouter
    // basename={process.env.REACT_APP_CLIENT_PORTAL_URL}
    forceRefresh={false}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
