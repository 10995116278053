import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import icon from './icon';
import event from './event';
import registrants from './registrants';
import messages from './messages';
import splitMessage from './splitMessage';
import cpManagementReducer from './cpManagement';
import websocket from './websocket';
import notification from './notification';
import chatReducer from './chat';
import socketio from './socketio';


export default combineReducers({
  alert,
  auth,
  icon,
  event,
  registrants,
  messages,
  websocket,
  splitMessage,
  cpManagementReducer,
  notification,
  chatReducer,
  socketio
});
