import { Button, Tooltip, styled } from "@mui/material";
import React, { useEffect } from "react";
import AddQuestionModal from "./AddQuestionModal";
import SplitQuestionModal from "./AddQuestionModal";
import { makeStyles } from '@mui/styles';

const SplitButtomCustom = styled(Button)(({ theme }) => ({
    marginTop: '0.2rem',
    backgroundColor: 'var(--clr-info)',
    color: 'var(--clr-w)',
    '&:hover': {
      backgroundColor: 'var(--clr-info60)',
    },
    width:'135px'
}))
const useStyles = makeStyles((theme) => ({
    tooltip: {
      padding: '0.5rem',
      backgroundColor: '#fff',
      border: '2px solid var(--clr-info40)',
      color: '#4A4A4A',
      fontSize: '12px',
    },
  }));

const AddQuestionButton = ({eventId}) =>{

    const classes = useStyles();


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>


        <SplitButtomCustom variant="contaied"  onClick={handleOpen}>
           Add Question
        </SplitButtomCustom>
        <AddQuestionModal open={open} handleClose={handleClose} setOpen={setOpen} eventid={eventId} />
        </>
    )
}

export default AddQuestionButton;