import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// MUI
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const contrastText = '#fff';

// NOTE:
// currently does not work :)
const theme = createTheme({
  palette: {
    new: {
      // light: '#333333',
      // main: '#333333',
      main: 'hsl(207, 100%, 40%)',
      dark: 'hsl(0, 0%, 20%)',
      contrastText: contrastText,
    },
    allowed: {
      main: 'hsl(79, 80%, 38%)',
      dark: 'hsl(0, 0%, 20%)',
      contrastText: contrastText,
    },
    replied: {
      main: 'hsl(54, 87%, 40%)',
      dark: 'hsl(0, 0%, 20%)',
      contrastText: contrastText,
    },
    archive: {
      main: 'hsl(0, 65%, 51%)',
      dark: 'hsl(0, 0%, 20%)',
      contrastText: contrastText,
    },
    all: {
      main: 'hsl(0, 0%, 20%)',
      dark: 'hsl(0, 0%, 20%)',
      contrastText: contrastText,
    },
  },
});

const ToggleStatusBtns = ({
  handleFilterMessages,
  hasBeenReloaded,
  handleAllowed,
  messages: { error },
}) => {
  const [status, setStatus] = useState('all');
  const [disableBtns, setDisabled] = useState(false);

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      setDisabled(!error.success);
    }
  }, [error]);

  useEffect(() => {
    if (hasBeenReloaded) {
      setStatus('all');
      handleFilterMessages('all');
    }
  }, [hasBeenReloaded, handleFilterMessages]);

  const handleChange = (e, value) => {
    setStatus(value);

    if (value === 'allowed') {
      handleAllowed();
    } else {
      handleFilterMessages(value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        minWidth: '410px',
        marginRight: '1rem',
        '& :first-of-type': {
          height: '36.5px',
          alignSelf: 'flex-start',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Box>
        <Typography variant='body1' color={'var(--clr-gray60)'} noWrap>
          Filter by status
        </Typography>
      </Box>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          disabled={disableBtns}
          value={status}
          exclusive
          onChange={handleChange}
          size='small'
          aria-label='message status'
          sx={{
            display: 'flex',
            gap: '0.75rem',
            '& button': {
              height: '36.5px',
            },
            '& :nth-of-type(n+1):nth-of-type(-n+5)': {
              minWidth: '60px',
              border: 'none',
              borderRadius: '5px',
              fontSize: '0.9rem',
              textTransform: 'unset',
              boxShadow: '2',
            },
            '& button.MuiToggleButton-root.Mui-selected': {
              backgroundColor: disableBtns
                ? 'var(--clr-gray10)'
                : 'var(--clr-info)',
              color: disableBtns ? 'var(--clr-gray40)' : '#fff',
            },
            '& button.MuiToggleButton-root.Mui-disabled': {
              boxShadow: '0',
            },
          }}
        >
          <ToggleButton
            value='all'
            disabled={status === 'all'}
            aria-label='all message'
          >
            All
          </ToggleButton>
          <ToggleButton
            value='new'
            aria-label='new message'
            disabled={status === 'new'}
          >
            New
          </ToggleButton>
          <ToggleButton
            value='allowed'
            aria-label='to be asked message'
            disabled={status === 'allowed'}
          >
            Approved
          </ToggleButton>
          <ToggleButton
            value='archive'
            aria-label='archived message'
            disabled={status === 'archive'}
          >
            Archived
          </ToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
    </Box>
  );
};

ToggleStatusBtns.propTypes = {
  messages: PropTypes.object,
};

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps, null)(ToggleStatusBtns);
