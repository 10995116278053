import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useMobileOrientation } from 'react-device-detect';
import PropTypes from 'prop-types';

// REDUX
import { setAlert } from '../../actions/alert';
import { changePassword, login, passwordUpdatedInitialState } from '../../actions/auth';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';

// COMPONENTS
import AuthFooter from './AuthFooter';
import AuthHeader from './AuthHeader';
import Description from './Description';
import SelectGroupModal from './SelectGroupModal';

import './Auth.css';
import './utilsAuth.css';

const ResetPassword = ({ setAlert, login, isAuthenticated, openSelectGroupModal }) => {

  const {passwordUpdated} = useSelector((state) => state.auth);


  const {userID, token} = useParams()
    console.log('params', userID, token)

    const dispatch = useDispatch()

useEffect(()=>{
      dispatch(passwordUpdatedInitialState())
},[])

  const { isPortrait } = useMobileOrientation();
  const [openModal, setOpenModal] = useState(false);
  const [showPsw, setShowPsw] = useState();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword:''
  });

  const {password, confirmPassword } = formData;

  useEffect(() => {
    if (openSelectGroupModal) setOpenModal(true);
  }, [openSelectGroupModal]);

  // console.log('openSelectGroupModal 1', openSelectGroupModal);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Passwords', password, confirmPassword)
    // login(email, password);

    //match password
    if(password === confirmPassword) {
      let data = {
        userID:userID,
        token:token,
        password:password
      }
      dispatch(changePassword(data))
    }

  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  // const handleCloseModal = (event, reason) => {
  //   // if (reason === 'backdropClick') return
  //   setOpenModal(false);
  // };

  // REDIRECT IF LOGGED IN
  if (isAuthenticated) {
    return <Navigate to='/event-dashboard' />;
  }

  ValidatorForm.addValidationRule('matchPassword', (value) => {
    if(value === password) {
      return true
    } else {
      return false
    }
});

  return (
    <>
      <Box sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* <AuthHeader /> */}


{
  passwordUpdated 
  ? 
  <Box className='login-container'>
        <Typography>Password was changed successfully. Click  
          <span style={{color:'#fe5e37', textDecoration:'underlinded'}}> <Link to="/login">here</Link>  </span> to login
          </Typography>
       
       
    </Box>
  :
  
  <Box className='login-container'>
  {isPortrait && <Description />}
  <Stack
    spacing={7}
    className={isPortrait ? 'login__form-container' : undefined}
    sx={{ maxWidth: '460px', paddingTop: '3rem' }}
  >
    <Typography variant='h5' component='h5' sx={{ fontWeight: '700' }}>
      Reset your password
    </Typography>
    <ValidatorForm
      className={isPortrait ? 'login__form-group' : undefined}
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Stack spacing={7}>

        <TextValidator
          placeholder='Password'
          name='password'
          value={password}
          onChange={(e) => handleChange(e)}
          type={showPsw ? 'text' : 'password'}
          validators={['required']}
          errorMessages={['this field is required']}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LockIcon sx={{ color: 'var(--clr-gray40)' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPsw ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          className='input-auth'
        />

<TextValidator
          placeholder='Confirm password'
          name='confirmPassword'
          value={confirmPassword}
          onChange={(e) => handleChange(e)}
          type={showPsw ? 'text' : 'password'}
          validators={['required', 'matchPassword']}
          errorMessages={['this field is required', "Password didn't match"]}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LockIcon sx={{ color: 'var(--clr-gray40)' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPsw ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          className='input-auth'
        />
        
        <Button
          type='submit'
          variant='contained'
          sx={[
            {
              textTransform: 'capitalize',
              fontSize: '1.5rem',
              fontWeight: '700',
              bgcolor: 'var(--clr-btn-cta)',
              '&:hover': {
                bgcolor: 'var(--clr-btn-cta-hover)',
              },
            },
          ]}
        >
          Reset
        </Button>


      

      </Stack>
    </ValidatorForm>
    
  </Stack>

</Box>
}






        <AuthFooter />
      </Box>

      {openModal && <SelectGroupModal open={openModal} />}
    </>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  openSelectGroupModal: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  openSelectGroupModal: state.auth.openSelectGroupModal,
});

export default connect(mapStateToProps, { setAlert, login })(ResetPassword);
