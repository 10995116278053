import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_DATA_HEIGH_ACCESSLEVEL,
  LOGIN_WITH_GROUP,
  LOGIN_FAIL,
  LOGOUT,
  REGISTRANT_SUCCESS,
  REGISTRANT_FAIL,
  CLEAR_REGISTRANT,
  PASSWORD_UPDATED,
  UPDATE_PASSWORD_FAIL,
  PASSWORD_UPDATED_INITIAL_STATE,
  LOGIN_FROM_MOBILE,
  OPEN_SELECT_GROUP_MODAL,
  SET_ACTIVE_PARTICIPANT,
  SET_PASSIVE_PRESENTER_DETAILS,
  PASSWORD_REST_SUCCESS,
  PASSWORD_REST_FAIL,
  PASSWORD_RESET,
  SET_ACTIVE_PARTICIPANT_NAME
} from '../constants/userConstants';

const initialState = {
  // token: localStorage.getItem('cpToken'),
  token: null,
  loggedInUserID: null,
  isAuthenticated: null,
  loading: true,
  userInfo: JSON.parse(localStorage.getItem('cpUserInfo')),
  user: null,
  group: null,
  registration: null,
  passwordUpdated: false,
  isLoginFromMobile: false,
  openSelectGroupModal: false,
  activeParticipant:'',
  passwordReset:null,
  activeParticipantName:'',
  passivePresenter:{
    active:false,
    userName:'',
    loggedInUserID:''
  }
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      console.log('Here',  payload.items[0].company)
      let cpUserInfoLocal = JSON.parse(localStorage.getItem('cpUserInfo'));
      localStorage.setItem(
        'cpUserInfo',
        JSON.stringify({
          ...cpUserInfoLocal,
          // companyID: payload.items[0].company._id,
          companyID: payload.items[0].company,

        })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token.token,
        user: payload.items[0],
        loggedInUserID: payload.items[0]._id,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('cpToken', payload.token.token);
      localStorage.setItem(
        'cpUserInfo',
        JSON.stringify({
          id: payload.items[0]._id,
          token: payload.token.token,
        })
      );
      return {
        ...state,
        ...payload,
        token: payload.token.token,
        loggedInUserID: payload.items[0]._id,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_DATA_HEIGH_ACCESSLEVEL:
      localStorage.setItem('cpToken', payload.token.token);
      localStorage.setItem(
        'cpUserInfo',
        JSON.stringify({
          id: payload.items[0]._id,
          token: payload.token.token,
        })
      );
      return {
        ...state,
        ...payload,
        user: payload.items[0],
        token: payload.token.token,
        loggedInUserID: payload.items[0]._id,
        loading: false,
      };
    case LOGIN_WITH_GROUP:
      let getCpUserInfoLocal = JSON.parse(localStorage.getItem('cpUserInfo'));
      localStorage.setItem(
        'cpUserInfo',
        JSON.stringify({
          ...getCpUserInfoLocal,
          group: payload,
        })
      );
      return {
        ...state,
        group: payload,
        isAuthenticated: true,
        loading: false,
      };
    case OPEN_SELECT_GROUP_MODAL:
      return {
        ...state,
        openSelectGroupModal: true,
        loading: false,
      };
    case REGISTRANT_SUCCESS:
      return {
        ...state,
        // registration: payload,
        registration: true,

        loading: false,
      };
    case PASSWORD_UPDATED:
      return {
        ...state,
        passwordUpdated: payload.success,
      };
    case PASSWORD_REST_SUCCESS:
      return {
      ...state,
      passwordReset:true
      }
    
    case PASSWORD_REST_FAIL:
      return {
      ...state,
      passwordReset:false
      }
    case PASSWORD_RESET:
      return {
        ...state,
        passwordReset:null
      }

    case REGISTRANT_FAIL:
      return {
        ...state,
        registration: null,
      };
    case UPDATE_PASSWORD_FAIL:
    case PASSWORD_UPDATED_INITIAL_STATE:
      return {
        ...state,
        passwordUpdated: false,
      };
    case CLEAR_REGISTRANT:
      return {
        ...state,
        registration: null,
        loading: true,
      };
    case LOGIN_FROM_MOBILE:
      return {
        ...state,
        isLoginFromMobile: true,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('cpToken');
      localStorage.removeItem('cpUserInfo');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        userInfo: null,
        user: null,
        openSelectGroupModal: false,
      };


        //set active participant 

        case SET_ACTIVE_PARTICIPANT:
          return {
            ...state,
            activeParticipant:payload
          }

          case SET_ACTIVE_PARTICIPANT_NAME:
            return {
              ...state,
              activeParticipantName:payload
            }
  

        case SET_PASSIVE_PRESENTER_DETAILS:
          return {
            ...state,
            passivePresenter:{
              active:true,
              userName:payload.userName,
              loggedInUserID:payload._id
            }

          }

    default:
      return state;
  }
}

export default authReducer;
