import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { connect, useDispatch, useSelector } from "react-redux";
import { AccessTimeFilledTwoTone, Autorenew, Close, CloseOutlined, Error } from "@mui/icons-material";
import { resetSocketioError } from "../../actions/socketio";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    display:'flex',
    flexDirection:'column',
    borderRadius:'10px',
    flexGap:'1rem',
    paddingTop:'1rem',
    paddingBottom:'1rem'

};


const ErrorPopUp = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const dispatch = useDispatch()
    const handleClose = () => {
        window.location.reload();
    }
    const socketError = useSelector((state) => state.socketio.socketError);

    const handleReload = ()=>{
        dispatch(resetSocketioError())
        window.location.reload();

    }

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={socketError ? true : false}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Autorenew sx={{ color: '#7CB332', fontSize: '4rem', textAlign: 'center' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                        <Typography>
                        Are you still there on the page?
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center',m:1 }}>

                    <Button variant="contained" color="primary" onClick={handleReload}>
                        Yes
                    </Button>
                    </Box>


                </Box>
            </Modal>
        </div>
    )
}

export default connect()(ErrorPopUp);