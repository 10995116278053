import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

// REDUX
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { loadUser } from './actions/auth';
import { PersistGate } from 'redux-persist/integration/react';

// COMPONENTS
import Landing from './components/layout/Landing';
import Auth from './components/auth/Auth';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Layout from './components/layout/Layout';
import NotFound from './components/utils-pages/NotFound';
import ThankYou from './components/utils-pages/ThankYou';
import CookiesPolicyPage from './components/utils-pages/CookiesPolicyPage';
import AlertCard from './components/UI/AlertCard';
import EventsDashboard from './components/routes/EventsDashboard';
import PrivateRoute from './components/routes/PrivateRoute';

import './App.css';
import './CustomToast.css';
// import 'react-toastify/dist/ReactToastify.css';

import { LOGOUT } from './constants/userConstants';
//import Events from './components/event/Events';
//import Event from './components/event/Event';
import Spinner from './components/UI/Spinner';
import PresenterView from './components/event/questions/PresenterView';
//import PrimusWs from './components/websocket/PrimusWs';

//Toast alerts
import { ToastContainer, toast } from 'react-toastify';
import PendingApproval from './components/utils-pages/PendingApproval';
import ResetPassword from './components/auth/ResetPassword';
import CPManagement from './components/cpManagement';
import { Toaster } from 'react-hot-toast';


const App = () => {
  useEffect(() => {
    console.log('------- central store', store.getState())
    store.dispatch(loadUser());

    // Logout from all tabs if logout in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.cpToken) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <AlertCard />
        <Toaster/>
        <ToastContainer autoClose={3000} hideProgressBar={true} />

        <Routes>
          <Route element={<Auth />}>
            <Route path='/' element={<Landing />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/register' element={<Register />} />

            <Route path='/password-reset/:userID/:token' element={<ResetPassword />} />
            <Route path='/password-reset-inapp' element={<ResetPassword />} />

          </Route>

          <Route element={<Layout />}>

            <Route
              path='/event-dashboard/*'
              element={<PrivateRoute component={EventsDashboard} />}
            />

            <Route
              path='/cp-management'
              element={<PrivateRoute path='root' component={CPManagement} />}
            />

            {/* <PrivateRoute path='/event-dashboard' element={<Events />} />
      <PrivateRoute path='/event-dashboard/edit/:id' element={<Event />} />  */}


            {/* <Route path="/event-dashboard" element={<PrivateRoute component={Events} />} />
      <Route path="/event-dashboard/edit/:id" element={<PrivateRoute component={Event} />} /> */}



          </Route>


          <Route path='/contact-us/thank-you' element={<ThankYou />} />
          <Route path='/pending-approval' element={<PendingApproval />} />

          <Route path='/cookies' element={<CookiesPolicyPage />} />
          <Route path='/presenterView/:token' element={<PresenterView />} />

          <Route path='*' element={<NotFound />} />
        </Routes>
      </PersistGate>

    </Provider>
  );
};

export default App;
