import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { deleteCpUser } from '../../../actions/cpManagement';
import { Typography } from '@mui/material';

function AlertDialog({handleClose, open,userID, text, action}) {
    const dispatch = useDispatch()

    const handleOnAgree = ()=>{
            console.log('selected user', userID)
            dispatch(deleteCpUser(userID.id))
            handleClose()
    }

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Delete user
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {/* Are you sure to delete the user? */}
          </DialogContentText>
          <DialogContentText>
            Name - {userID && userID.name}
          </DialogContentText>
          <DialogContentText>
            Email - {userID &&  userID.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOnAgree} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;