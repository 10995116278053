import React from 'react';
// import { isMobileOnly, isTablet } from 'react-device-detect';
import { useMobileOrientation } from 'react-device-detect';

// MUI
import { Box } from '@mui/system';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// IMG
import logo from '../../static/img/brr-logo.png';

const Description = () => {
  const { isPortrait } = useMobileOrientation();

  return (
    <Box className={isPortrait ? 'portrait-text-description' : undefined}>
      {!isPortrait && (
        <>
          <CardMedia
            component='img'
            height='70'
            image={logo}
            alt='BRR Logo'
            sx={{ objectFit: 'contain', width: 'auto' }}
          />
          <Typography
            gutterBottom
            variant='h6'
            component='h6'
            sx={{
              color: 'var(--clr-gray40)',
              marginTop: 'min(8vh, 200px)',
            }}
          >
            Welcome to
          </Typography>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          gap: '1.25rem',
          marginTop: '1rem',
        }}
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{
            color: 'var(--clr-gray80)',
            fontWeight: '700',
          }}
        >
          BRR Media
        </Typography>
        <Typography
          variant='h5'
          component='h5'
          sx={{
            color: 'var(--clr-gray40)',
            fontWeight: '400',
          }}
        >
          Client Portal
        </Typography>
      </Box>
      <Typography variant='body1' sx={{ marginTop: 'min(3vh, 40px)' }}>
        Through this online portal, you can access your webcast events.
      </Typography>
      <Typography
        variant='body1'
        sx={{
          marginTop: 'min(5vh, 72px)',
        }}
      >
        Please register in order to gain access or, if you have already
        registered, then log in with your email and password credentials. Once
        you have logged in, you will be able to access information relating to
        your webcast events, including the registered viewers and details of
        questions submitted.
      </Typography>
    </Box>
  );
};

export default Description;
