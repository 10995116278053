import { Circle, Close, NotificationAdd, NotificationImportant, Notifications } from "@mui/icons-material";
import { Badge, Box, Button, Container, Grid, IconButton, List, ListItem, ListItemText, Menu, Paper, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearNotification, markAllasRead } from "../../actions/notification";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
      },
  }));
const NotificationsPanel = ()=>{
    const classes = useStyles();
    const dispatch = useDispatch()

    const notifications = useSelector((state) => state.notification.allNotifications);
    const pendingNotfications = notifications.filter((each)=> each.read === false)



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      dispatch(markAllasRead())
      //make notification all read as true
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMarkAsRead = ()=>{
        dispatch(clearNotification())
    }

    return (
        <>
        {/* <Badge badgeContent={pendingNotfications.length>0 ? pendingNotfications.length : 0 } color="secondary"> */}
        <Badge badgeContent={
            pendingNotfications.length>0 ? <span style={{color:'black', background:'yellow',height:20 ,width:20, borderRadius:'50%', textAlign:'center'}}>
                <p style={{marginTop:5}}>  
                {pendingNotfications.length>0 ? pendingNotfications.length : 0}
                </p>
                
                </span>
            : 0 
        } >
                      <Tooltip title='Notification'>

        <Notifications onClick={handleClick} sx={{cursor:'pointer'}}/>
        </Tooltip>
        </Badge>
        
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
     {/* <Box sx={{height:'400px', width:'20vw', background:''}}> */}
        <Container sx={{height:'400px', width:'20vw', background:''}}>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
            <Typography sx={{fontSize:'1.4rem', fontWeight:'bold'}}>
                Notifications
            </Typography>
            <IconButton onClick={handleClose}>
            <Close/>
            </IconButton>
            </Box>

       {
notifications.length>0 &&        <Box sx={{display:'flex',placeContent:'center'}}>
            <Button  
             sx={{
            minWidth: 'max-content',
            borderRadius:'10px',
            padding: '0.8rem',
            textTransform: 'capitalize',
            alignSelf: 'center',
            backgroundColor: '#7CB332',
            color:'white',
            '&:hover': {
              backgroundColor: '#aed581',
              '& h6': {
                color: 'var(--clr-w)',
              },
            },
          }} onClick={handleMarkAsRead}>
                Mark all as read
            </Button>
        </Box>

       }

          {
notifications.length >0 ?

     <List className={classes.root} >

       { notifications.toReversed().map((item, index) => (
            <Box sx={{background:'', m:'0.4rem', borderLeft:'8px solid green',}}>

          <ListItem key={index}>
            <ListItemText primary={item.title} secondary={item.time} />
          </ListItem>
          </Box>

        ))   

        }


      </List> :

<Box sx={{display:'flex', placeContent:'center', placeItems:'center', height:'70%'}}>
<Typography>
    No new notfications
</Typography>
</Box>
          }


    </Container>
        
        {/* </Box> */}
      </Menu>
        
        </>
    )
}

export default connect()(NotificationsPanel);