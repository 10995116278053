import React, { useEffect, useState } from "react";
import { Box, Button, Fade, InputAdornment, Modal, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CheckCircleOutline, MailOutlineOutlined } from "@mui/icons-material";
import { useMobileOrientation } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passwordRest } from "../../actions/auth";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height:'50vh',
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ResetPasswordModal = ({open, handleClose})=>{

    const dispatch = useDispatch();
    const {passwordReset} = useSelector((state) => state.auth);

    const { isPortrait } = useMobileOrientation();
    const [email, setEmail] = useState('')
    const [linkSent, setLinkSent] = useState(false)

    const handleChange = (e) => {
        setEmail(e.target.value.trim());
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(passwordRest({email:email}))

        // login(email, password);
      };

      useEffect(()=>{
            setLinkSent(false)
      },[])
    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          <Box sx={{ minHeight: '100%', display: 'flex', justifyContent:'center' }}>

        <Box sx={{width:'100%'}}>
          <Stack
            spacing={7}
            // className={isPortrait ? 'login__form-container' : undefined}
            sx={{ width: 'auto', paddingTop: '3rem' }}
          >

          {
            passwordReset ?
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>

            <Typography sx={{fontSize:'2rem', textAlign:'center', placeContent:'center'}}>
                Reset Link was sent to you email.
            </Typography>
            <CheckCircleOutline
            sx={{
              fontSize: 'clamp(6rem, 10vw, 12rem)',
              color: 'var(--clr-success)',
            }}
          />
            
            
            </Box>
            :
            <>
          
            <Typography variant='h5' component='h5' sx={{ fontWeight: '700' }}>
              Password Reset
            </Typography>
            <ValidatorForm
            //   className={isPortrait ? 'login__form-group' : undefined}
              onSubmit={handleSubmit}
              onError={(errors) => console.log(errors)}
            >
              <Stack spacing={7}>
                <TextValidator
                  placeholder='Email'
                  name='email'
                  value={email}
                  onChange={(e) => handleChange(e)}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'this field is required',
                    'email is not valid',
                  ]}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <MailOutlineOutlined
                          sx={{ color: 'var(--clr-gray40)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                //   className='input-auth'
                />

                
                <Button
                  type='submit'
                  variant='contained'
                  sx={[
                    {
                      textTransform: 'capitalize',
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      bgcolor: 'var(--clr-btn-cta)',
                      '&:hover': {
                        bgcolor: 'var(--clr-btn-cta-hover)',
                      },
                    },
                  ]}
                >
                  Send Reset Link
                </Button>


              

              </Stack>
            </ValidatorForm>
            </>
          }  
   
          </Stack>

        </Box>



      </Box>
          </Box>
        </Fade>
      </Modal>
    )

}

export default ResetPasswordModal;