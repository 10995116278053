import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// MUI
import Box from '@mui/material/Box';

// Components
import Spinner from '../UI/Spinner';

const Landing = ({ isAuthenticated, loading }) => {
  // console.log('LOADING', loading);

  // if (isAuthenticated) {
  //   console.log('AUTH IN LANDING 1', isAuthenticated);
  //   return <Navigate to='/event-dashboard' />;
  // } else {
  //   console.log('AUTH IN LANDING 2', isAuthenticated);
  // }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {loading && <Spinner />}
      {isAuthenticated ? (
        <Navigate to='/event-dashboard' />
      ) : (
        <Navigate to='/login' />
      )}
    </Box>
  );
};

Landing.propTypes = {
  loading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
