import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, styled, TextField, Tooltip } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { addNewQuestion, addQuestion, deleteMessage, postNewSplitMessage } from "../../../../actions/splitQuestions";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ContentCopy } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 9000,
};


const SplitButton = styled(Button)(({ theme }) => ({
  // marginTop: '0.2rem',
  backgroundColor: 'var(--clr-gray40)',
  color: 'var(--clr-gray10)',
  '&:hover': {
    backgroundColor: 'var(--clr-gray80)',
  },
}))

const PresenterLinkModal = ({ open, handleClose, setOpen, eventid, link, clearCopyText }) => {



  useEffect(()=>{
      console.log('token from modal typeof', typeof(link) )
      console.log('token from modal',(link) )

  },[link])



  const eventID = useSelector((state) => state.event.event.items[0]._id)

  const [copied, setCopied] = useState(false)

  const dispatch = useDispatch();


 

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ background: "#dcdde1", fontSize: "1rem", textAlign: 'center' }}
            p={2}
          >
            Passive presenter link
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '1rem' }}>

            <Box sx={{display:'flex'}}> 

            <Typography sx={{ wordWrap: 'break-word', m: 2 }} >
              {
                // ` http://localhost:3000/presenterView/${link}`
                
                link ? link.link : ''
                
              }
            </Typography>


        


        <CopyToClipboard text={link?  link.link : ''}

onCopy={() => setCopied(true)}>
{/* <Button variant="outlined">Copy to clipboard</Button> */}
<Tooltip title="Copy Link">

<IconButton>
              <ContentCopy/> 
            </IconButton>
            </Tooltip>
</CopyToClipboard>

              </Box>


            
            <Typography sx={{m:2}}>
              Expires at - {link ?  new Date(link.expiresIn).toLocaleDateString() : ''}
              </Typography>


          </Box>


          <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'center' }} spacing={2} m={2}>

          {

<Typography sx={{ textAlign: 'center' }}>
  {copied ? (<span style={{ color: 'red' }}>Copied</span>) : ''}

</Typography>
}
            </Stack>
        </Box>

      </Modal>
    </div>
  );
};

export default connect()(PresenterLinkModal);