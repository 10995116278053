import axios from "axios";

export async function getActiveParticipantName(userID) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/account/${userID}/?token=${process.env.REACT_APP_API_TOKEN}`)
        if(response.data.success) {
            let name = response.data.items[0].name
            return  `${name.first + ' ' +  name.last}`
        } else {
            return null
        }
    } catch (e) {
        console.log(e)
    }
  
}
