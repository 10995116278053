import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// REDUX
import { clearRegister } from '../../actions/auth';

// MUI
import MuiLink from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Avatar from '@mui/material/Avatar';

// COMPONENTS
import BRRSocialFollow from '../UI/inputs/BRRSocialFollow';

// IMG
import logo from '../../static/img/brr-logo.png';

import './ThankYou.css';
import { Pending, PendingActions } from '@mui/icons-material';

const NoEventsPage = ({ auth: { registration }, clearRegister }) => {
  return (
    <>


      <div className='thank-you_container'>

        <div className='thank-you_content'>

          {/* <Typography variant='h4'>We just received your message.</Typography> */}
          <Typography variant='h5' align='center'>
            No Events Attached
          </Typography>
       
        </div>
  
      </div>
    </>
  );
};

NoEventsPage.propTypes = {
  auth: PropTypes.object.isRequired,
  clearRegister: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { clearRegister })(NoEventsPage);
