import React, { useState, useEffect } from 'react';
import { BASE_PREVIEW_EVENT_URL, BASE_VIEW_EVENT_URL } from '../../../config';

// MUI
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#fff',
    border: '2px solid var(--clr-success60)',
    color: '#4A4A4A',
    fontSize: '12px',
  },
}));

const ViewEventBtn = ({ icon, eventId, preview = false, backgroundClr }) => {
  const [muiIcon, setMuiIcon] = useState();
  const [baseUrl, setBaseUrl] = useState();
  const [btnText, setBtnText] = useState('');
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    if (preview) {
      setBaseUrl(BASE_PREVIEW_EVENT_URL);
      setBtnText('Review Event');
      setTooltipText('Click to preview event');
    } else {
      setBaseUrl(BASE_VIEW_EVENT_URL);
      setBtnText('Live Event');
      setTooltipText('Click to view event');
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (icon === 'preview') setMuiIcon(<VisibilityIcon />);
    else setMuiIcon(<OpenInNewIcon />);

    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <Tooltip
      title={tooltipText}
      placement='left-start'
      classes={{ tooltip: classes.tooltip }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-15, -10],
            },
          },
        ],
      }}
    >
      <Button
        href={`${baseUrl}${eventId}`}
        target='_blank'
        rel='noopener'
        variant='contained'
        color='inherit'
        sx={{
          height: '30px',
          justifyContent: 'center',
          gap: '5px',
          minWidth: '130px',
          padding: '12px 12px 12px 8px',
          backgroundColor: `${backgroundClr}`,
          textTransform: 'capitalize',
          '& svg': {
            width: '20px',
            height: '0.8rem',
          },

          '&:hover': {
            color: 'var(--clr-w)',
            backgroundColor: `${backgroundClr}d9`,
          },
        }}
      >
        {muiIcon}
        <Typography variant='body2'>{btnText}</Typography>
      </Button>
    </Tooltip>
  );
};

export default ViewEventBtn;
