import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Menu } from '@mui/icons-material';
import Sidebar from './Sidebar';

export default function SideDrawer() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            //   sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Sidebar />

        </Box>
    );

    return (
        <div>
            <Menu onClick={toggleDrawer('left', true)} sx={{ cursor: 'pointer', fontSize: 'xlarge', color: 'var(--clr-w)', height: '2rem', width: '2rem' }} />

            <React.Fragment key={'anchor'}>
                <SwipeableDrawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    onOpen={toggleDrawer('left', true)}
                >
                    {list('left')}

                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}